import {ObjectMapperHelper} from "../../core/object-mapper-helper";
import {UUID} from "../uuid";

export class IncidentDocument {
  readonly id: UUID
  readonly incidentId: UUID
  readonly customerId: UUID
  readonly providerId: UUID
  readonly filename: string
  readonly mimeType: string
  readonly description: string
  readonly isActive: boolean
  readonly deletedAt: Date
  readonly updatedAt: Date
  readonly createdAt: Date
  readonly createdBy: UUID
  readonly updatedBy: UUID

    constructor(data: any) {
        const helper = ObjectMapperHelper.of(data);
        this.id = helper.getUUID("id")
        this.incidentId = helper.getUUID("incidentId")
        this.customerId = helper.getUUID("customerId")
        this.providerId = helper.getUUID("providerId")
        this.filename = helper.getString("filename")
        this.mimeType = helper.getString("mimeType")
        this.description = helper.getString("description")
        this.isActive = helper.getBoolean("isActive")
        this.deletedAt = helper.getDate("deletedAt")
        this.updatedAt = helper.getDate("updatedAt")
        this.createdAt = helper.getDate("createdAt")
        this.createdBy = helper.getUUID("createdBy")
        this.updatedBy = helper.getUUID("updatedBy")
    }
}
