import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {catchError, finalize, forkJoin, Observable, of, Subscription, switchMap, tap,} from "rxjs";
import {CanDeactivateComponent} from "../../core/can-deactivate-guard";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {EnvironmentService} from "../../core/environment-service";
import {LanguageCountry} from "../../domain/language-country";
import {MaskUtil} from "../../core/mask-util";
import {Asset} from "../../domain/asset/asset";
import {UUID} from "../../domain/uuid";
import {CreateUpdateIncident, Incident, IncidentStatus, IncidentType,} from "../../domain/incident/incident";
import {IncidentService} from "../../domain/incident/incident-service";
import {SlaDurationService} from "../../domain/sla-duration/sla-duration-service";
import {PriorityLevelService} from "../../domain/priority_level/priority-level-service";
import {ServiceCatGroupService} from "../../domain/service-cat-group/service-cat-group-service";
import {ServiceCategoryService} from "../../domain/service_category/service-category-service";
import {ServiceCatGroup} from "../../domain/service-cat-group/service-cat-group";
import {ServiceCategory} from "../../domain/service_category/service-category";
import {PriorityLevel} from "../../domain/priority_level/priority-level";
import {SlaDuration} from "../../domain/sla-duration/sla-duration";
import {EventUtil} from "../../core/event-util";
import {DialogService} from "../../shared/dialogs/dialog-service";
import {Facility} from "../../domain/facility/facility";
import {FileData} from "../../core/files-upload/file-data";
import {SnackbarService} from "../../shared/snackbar/snackbar-service";
import {DocumentTypeEnum} from "../../domain/document/document";
import {DocumentService} from "../../domain/document/document-service";
import {SilentError} from "../../core/error/silent-error";
import {FacilityStorageService} from "../../core/storage/facility-storage-service";
import {Booking} from "../../domain/booking/booking";

interface IncidentForm {
  title: FormControl<string>
  description: FormControl<string>
  type: FormControl<string>
  serviceCatGroupId: FormControl<UUID | string>
  serviceCategoryId: FormControl<UUID | string>
  slaDurationId: FormControl<UUID | string>
  deadlineEndMinutes: FormControl<number>
  priorityLevelId: FormControl<UUID | string>
  status: FormControl<string>
  assets: FormArray
  contactName: FormControl<string>
  contactEmail: FormControl<string>
  contactPhone: FormControl<string>
}

@Component({
  templateUrl: './incident-edit.component.html',
})
export class IncidentEditComponent implements OnInit, CanDeactivateComponent, OnDestroy {
  maskUtil = new MaskUtil();
  protected readonly faSpinner = faSpinner;
  triggerClick = EventUtil.triggerClick;
  public incident: Incident;
  inProgress = true;
  form: FormGroup<IncidentForm>;
  countriesList: LanguageCountry[] = [];
  subscription = new Subscription();
  title = "";
  incidentTypes: string[] = Object.keys(IncidentType).map(key => IncidentType[key]);
  incidentStatus: string[] = Object.keys(IncidentStatus).map(key => IncidentStatus[key]);

  serviceCatGroup: ServiceCatGroup[] = []

  serviceCategory: ServiceCategory[] = []
  serviceCategoryFiltered: ServiceCategory[] = []


  priorityLevels: PriorityLevel[] = []

  slas: SlaDuration[] = []

  assets: Asset[] = []

  selectedAssets: Asset[] = [];

  facility: Facility | null;

  allFiles: FileData[] = [];

  constructor(private fb: FormBuilder,
              private environmentService: EnvironmentService,
              private incidentService: IncidentService,
              private router: Router,
              private route: ActivatedRoute,
              private slaDurationService: SlaDurationService,
              private priorityLevelService: PriorityLevelService,
              private serviceCatGroupService: ServiceCatGroupService,
              private serviceCategoryService: ServiceCategoryService,
              private dialogService: DialogService,
              private facilityStorageService: FacilityStorageService,
              private documentService: DocumentService,
              private snackbarService: SnackbarService,
  ) {
  }

  ngOnInit() {
    this.countriesList = this.environmentService.langCountry;
    const incidentId = this.route.snapshot.paramMap.get('id');

    this.subscription.add(this.facilityStorageService.currentFacility$.subscribe(facility => {
      this.facility = facility;
    }))

    this.setTitle(incidentId === "new");

    this.getInitialData(incidentId);
  }


  getBooking(): Booking {
    if (this.incident?.booking){
      return this.incident?.booking
    }

    return {
      customerId: this.facility?.customerId,
      facilityId: this.facility?.id,
      incidentId: this.incident?.id,
      completedAt: null,
      createdAt: null,
      createdBy: null,
      deletedAt: null,
      description: null,
      expectedCompletionDate: null,
      deadlineEndDate: this.incident?.deadlineEndAt,
      id: this.incident?.bookingId,
      isActive: true,
      providerId: null,
      status: null,
      tenantId: null,
      title: null,
      updatedAt: null,
      updatedBy: null
    }
  }


  getInitialData(incidentId: string) {
    forkJoin(
      {
        incident: this.getInitDataOfIncident(incidentId),
        serviceCatGroup: this.serviceCatGroupService.all(),
        serviceCategory: this.serviceCategoryService.all(),
        priorityLevels: this.priorityLevelService.all(),
        slas: this.slaDurationService.all(),
      }
    ).subscribe({
      next: ({
        incident,
        serviceCatGroup,
        serviceCategory,
        priorityLevels,
        slas
      }) => {
        this.serviceCatGroup = serviceCatGroup;
        this.serviceCategory = serviceCategory;
        this.priorityLevels = priorityLevels;
        this.slas = slas;
        this.inProgress = false;
        this.initForm();
      },
      complete: () => {
        this.inProgress = false;
        this.initForm();
      }
    })

  }

  getInitDataOfIncident(incidentId: string): Observable<Incident>{
    if(!incidentId || incidentId === "new"){
      return of(null)
    }

    return this.incidentService.byId(incidentId).pipe(
      tap((resp) => {
        this.incident = resp;

        this.selectedAssets = resp.assets;
        this.allFiles = resp.documents.map((doc) => new FileData(
          doc.filename,
          "",
          doc.mimeType,
          null,
          true,
          null,
          doc.id,
        ));


      })
    );
  }


  setTitle(isNewTenant: boolean): void {
    this.title = isNewTenant ? "COMMON_NEW" : "COMMON_EDIT";
  }

  initForm(): void {
    const incident = this.incident;
    this.form = this.fb.group({
      title: [incident?.title ?? "", Validators.required],
      description: [incident?.description ?? "", Validators.required],
      type: [incident?.type ?? "", Validators.required],
      serviceCatGroupId: [incident?.serviceCatGroupId ?? null, Validators.required],
      serviceCategoryId: [incident?.serviceCategoryId ?? null, Validators.required],
      slaDurationId: [incident?.slaDurationId ?? null, Validators.required],
      deadlineEndMinutes: [40, Validators.required],
      priorityLevelId: [incident?.priorityLevelId ?? null, Validators.required],
      status: [incident?.status ?? IncidentStatus.New.toString(), Validators.required],
      assets: this.fb.array([]),
      contactName: [incident?.contactName ?? "", Validators.required],
      contactEmail: [incident?.contactEmail ?? "", Validators.required],
      contactPhone: [incident?.contactPhone ?? "", Validators.required],
    });

    this.listenerForServiceCatGroupChange();

    if (!incident) {
      this.form.controls.status.disable();
    }

  }

  listenerForServiceCatGroupChange() {
    if (this.incident?.serviceCatGroupId) {
      this.serviceCategoryFiltered = this.serviceCategory.filter((sc) => sc.serviceCatGroupId === this.incident.serviceCatGroupId);
    }

    this.form.controls.serviceCatGroupId.valueChanges.subscribe((value) => {
      this.serviceCategoryFiltered = this.serviceCategory.filter((sc) => sc.serviceCatGroupId === value);
      this.form.controls.serviceCategoryId.reset();
    })
  }


  async save(): Promise<void> {
    if (this.form.invalid) {
      return;
    }
    this.inProgress = true;

    const isUpdate = !!this.incident?.id;

    const incidentData : Partial<CreateUpdateIncident> = {
      ...this.form.getRawValue(),
      status: this.form.get('status').value as IncidentStatus,
      type: this.form.get('type').value as IncidentType,
      customerId: this.facility.customerId,
      facilityId: this.facility.id,
      assetIds: this.selectedAssets.map(asset => asset.id)
    };

    const selectedMethod = isUpdate ?
      this.incidentService.update(incidentData, this.incident.id) :
      this.incidentService.create(incidentData);


    selectedMethod.pipe(
      switchMap((incident) =>
       this.documentService.uploadDocuments(
        this.allFiles,
        DocumentTypeEnum.IncidentAttachment,
        null,
        this.facility.customerId,
        null,
        incident.id,
      )),
      catchError(error => {
      this.inProgress = false;
      throw error;
    })).subscribe( resp => {
      this.allFiles = resp;
      this.inProgress = false;

      const fileNameOfFailed = resp.filter(file => file.requestFailed).map(file => file.file.name).join(", ");
      if (fileNameOfFailed.length > 0) {
        this.dialogService.errorDialog("INCIDENT_SAVED_BUT_ALL_DOCUMENTS_COULD_NOT_BE_STORED", false, "INCIDENT_SAVED");
        throw new SilentError(500, "Unable to save incident documents: " + fileNameOfFailed, "INCIDENT_SAVED_BUT_ALL_DOCUMENTS_COULD_NOT_BE_STORED");
      } else {
        this.snackbarService.success("INCIDENT_SAVED");
        this.navigateBack();
      }
    }, finalize(() => {

    }));
  }

  private navigateBack() {
    this.router.navigate(["events/incidents"]).then(/* DO NOTHING */);
  }

  canSave(): boolean {
    return this.form != null && this.form.dirty && this.form.valid && !this.inProgress;
  }

  cancel() {
    this.navigateBack();
  }

  canDeactivateComponent(): boolean {
    return (this.form && this.form.dirty) ?? false;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  removeAsset(id: UUID) {
    this.selectedAssets = this.selectedAssets.filter(asset => asset.id !== id);
    this.form.markAsDirty();
  }

  openDialog() {
    this.dialogService.openSelectAssetDialog(this.selectedAssets).subscribe((assets) => {
      if (!assets) return;
      this.selectedAssets = assets;
      this.form.markAsDirty();
    });
  }


  showAssetDetails(asset: Asset) {
    this.dialogService.openAssetDialog(asset).subscribe();
  }


  onFileListChange(files: FileData[]): void {
    this.form.markAsDirty();
    this.allFiles = files;
  }

  protected readonly DocumentTypeEnum = DocumentTypeEnum;
}
