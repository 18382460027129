import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormGroup} from "@angular/forms";
import {Subscription} from "rxjs";
import {CanDeactivateComponent} from "../../../core/can-deactivate-guard";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {EnvironmentService} from "../../../core/environment-service";
import {LanguageCountry} from "../../../domain/language-country";
import {Asset} from "../../../domain/asset/asset";
import {EventUtil} from "../../../core/event-util";
import {AssetService} from "../../../domain/asset/asset-service";
import {UUID} from "../../../domain/uuid";
import {thirdFormGroup} from "./wizard-incident-main.component";
import {DialogService} from "../../../shared/dialogs/dialog-service";

@Component({
  selector: 'wizard-incident-asset-form',
  templateUrl: './wizard-incident-asset-form.component.html',
})
export class WizardIncidentAssetFormComponent implements OnInit, CanDeactivateComponent, OnDestroy {
  triggerClick = EventUtil.triggerClick;
  protected readonly faSpinner = faSpinner;
  inProgress = false;

  assets: Asset[] = []

  selectedAssets: Asset[] = [];

  @Input()
  form: FormGroup<thirdFormGroup>;

  @Output()
  onSelectedAssetChange = new EventEmitter<Asset[]>();


  countriesList: LanguageCountry[] = [];
  subscription = new Subscription();
  constructor(private fb: FormBuilder,
              private environmentService: EnvironmentService,
              private assetService: AssetService,
              private router: Router,
              private route: ActivatedRoute,
              private dialogService: DialogService){
  }

  ngOnInit() {
    this.countriesList = this.environmentService.langCountry;

  }

  openDialog() {
    this.dialogService.openSelectAssetDialog(this.selectedAssets).subscribe((assets) => {
      if(!assets) return;
      this.selectedAssets = assets;
      this.onSelectedAssetChange.emit(assets);
    });
  }

  removeAsset(id: UUID){
    this.selectedAssets = this.selectedAssets.filter(asset => asset.id !== id);
    this.onSelectedAssetChange.emit(this.selectedAssets);
  }



  private navigateBack() {
    const url =  'events/incidents';
    this.router.navigate(["/" + url]).then(/* DO NOTHING */);
  }

  canSave(): boolean {
    return this.form != null && this.form.dirty && this.form.valid && !this.inProgress;
  }

  cancel() {
    this.navigateBack();
  }

  toggleSort(sort: string): void {
    console.log('sort:', sort)
  }

  canDeactivateComponent(): boolean {
    return (this.form && this.form.dirty) ?? false;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  handleShowAsset(asset: Asset) {
    this.dialogService.openAssetDialog(asset);
  }
}
