<mat-form-field appearance="outline" class="w-full">
  <mat-label>{{"LOGIN_PASSWORD" | translate}}</mat-label>
  <input matInput [formControl]="control" [type]="hide ? 'password' : 'text'"
         placeholder="{{'LOGIN_PASSWORD' | translate}}">
  <button type="button" mat-icon-button matSuffix class="password-toggle" (click)="hide = !hide"
          [attr.aria-label]="hide ? 'Show password' : 'Hide password'" [attr.aria-pressed]="hide">
    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
  </button>
  <mat-error *ngIf="control.getError('minLength')">
    {{'PASSWORD_ERROR_MIN_LENGTH' | translate}}
  </mat-error>
  <mat-error *ngIf="control.getError('upperCase')">
    {{'PASSWORD_ERROR_UPPER_CASE' | translate}}
  </mat-error>
  <mat-error *ngIf="control.getError('lowerCase')">
    {{'PASSWORD_ERROR_LOWER_CASE' | translate}}
  </mat-error>
  <mat-error *ngIf="control.getError('numeric')">
    {{'PASSWORD_ERROR_NUMERIC' | translate}}
  </mat-error>
  <mat-error *ngIf="control.getError('specialChar')">
    {{'PASSWORD_ERROR_SPECIAL_CHAR' | translate}}
  </mat-error>
</mat-form-field>




