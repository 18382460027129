import {Component} from "@angular/core";
import {UserRole} from "../../domain/user/user-role";
import {Router} from "@angular/router";
import {UserService} from "../../domain/user/user-service";
import {AppRoutes} from "../../app-routing.module";

interface AdminPages {
  title: string;
  route: string;
  role: UserRole;
}

@Component({
  templateUrl: './admin-management.component.html',
})
export class AdminManagementComponent {
  pages: AdminPages[] = [
    {title: 'TENANTS', route: 'tenants', role: UserRole.SuperAdmin},
    {title: 'CUSTOMERS', route: 'customers', role: UserRole.SuperAdmin},
  ];

  constructor(private router: Router, private userService: UserService) {

  }

  checkRole(page: AdminPages): boolean {
    return this.userService.hasRequiredRole(page.role);
  }

  navigateTo(card: AdminPages) {
    const path = `/${AppRoutes.ADMIN_MANAGEMENT}/${card.route}`
    this.router.navigate([path]).then(/* DO NOTHING */);
  }
}
