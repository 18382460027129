import {Injectable} from "@angular/core";
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from "@ngx-translate/core";
import {EnvironmentService} from "../../core/environment-service";

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(
    private _snackBar: MatSnackBar,
    private translationService: TranslateService,
    private environmentService: EnvironmentService
  ) {
    translationService.use(this.environmentService.language ?? "sv")
  }

  error(key: string): void {
    const message = this.translationService.instant(key);
    this._snackBar.open(message, null, {
      panelClass: ['error-snackbar'],
      duration: 2000
    })
  }

  success(key: string): void {
    const message = this.translationService.instant(key)
    this._snackBar.open(message, null, {
      panelClass: ['success-snackbar'],
      duration: 2000
    })
  }
}
