import {GlobalStorageAction, GlobalStorageService} from "./global-stroage-service";
import {BehaviorSubject, filter, Observable, switchMap, take} from "rxjs";
import {Injectable} from "@angular/core";
import {Facility} from "../../domain/facility/facility";
import {storageKeys} from "../storage-helper";


@Injectable(
  {providedIn: 'root'}
)
export class FacilityStorageService {
  currentFacility: Facility | null = null;
  facilities: Facility[] | null = null;

  currentFacilitySubject: BehaviorSubject<Facility>;
  private internalCurrentFacility$: Observable<Facility | null>;

  facilitiesSubject: BehaviorSubject<Facility[]> = new BehaviorSubject<Facility[]>(null);
  facilities$: Observable<Facility[] | null> = this.facilitiesSubject.asObservable();

  private initInitialized = new BehaviorSubject<boolean>(false);
  private initInitialized$ = this.initInitialized.asObservable();

  private initialized = false;
  constructor(
    private globalStorageService: GlobalStorageService,
  ) {
    this.initCurrentFacility();

    this.initInitialized.next(true);
    this.initialized = true;

    this.globalStorageService.action$.subscribe(action => {
      if (action === GlobalStorageAction.CLEAR){
        this.clear();
      }
    });
  }

  private initCurrentFacility() {
    const facilityRaw = localStorage.getItem(storageKeys.facility);
    this.currentFacility = facilityRaw ? new Facility(JSON.parse(facilityRaw)) : null;

    this.currentFacilitySubject = new BehaviorSubject<Facility>(this.currentFacility);
    this.internalCurrentFacility$ = this.currentFacilitySubject.asObservable();
  }

  public get currentFacility$(): Observable<Facility> {
    if (!this.initialized){
      return this.initInitialized$.pipe(
        filter((initialized) => initialized),
        take(1),
        switchMap(() => this.internalCurrentFacility$)
      );
    }
    return this.internalCurrentFacility$;
  }


  public setCurrentFacility(facility: Facility | null) {
    this.currentFacility = facility;
    this.currentFacilitySubject.next(this.currentFacility);
    if (facility) {
      localStorage.setItem(storageKeys.facility, JSON.stringify(facility));
    } else {
      localStorage.removeItem(storageKeys.facility);
    }
  }

  private clear() {
    this.setCurrentFacility(null);
  }
}
