import {Component, EventEmitter, Input, Output} from "@angular/core";
import {FormGroup} from "@angular/forms";
import {EventUtil} from "../../../core/event-util";
import {FirstFormGroup} from "./wizard-incident-main.component";
import {IncidentType} from "../../../domain/incident/incident";
import {FileData} from "../../../core/files-upload/file-data";
import {DocumentTypeEnum} from "../../../domain/document/document";


@Component({
  selector: 'wizard-incident-basic-form',
  templateUrl: './wizard-incident-basic-form.component.html',
})
export class WizardIncidentBasicFormComponent {
  triggerClick = EventUtil.triggerClick;

  @Input()
  allFiles: FileData[] = [];

  @Input()
  form: FormGroup<FirstFormGroup>;

  @Output()
  onFileListChange = new EventEmitter<FileData[]>();

  incidentTypes: string[] = Object.keys(IncidentType).map(key => IncidentType[key]);

  constructor() {}

  handleOnFileListChange(files: FileData[]) {
    this.onFileListChange.emit(files);
  }

  protected readonly DocumentTypeEnum = DocumentTypeEnum;
}
