import {Component, OnDestroy, OnInit} from "@angular/core";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Country} from "../../domain/language-country";
import {finalize, first, Subscription} from "rxjs";
import {EnvironmentService} from "../../core/environment-service";
import {ActivatedRoute, Router} from "@angular/router";
import {FormUtil} from "../../core/form-util";
import {Facility} from "../../domain/facility/facility";
import {FacilityService} from "../../domain/facility/facility-service";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {CanDeactivateComponent} from "../../core/can-deactivate-guard";
import {CustomerService} from "../../domain/customer/customer-service";
import {Customer} from "../../domain/customer/customer";
import {AppRoutes, CheckIfAdminRoute} from "../../app-routing.module";
import {Paginator} from "../../core/paginator";


interface AddressForm {
  street: FormControl<string>;
  state: FormControl<string>;
  postalCode: FormControl<string>;
  city: FormControl<string>;
  country: FormControl<string>;
}

interface FacilityForm {
  name: FormControl<string>;
  description: FormControl<string>;
  address: FormGroup<AddressForm>;
  contactName: FormControl<string>;
  contactPhone: FormControl<string>;
  contactEmail: FormControl<string>;
  latitude: FormControl<number>;
  longitude: FormControl<number>;
  customerId?: FormControl<string>;
}

@Component({
  templateUrl: 'facility-edit-admin.component.html'
})
export class FacilityEditAdminComponent implements OnInit, CanDeactivateComponent, OnDestroy {
  protected readonly faSpinner = faSpinner;
  customer: Customer;
  facility: Facility | null = null;
  inProgress = false;
  form: FormGroup<FacilityForm>;
  countriesList: Country[] = [];
  subscription = new Subscription();
  title = "";

  googleMap: { apiKey: string;  };

  appAdminRoute: AppRoutes;
  customers: Customer[] = [];

  constructor(private fb: FormBuilder,
              private environmentService: EnvironmentService,
              private facilityService: FacilityService,
              private customerService: CustomerService,
              private router: Router,
              private route: ActivatedRoute) {
    this.googleMap = environmentService.maps;
    
  }


  ngOnInit() {
    this.countriesList = this.environmentService.countries;
    const facilityId = this.route.snapshot.paramMap.get('id');

    this.customerService.currentCustomer$.pipe(first()).subscribe(customer => {
      this.customer = customer;
    });

    this.fetchCustomersIfAdminRoute();

    if (facilityId === "new") {
      this.initForm();
      this.setTitle(true);
      return;
    }
    this.setTitle(false);
    this.facilityService.by(facilityId!).subscribe(facility => {
      this.facility = facility
      this.initForm();
    });
  }


  fetchCustomersIfAdminRoute(){
    const appAdminRoute = CheckIfAdminRoute(this.router.url);
    this.appAdminRoute = appAdminRoute;

    const isAdminRoute = !!appAdminRoute;

    if (isAdminRoute) {
      const paginator = new Paginator<Customer>("name");
      paginator.setTotal(400);
      this.customerService.all("", paginator).subscribe(resp => {
        this.customers = resp.items;
      });
    }
  }


  setTitle(isNewTenant: boolean): void {
    this.title = isNewTenant ? "COMMON_NEW" : "COMMON_EDIT";
  }

  initForm(): void {
    const facility = this.facility;
    const countryCode = facility?.address.country ?? this.environmentService.countryDefaultCode

    this.form = this.fb.group({
      name: [facility?.name, Validators.required],
      description: [facility?.description, Validators.required],
      address: this.fb.group({
        street: [facility?.address.street],
        state: [facility?.address.state],
        postalCode: [facility?.address.postalCode ?? ""],
        city: [facility?.address.city ?? ""],
        country: [facility?.address.country ?? this.environmentService.countryDefaultCode],
      }),
      contactName: [facility?.contactName, Validators.required],
      contactPhone: [facility?.contactPhone, [FormUtil.validatePhone(countryCode), Validators.required]],
      contactEmail: [facility?.contactEmail, [Validators.email, Validators.required]],
      latitude: [facility?.latitude],
      longitude: [facility?.longitude],
    });

    if (this.appAdminRoute) {
      this.form.addControl('customerId', new FormControl(facility?.customerId, Validators.required));
    }

    this.subscription.add(
      this.form.get("address.country")!.valueChanges.subscribe(countryCode => {
        if (countryCode != null) {
          this.form!.get("contactPhone")!.setValidators(FormUtil.validatePhone(countryCode));
          this.form!.get("contactPhone")!.updateValueAndValidity();
        }
      })
    );
  }

  save(): void {
    if (this.form?.invalid) {
      return;
    }
    this.inProgress = true;
    const data: Partial<Facility> = {
      ...this.form?.getRawValue(),
      customerId: this.form.controls.customerId?.value || this.facility?.customerId
    };

    this.facilityService.createOrUpdate(this.facility?.id, data)
      .pipe(finalize(() => this.inProgress = false))
      .subscribe(() => {
        this.form?.markAsPristine();
        this.navigateBack();
      });
  }

  private navigateBack() {
    if (this.appAdminRoute) {
      const url = this.appAdminRoute + '/facilities';
      this.router.navigate(["/" + url]).then(/* DO NOTHING */);
    }
  }

  canSave(): boolean {
    return this.form != null && this.form.dirty && this.form.valid && !this.inProgress;
  }

  cancel() {
    this.router.navigate([`/${AppRoutes.ADMIN_CUSTOMER}/facilities`]).then(/* DO NOTHING */);
  }

  canDeactivateComponent(): boolean {
    return this.form?.dirty ?? false;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  isEditing() {
    return this.facility != null && this.facility.id != null;
  }

  remove() {
    this.inProgress = true;
    this.facilityService.remove(this.facility)
      .pipe(finalize(() => this.inProgress = false))
      .subscribe(() => {
        this.form?.markAsPristine();
        this.router.navigate(['./admin/facilities']).then(/* DO NOTHING */);
      });
  }

  openDirections(): void {
    const isiPhone = navigator.userAgent.toLowerCase().includes('iphone');
    const isiPad = navigator.userAgent.toLowerCase().includes('ipad');

    const destination = `${this.facility.latitude},${this.facility.longitude}`;

    let url =  `https://www.google.com/maps/dir/?api=1&destination=${destination}`;

    if  (isiPhone || isiPad) {
       url = `http://maps.apple.com/?q=${destination}`;
    }

    window.open(url, '_blank');
  }

  get mapUrl() {
    const coordinates = `${this.facility.latitude},${this.facility.longitude}`;
    const labelParam = `label:|${coordinates}`;
    return `https://maps.googleapis.com/maps/api/staticmap?center=${coordinates}&markers=${labelParam}&zoom=15&size=600x300&key=${this.googleMap.apiKey}`
  }
}
