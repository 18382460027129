import {ApiService} from "../../core/api-service";
import {RespWrapper} from "../resp-wrapper";
import {catchError, forkJoin, map, Observable, of} from "rxjs";
import {UUID} from "../uuid";
import {UrlBuilder} from "../url-builder";
import {DocumentTypeEnum, GetPreSignedUrl, ResponseDocument, ResPreSignedUrl, UploadDocument} from "./document";
import {Injectable} from "@angular/core";
import {FileData} from "../../core/files-upload/file-data";

@Injectable(
  {providedIn: 'root'}
)
export class DocumentService {
  apiPath = '/v1/documents';


  constructor(private apiService: ApiService) {
  }


  public getPreSignedUrl(data: GetPreSignedUrl): Observable<ResPreSignedUrl[]> {
    const url = `${this.apiPath}/pre-signed-urls`
    return this.apiService.post<RespWrapper<ResPreSignedUrl[]>>(url, data).pipe(
      map((resp) => {
        return resp.data.map(d => new ResPreSignedUrl(d))
      })
    )
  }


  uploadDocuments(
    files: FileData[],
    documentType: DocumentTypeEnum,
    tenantId?: UUID,
    customerId?: UUID,
    providerId?: UUID,
    incidentId?: UUID,
  ): Observable<FileData[]> {

    // Needed due to the fact that we are using forkJoin, if array is empty the subscription will not be called
    const filteredFiles = files.filter(fileData => !fileData.storedFile)
    if (filteredFiles.length === 0) {
      return of(files)
    }

    return forkJoin(files.map(fileData => {
      if (fileData.storedFile){
        return of(fileData)
      }

      const doc = new UploadDocument();
      doc.file = fileData.file;
      doc.documentType = documentType;
      doc.customerId = customerId;
      doc.incidentId = incidentId;
      doc.tenantId = tenantId;
      doc.providerId = providerId;

      return this.create(doc).pipe(map((resp) => {
          return new FileData(
            resp.fileName,
            "",
            resp.mimeType,
            null,
            true,
           null,
            resp.documentId,
          );
        }),
        catchError(error => {
          fileData.requestFailed = true;
          return of(fileData)
        }))
    }))
  }


  create(doc: UploadDocument): Observable<ResponseDocument> {
    const url = UrlBuilder.of(`${this.apiPath}/upload`).toUrl();
    const formData: FormData = new FormData();
    formData.append('file', doc.file, doc.file.name);
    formData.append('documentType', doc.documentType)


    if (doc.customerId) {
      formData.append('customerId', doc.customerId)
    }
    if (doc.incidentId) {
      formData.append('incidentId', doc.incidentId)
    }

    if (doc.tenantId) {
      formData.append('tenantId', doc.tenantId)
    }
    if (doc.providerId) {
      formData.append('providerId', doc.providerId)
    }
    if (doc.description) {
      formData.append('description', doc.description)
    }

    return this.apiService.postFormData<RespWrapper<ResponseDocument>>(url, formData).pipe(
      map((resp) => new ResponseDocument(resp.data))
    )
  }

  remove(documentId: UUID, type: DocumentTypeEnum): Observable<any> {
    const url = UrlBuilder.of(`${this.apiPath}/${documentId}`).add("type", type).toUrl();

    return this.apiService.delete(url);
  }

}
