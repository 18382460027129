import {Router} from "@angular/router";
import {inject} from "@angular/core";
import {UserService} from "../domain/user/user-service";
import {AppRoutes} from "../app-routing.module";

export const authGuard = () => {
  const userService = inject(UserService);
  const router = inject(Router);
  if (!userService.isLoggedIn) {
    router.navigate(['login']).then(/* DO NOTHING */);
    return false;
  }
  if (userService.isSuperAdmin()) {
    router.navigate([AppRoutes.ADMIN_MANAGEMENT]).then(/* DO NOTHING */);
    return false;
  }
  return true;
};
