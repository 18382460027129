<div class="max-w-screen-2xl lg:px-16 md:p-4 py-2">
  <mat-card class="lg:p-6">
    <mat-card-header class="flex items-center">
      <h1 style="margin-bottom: 4px">
        <span>{{'TENANT' | translate}}&nbsp;&ndash;&nbsp;</span>
        <span *ngIf="title">{{(title | translate).toLowerCase()}}</span>
      </h1>
    </mat-card-header>
    <hr class="h-px mb-8">
    <mat-spinner *ngIf="inProgress"></mat-spinner>

    <mat-card-content *ngIf="!inProgress">
      <tenant-form [tenant]="tenant" (tenantForm)="onFormChange($event)" />
    </mat-card-content>
    <mat-card-actions align="end">
      <button type="button" color="primary" class="ml-2" mat-flat-button [disabled]="!canSave()" (click)="save()">
        {{"COMMON_SAVE" | translate}}
        <fa-icon *ngIf="inProgress" [icon]="faSpinner" animation="spin" class="ml-1"/>
      </button>
    </mat-card-actions>
  </mat-card>
</div>


