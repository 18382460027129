<form *ngIf="form" [formGroup]="form" class="flex flex-wrap">
  <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
    <mat-label>{{ "COMMON_NAME" | translate }}</mat-label>
    <input matInput formControlName="name">
  </mat-form-field>

  <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
    <mat-label>{{ "COMMON_ORGANIZATION_NUMBER" | translate }}</mat-label>
    <input matInput formControlName="organizationNumber">
  </mat-form-field>

  <ng-container formGroupName="address">
    <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
      <mat-label>{{ "COMMON_ADDRESS" | translate }}</mat-label>
      <input matInput formControlName="street">
    </mat-form-field>

    <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
      <mat-label>{{ "COMMON_CITY" | translate }}</mat-label>
      <input matInput formControlName="city">
    </mat-form-field>

    <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
      <mat-label>{{ "COMMON_POSTAL_CODE" | translate }}</mat-label>
      <input matInput formControlName="postalCode">
    </mat-form-field>

    <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
      <mat-label>{{ "COMMON_COUNTRY" | translate }}</mat-label>
      <mat-select formControlName="country">
        <mat-option *ngFor="let country of countriesList" [value]="country.code">
          {{ country.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>
</form>
