export class EventUtil {
  static triggerClick(event: Event): void {
    if (event instanceof KeyboardEvent) {
      const target = event.target as HTMLElement;
      if (target.tagName === 'BUTTON' || target.closest('button')) {
        return;
      }
      if (event.code === 'Space' || event.code === 'Enter') {
        event.preventDefault();
        target.click();
      }
    }
  }
}
