<form [formGroup]="form" class="flex flex-col">

  <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
    <mat-label>{{"SERVICE_CATEGORY_GROUP" | translate}}</mat-label>
    <mat-select formControlName="serviceCatGroupId">
      <mat-option *ngFor="let serviceCategoryGroup of serviceCatGroup" [value]="serviceCategoryGroup.id">
        {{ serviceCategoryGroup.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
    <mat-label>{{"SERVICE_CATEGORY" | translate}}</mat-label>
    <mat-select formControlName="serviceCategoryId">
      <mat-option *ngFor="let serviceCategory of serviceCategoryFiltered" [value]="serviceCategory.id">
        {{ serviceCategory.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
    <mat-label>{{"PRIORITY" | translate}}</mat-label>
    <mat-select formControlName="priorityLevelId">
      <mat-option *ngFor="let priorityLevel of priorityLevels" [value]="priorityLevel.id">
        {{ priorityLevel.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>


  <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
    <mat-label>{{"SLA" | translate}}</mat-label>
    <mat-select formControlName="slaDurationId">
      <mat-option *ngFor="let sla of slas" [value]="sla.id">
        {{ sla.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>
