import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {SortUtil} from "./sorting-service";

export class Paginator<T> extends SortUtil<T> {
  pageSizeOptions: number[];
  pageIndex = 0;
  pageSize = 10;
  totalCount = 0;
  end = 0;
  start = 0;
  matPaginator: MatPaginator | null = null;

  constructor(sortBy: keyof T = null, pageSizeOptions: number[] = [1, 3, 4, 5, 10], lang: string = "sv") {
    super(sortBy, lang);
    this.pageSizeOptions = pageSizeOptions;
  }

  updateSort(sortUtil: SortUtil<T>) {
    this.sortName = sortUtil.sortName;
    this.sortOrder = sortUtil.sortOrder;
    this.lang = sortUtil.lang;
    this.sortingFuncs = sortUtil.sortingFuncs;
  }

  get pageNumber(): string {
    return (this.pageIndex + 1).toString();
  }

  setTotal(totalCount: number) {
    this.totalCount = totalCount;
  }

  updatePagination<T>(data: T[]) {
    this.totalCount = data.length;
    this.end = (this.pageIndex + 1) * this.pageSize;
    this.start = this.pageIndex * this.pageSize;
  }

  onPageChange(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
  }

  getPaged<T>(data: T[]): T[] {
    return data.slice(this.start, this.end);
  }

  firstPage() {
    this.matPaginator?.firstPage();
  }

  sortBy(name: keyof T) {
    this.updateSorting(name)
    this.firstPage();
  }
}
