import {ObjectMapperHelper} from "../core/object-mapper-helper";




export class AddressDetails {
  street: string;
  city: string;
  postalCode: string;
  country: string;
  state: string;

  constructor(data: any) {
    const helper = ObjectMapperHelper.of(data);
    this.street = helper.getString("street");
    this.city = helper.getString("city");
    this.postalCode = helper.getString("postalCode");
    this.country = helper.getString("country");
    this.state = helper.getString("state");
  }
}
