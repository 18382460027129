import {Component, Input, OnInit} from "@angular/core";
import {FormGroup} from "@angular/forms";
import {EnvironmentService} from "../../../core/environment-service";
import {LanguageCountry} from "../../../domain/language-country";
import {EventUtil} from "../../../core/event-util";
import {SecondFormGroup} from "./wizard-incident-main.component";
import {SlaDurationService} from "../../../domain/sla-duration/sla-duration-service";
import {PriorityLevelService} from "../../../domain/priority_level/priority-level-service";
import {ServiceCatGroupService} from "../../../domain/service-cat-group/service-cat-group-service";
import {ServiceCategoryService} from "../../../domain/service_category/service-category-service";
import {SlaDuration} from "../../../domain/sla-duration/sla-duration";
import {PriorityLevel} from "../../../domain/priority_level/priority-level";
import {ServiceCategory} from "../../../domain/service_category/service-category";
import {ServiceCatGroup} from "../../../domain/service-cat-group/service-cat-group";
import {forkJoin} from "rxjs";

@Component({
  selector: 'wizard-incident-general-form',
  templateUrl: './wizard-incident-general-form.component.html',
})
export class WizardIncidentGeneralFormComponent implements OnInit {
  triggerClick = EventUtil.triggerClick;

  serviceCatGroup: ServiceCatGroup[] = []

  serviceCategory: ServiceCategory[] = []
  serviceCategoryFiltered: ServiceCategory[] = []

  priorityLevels: PriorityLevel[] = []

  slas: SlaDuration[] = []

  @Input()
  form: FormGroup<SecondFormGroup>;

  countriesList: LanguageCountry[] = [];

  constructor(
    private environmentService: EnvironmentService,
    private slaDurationService: SlaDurationService,
    private priorityLevelService: PriorityLevelService,
    private serviceCatGroupService: ServiceCatGroupService,
    private serviceCategoryService: ServiceCategoryService) {
  }

  ngOnInit() {

    forkJoin({
      slas: this.slaDurationService.all(),
      priorityLevels: this.priorityLevelService.all(),
      serviceCatGroup: this.serviceCatGroupService.all(),
      serviceCategory: this.serviceCategoryService.all()
    }).subscribe(({slas, priorityLevels, serviceCatGroup, serviceCategory}) => {
      this.slas = slas;
      this.priorityLevels = priorityLevels;
      this.serviceCatGroup = serviceCatGroup;
      this.serviceCategory = serviceCategory;
    })
    this.countriesList = this.environmentService.langCountry;

    this.listenerForServiceCatGroupChange();
    this.listenerForServiceCategoryChange();
    this.listenerForPriorityChange();
    this.listenerForSlasChange();
  }

  listenerForServiceCategoryChange() {
    this.form.controls.serviceCategoryId.valueChanges.subscribe((value) => {
      const serviceCategory = this.serviceCategory.find(s => s.id === value);

      this.form.patchValue({
        serviceCategoryName: serviceCategory?.name ?? ''
      })

    });
  }

  listenerForSlasChange() {
    this.form.controls.slaDurationId.valueChanges.subscribe((value) => {
      const sla = this.slas.find(sla => sla.id === value);
      if (sla) {
        this.form.patchValue({
          deadlineEndMinutes: sla.durationMinutes,
          slaDurationName: sla?.name,
        })
      }
    });
  }


  listenerForPriorityChange() {
    this.form.controls.priorityLevelId.valueChanges.subscribe((value) => {
      const priority = this.priorityLevels.find(p => p.id === value);

      this.form.patchValue({
        priorityLevelName: priority?.name ?? ''
      })

    });
  }


  listenerForServiceCatGroupChange() {
    if (this.form.controls?.serviceCatGroupId?.value) {
      this.serviceCategoryFiltered = this.serviceCategory.filter((sc) => sc.serviceCatGroupId === this.form.controls.serviceCatGroupId.value);
    }

    this.form.controls.serviceCatGroupId.valueChanges.subscribe((value) => {
      this.serviceCategoryFiltered = this.serviceCategory.filter((sc) => sc.serviceCatGroupId === value);
      this.form.controls.serviceCategoryId.reset();


      const serviceCatGroup = this.serviceCatGroup.find(s => s.id === value);
      this.form.patchValue({
        serviceCatGroupName: serviceCatGroup?.name ?? ''
      })
    })
  }

}
