import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  templateUrl: './error-dialog.component.html',
})
export class ErrorDialogComponent {
  message: string;
  title: string;
  postedToBackend = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { title: string | null, message: string, errorPosted: boolean }) {
    this.message = data.message;
    this.postedToBackend = data.errorPosted;
    this.title = data.title ? data.title : 'ALERT_ERROR_DEFAULT_TITLE';
  }
}
