import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {FormGroup} from "@angular/forms";
import {finalize, Subscription} from "rxjs";
import {CanDeactivateComponent} from "../../core/can-deactivate-guard";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {EnvironmentService} from "../../core/environment-service";
import {AppRoutes} from "../../app-routing.module";
import {UserService} from "../../domain/user/user-service";
import {User} from "../../domain/user/user";
import {MutablePartial} from "../../domain/global-types";
import {Tenant} from "../../domain/tenant/tenant";
import {DialogService} from "../../shared/dialogs/dialog-service";
import {UserForm} from "./user-form.component";

@Component({
  selector: "user-edit",
  templateUrl: './user-edit.component.html',
})
export class UserEditComponent implements OnInit, CanDeactivateComponent, OnDestroy {
  protected readonly faSpinner = faSpinner;
  user: User;
  inProgress = true;
  isSaving = false;
  form: FormGroup<UserForm>;
  subscription = new Subscription();
  title = "";
  tenants: Tenant[] = [];

  constructor(
    private environmentService: EnvironmentService,
    private userService: UserService,
    private router: Router,
    private dialogService: DialogService,
    private route: ActivatedRoute) {
  }

  ngOnInit() {
    const userId = this.route.snapshot.paramMap.get('id');
    if (userId === "new") {
      this.setTitle(true);
      this.inProgress = false;
      return;
    }
    this.setTitle(false);
    this.userService.byId(userId)
      .pipe(finalize(() => {
        this.inProgress = false
      }))
      .subscribe(user => {
        this.user = user
      });
  }

  setTitle(isNewTenant: boolean): void {
    this.title = isNewTenant ? "COMMON_NEW" : "COMMON_EDIT";
  }

  save(): void {
    if (this.form.invalid) {
      return;
    }

    this.isSaving = true;
    const data: MutablePartial<User> = this.form.getRawValue();
    data.customerId = this.user ? this.user.customerId : this.environmentService.companionToken?.customerId;
    this.userService.createOrUpdate(this.user, data)
      .pipe(finalize(() => this.isSaving = false))
      .subscribe(() => {
        this.form.markAsPristine();
        this.navigateBack();
      });
  }

  private navigateBack() {
    this.router.navigate([`/${AppRoutes.ADMIN_CUSTOMER}/users`]).then(/* DO NOTHING */);
  }

  canSave(): boolean {
    return this.form != null && this.form.dirty && this.form.valid && !this.inProgress && !this.isSaving;
  }

  cancel() {
    this.navigateBack();
  }

  canDeactivateComponent(): boolean {
    return (this.form && this.form.dirty) ?? false;
  }

  setPassword() {
    this.dialogService.openSetPasswordDialog(this.user).subscribe(response => {});
  }

  onFormChange(form: FormGroup<UserForm>) {
    this.form = form;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
