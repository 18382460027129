import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Asset} from "../../domain/asset/asset";

@Component({
  templateUrl: './asset-dialog.component.html',
})
export class AssetDialogComponent {
  asset: Asset;
  constructor(
    private dialogRef: MatDialogRef<AssetDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: Asset,
  ) {
    this.asset = data;
  }

  onClose(): void {
    this.dialogRef.close(true);
  }
}
