import {Injectable} from "@angular/core";
import {ApiService} from "../../../core/api-service";
import {User} from "../../../domain/user/user";
import {map, Observable} from "rxjs";
import {Paginated, RespPaginated, RespWrapper} from "../../../domain/resp-wrapper";
import {Tenant} from "../../../domain/tenant/tenant";
import {Paginator} from "../../../core/paginator";
import {UrlBuilder} from "../../../domain/url-builder";
import {UUID} from "../../../domain/uuid";

@Injectable(
  {providedIn: 'root'}
)
export class UserManagementService {
  apiPath = '/v1/admin/users';

  constructor(private apiService: ApiService,) {
  }

  public byId(userId: string): Observable<User> {
    const url = `${this.apiPath}/${userId}`;
    return this.apiService.get<RespWrapper<User>>(url).pipe(map((resp) => {
        return new User(resp.data)
      }),
    );
  }

  public all(searchTerm: string = null, paginator: Paginator<User>): Observable<Paginated<User>> {
    const url = UrlBuilder.of(this.apiPath).addPagination(paginator).addSearch(searchTerm).toUrl();
    return this.apiService.get<RespPaginated<User>>(url)
      .pipe(map((resp) => Paginated.of<User>(resp, User)));
  }

  createOrUpdate(user: User | null, data: Partial<User>, useAdminApi = false) {
    if (user && user.id) {
      const url = `${this.apiPath}/${user.id}`;
      return this.apiService.patch<RespWrapper<Tenant>>(url, data);
    }
    return this.apiService.post<RespWrapper<Tenant>>(this.apiPath, data);
  }

  resetUserPassword(userId: UUID, password: string) {
    const url = `${this.apiPath}/${userId}/password/reset`;
    return this.apiService.post<RespWrapper<User>>(url, JSON.stringify(password));
  }
}
