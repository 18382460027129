import {ChangeDetectorRef, Component, OnDestroy, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {Credentials} from "../../domain/credentials";
import {MatDialog} from '@angular/material/dialog';
import {LanguageService} from "../../core/language-service";
import {finalize, interval, Subscription} from "rxjs";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {UserService} from "../../domain/user/user-service";
import {EnvironmentService} from "../../core/environment-service";
import {FaqDialogComponent} from '../../shared/dialogs/faq-dialog.component';
import {DialogService} from "../../shared/dialogs/dialog-service";
import {Language} from "../../domain/language-country";
import {UserRole} from "../../domain/user/user-role";
import {AppRoutes} from "../../app-routing.module";


@Component({
  templateUrl: "login.component.html",
  styleUrls: ["login.component.scss"]
})
export class LoginComponent implements OnInit, OnDestroy {
  hide = true;
  form: FormGroup | undefined;
  loginAttemptFailed = false;
  subscriptions = new Subscription();
  inProgress = false;
  backgroundImageUrl: string;
  private backgroundChangeInterval$: Subscription;
  private imageUrls: string[] = [];
  private currentImageIndex = 0;
  protected readonly faSpinner = faSpinner;
  languages: Language[] | null = null;

  constructor(private changeDetectorRef: ChangeDetectorRef,
              private userService: UserService,
              private fb: FormBuilder,
              private languageService: LanguageService,
              private router: Router,
              private environmentService: EnvironmentService,
              public dialog: MatDialog,
              private dialogService: DialogService) {
  }


  ngOnInit(): void {
    this.initForm();
    this.languages = this.languageService.languages;
    this.preloadImages();
    this.backgroundImageUrl = this.imageUrls[0];

    const changeInterval = 7000;
    this.backgroundChangeInterval$ = interval(changeInterval).subscribe(() => {
      this.loadRandomBackgroundImage();
    });
  }

  preloadImages(): void {
    const numberOfImages = 13;
    for (let i = 1; i <= numberOfImages; i++) {
      const imageUrl = `assets/images/image_login_0${i}.webp`;
      this.imageUrls.push(imageUrl);

      const img = new Image();
      img.src = imageUrl;
    }
  }

  loadRandomBackgroundImage(): void {
    this.currentImageIndex = (this.currentImageIndex + 1) % this.imageUrls.length;
    this.backgroundImageUrl = this.imageUrls[this.currentImageIndex];
    const element = document.querySelector('.welcome-section');
    element.classList.remove('change-background');

    setTimeout(() => {
      this.backgroundImageUrl = this.imageUrls[this.currentImageIndex];
      element.classList.add('change-background');
      this.changeDetectorRef.detectChanges();
    }, 100);
  }

  initForm(): void {
    const rememberedUsername = this.environmentService.rememberedUsername || '';

    this.form = this.fb.group({
      username: [rememberedUsername, Validators.required],
      password: ['', Validators.required],
      rememberMe: [!!rememberedUsername]
    });

    this.subscriptions.add(this.form.valueChanges.subscribe(() => {
      this.loginAttemptFailed = false;
    }));
  }

  login(): void {
    if (this.form?.invalid) {
      return;
    }
    this.inProgress = true;
    const values = this.form?.getRawValue();
    const credentials: Credentials = {
      userName: values.username.trim(),
      password: values.password.trim()
    }

    const rememberMe = values.rememberMe ? credentials.userName : null;
    this.environmentService.setRememberedUsername(rememberMe);

    this.subscriptions.add(this.userService.login(credentials)
      .pipe(finalize(() => this.inProgress = false))
      .subscribe(user => {

        this.loginAttemptFailed = !user;
        if (!this.loginAttemptFailed) {

          if (user.roles.includes(UserRole.SuperAdmin)) {
            this.router.navigate([`${AppRoutes.ADMIN_MANAGEMENT}/tenants`]).then(/* DO NOTHING */);
            return;
          }

          if (user.roles.includes(UserRole.CustomerAdmin) || user.roles.includes(UserRole.TenantAdmin)) {
            this.router.navigate([`${AppRoutes.ADMIN_CUSTOMER}/`]).then(/* DO NOTHING */);
            return;
          }

          this.router.navigate(['']).then(/* DO NOTHING */);
        }
      }));
  }

  openFaqDialog(): void {
    this.dialog.open(FaqDialogComponent, {
      width: '300px',
      position: {bottom: '60px', right: '20px'}
    });
  }

  openForgotPasswordDialog() {
    this.dialogService.openForgotPasswordDialog().subscribe();
  }

  useLanguage(language: Language) {
    this.languageService.useLanguage(language.code);
  }

  currentLanguageLabel(): string {
    return this.languageService.currentLanguageLabel();
  }

  loginDisabled(): boolean {
    return this.form?.invalid || this.inProgress;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    if (this.backgroundChangeInterval$) {
      this.backgroundChangeInterval$.unsubscribe();
    }
  }
}
