import {UUID} from "../uuid";
import {ObjectMapperHelper} from "../../core/object-mapper-helper";
import {FileData} from "../../core/files-upload/file-data";


export enum DocumentTypeEnum {
  ProfilePicture = 'ProfilePicture',
  Identification = 'Identification',
  ProviderAgreement = 'ProviderAgreement',
  Contract = 'Contract',
  Invoice = 'Invoice',
  License = 'License',
  IncidentAttachment = 'IncidentAttachment',
  AssetAttachment = 'AssetAttachment',
  FacilityAttachment = 'FacilityAttachment',
  Other = 'Other',
  Certificate = 'Certificate'
}



class Document {
 readonly  id: UUID
 readonly  customerId: UUID
 readonly  tenantId: UUID
 readonly  providerId: UUID
 readonly  documentType: DocumentTypeEnum
 readonly  filename: string
 readonly  filePath: string
 readonly  mimeType: string
 readonly  description: string
 readonly  isActive: boolean

  constructor(data: any) {
    const helper = ObjectMapperHelper.of(data);
    this.id = helper.getUUID("id")
    this.customerId = helper.getUUID("customerId")
    this.tenantId = helper.getUUID("tenantId")
    this.providerId = helper.getUUID("providerId")
    this.documentType = helper.getEnum("documentType", DocumentTypeEnum)
    this.filename = helper.getString("filename")
    this.filePath = helper.getString("filePath")
    this.mimeType = helper.getString("mimeType")
    this.description = helper.getString("description")
    this.isActive = helper.getBoolean("isActive")
  }
 }


export class UploadDocument {
  file: File
  documentType: DocumentTypeEnum
  customerId: UUID
  tenantId?: UUID
  incidentId?: UUID
  providerId?: UUID
  description?: string

  public static fromFileData(file: FileData, documentType: DocumentTypeEnum, customerId: UUID, tenantId: UUID, incidentId: UUID): UploadDocument {
    return {
      file: file.file,
      documentType: documentType,
      customerId: customerId,
      incidentId: incidentId,
      tenantId: tenantId,
    }
  }
}

export class ResponseDocument {
  fileName: string
  mimeType: string
  documentId: UUID

  constructor(data: any) {
    const helper = ObjectMapperHelper.of(data);
    this.fileName = helper.getString("fileName")
    this.mimeType = helper.getString("mimeType")
    this.documentId = helper.getUUID("documentId")
  }
}


export class GetPreSignedUrl {
  documentIds: UUID[]
  documentType: DocumentTypeEnum
  expiresInMinutes: number

  constructor(documentIds: UUID[], documentType: DocumentTypeEnum, expiresInMinutes: number) {
    this.documentIds = documentIds
    this.documentType = documentType
    this.expiresInMinutes = expiresInMinutes
  }
}


export class ResPreSignedUrl {
  readonly id: UUID
  readonly preSignedUrl: string


  constructor(data: any) {
    const helper = ObjectMapperHelper.of(data);
    this.id = helper.getUUID("id")
    this.preSignedUrl = helper.getString("preSignedUrl")
  }
}
