<div class="max-w-screen-2xl lg:px-16 md:p-4 py-2">
  <mat-card class="lg:p-6 mat-mdc-elevation-specific mat-elevation-z0"
            style="background-color: transparent !important;">
    <mat-card-header class="flex items-center">
      <h1 style="margin-bottom: 4px">
        <span>{{ 'INCIDENT' | translate }}&nbsp;&ndash;&nbsp;</span>
        <span *ngIf="title">{{ (title | translate).toLowerCase() }}</span>
      </h1>
    </mat-card-header>
    <hr class="h-0.5 mb-8 dark:bg-gray-300">
    <mat-spinner *ngIf="!form"></mat-spinner>

    <mat-card-content>
      <form *ngIf="form" [formGroup]="form" class="flex flex-wrap">
        <div class="grid grid:cols-1 xl:grid-cols-2 gap-4">
          <div class="grid grid:cols-1 gap-4">
            <mat-card class="card-border mat-mdc-elevation-specific mat-elevation-z0">
              <mat-card-title>
                <div class="text-sm p-2 pl-4 font-bold">{{ "Bass" }}</div>
              </mat-card-title>
              <mat-card-content>
                <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
                  <mat-label>{{ "COMMON_NAME" | translate }}</mat-label>
                  <input matInput formControlName="title">
                </mat-form-field>

                <mat-form-field class="w-full md:p-2" appearance="outline">
                  <mat-label>{{"DESCRIPTION" | translate}}</mat-label>
                  <textarea rows="3" matInput formControlName="description"></textarea>
                </mat-form-field>

                <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
                  <mat-label>{{"INCIDENT_STATUS" | translate}}</mat-label>
                  <mat-select formControlName="status">
                    <mat-option *ngFor="let status of incidentStatus" [value]="status">
                      {{ ("INCIDENT_STATUS_" + status) | translate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
                  <mat-label>{{"INCIDENT_TYPE" | translate}}</mat-label>
                  <mat-select formControlName="type">
                    <mat-option *ngFor="let incident of incidentTypes" [value]="incident">
                      {{ ("INCIDENT_TYPE_" + incident) | translate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <div class="w-full md:p-2">
                  <app-file-upload
                    [documentType]="DocumentTypeEnum.IncidentAttachment"
                    [allFiles]="allFiles"
                    (onFileListChange)="onFileListChange($event)"
                  ></app-file-upload>
                </div>
              </mat-card-content>
            </mat-card>


          </div>
          <div class="grid grid:cols-1 gap-4">

            <mat-card class="card-border mat-mdc-elevation-specific mat-elevation-z0">
              <mat-card-title>
                <div class="text-sm p-2 pl-4 font-bold">{{ "Specification" }}</div>
              </mat-card-title>
              <mat-card-content>
                <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
                  <mat-label>{{"SERVICE_CATEGORY_GROUP" | translate}}</mat-label>
                  <mat-select formControlName="serviceCatGroupId">
                    <mat-option *ngFor="let serviceCategoryGroup of serviceCatGroup"
                                [value]="serviceCategoryGroup.id">
                      {{ serviceCategoryGroup.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
                  <mat-label>{{"SERVICE_CATEGORY" | translate}}</mat-label>
                  <mat-select formControlName="serviceCategoryId">
                    <mat-option *ngFor="let serviceCategory of serviceCategoryFiltered" [value]="serviceCategory.id">
                      {{ serviceCategory.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
                  <mat-label>{{"PRIORITY" | translate}}</mat-label>
                  <mat-select formControlName="priorityLevelId">
                    <mat-option *ngFor="let priorityLevel of priorityLevels" [value]="priorityLevel.id">
                      {{ priorityLevel.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>


                <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
                  <mat-label>{{"SLA" | translate}}</mat-label>
                  <mat-select formControlName="slaDurationId">
                    <mat-option *ngFor="let sla of slas" [value]="sla.id">
                      {{ sla.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </mat-card-content>
            </mat-card>

          <mat-card class="card-border mat-mdc-elevation-specific mat-elevation-z0">
            <mat-card-title>
              <div class="text-sm p-2 pl-4 font-bold">{{ "ASSETS" | translate }}</div>
            </mat-card-title>
            <mat-card-content>
              <div class="">
                <div class="flex pb-4">
                  <button mat-button (click)="openDialog()">
                    {{ "INCIDENT_WIZ_SELECT_ASSETS_INCIDENT" | translate }}
                    <mat-icon iconPositionEnd>chevron_right</mat-icon>
                  </button>
                </div>

                <ng-container *ngIf="selectedAssets.length === 0">
                  <p class="text-xs">{{ "NO_ASSET_SELECTED" | translate }}</p>
                </ng-container>

                <div class="mb-4" *ngFor="let asset of selectedAssets">
                  <mat-chip class="p-0 mt-2 cursor-pointer" (click)="showAssetDetails(asset)">
                    <span class="text-xs inline-block">{{asset.modelName}}</span>
                    <button matChipRemove (click)="removeAsset(asset.id)" aria-label="Remove orange">
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </mat-chip>
                </div>
              </div>


            </mat-card-content>
          </mat-card>


          <mat-card class="card-border mat-mdc-elevation-specific mat-elevation-z0">
            <mat-card-title>
              <div class="text-sm p-2 pl-4 font-bold">{{ "COMMON_CONTACT_PERSON" | translate }}</div>
            </mat-card-title>
            <mat-card-content>
              <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
                <mat-label>{{"COMMON_NAME" | translate}}</mat-label>
                <input matInput formControlName="contactName">
              </mat-form-field>

              <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
                <mat-label>{{"COMMON_EMAIL" | translate}}</mat-label>
                <input matInput formControlName="contactEmail">
              </mat-form-field>

              <div class="w-full md:w-1/2 md:p-2">
                <phone-number [control]="form.controls.contactPhone"></phone-number>
              </div>
            </mat-card-content>
          </mat-card>
          </div>
        </div>

      </form>
    </mat-card-content>
    <mat-card-actions *ngIf="form" align="end" class="mt-4">
      <button type="button" mat-flat-button (click)="cancel()">{{ "COMMON_CANCEL" | translate }}</button>
      <button type="button" color="primary" class="ml-2" mat-flat-button [disabled]="!canSave()" (click)="save()">
        {{ (incident?.id ? "COMMON_UPDATE" : "COMMON_SAVE")| translate }}
        <fa-icon *ngIf="inProgress" [icon]="faSpinner" animation="spin" class="ml-1"/>
      </button>
    </mat-card-actions>
  </mat-card>


  <div *ngIf="form" class="grid grid:cols-1 xl:grid-cols-2 gap-4 xl:p-10 lg:p-10 md:p-4 sm:p-2 ">
    <booking-view [booking]="getBooking()" />
  </div>
</div>

