import {Injectable} from "@angular/core";
import {ApiService} from "../../core/api-service";
import {map, Observable} from "rxjs";
import {Tenant} from "./tenant";
import {Paginated, RespPaginated, RespWrapper} from "../resp-wrapper";
import {Paginator} from "../../core/paginator";
import {UrlBuilder} from "../url-builder";

@Injectable(
  {providedIn: 'root'}
)
export class TenantService {
  apiPath = '/v1/tenants';

  constructor(private apiService: ApiService) {
  }

  public all(searchTerm: string = null, paginator: Paginator<Tenant>): Observable<Paginated<Tenant>> {
    const url = UrlBuilder.of(this.apiPath).addPagination(paginator).addSearch(searchTerm).toUrl();

    return this.apiService.get<RespPaginated<Tenant>>(url).pipe(map((resp) => {
      return Paginated.of<Tenant>(resp, Tenant);
    }));
  }

  public get(): Observable<Tenant> {
    const url = `${this.apiPath}`;
    return this.apiService.get<RespWrapper<Tenant>>(url).pipe(
      map(resp => new Tenant(resp.data)));
  }

  update(data: Partial<Tenant>) {
    const url = `${this.apiPath}`;
    return this.apiService.patch<RespWrapper<Tenant>>(url, data);
  }
}
