import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from "@angular/core";
import {Router} from "@angular/router";
import {CustomerService} from "../../domain/customer/customer-service";
import {Customer} from "../../domain/customer/customer";
import {AppRoutes} from "../../app-routing.module";
import {Paginator} from "../../core/paginator";
import {Paginated} from "../../domain/resp-wrapper";
import {finalize} from "rxjs";

@Component({
  selector: 'customers',
  templateUrl: './customers.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomersComponent {
  paginated: Paginated<Customer>;
  inProgress = true;

  constructor(
    private router: Router,
    private customerService: CustomerService,
    private cd: ChangeDetectorRef) {
  }

  onSearch(data: { searchTerm: string, paginator: Paginator<Customer> }) {
    this.inProgress = true;
    this.customerService.all(data.searchTerm, data.paginator)
      .pipe(finalize(() => {
        this.inProgress = false;
        this.cd.markForCheck();
      }))
      .subscribe(response => {
        this.paginated = response;
      });
  }

  onCreate() {
    this.router.navigate([`${AppRoutes.ADMIN_CUSTOMER}/customers/new`]).then(/* DO NOTHING */);
  }

  onNavigateToEdit(tenant: Customer) {
    this.router.navigate([`${AppRoutes.ADMIN_CUSTOMER}/customers`, tenant.id]).then(/* DO NOTHING */);
  }
}
