import {AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {MatDrawer} from "@angular/material/sidenav";
import {map, of, Subscription, switchMap} from "rxjs";
import {User} from "../../domain/user/user";
import {Device} from "../../core/device";
import {Customer} from "../../domain/customer/customer";
import {LanguageService} from "../../core/language-service";
import {CustomerService} from "../../domain/customer/customer-service";
import {DeviceService} from "../../core/device.service";
import {UserService} from "../../domain/user/user-service";
import {UserRole} from "../../domain/user/user-role";
import {Language} from "../../domain/language-country";
import {Facility} from "../../domain/facility/facility";
import {AppRoutes} from "../../app-routing.module";
import {TokenStorageService} from "../../core/storage/token-storage-service";
import {UserStorageService} from "../../core/storage/user-storage-service";
import {FacilityStorageService} from "../../core/storage/facility-storage-service";

interface MenuItem {
  label: string;
  icon: string;
  route?: string;
  open?: boolean;
  role: UserRole | null;
  children?: MenuItem[];
}

@Component({
  selector: 'dashboard-wrapper',
  templateUrl: './dashboard-wrapper.component.html',
})
export class DashboardWrapperComponent implements OnInit, OnDestroy, AfterViewInit {
  user: User | null = null;
  subscription: Subscription = new Subscription()
  device: Device | null = null;
  @ViewChild('drawer') public drawer: MatDrawer | null = null;
  languages: Language[] | null = null;
  customer: Customer | null = null;
  facility: Facility | null = null;
  inProgress = true;

  linksManagement: MenuItem[] = [
    {
      label: 'MENU_TENANTS',
      route: `/${AppRoutes.ADMIN_MANAGEMENT}/tenants`,
      icon: 'business',
      role: UserRole.SuperAdmin
    },
    {
      label: 'MENU_CUSTOMERS',
      route: `/${AppRoutes.ADMIN_MANAGEMENT}/customers`,
      icon: 'work',
      role: UserRole.SuperAdmin
    },
    {
      label: 'MENU_PROVIDERS',
      route: `/${AppRoutes.ADMIN_MANAGEMENT}/providers`,
      icon: 'handyman',
      role: UserRole.SuperAdmin
    },
    {
      label: 'MENU_ADMIN_USERS',
      route: `/${AppRoutes.ADMIN_MANAGEMENT}/users`,
      icon: 'group',
      role: UserRole.SuperAdmin
    }
  ];

  linksCustomerAdmin = [
    {label: 'MENU_ADMIN_OVERVIEW', route: `/${AppRoutes.ADMIN_CUSTOMER}`, icon: 'home', role: UserRole.CustomerAdmin},
    {
      label: 'MENU_ADMIN_FACILITIES',
      route: `/${AppRoutes.ADMIN_CUSTOMER}/facilities`,
      icon: 'apartment',
      role: UserRole.CustomerAdmin
    },
    {
      label: 'MENU_ADMIN_FACILITIES_GROUPS',
      route: `/${AppRoutes.ADMIN_CUSTOMER}/facilities-groups`,
      icon: 'business',
      role: UserRole.CustomerAdmin
    },
    {
      label: 'MENU_CUSTOMERS',
      route: `/${AppRoutes.ADMIN_CUSTOMER}/customers`,
      icon: 'work',
      role: UserRole.CustomerAdmin
    },
    {
      label: 'MENU_ADMIN_USERS',
      route: `/${AppRoutes.ADMIN_CUSTOMER}/users`,
      icon: 'group',
      role: UserRole.CustomerAdmin
    },
  ];
  linksTenantAdmin = [
    ...this.linksCustomerAdmin,
    {
      label: 'MENU_ADMIN_TENANT',
      route: `/${AppRoutes.ADMIN_CUSTOMER}/tenant`,
      icon: 'business',
      role: UserRole.TenantAdmin
    }]
  links: MenuItem[] = [];
  private _isAdminView: boolean;

  get isAdminView(): boolean {
    return this._isAdminView;
  }

  @Input() set isAdminView(isAdmin: boolean) {
    this._isAdminView = isAdmin;
    this.setupLinks();
  }

  constructor(
    private languageService: LanguageService,
    private customerService: CustomerService,
    private deviceService: DeviceService,
    private facilityStorageService: FacilityStorageService,
    public router: Router,
    private userService: UserService,
    private cdr: ChangeDetectorRef,
    private tokenStorageService: TokenStorageService,
    private userStorageService: UserStorageService
  ) {
  }

  ngOnInit(): void {
    this.languages = this.languageService.languages;
    this.subscription.add(this.deviceService.device().subscribe(device => {
      this.device = device;
      this.showOrHideSideBar();
    }));

    this.subscription.add(this.userStorageService.currentUser$.pipe(
        switchMap(user => {
          if (!user) {
            return of({user, customer: null});
          }
          // TODO in future we shall be able to handle multiple customers
          const customerId = user.customerId;
          if (!user.customerId) {
            return of({user, customer: null})
          }

          return this.customerService.by(customerId).pipe(map(customer => ({user, customer})));
        }),
      ).subscribe(({user, customer}) => {
          this.user = user;
          this.customer = customer;
          this.customerService.setCustomer(customer);
          this.showOrHideSideBar();

          if (this.facilityStorageService.currentFacility && !this.userService.isAdmin()) {
            this.navToPickFacility();
          }
          this.setupLinks();
        }
      )
    );

    this.subscription.add(this.facilityStorageService.currentFacility$.subscribe(facility => {
      this.facility = facility;
      this.setupLinks();
    }))
  }

  ngAfterViewInit() {
    this.showOrHideSideBar()
  }

  setupLinks() {
    this.links = this.getLink();
  }

  getLink() {
    if (this.userService.isSuperAdmin()) {
      return this.linksManagement;
    }
    if (!this.isAdminView) {
      return this.setupFacilityLinks(this.facility);
    }
    if (this.userService.isTenantAdmin()) {
      return this.linksTenantAdmin;
    }
    if (this.userService.isAdmin()) {
      return this.linksCustomerAdmin;
    }
    return [];
  }

  setupFacilityLinks(facility: Facility): MenuItem[] {
    if (facility == null) {
      return [];
    }
    const facilityRoute = `/facilities/${facility.id}`;
    return [
      {label: 'MENU_OVERVIEW', route: '/home', icon: 'home', role: null},
      {
        label: 'MENU_EVENTS',
        route: '/events',
        icon: 'event_note',
        role: null,
        children: [
          {label: 'Bookings', icon: 'sub-icon-1', route: 'events/bookings', open: false, role: null},
          {label: 'Incidents', icon: 'sub-icon-1', route: 'events/incidents', open: false, role: null},
          // {label: 'Service', icon: 'sub-icon-1', route: 'events/incidents', open: false, role: null}
        ]
      },
      {label: 'MENU_FACILITY', route: facilityRoute, icon: 'apartment', role: null},
      {label: 'MENU_ASSETS', route: '/assets', icon: 'star_border', role: null},
    ];
  }

  private showOrHideSideBar() {
    if (this.drawer && this.device?.isDesktop) {
      this.drawer.open();
      this.cdr.detectChanges();
    } else {
      this.drawer?.close();
    }
  }

  isLoggedIn(): boolean {
    return this.userService.isLoggedIn;
  }

  navigate(route: string) {
    this.router.navigate([route]).then(/* DO NOTHING */);
    this.showOrHideSideBar();
  }

  getSidenavMode() {
    return this.device?.isMobile ? 'over' : 'side';
  }

  isSuperAdmin(): boolean {
    return this.userService.isSuperAdmin();
  }

  toggleSubMenu(panel: MenuItem): void {
    panel.open = !panel.open;
  }

  navToPickFacility() {
    this.router.navigate(['/facility-pick']).then(/* DO NOTHING */);
    this.showOrHideSideBar();
  }

  getMenuTitle(): string {
    if (this.isSuperAdmin()) {
      return "MENU_MANAGEMENT"
    }
    if (this.isAdminView) {
      return "MENU_ADMIN"
    }
    return "MENU_FACILITY";
  }

  toggleSideNav(): void {
    this.drawer?.toggle()
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
