import {Injectable} from "@angular/core";
import {MatPaginatorIntl} from "@angular/material/paginator";
import {TranslateService} from "@ngx-translate/core";

@Injectable()
export class MatPaginationIntlService extends MatPaginatorIntl {
  constructor(private translateService: TranslateService) {
    super();
    this.init();
  }

  private init() {
    this.translateLabels();
    this.translateService.onLangChange.subscribe(() => {
      this.translateLabels();
    });
  }

  override getRangeLabel = (page: number, pageSize: number, length: number): string => {
    const of = this.translateService.instant("TABLE_PAGINATOR_OF");
    if (length === 0 || pageSize === 0) {
      return `0 ${of} ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize > length ? (Math.ceil(length / pageSize) - 1) * pageSize : page * pageSize;
    const endIndex = Math.min(startIndex + pageSize, length);

    return `${startIndex + 1} - ${endIndex} ${of} ${length}`;
  };

  translateLabels(): void {
    this.firstPageLabel = this.translateService.instant("TABLE_PAGINATOR_FIRST_PAGE");
    this.itemsPerPageLabel = this.translateService.instant("TABLE_PAGINATOR_ITEMS_PER_PAGE");
    this.lastPageLabel = this.translateService.instant("TABLE_PAGINATOR_LAST_PAGE");
    this.nextPageLabel = this.translateService.instant("TABLE_PAGINATOR_NEXT_PAGE");
    this.previousPageLabel = this.translateService.instant("TABLE_PAGINATOR_PREVIOUS_PAGE");
    this.changes.next(); // Trigger refresh of translations
  }
}
