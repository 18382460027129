import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Customer} from "../../domain/customer/customer";
import {EnvironmentService} from "../../core/environment-service";
import {Country} from "../../domain/language-country";

export interface AddressForm {
  street: FormControl<string>;
  state: FormControl<string>;
  postalCode: FormControl<string>;
  city: FormControl<string>;
  country: FormControl<string>;
}

export interface CustomerForm {
  name: FormControl<string>;
  organizationNumber: FormControl<string>;
  contactId: FormControl<string>;
  address: FormGroup<AddressForm>
  isActive: FormControl<boolean>;
}

@Component({
  selector: 'customer-form',
  templateUrl: './customer-form.component.html',
})
export class CustomerFormComponent implements OnInit {
  form: FormGroup<CustomerForm>;
  countriesList: Country[] = [];

  @Input() customer: Customer | null = null;
  @Input() disabled = false;
  @Output() customerForm = new EventEmitter<FormGroup<CustomerForm>>();

  constructor(private fb: FormBuilder,
              private environmentService: EnvironmentService,
  ) {
  }

  ngOnInit() {
    this.countriesList = this.environmentService.countries;
    this.initForm();
    this.form.valueChanges.subscribe(() => {
      this.customerForm.emit(this.form);
    })
  }

  initForm(): void {
    const customer = this.customer;
    this.form = this.fb.group({
      name: [customer?.name, Validators.required],
      organizationNumber: [customer?.organizationNumber ?? ""],
      contactId: [customer?.contactId],
      address: this.fb.group({
        street: [customer?.address.street],
        state: [customer?.address.state],
        postalCode: [customer?.address.postalCode ?? ""],
        city: [customer?.address.city ?? ""],
        country: [customer?.address.country ?? this.environmentService.countryDefaultCode],
      }),
      // invoiceId: [customer?.invoiceId],
      isActive: [customer?.isActive ?? true],
    });
  }
}

