import {Component, Input} from "@angular/core";
import {FormGroup} from "@angular/forms";
import {EventUtil} from "../../../core/event-util";
import {forthFormGroup} from "./wizard-incident-main.component";

@Component({
  selector: 'wizard-incident-contact-form',
  templateUrl: './wizard-incident-contact-form.component.html',
})
export class WizardIncidentContactFormComponent {
  triggerClick = EventUtil.triggerClick;

  @Input()
  form: FormGroup<forthFormGroup>;
  constructor(){}
}
