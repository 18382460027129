import {Injectable} from "@angular/core";
import {ApiService} from "../../core/api-service";
import {map, Observable} from "rxjs";
import {RespWrapper} from "../resp-wrapper";
import {AssetType} from "./asset-type";
import {UUID} from "../uuid";
import {UrlBuilder} from "../url-builder";

@Injectable(
  {providedIn: 'root'}
)
export class AssetTypeService {
  apiPath = '/v1/assetTypes';

  constructor(private apiService: ApiService) {
  }

  public getListByCustomerId(customerId: UUID): Observable<AssetType[]> {
    const url = UrlBuilder.of(this.apiPath).add("customerId", customerId).toUrl();
    return this.apiService.get<RespWrapper<AssetType[]>>(url).pipe(map((resp: any) => {
      return resp.data.map((t: AssetType) => new AssetType(t));
    }));
  }
}
