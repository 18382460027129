import {UUID} from "../uuid";
import {ObjectMapperHelper} from "../../core/object-mapper-helper";


export enum EventType {
  SERVICE = 'SERVICE',
  BOOKING = 'BOOKING',
  INCIDENT = 'INCIDENT',
  ORDER = 'ORDER'
}

export enum EventKind {
  PLASTIC = 'PLASTIC',
  METAL = 'METAL',
  GLASS = 'GLASS',
  PAPER = 'PAPER',
  RUBBER = 'RUBBER',
}

export class Event {
  readonly event_id: UUID
  readonly title: string
  readonly deadline_end_at: Date
  readonly type: EventType
  readonly created_at: Date

  constructor(data: any) {
    const helper = ObjectMapperHelper.of(data);
    this.event_id = helper.getUUID('event_id');
    this.title = helper.getString('title');
    this.deadline_end_at = helper.getDate('deadline_end_at');
    this.type = helper.getEnum('type', EventType)
    this.created_at = helper.getDate('created_at')
  }
}
