import {IConfig} from "ngx-mask";

export class MaskUtil {

  getDigitMask(): string {
    return this.createDigit(20);
  }

  get socialSecurityNr(): string {
    return MaskSymbol.socialSequrity.repeat(13);
  }

  get phoneMask(): string {
    return this.createDigitAndSpace(20);
  }

  private createDigitAndSpace(length: number): string {
    return MaskSymbol.digitSpace.repeat(length);
  }

  private createDigit(length: number): string {
    return MaskSymbol.digit.repeat(length);
  }
}

export enum MaskSymbol {
  decimal = "1",
  digit = '2',
  orgNo = '3',
  slug = '4',
  digitSpace = '5',
  socialSequrity = '6'
}

const MaskPatterns = {
  [MaskSymbol.decimal]: {pattern: new RegExp(/[\d,.-]/i)},
  [MaskSymbol.digit]: {pattern: new RegExp(/\d/)},
  [MaskSymbol.orgNo]: {pattern: new RegExp(/[\d0-9-]/i)},
  [MaskSymbol.slug]: {pattern: new RegExp(/[\da-z0-9-]/i)},
  [MaskSymbol.digitSpace]: {pattern: new RegExp(/[\d ]/i)},
  [MaskSymbol.socialSequrity]: {pattern: new RegExp(/^[\d-]/i)},
}

export function maskConfig(): Partial<IConfig> {
  return {
    validation: false,
    dropSpecialCharacters: false,
    patterns: MaskPatterns
  };
}
