import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Router} from "@angular/router";
import {Subscription} from "rxjs";
import {User} from "../../domain/user/user";
import {Device} from "../../core/device";
import {LanguageService} from "../../core/language-service";
import {DeviceService} from "../../core/device.service";
import {UserService} from "../../domain/user/user-service";
import {Language} from "../../domain/language-country";
import {Facility} from "../../domain/facility/facility";
import {TokenStorageService} from "../../core/storage/token-storage-service";
import {AppRoutes} from "../../app-routing.module";
import {FacilityStorageService} from "../../core/storage/facility-storage-service";
import {UserStorageService} from "../../core/storage/user-storage-service";

@Component({
  selector: 'top-toolbar',
  templateUrl: './top-toolbar.component.html',
})
export class TopToolbarComponent implements OnInit, OnDestroy {
  device: Device | null = null;
  user: User | null = null;
  facility: Facility;
  subscription: Subscription = new Subscription()
  languages: Language[] | null = null;
  accessTokenExpiresAtInSeconds: number | null = null;
  refreshTokenExpiresAtInSeconds: number | null = null;
  timer: any;
  isTimerRunning: boolean = false;

  @Input() isAdminView: boolean;
  @Output() toggleSidenavChange = new EventEmitter<void>();

  constructor(
    private languageService: LanguageService,
    private deviceService: DeviceService,
    private facilityStorageService: FacilityStorageService,
    public router: Router,
    private userService: UserService,
    private userStorageService: UserStorageService,
    private tokenStorageService: TokenStorageService
  ) {
  }

  ngOnInit(): void {
    this.languages = this.languageService.languages;
    this.subscription.add(this.deviceService.device().subscribe(device => {
      this.device = device;
    }));

    this.subscription.add(this.userStorageService.currentUser$.subscribe(user => {
      this.user = user;
    }))

    this.subscription.add(this.facilityStorageService.currentFacility$.subscribe(facility => {
      this.facility = facility;
    }))

    this.subscription.add(this.tokenStorageService.token$.subscribe(tokenData => {
      if(!tokenData?.userId) {
        console.log("users access token hase been manually removed", new Date());

        this.router.navigate(['/login']).then(/* do nothing */);
        return;
      }
      const currentTime = Date.now() / 1000;
      this.accessTokenExpiresAtInSeconds = Number((tokenData?.accessTokenExpires - currentTime).toFixed(0));
      this.refreshTokenExpiresAtInSeconds = Number((tokenData?.refreshTokenExpires - currentTime).toFixed(0));
      this.startTimer()
    }))
  }

  toggleSideNav() {
    this.toggleSidenavChange.emit();
  }

  useLanguage(country: Language) {
    this.languageService.useLanguage(country.code);
  }

  toAdmin(): void {
    this.router.navigate([`${AppRoutes.ADMIN_CUSTOMER}`]).then(/* DO NOTHING */);
  }

  pickFacility(): void {
    this.router.navigate(['/facility-pick']).then(/* DO NOTHING */);
  }

  logout(): void {
    this.userService.logout();
    this.router.navigate(['/login']).then(/* DO NOTHING */);
  }

  startTimer(): void {
    if (this.isTimerRunning) {
      return;
    }
    this.isTimerRunning = true;

    this.timer = setInterval(() => {
      if (this.accessTokenExpiresAtInSeconds > 0) {
        this.accessTokenExpiresAtInSeconds--; // Decrement countdown
      }

      if (this.refreshTokenExpiresAtInSeconds > 0) {
        this.refreshTokenExpiresAtInSeconds--; // Decrement countdown
      }

      if (this.accessTokenExpiresAtInSeconds <= 0 && this.refreshTokenExpiresAtInSeconds <= 0) {
        clearInterval(this.timer); // Stop timer when countdown reaches 0
        this.isTimerRunning = false;
        //this.userService.logout();
      }
    }, 1000); // Update countdown every second (1000 milliseconds)
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    clearInterval(this.timer);
  }

  isAdmin(): boolean {
    return this.userService.isAdmin();
  }
  isSuperAdmin(): boolean {
    return this.userService.isSuperAdmin();
  }
}
