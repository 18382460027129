import parsePhoneNumberFromString, {CountryCode, parsePhoneNumber} from "libphonenumber-js";

export class PhoneUtil {
  private static internationalPrefix = "+"

  static isCountryCode(value: string): boolean {
    return (value as CountryCode) === value;
  }

  static isValidPhone(phone: string, countryCode: string): boolean {
    if (phone == null || phone.trim().length === 0 || !this.isCountryCode(countryCode)) {
      return false
    }
    const cleanedPhone = phone.trim();
    const localPhone = parsePhoneNumberFromString(cleanedPhone, {
      defaultCountry: countryCode as CountryCode,
      extract: false
    })
    if (!localPhone) {
      return false
    }
    if (localPhone.country !== countryCode) {
      const internationalPhone = parsePhoneNumber(cleanedPhone);
      return internationalPhone.isValid() && internationalPhone.number[0] === PhoneUtil.internationalPrefix;
    }
    return localPhone.isValid()
  }

  static validInternational(phone: string): boolean {
    if (phone == null || phone.trim().length === 0) {
      return false
    }
      const internationalPhone = parsePhoneNumberFromString(phone.trim());

      return internationalPhone && internationalPhone.isValid() && internationalPhone.number[0] === PhoneUtil.internationalPrefix;
  }
}
