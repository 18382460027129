export interface RespWrapper<T> {
  data: T;
}

export interface RespPaginated<T> {
  items: T[];
  totalCount: number;
}

export class Paginated<T> {
  items: T[];
  totalCount: number;

  static of<T>(resp: Paginated<T>, clazz: { new(item: any): T }): Paginated<T> {
    const i = resp.items.map(item => new clazz(item));

    const paginated = new Paginated<T>();
    paginated.items = i;
    paginated.totalCount = resp.totalCount;
    return paginated;
  }
}
