import {CompanionToken} from "../../auth/companion-token";
import {UUID} from "../../domain/uuid";
import {BehaviorSubject, filter, Observable, switchMap, take} from "rxjs";
import {storageKeys} from "../storage-helper";
import {Authentication} from "../../auth/authentication";
import {GlobalStorageAction, GlobalStorageService} from "./global-stroage-service";
import {Injectable} from "@angular/core";

export interface TokenUserData {
  userId: string;
  roles: string[];
  refreshTokenExpires: number;
  accessTokenExpires: number;
  authentication: Authentication;
}

const initialTokenData: TokenUserData = {
  userId: null,
  roles: [],
  refreshTokenExpires: 0,
  accessTokenExpires: 0,
  authentication: {
    accessToken: null,
    refreshToken: null,
    refreshTokenExpiration: null
  }
}

@Injectable(
  {providedIn: 'root'}
)
export class TokenStorageService {
  userId?: UUID;
  roles: string[];
  refreshTokenExpires?: number;
  accessTokenExpires?: number;

  private tokenSubject: BehaviorSubject<TokenUserData>;
  private internalToken$: Observable<TokenUserData>;

  public initialized$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly globalStorageService: GlobalStorageService,
  ) {
    const authentication = this.loadFromLocalStorage();
    const extractedData = this.extractDataFromAccessToken(authentication);
    this.setInternalTokenData(extractedData);

    this.tokenSubject = new BehaviorSubject<TokenUserData>(extractedData);
    this.initialized$.next(true);

    this.internalToken$ = this.tokenSubject.asObservable();

    this.globalStorageService.action$.subscribe(action => {
      if (action === GlobalStorageAction.CLEAR){
        this.clear();
      }
    });

    this.addListenerOnAccessTokenChange();
  }

  private addListenerOnAccessTokenChange(): void {
    window.addEventListener('storage', (event) => {
      if (event.storageArea === localStorage && event.key === storageKeys.accessToken) {
        if (this.userId && !event.newValue) {
          console.log("users access token hase been manually removed");
          this.globalStorageService.clearStorage();
        }
      }
    });
  }

  public get token$(): Observable<TokenUserData> {
    if (!this.initialized$.value){
      return this.initialized$.pipe(
        filter((initialized) => initialized),
        take(1),
        switchMap(() => this.internalToken$)
      );
    }
    return this.internalToken$;
  }

  private setInternalTokenData(tokenData: TokenUserData): void {
    this.userId = tokenData.userId;
    this.roles = tokenData.roles;
    this.refreshTokenExpires = tokenData.refreshTokenExpires;
    this.accessTokenExpires = tokenData.accessTokenExpires;
    this.refreshTokenExpires = tokenData.refreshTokenExpires;
    this.accessTokenExpires = tokenData.accessTokenExpires;
  }


  private loadFromLocalStorage(): Authentication {
    const accessToken = localStorage.getItem(storageKeys.accessToken);
    const refreshToken = localStorage.getItem(storageKeys.refreshToken);
    const accessTokenExpiresAt = localStorage.getItem(storageKeys.refreshTokenExpiration);
    return {
      accessToken: accessToken ?? null,
      refreshToken: refreshToken ?? null,
      refreshTokenExpiration: accessTokenExpiresAt ?? null
    }
  }

  private extractDataFromAccessToken(token: Authentication): TokenUserData {
    if(!token?.accessToken || !token.refreshToken) return {
      userId: null,
      roles: [],
      refreshTokenExpires: 0,
      accessTokenExpires: 0,
      authentication: token
    }

    const companionToken = CompanionToken.of(token?.accessToken)
    return {
      userId: companionToken?.userId ?? null,
      roles: companionToken?.roles ?? [],
      refreshTokenExpires: Number(token.refreshTokenExpiration) ?? 0,
      accessTokenExpires: companionToken?.expiresAt ?? 0,
      authentication: token
    }
  }

  setToken(authentication: Authentication): void {
    localStorage.setItem(storageKeys.accessToken, authentication.accessToken);
    localStorage.setItem(storageKeys.refreshToken, authentication.refreshToken);
    localStorage.setItem(storageKeys.refreshTokenExpiration, authentication.refreshTokenExpiration);

    const extractedData = this.extractDataFromAccessToken(authentication);

    this.setInternalTokenData(extractedData);
    this.tokenSubject.next(extractedData);
  }

  clear(): void {
    this.setInternalTokenData(initialTokenData);
    this.tokenSubject.next(initialTokenData);

    localStorage.removeItem(storageKeys.accessToken);
    localStorage.removeItem(storageKeys.refreshToken);
    localStorage.removeItem(storageKeys.refreshTokenExpiration);
  }
}
