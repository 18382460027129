import {AbstractControl, ValidationErrors, ValidatorFn} from "@angular/forms";
import {PhoneUtil} from "./phone-util";

export class FormUtil {

  static validatePhone(countryCode: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value != null && control.value.trim() == "") {
        return null;
      }
      return PhoneUtil.isValidPhone(control.value, countryCode) ? null : {phoneError: control.value};
    }
  }

  static validateInternational(control: AbstractControl): ValidationErrors | null {
    if (control.value == null || control.value.trim() == "") {
      return null;
    }
    return PhoneUtil.validInternational(control.value) ? null : {phoneError: control.value};
  }
}
