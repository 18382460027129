<div class="max-w-screen-2xl xl:px-16 md:p-4 py-2">
  <mat-card class="lg:p-6">
    <mat-card-header class="flex items-center">
      <h1 style="margin-bottom: 4px">{{ 'ASSETS' | translate }}</h1>
    </mat-card-header>
    <div class="px-4">
      <hr class="mb-8">
    </div>
    <mat-spinner *ngIf="!assets"></mat-spinner>

    <mat-card-content *ngIf="assets">
      <div class="flex flex-col md:flex-row justify-between mb-4 md:items-center">
        <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
          <mat-label>{{ 'COMMON_SEARCH' | translate }}</mat-label>
          <button *ngIf="searchCtrl.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
            <mat-icon>close</mat-icon>
          </button>
          <mat-icon matSuffix *ngIf="!searchCtrl.value">search</mat-icon>
          <input matInput [formControl]="searchCtrl">
        </mat-form-field>
        <div class="w-full flex justify-end">
          <button mat-flat-button color="primary" (click)="create()">{{ "COMMON_CREATE" | translate }}</button>
        </div>
      </div>

      <div>
        <table class="w-full hidden md:table table-fixed">
          <thead>
          <tr class="bg-gray-200">
            <th class="text-left px-2 py-4 flex flex-row cursor-pointer" (click)="toggleSort('name')">
              <header-sort-icon name="name" [sortUtil]="paginator"></header-sort-icon>
              <span class="ml-1">{{ "COMMON_NAME" | translate }}</span>
            </th>
            <th class="text-left px-2 py-4 cursor-pointer">{{ "COMMON_DESCRIPTION" | translate }}</th>
            <th class="text-left px-2 py-4 cursor-pointer" (click)="toggleSort('modelName')">
              <header-sort-icon name="modelName" [sortUtil]="paginator"></header-sort-icon>
              {{ "ASSET_MODEL_NAME" | translate }}
            </th>
            <th class="text-left px-2 py-4 cursor-pointer" (click)="toggleSort('modelNumber')">
              <header-sort-icon name="modelNumber" [sortUtil]="paginator"></header-sort-icon>
              {{ "ASSET_MODEL_NUMBER" | translate }}
            </th>
            <th class="w-24 text-left px-2 py-4">{{ "COMMON_STATUS" | translate }}</th>
          </tr>
          </thead>
          <tbody>
            @for (asset of assets; track asset.id) {
              <tr (click)="navigateToEdit(asset)" enterSpacePress
                  class="hover:bg-gray-100 cursor-pointer border-t-2" tabindex="0">
                <td class="text-left p-2">{{ asset.name }}</td>
                <td class="text-left p-2">{{ asset.description }}</td>
                <td class="text-left p-2">{{ asset.modelName }}</td>
                <td class="text-left p-2">{{ asset.modelNumber }}</td>
                <td class="w-24 text-left p-2">
                  <div [ngClass]="asset.deleted ? 'status-icon-inactive' : 'status-icon-success'"
                       class="inline-block px-2.5 py-1 rounded-xl text-center">
                    {{ (asset.deleted ? 'COMMON_INACTIVE' : 'COMMON_ACTIVE') | translate }}
                  </div>
                </td>
              </tr>
            } @empty {
              <tr class="p-2">{{ "COMMON_NO_ENTRIES" | translate }}</tr>
            }
          </tbody>
        </table>

        <ul class="md:hidden divide-y list-none">
          @for (asset of assets; track asset.id) {
            <li class="hover:bg-gray-100 cursor-pointer p-2 list-none">
              <div (click)="navigateToEdit(asset)" (keydown.space)="triggerClick($event)"
                   (keydown.enter)="triggerClick($event)" tabindex="0" role="button">
                <div class="flex items-center justify-between space-x-2">
                  <div class="flex flex-row">
                    <div class="font-bold">{{ asset.name }}</div>
                  </div>
                  <div [ngClass]="asset.deleted ? 'status-icon-inactive' : 'status-icon-success'"
                       class="inline-block px-2.5 py-1 rounded-xl">
                    {{ (asset.deleted ? 'COMMON_INACTIVE' : 'COMMON_ACTIVE') | translate }}
                  </div>
                </div>
                <div>
                  <div class="text-sm">{{ asset.description }}</div>
                </div>
              </div>
            </li>
          } @empty {
            <li class="p-2">{{ "COMMON_NO_ENTRIES" | translate }}</li>
          }
        </ul>

        <mat-paginator class="mt-2" showFirstLastButtons
                       [pageSizeOptions]="paginator.pageSizeOptions"
                       [pageSize]="paginator.pageSize"
                       [pageIndex]="paginator.pageIndex"
                       [length]="paginator.totalCount"
                       (page)="onPageChange($event)">
        </mat-paginator>
      </div>

    </mat-card-content>
  </mat-card>
</div>
