import {Injectable} from "@angular/core";
import {ApiService} from "../../core/api-service";
import {map, Observable} from "rxjs";
import {EnvironmentService} from "../../core/environment-service";
import {RespWrapper} from "../resp-wrapper";
import {Customer} from "../customer/customer";
import {FacilityGroup} from "./facility-group";
import {UUID} from "../uuid";

@Injectable(
  {providedIn: 'root'}
)
export class FacilityGroupService {
  apiPath = '/v1/FacilityGroups';

  constructor(private apiService: ApiService, private environmentService: EnvironmentService) {
  }

  public byCustomer(customer: Customer): Observable<FacilityGroup[]> {
    const url = `${this.apiPath}?customerId=${customer.id}`;
    return this.apiService.get<RespWrapper<FacilityGroup[]>>(url)
      .pipe(map((resp => resp.data.map(g => FacilityGroup.fromJSON(g)))));
  }

  createOrUpdate(id: string, data: Partial<FacilityGroup>): Observable<FacilityGroup> {
    if (id) {
      const url = `${this.apiPath}/${id}`;
      return this.apiService.patch<RespWrapper<FacilityGroup>>(url, data)
        .pipe(map(resp => FacilityGroup.fromJSON(resp.data)));
    }
    return this.apiService.post<RespWrapper<FacilityGroup>>(this.apiPath, data)
      .pipe(map(resp => FacilityGroup.fromJSON(resp.data)));
  }

  move(id: string, parentId: UUID, order: number) {
    const url = `${this.apiPath}/${id}/Move`;
    return this.apiService.patch(url, {parentId, order});
  }

  removeFromGroup(group: FacilityGroup, facilityId: UUID): Observable<FacilityGroup> {
    const url = `${this.apiPath}/${group.id}/Facilities/${facilityId}/remove`;
    return this.apiService.delete<RespWrapper<FacilityGroup[]>>(url)
      .pipe(map(resp => FacilityGroup.fromJSON(resp.data)));
  }

  addToGroup(group: FacilityGroup, facilityId: UUID) {
    const url = `${this.apiPath}/${group.id}/Facilities/${facilityId}/add`;
    return this.apiService.post<RespWrapper<FacilityGroup[]>>(url, {})
      .pipe(map(resp => FacilityGroup.fromJSON(resp.data)));
  }
}
