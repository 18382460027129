<div class="max-w-screen-2xl lg:px-16 md:p-4 py-2">
  <mat-card class="lg:p-6 mat-mdc-elevation-specific mat-elevation-z0"
            style="background-color: transparent !important;">
    <mat-card-header class="flex items-center">
      <h1 style="margin-bottom: 4px">
        <span>{{ 'BOOKING' | translate }}&nbsp;&ndash;&nbsp;</span>
        <span *ngIf="title">{{ (title | translate).toLowerCase() }}</span>
      </h1>
    </mat-card-header>
    <hr class="h-0.5 mb-8 dark:bg-gray-300">
    <mat-spinner *ngIf="!form"></mat-spinner>

    <mat-card-content *ngIf="form">
      <form *ngIf="form" [formGroup]="form" class="flex flex-wrap">
        <div class="grid grid:cols-1 xl:grid-cols-2 gap-4">
          <div class="grid grid:cols-1 gap-4">
            <mat-card class="card-border mat-mdc-elevation-specific mat-elevation-z0">
              <mat-card-title>
                <div class="text-sm p-2 pl-4 font-bold">{{ "Bass" }}</div>
              </mat-card-title>
              <mat-card-content>

                <mat-form-field class="w-full md:p-2" appearance="outline">
                  <mat-label>{{ "COMMON_TITLE" | translate }}</mat-label>
                  <input matInput formControlName="title">
                </mat-form-field>

                <mat-form-field class="w-full md:p-2" appearance="outline">
                  <mat-label>{{"DESCRIPTION" | translate}}</mat-label>
                  <textarea rows="3" matInput formControlName="description"></textarea>
                </mat-form-field>

                <div class="md:p-2">
                  <date-input [control]="form.controls.expectedCompletionDate" label="BOOKING_EXPECTED_COMPLETED_DATE" />
                </div>

                <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
                  <mat-label>{{"BOOKING_STATUS" | translate}}</mat-label>
                  <mat-select formControlName="status">
                    <mat-option *ngFor="let status of bookingStatus" [value]="status">
                      {{ translateBookingStatus(status) }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <div class="w-full md:w-1/2 md:p-2">
                  <mat-checkbox color="primary" formControlName="isActive">
                    <span class="whitespace-nowrap">{{ "BOOKING_IS_ACTIVE" | translate }}</span>
                  </mat-checkbox>
                </div>


              </mat-card-content>
            </mat-card>
          </div>
        </div>

      </form>
    </mat-card-content>



    <mat-card-actions class="mt-4 flex justify-between">
      <button type="button" mat-flat-button (click)="delete()" [disabled]="!booking?.id">{{ "COMMON_DELETE" | translate }}</button>
      <div>
        <button type="button" mat-flat-button (click)="cancel()">{{ "COMMON_CANCEL" | translate }}</button>
        <button type="button" color="primary" class="ml-2" mat-flat-button [disabled]="!canSave()" (click)="save()">
          {{ (booking?.id ? "COMMON_UPDATE" : "COMMON_SAVE")| translate }}
          <fa-icon *ngIf="inProgress" [icon]="faSpinner" animation="spin" class="ml-1"/>
        </button>
      </div>
    </mat-card-actions>
  </mat-card>
</div>
