<div class="max-w-screen-2xl xl:px-16 md:p-4 py-2 px-2">

  <mat-card>
    <mat-card-content>
      <div class="flex justify-around">
        <div class="flex flex-col items-center">
          <div class="mb-2 flex justify-center">
            {{ "EVENTS" | translate }}
          </div>
          <h5 class="mb-2 font-bold text-primary text-center">344 st</h5>
          <div class="font-thin text-xs">Senaste 5 dagarna</div>
        </div>
        <div style="width: 1.25px"
          class=" self-stretch border-t-0 bg-gradient-to-tr from-transparent via-neutral-300 to-transparent opacity-10 dark:opacity-100">
        </div>

        <div class="flex flex-col items-center">
          <div class="mb-2 flex justify-center">
            {{ "INCIDENTS_CLOSED" | translate }}
          </div>
          <h5 class="mb-2 font-bold text-primary text-center">23 st</h5>
          <div class="font-thin text-xs">Senaste 5 dagarna</div>
        </div>
        <div style="width: 1.25px"
          class=" self-stretch border-t-0 bg-gradient-to-tr from-transparent via-neutral-300 to-transparent opacity-25 dark:opacity-100">
        </div>
        <div class="flex flex-col items-center">
          <div class="mb-2 flex justify-center">
            <div class="text-sm">{{ "ASSETS" | translate }}</div>
          </div>
          <h5 class="mb-2 font-bold text-primary text-center">1223 st</h5>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <div class="flex justify-center items-center flex-col mt-4 mb-12 md:mt-24 md:mb-40 p-0 ">
    <h2 style="margin-bottom: 4px" class="text-sm font-thin">{{ 'EVENT_TITLE_CREATE' | translate }}</h2>
    <mat-card class="max-w-screen-sm justify-around">
      <div class="grid grid-cols-2 border-b-2 border-gray-300 color-button-quickactions-bg ">
        <div class="flex flex-col p-4 border-r-2 border-gray-300 quickaction-square-button" role="button" tabindex="0"
          mat-ripple (click)="toIncident()">
          <div class="flex justify-center">
            <fa-icon [icon]="faBolt" class="mr-2 color-icon-quickaction" size="2x" />
          </div>
          <div class="text-center color-icon-quickaction">{{ 'INCIDENT' | translate }}</div>
        </div>
        <div class="flex flex-col p-4 quickaction-square-button" role="button" tabindex="0" mat-ripple>
          <div class="flex justify-center ">
            <fa-icon [icon]="faCalendar" class="mr-2 color-icon-quickaction" size="2x" />
          </div>
          <div class="text-center color-icon-quickaction">{{ 'BOOKING' | translate }}</div>
        </div>
      </div>

      <div class="grid grid-cols-2 color-button-quickactions-bg">
        <div class="flex flex-col p-4 border-r-2 border-gray-300 quickaction-square-button" role="button" tabindex="0"
          mat-ripple>
          <div class="flex justify-center">
            <fa-icon [icon]="faBellConcierge" class="mr-2 color-icon-quickaction" size="2x" />
          </div>
          <div class="text-center color-icon-quickaction">{{ 'SERVICE' | translate }}</div>
        </div>
        <div class="flex flex-col p-4 quickaction-square-button" role="button" tabindex="0" mat-ripple>
          <div class="flex justify-center">
            <fa-icon [icon]="faOtter" class="mr-2 color-icon-quickaction" size="2x" />
          </div>
          <div class="text-center color-icon-quickaction">{{ 'Övrigt' | translate }}</div>
        </div>
      </div>
    </mat-card>
  </div>

  <mat-card class="lg:p-6">
    <mat-card-header class="flex items-center">
      <h2 style="margin-bottom: 4px" class="text-base">{{ 'EVENTS_LATEST' | translate }}</h2>
    </mat-card-header>
    <div class="px-4">
      <hr class="mb-2">
    </div>

    <mat-spinner *ngIf="!events"></mat-spinner>

    <mat-card-content *ngIf="events">
      <ul class="divide-y list-none">
        <li *ngFor="let event of events" class="hover:bg-gray-100 cursor-pointer p-2 list-none">
          <div (click)="navigateToEdit(event)" (keydown.space)="triggerClick($event)"
            (keydown.enter)="triggerClick($event)" tabindex="0" role="button">
            <div class="flex items-center justify-between space-x-2">
              <div class="flex flex-row">
                <div class="font-bold">{{ event.title }}</div>
              </div>
            </div>
            <div>{{ event.created_at | dateTime }}</div>
          </div>
        </li>
      </ul>
    </mat-card-content>
  </mat-card>
</div>