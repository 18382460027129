import {ChangeDetectionStrategy, Component, OnInit} from "@angular/core";

@Component({
    templateUrl: "events.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
  }
)
export class EventsComponent implements OnInit {


  ngOnInit(): void {
    console.log("EventsComponent.ngOnInit");
  }

}
