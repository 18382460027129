import {BreakpointObserver, Breakpoints, BreakpointState} from "@angular/cdk/layout";
import {map, Observable} from "rxjs";

export class Device {
  private static breakpoints = [Breakpoints.Small, Breakpoints.XSmall];
  breakpointState: BreakpointState;

  constructor(state: BreakpointState) {
    this.breakpointState = state;
  }

  static of(observer: BreakpointObserver): Observable<Device> {
    return observer.observe(Device.breakpoints).pipe(map(state => this.ofState(state)));
  }

  private static ofState(state: BreakpointState): Device {
    return new Device(state);
  }

  get isMobile(): boolean {
    return this.breakpointState.breakpoints[Breakpoints.Small] || this.breakpointState.breakpoints[Breakpoints.XSmall];
  }

  get isDesktop(): boolean {
    return !this.isMobile;
  }
}
