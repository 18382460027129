<div class="max-w-screen-2xl lg:px-16 md:p-4 py-2">
  <mat-card class="lg:p-6 mb-6">
    <mat-card-header class="flex items-center justify-start">
      <h1>
        <span class="font-bold">{{'FACILITY' | translate}}&nbsp;&ndash;&nbsp;</span>
        <span *ngIf="title" class="font-italic">{{(title | translate).toLowerCase()}}</span>
      </h1>
    </mat-card-header>
    <hr class="h-px mb-8">
    <mat-spinner *ngIf="!form"></mat-spinner>

    <mat-card-content *ngIf="form" [formGroup]="form">
      <div class="flex flex-wrap -mx-2">
        <!-- General Information Section -->
        <div class="w-full lg:w-1/2 px-2 mb-6">
          <h2 class="mb-4 font-bold">{{ "COMMON_GENERAL_INFO" | translate }}</h2>
          <mat-form-field class="w-full" appearance="outline">
            <mat-label for="facilityName">{{"FACILITY_NAME" | translate}}</mat-label>
            <input matInput formControlName="name" id="facilityName">
          </mat-form-field>

          <mat-form-field class="w-full" appearance="outline">
            <mat-label for="facilityDescription">{{"FACILITY_DESCRIPTION" | translate}}</mat-label>
            <textarea matInput formControlName="description" id="facilityDescription"></textarea>
          </mat-form-field>
        </div>

        <!-- Location Details Section -->
        <div class="w-full lg:w-1/2 px-2 mb-6">
          <h2 class="mb-4 font-bold">{{ "LOCATION_DETAILS" | translate }}</h2>
          <ng-container formGroupName="address">
            <mat-form-field class="w-full" appearance="outline">
              <mat-label for="addressStreet">{{"COMMON_ADDRESS" | translate}}</mat-label>
              <input matInput formControlName="street" id="addressStreet">
            </mat-form-field>

            <div class="flex -mx-2">
              <mat-form-field class="w-1/2 px-2" appearance="outline">
                <mat-label for="addressCity">{{"COMMON_CITY" | translate}}</mat-label>
                <input matInput formControlName="city" id="addressCity">
              </mat-form-field>

              <mat-form-field class="w-1/2 px-2" appearance="outline">
                <mat-label for="addressPostalCode">{{"COMMON_POSTAL_CODE" | translate}}</mat-label>
                <input matInput formControlName="postalCode" id="addressPostalCode">
              </mat-form-field>
            </div>

            <mat-form-field class="w-full" appearance="outline">
              <mat-label for="countrySelect">{{"COMMON_COUNTRY" | translate}}</mat-label>
              <mat-select formControlName="country" id="countrySelect">
                <mat-option *ngFor="let country of countriesList" [value]="country.code">
                  {{ country.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>

          <div class="flex -mx-2">
            <mat-form-field class="w-1/2 px-2" appearance="outline">
              <mat-label for="facilityLongitude">{{"FACILITY_LONGITUDE" | translate}}</mat-label>
              <input type="number" matInput formControlName="longitude" id="facilityLongitude">
            </mat-form-field>

            <mat-form-field class="w-1/2 px-2" appearance="outline">
              <mat-label for="facilityLatitude">{{"FACILITY_LATITUDE" | translate}}</mat-label>
              <input type="number" matInput formControlName="latitude" id="facilityLatitude">
            </mat-form-field>
          </div>
        </div>
      </div>

      <!-- Contact Information Section -->
      <div class="mb-6">
        <h2 class="mb-4 font-bold">{{ "COMMON_CONTACT_INFO" | translate }}</h2>
        <div class="flex flex-wrap -mx-2">
          <mat-form-field class="w-full lg:w-1/3 px-2" appearance="outline">
            <mat-label for="contactEmail">{{"FACILITY_CONTACT_EMAIL" | translate}}</mat-label>
            <input matInput formControlName="contactEmail" id="contactEmail">
          </mat-form-field>

          <mat-form-field class="w-full lg:w-1/3 px-2" appearance="outline">
            <mat-label for="contactPhone">{{"FACILITY_CONTACT_PHONE" | translate}}</mat-label>
            <input matInput formControlName="contactPhone" id="contactPhone">
          </mat-form-field>

          <mat-form-field class="w-full lg:w-1/3 px-2" appearance="outline">
            <mat-label for="contactName">{{"FACILITY_CONTACT_NAME" | translate}}</mat-label>
            <input matInput formControlName="contactName" id="contactName">
          </mat-form-field>
        </div>
      </div>

      <!-- Administrative Settings Section (Conditional) -->
      @if (appAdminRoute) {
      <div class="mb-6">
        <h2 class="mb-4 font-bold">{{ 'FACILITY_SETTINGS' | translate }}</h2>
        <mat-form-field class="w-full" appearance="outline">
          <mat-label for="customerSelect">{{ 'CUSTOMER' | translate }}</mat-label>
          <mat-select formControlName="customerId" id="customerSelect">
            <mat-option *ngFor="let customer of customers" [value]="customer.id">
              {{ customer.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      }

      <div class="flex justify-between">
        <button *ngIf="isEditing()" mat-button color="warn" (click)="remove()" [disabled]="inProgress"
          class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
          <mat-icon>delete</mat-icon>
          {{'COMMON_DELETE' | translate}}
        </button>
        <div>
          <button mat-flat-button (click)="cancel()" [disabled]="inProgress"
            class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
            <mat-icon>cancel</mat-icon>
            {{'COMMON_CANCEL' | translate}}
          </button>
          <button type="button" mat-flat-button color="primary" [disabled]="!canSave()" (click)="save()"
            class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ml-4">
            <mat-icon>save</mat-icon>
            {{"COMMON_SAVE" | translate}}
            <fa-icon *ngIf="inProgress" [icon]="faSpinner" animation="spin" class="ml-1"></fa-icon>
          </button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <!-- Accordion for Map Display -->
  <mat-accordion multi="true" class="mb-6">
    <mat-expansion-panel *ngIf="facility?.latitude && facility?.longitude">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'MAP_PREVIEW' | translate }}
        </mat-panel-title>
        <mat-panel-description>
          <button mat-stroked-button (click)="$event.stopPropagation(); openDirections()"
            class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
            {{'FACILITY_OPEN_MAP' | translate}}
          </button>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div class="flex justify-center items-center">
        <img [src]="mapUrl" alt="Map preview" class="w-full max-w-full max-h-[500px]">
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>