import {ObjectMapperHelper} from "../../core/object-mapper-helper";
import {UUID} from "../uuid";
import {AddressDetails} from "../address-details";

export class Facility {
  readonly id: UUID;
  readonly customerId: UUID | null;
  readonly name: string;
  readonly description: string;
  readonly address: AddressDetails;
  readonly contactPhone: string;
  readonly contactEmail: string;
  readonly contactName: string;
  readonly latitude: number;
  readonly longitude: number;
  readonly isActive: boolean;

  constructor(data: any) {
    const helper = ObjectMapperHelper.of(data);
    this.id = helper.getUUID('id');
    this.customerId = helper.getUUID('customerId');
    this.name = helper.requireString('name');
    this.description = helper.getString('description');
    this.address = new AddressDetails(data?.address);
    this.contactPhone = helper.getString('contactPhone');
    this.contactEmail = helper.getString('contactEmail');
    this.contactName = helper.getString('contactName');
    this.latitude = helper.getNumber('latitude');
    this.longitude = helper.getNumber('longitude');
    this.isActive = helper.getBoolean('isActive');
  }

  get countryTranslateKey(): string {
    return `COUNTRY_${this.address.country}`;
  }
}
