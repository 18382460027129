import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {EnvironmentService} from "../../core/environment-service";
import {LanguageCountry} from "../../domain/language-country";
import {User} from "../../domain/user/user";
import {MaskUtil} from "../../core/mask-util";
import {FormUtil} from "../../core/form-util";
import {Tenant} from "../../domain/tenant/tenant";
import {UUID} from "../../domain/uuid";
import {UserRole} from "../../domain/user/user-role";

export interface UserForm {
  userName: FormControl<string>;
  email: FormControl<string>;
  firstName: FormControl<string>;
  lastName: FormControl<string>;
  socialSecurityNumber: FormControl<string>;
  phoneNumber: FormControl<string>;
  isActive: FormControl<boolean>;
  tenantId?: FormControl<UUID>;
  roles: FormControl<string[]>;
}

@Component({
  selector: "user-form",
  templateUrl: './user-form.component.html',
})
export class UserFormComponent implements OnInit {
  maskUtil = new MaskUtil();
  form: FormGroup<UserForm>;
  countriesList: LanguageCountry[] = [];
  subscription = new Subscription();
  roles: UserRole[] = Object.values(UserRole);

  @Input() isManagement = false;
  @Input() user: User;
  @Input() tenants: Tenant[];
  @Output() userForm = new EventEmitter<FormGroup<UserForm>>();

  constructor(private fb: FormBuilder, private environmentService: EnvironmentService) {
  }

  ngOnInit() {
    this.countriesList = this.environmentService.langCountry;
    this.initForm();
    this.form.valueChanges.subscribe(() => {
      this.userForm.emit(this.form);
    });
  }

  initForm(): void {
    const user = this.user;
    this.form = this.fb.group({
      userName: [user?.userName, Validators.required],
      email: [user?.email, Validators.required],
      firstName: [user?.firstName, Validators.required],
      lastName: [user?.lastName, Validators.required],
      socialSecurityNumber: [user?.socialSecurityNumber, Validators.required],
      phoneNumber: [user?.phoneNumber, FormUtil.validateInternational],
      isActive: user?.isActive ?? true,
      roles: [user?.roles]
    });

    if (this.isManagement) {
      this.form.addControl('tenantId', new FormControl(user?.tenantId, Validators.required));
    }
  }
}
