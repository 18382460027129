import {ObjectMapperHelper} from "../../core/object-mapper-helper";
import {UUID} from "../uuid";
import {Metadata} from "../metadata";

export class SlaDuration extends Metadata {
  readonly id: UUID;
  readonly name: string;
  readonly description: string;
  readonly durationMinutes: number;
  readonly customerId: UUID;
  readonly tenantId: UUID;

  constructor(data: any) {
    super(data);
    const helper = ObjectMapperHelper.of(data);

    this.id = helper.getUUID('id');
    this.name = helper.requireString('name');
    this.description = helper.getString('description');
    this.durationMinutes = helper.getNumber('durationMinutes');
    this.customerId = helper.getUUID('customerId');
    this.tenantId = helper.getUUID('tenantId');
  }
}
