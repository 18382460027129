<h1 mat-dialog-title>{{"ASSET" | translate}}</h1>
<mat-dialog-content>

  <table class="w-full md:table table-fixed">
    <tbody>
    <tr>
      <td class="text-left p-2">{{"ASSET_MODEL_NAME" | translate}}</td>
      <td class="text-left font-bold p-2">{{asset.modelName}}</td>
    </tr>
    <tr>
      <td class="text-left p-2">{{"ASSET_MODEL_NUMBER" | translate}}</td>
      <td class="text-left font-bold p-2">{{asset.modelNumber}}</td>
    </tr>
    <tr>
      <td class="text-left p-2">{{"DESCRIPTION" | translate}}</td>
      <td class="text-left font-bold p-2">{{asset.description}}</td>
    </tr>
    <tr>
      <td class="text-left p-2">{{"ASSET_DEPTH" | translate}}</td>
      <td class="text-left font-bold p-2">{{asset.depth}}</td>
    </tr>
    <tr>
      <td class="text-left p-2">{{"ASSET_HEIGHT" | translate}}</td>
      <td class="text-left font-bold p-2">{{asset.height}}</td>
    </tr>
    <tr>
      <td class="text-left p-2">{{"ASSET_INSTALLATION_DATE" | translate}}</td>
      <td class="text-left font-bold p-2">{{asset.installationDate}}</td>
    </tr>
    <tr>
      <td class="text-left p-2">{{"ASSET_LAST_SERVICE_DATE" | translate}}</td>
      <td class="text-left font-bold p-2">{{asset.lastServiceDate}}</td>
    </tr>
    <tr>
      <td class="text-left p-2">{{"ASSET_NAME" | translate}}</td>
      <td class="text-left font-bold p-2">{{asset.name}}</td>
    </tr>
    <tr>
      <td class="text-left p-2">{{"ASSET_REFRIGERANT_AMOUNT" | translate}}</td>
      <td class="text-left font-bold p-2">{{asset.refrigerantAmount}}</td>
    </tr>
    <tr>
      <td class="text-left p-2">{{"ASSET_COST_ACCOUNT_NUMBER" | translate}}</td>
      <td class="text-left font-bold p-2">{{asset.costAccountNumber}}</td>
    </tr>
    </tbody>
  </table>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button type="button" mat-flat-button color="primary" (click)="onClose()">{{"COMMON_CLOSE" | translate}}</button>
</mat-dialog-actions>
