import {UUID, UUIDUtil} from "../domain/uuid";

export class ObjectMapperHelper {
  readonly data: any;

  constructor(data: any) {
    this.data = data;
  }

  static of(json: string): ObjectMapperHelper {
    return new ObjectMapperHelper(json);
  }


  getString(key: string): string | null {
    return this.getValue(key);
  }

  isNumeric(n: any) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  getValue(key: string): any {
    return this.data[key] ?? null;
  }

  getUUID(key: string): UUID | null {
    const value = this.getValue(key);

    if (this.isStringType(value) && UUIDUtil.isValid(value)){
      return value
    }
    if (value == null) {
      return value;
    }

    throw new Error(`ObjectMapperHelper Type UUDI mismatch for property ${key} got value ${value}`);
  }

  getArray<T>(key: string): T[]  {
      const value = this.getValue(key);

      if(Array.isArray(value)){
        return value;
      }
      return []
  }

  getEnum<T>(key: string, enumType: T): T[keyof T]{
    const enumValues = Object.values(enumType);
    const str = this.getString(key);

    for (const value of enumValues) {
        if (value === str) {
            return value as T[keyof T];
        }
    }
    return null;
    // throw new Error(`ObjectMapperHelper ENUM mismatch for property ${key} got value ${str}`);
  }

  requiredUUID(key: string): UUID {
    const value = this.getValue(key);

    if (this.isStringType(value) && UUIDUtil.isValid(value)){
      return value
    }
    throw new Error(`ObjectMapperHelper Missing required UUID property ${key} got value ${value}`);
  }

  getObject<T>(key: string, clazz: new (value: string) => T): T | null {
    const value = this.getValue(key);
    if (value== null || !this.isObjectType(value)) {
      return null;
    }
    return new clazz(value);
  }

  getDate(key: string): Date | null {
    const value = this.getString(key);
    if (value) {
      const parsedDate = new Date(value);
      if (!isNaN(parsedDate.getTime())) {
        return parsedDate;
      }
    }
    return null;
  }

  get(key: string): Date | null {
    const value = this.getString(key);
    if (value) {
      const parsedDate = new Date(value);
      if (!isNaN(parsedDate.getTime())) {
        return parsedDate;
      }
    }
    return null;
  }

  requireString(key: string): string {
    const value = this.getString(key);
    if (!this.isStringType(value)) {
      throw new Error(`ObjectMapperHelper Missing required string property ${key} got value ${value}`);
    }
    return value as string;
  }

  getNumber(key: string): number | null {
    const value = this.getValue(key);
    if (this.isStringType(value) && value.trim().length === 0) {
      return null;
    }
    if (!this.isNumeric(value)) {
      return null;
    }
    return parseFloat(value);
  }

  requireNumber(key: string): number {
    const value = this.getNumber(key);
    if (value == null) {
      throw new Error(`Missing required number property ${key} got value ${value}`);
    }
    return value;
  }

  public getBoolean(key: string): boolean {
    const value = this.getValue(key);

    if (!value) {
      return false;
    }

    if (this.isBooleanType(value)) {
      return value;
    }

    if (this.isNumeric(value)) {
      return value !== 0;
    }

    if (this.isStringType(value)) {
      return false;
    }

    return value !== "false";
  }

  private isBooleanType(value: any): boolean {
    return typeof value === "boolean";
  }

  private isStringType(value: any): boolean {
    return typeof value === "string";
  }

  private isObjectType(value: any): boolean {
    return typeof value === "object";
  }

  fromClazz(key: string, clazz: any) {
    const value = this.getValue(key);
    if (!value || typeof value !== "object") {
      return null;
    }
    return new clazz(value);
  }
}
