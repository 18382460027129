import {Component, EventEmitter, Input, Output} from "@angular/core";
import {CrudinatorField} from "../../domain/crudinator/crudinator-field";
import {CrudinatorFieldType} from "../../domain/crudinator/crudinator-field-type";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";


@Component({
  selector: 'crudinator-edit',
  templateUrl: './crudinator-edit.component.html',
})
export class CrudinatorEditComponent {
  protected readonly faSpinner = faSpinner;
  fieldType = CrudinatorFieldType;
  @Input() fields: CrudinatorField[] | null = null
  @Input() obj: any | null = null
  @Input() inProgress = false;
  @Input() editTitle = "COMMON_CREATE";
  @Input() prefixLabels = "";
  @Input() isCreating = false;


  @Output() saveChange = new EventEmitter<any>();
  @Output() cancelChange = new EventEmitter<void>();
  @Output() removeChange = new EventEmitter<any>();

  onStringChange(value: string, name: string) {
    this.obj[name] = value.trim();
  }


  save(): void {
    this.saveChange.emit(this.obj);
  }

  cancel(): void {
    this.cancelChange.emit();
  }

  remove() {
    this.removeChange.emit(this.obj);
  }
}
