import {Component, Input} from "@angular/core";

@Component({
  selector: 'heading-h1',
  template: `<h1 [class.text-xl]="large">{{ text | translate }}</h1>`,
})
export class HeadingH1Component {
  @Input() text: string;
  @Input() large = false;
}
