import {Injectable} from "@angular/core";
import {ApiService} from "../../core/api-service";
import {map, Observable} from "rxjs";
import {Paginated, RespPaginated, RespWrapper} from "../resp-wrapper";
import {Paginator} from "../../core/paginator";
import {UrlBuilder} from "../url-builder";
import {CreateIncident, Incident, CreateUpdateIncident} from "./incident";
import {UUID} from "../uuid";
import {IncidentDocument} from "./incident_document";

@Injectable(
  {providedIn: 'root'}
)
export class IncidentService {
  apiPath = '/v1/incidents';


  constructor(private apiService: ApiService) {}

  public all(searchTerm: string = null, facilityId: UUID, paginator: Paginator<Incident>): Observable<Paginated<Incident>> {
    const url = UrlBuilder.of(this.apiPath).addPagination(paginator).addSearch(searchTerm).add("facility_id", facilityId).toUrl();
    return this.apiService.get<RespPaginated<Incident>>(url)
      .pipe(map((resp) => Paginated.of<Incident>(resp, Incident)));
  }

  public byId(id: string): Observable<Incident>{
    const url = `${this.apiPath}/${id}`
    return this.apiService.get<RespWrapper<Incident>>(url).pipe(
      map( (resp)=> new Incident(resp.data)));
  }

  public create(incident: Partial<CreateIncident>): Observable<Incident> {
    return this.apiService.post<RespWrapper<Incident>>(this.apiPath, incident).pipe(
      map((resp) => new Incident(resp.data)));
  }

  public update(incident: Partial<CreateUpdateIncident>, id: string): Observable<Incident> {
    return this.apiService.patch<RespWrapper<Incident>>(`${this.apiPath}/${id}`, incident).pipe(
      map((resp) => new Incident(resp.data)));
  }

  removeDocument(documentId: UUID, incidentId: UUID): Observable<any> {
    const url = UrlBuilder.of(`${this.apiPath}/${incidentId}/document/${documentId}`).toUrl();
    return this.apiService.delete<RespWrapper<FormData>>(url).pipe(
      map((resp) => resp.data)
    )
  }

  uploadDocument(file: File, incidentId: UUID, customerId: UUID): Observable<any> {
    const url = UrlBuilder.of(`${this.apiPath}/${incidentId}/document`).toUrl();
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('customerId', customerId)
    return this.apiService.postFormData<RespWrapper<FormData>>(url, formData).pipe(
      map((resp) => resp.data)
    )
  }

  getDocumentWithPreSignedUrl(incidentId: UUID,documentId: UUID): Observable<IncidentDocument> {
    const url = UrlBuilder.of(`${this.apiPath}/${incidentId}/document/${documentId}`).toUrl();
    return this.apiService.get<RespWrapper<string>>(url).pipe(
      map((resp) => new IncidentDocument(resp.data))
    )
  }
}
