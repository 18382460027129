<h2 mat-dialog-title>{{"FORGOT_PASSWORD_TITLE" | translate }}</h2>
<mat-dialog-content>
    <p>{{"FORGOT_PASSWORD_DESCRIPTION" | translate }}</p>
    <mat-form-field class="full-width">
        <mat-label>{{"COMMON_EMAIL" |
            translate}}</mat-label>
        <input matInput placeholder="" [(ngModel)]="email">
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button type="button" mat-flat-button (click)="onCancel()">{{"COMMON_CANCEL" | translate}}</button>
    <button type="button" mat-flat-button color="primary" (click)="resetPassword()">{{"RESET_PASSWORD_BUTTON" |
        translate}}</button>
</mat-dialog-actions>