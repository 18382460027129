import {Injectable} from "@angular/core";
import {ApiService} from "../../core/api-service";
import {map, Observable} from "rxjs";
import {Facility} from "./facility";
import {Paginator} from "../../core/paginator";
import {Paginated, RespPaginated} from "../resp-wrapper";
import {UrlBuilder} from "../url-builder";
import {FacilityStorageService} from "../../core/storage/facility-storage-service";
import {FacilityGroup} from "./facility-group";

@Injectable(
  {providedIn: 'root'}
)
export class FacilityService {
  apiPath = '/v1/facilities';

  constructor(
    private apiService: ApiService,
    private facilityStorageService: FacilityStorageService
  ) {}

  public setFacility(facility: Facility) {
    this.facilityStorageService.setCurrentFacility(facility);
  }

  public all(searchTerm: string = null, paginator: Paginator<Facility>, group: FacilityGroup = null): Observable<Paginated<Facility>> {
    const url = UrlBuilder.of(this.apiPath).addPagination(paginator).addSearch(searchTerm).add("facilityGroupId", group?.id).toUrl();

    return this.apiService.get<RespPaginated<Facility>>(url).pipe(map((resp) => {
      return Paginated.of<Facility>(resp, Facility);
    }));
  }

  public by(facilityId: string): Observable<Facility> {
    const url = `${this.apiPath}/${facilityId}`;
    return this.apiService.get(url).pipe(map((resp: any) => new Facility(resp.data)));
  }

  createOrUpdate(id: string, data: Partial<Facility>) {
    if (id) {
      const url = `${this.apiPath}/${id}`;
      return this.apiService.patch(url, data);
    }
    return this.apiService.post(this.apiPath, data);
  }

  remove(facility: Facility | null) {
    const url = `${this.apiPath}/${facility?.id}`
    return this.apiService.delete(url);
  }
}
