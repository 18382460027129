import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from "./pages/home/home.component";
import {authGuard} from "./auth/auth-guard";
import {LoginComponent} from "./pages/login/login-component";
import {authSuperAdminGuard} from "./auth/auth-super-admin-guard";
import {TenantEditComponent} from "./pages/tenant/tenant-edit.component";
import {CustomersComponent} from './pages/customer/customers.component';
import {CustomerEditComponent} from './pages/customer/customer-edit.component';
import {canDeactivateGuard} from "./core/can-deactivate-guard";
import {RouterParentComponent} from "./router.parent.component";
import {authAdminGuard} from "./auth/auth-admin-guard";
import {FacilityEditAdminComponent} from "./pages/facilities-admin/facility-edit-admin.component";
import {FacilitiesAdminComponent} from "./pages/facilities-admin/facilities-admin.component";
import {AdminManagementComponent} from "./pages/admin-management/admin-management.component";
import {UsersComponent} from "./pages/user/users.component";
import {UserEditComponent} from "./pages/user/user-edit.component";
import {IncidentsComponent} from "./pages/incident/incidents.component";
import {WizardIncidentMainComponent} from "./pages/incident/wizard/wizard-incident-main.component";
import {AssetsComponent} from "./pages/assets/assets.component";
import {AssetEditComponent} from "./pages/assets/asset-edit.component";
import {FacilityPickComponent} from "./pages/facility-pick/facility-pick.component";
import {IncidentEditComponent} from "./pages/incident/incident-edit.component";
import {AdminHomeComponent} from "./pages/admin/admin-home.component";
import {BookingsComponent} from "./pages/bookings/bookings.component";
import {BookingEditComponent} from "./pages/bookings/booking-edit.component";
import {ProviderComponent} from "./pages/provider/provider.component";
import {ProviderEditComponent} from "./pages/provider/provider-edit.component";
import {FacilityGroupsComponent} from "./pages/facility-group/facility-groups.component";
import {UsersManagementAdminComponent} from "./admin-managment/pages/users/users-management-admin.component";
import {UserEditManagementAdminComponent} from "./admin-managment/pages/users/user-edit-management-admin.component";
import {
  CustomerEditManagementAdminComponent
} from "./admin-managment/pages/customers/customer-edit-management-admin.component";
import {
  CustomersManagementAdminComponent
} from "./admin-managment/pages/customers/customers-management-admin.component";
import {TenantEditManagementComponent} from "./admin-managment/pages/tenants/tenant-edit-management.component";
import {TenantsManagementComponent} from "./admin-managment/pages/tenants/tenants-management.component";
import {ProviderManagementAdminComponent} from "./admin-managment/pages/providers/provider-management-admin.component";
import {
  ProviderEditManagementAdminComponent
} from "./admin-managment/pages/providers/provider-edit-management-admin.component";

export enum AppRoutes {
  ADMIN_MANAGEMENT = 'admin-management',
  ADMIN_CUSTOMER = 'admin-customer',
  INCIDENTS = 'events/incidents',
}

export const CheckIfAdminRoute = (url: string): AppRoutes => {
  if (url.includes(AppRoutes.ADMIN_MANAGEMENT)) {
    return AppRoutes.ADMIN_MANAGEMENT
  }
  if (url.includes(AppRoutes.ADMIN_CUSTOMER)) {
    return AppRoutes.ADMIN_CUSTOMER
  }

  return undefined;
}

const routes: Routes = [
  {path: '', redirectTo: '/home', pathMatch: 'full'},
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [authGuard]
  },
  {
    path: 'assets',
    component: AssetsComponent,
    canActivate: [authGuard]
  },
  {
    path: "assets/:id",
    component: AssetEditComponent,
    canActivate: [authGuard]
  },
  {
    path: 'facilities/:id',
    component: FacilityEditAdminComponent,
    canDeactivate: [canDeactivateGuard],
    canActivate: [authGuard]
  },
  {
    path: 'facility-pick',
    component: FacilityPickComponent,
    canActivate: [authGuard]
  },
  {
    path: 'events',
    canActivate: [authGuard],
    children: [
      {
        path: "incidents",
        component: IncidentsComponent
      },
      {
        path: "incidents/:id",
        component: IncidentEditComponent,
        canDeactivate: [canDeactivateGuard]
      },
      {
        path: "incident-wizard",
        component: WizardIncidentMainComponent,
        canDeactivate: [canDeactivateGuard]
      },
      {
        path: "bookings",
        component: BookingsComponent,
      },
      {
        path: "bookings/:id",
        component: BookingEditComponent,
        canDeactivate: [canDeactivateGuard]
      },
    ]
  },
  {
    path: AppRoutes.ADMIN_CUSTOMER,
    component: RouterParentComponent,
    canActivate: [authAdminGuard],
    children: [
      {
        path: '',
        component: AdminHomeComponent,
      },
      {
        path: 'customers',
        component: CustomersComponent,
        canDeactivate: [canDeactivateGuard]
      },
      {
        path: 'customers/:id',
        component: CustomerEditComponent,
        canDeactivate: [canDeactivateGuard]
      },
      {
        path: 'facilities',
        component: FacilitiesAdminComponent,
      },
      {
        path: 'facilities/:id',
        component: FacilityEditAdminComponent,
        canDeactivate: [canDeactivateGuard]
      },
      {
        path: 'facilities-groups',
        component: FacilityGroupsComponent,
        canDeactivate: [canDeactivateGuard]
      },
      {
        path: 'tenant',
        component: TenantEditComponent,
        canDeactivate: [canDeactivateGuard]
      },
      {
        path: 'users',
        component: UsersComponent,
        canDeactivate: [canDeactivateGuard]
      },
      {
        path: 'users/:id',
        component: UserEditComponent,
        canDeactivate: [canDeactivateGuard]
      }
    ]
  },
  {
    path: AppRoutes.ADMIN_MANAGEMENT,
    component: RouterParentComponent,
    canActivate: [authSuperAdminGuard],
    children: [
      {
        path: '',
        component: AdminManagementComponent,
      },
      {
        path: 'tenants',
        component: TenantsManagementComponent,
      },
      {
        path: 'tenants/:id',
        component: TenantEditManagementComponent,
        canDeactivate: [canDeactivateGuard]
      },
      {
        path: 'customers',
        component: CustomersManagementAdminComponent,
      },
      {
        path: 'customers/:id',
        component: CustomerEditManagementAdminComponent,
        canDeactivate: [canDeactivateGuard]
      },
      {
        path: 'providers',
        component: ProviderManagementAdminComponent,
      },
      {
        path: 'providers/:id',
        component: ProviderEditManagementAdminComponent,
        canDeactivate: [canDeactivateGuard]
      },
      {
        path: 'users',
        component: UsersManagementAdminComponent,
        canDeactivate: [canDeactivateGuard]
      },
      {
        path: 'users/:id',
        component: UserEditManagementAdminComponent,
        canDeactivate: [canDeactivateGuard]
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
