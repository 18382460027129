<div class="max-w-screen-2xl lg:px-16 md:p-4 py-2">
  <mat-card class="lg:p-6">
    <mat-card-header class="flex items-center">
      <h1 style="margin-bottom: 4px">{{ 'CUSTOMERS' | translate }}</h1>
    </mat-card-header>
    <hr class="h-px mb-8">
    <mat-spinner *ngIf="inProgress"></mat-spinner>

    <mat-card-content>
      <customers-table
        [customerPaginated]="paginated"
        (paginatorChange)="onSearch($event)"
        (createChange)="onCreate()"
        (editChange)="onNavigateToEdit($event)"
      ></customers-table>
    </mat-card-content>
  </mat-card>
</div>
