import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from "@angular/core";
import {faCloudUpload, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {faFile} from "@fortawesome/free-regular-svg-icons";
import {UUID} from "../../domain/uuid";
import {FileData} from "./file-data";
import {DialogService} from "../../shared/dialogs/dialog-service";
import {DocumentService} from "../../domain/document/document-service";
import {map, of, Subscription} from "rxjs";
import {DocumentTypeEnum, GetPreSignedUrl} from "../../domain/document/document";

export interface FilePreview {
  id: UUID;
  documentId?: UUID;
  storedFile: boolean;
  filename: string;
  dataUrl: string;
  url?: string;
  mimeType: string;
}

@Component({
  selector: 'app-file-upload',
  templateUrl: 'file-upload.component.html',
  styleUrls: ['file-upload.component.scss']
})
export class FileUploadComponent {
  @ViewChild('fileInput') fileInput: ElementRef;

  @Output()
  onFileListChange = new EventEmitter<FileData[]>();

  @Input()
  documentType: DocumentTypeEnum;

  @Input()
  allFiles: FileData[] = [];

  isHovered = false;
  files: File[] = [];

  private subscription = new Subscription();

  constructor(
    private documentService: DocumentService,
    private dialogService: DialogService,
  ) {
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    if (event.dataTransfer.files.length > 0) {
      this.handleFiles(event.dataTransfer.files).then(/*do nothing*/)
    }

    this.isHovered = false;

  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isHovered = true;
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isHovered = false;
  }

  async onFileSelected(event: Event): Promise<void> {
    const input = event.target as HTMLInputElement;
    const files = input.files;

    if (files && files.length > 0) {
      await this.handleFiles(files);
    }
    this.fileInput.nativeElement.value = '';
  }


  async handleFiles(fileList: FileList): Promise<void> {
    const files = Array.from(fileList)

    const promises = files.map(file => FileData.of(file));

    const fileDataList = await Promise.all(promises);

    this.onFileListChange.emit(this.allFiles.concat(fileDataList));
  }


  async handleOpenFilePreviewDialog() {
    const ids = this.allFiles.filter(file => file.storedFile).map(file => file.id);

    const data = new GetPreSignedUrl(ids, this.documentType, 2);

    this.documentService.getPreSignedUrl(data).subscribe(resp => {
      const fileDataList = this.allFiles.map(fileData => {
        if (fileData.storedFile) {
          fileData.dataUrl = resp.find(d => d.id === fileData.id)?.preSignedUrl ?? "";
        }
        return fileData
      });

      this.onFileListChange.emit(fileDataList);
      this.dialogService.openAttachmentDialog(fileDataList).subscribe(() => {
      });
    })
  }

  removeFile(fileData: FileData) : void{
    if (fileData.storedFile) {
      return this.showDialogForDelete(fileData);
    }
    const files = this.allFiles.filter(file => file.id !== fileData.id);
    this.onFileListChange.emit(files);
  }

  showDialogForDelete(fileData: FileData): void {
    this.subscription.add(
      this.dialogService.onDeleteDialog("COMMON_DOCUMENT_DELETE_QUESTION").pipe(
        map((remove) => {
          if (remove) {
              this.documentService.remove(fileData.id, this.documentType).subscribe(() => {
                const files = this.allFiles.filter(file => file.id !== fileData.id);
                this.onFileListChange.emit(files);
              })
          }
          return of(null);
        })).subscribe()
    );
  }


  protected readonly faSpinner = faSpinner;
  protected readonly faCloudUpload = faCloudUpload;
  protected readonly faFile = faFile;
}
