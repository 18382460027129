<mat-card class="card-border mat-mdc-elevation-specific mat-elevation-z0">
  <mat-card-title class="bg-gray-100">
    <div class="flex justify-between p-2 pb-0">
      <div>
        <span class="text-sm pl-4 font-bold">{{ "BOOKING_INFORMATION" | translate }}</span>
      </div>
      <div>
        <button type="button" mat-icon-button
                matTooltip="{{(bookingExists()? 'BOOKING_EDIT' : 'BOOKING_ADD') | translate}}" aria-label="Edit"
                (click)="openDialog()" style="margin-top: -4px; padding: 8px !important;">
          <mat-icon>{{ bookingExists() ? "edit" : "add" }}</mat-icon>
        </button>
      </div>

    </div>

  </mat-card-title>
  <mat-card-content class="bg-gray-100">
    <hr class="h-px mb-4">
    <div *ngIf="bookingExists()">
      <div class="flex">
        <div>
          <span class="text-left p-2">{{ "COMMON_TITLE" | translate }}</span>
        </div>
        <div>
          <span class="text-left font-semibold p-2">{{ internalBooking?.title }}</span>
        </div>
      </div>
      <div class="flex">
        <span class="text-left p-2">{{ "COMMON_DESCRIPTION" | translate }}</span>
        <span class="text-left font-semibold p-2">{{ internalBooking?.description }}</span>
      </div>
      <div class="flex">
        <span class="text-left p-2">{{ "COMMON_DEADLINE" | translate }}</span>
        <span class="text-left ml-2 font-semibold p-2">{{ internalBooking?.deadlineEndDate | dateTime }}</span>
      </div>
      <div class="flex">
        <span class="text-left p-2">{{ "COMMON_STATUS" | translate }}</span>
        <span
          class="text-left ml-2 font-semibold p-2">{{ ("BOOKING_STATUS_" + internalBooking?.status) | translate }}</span>
      </div>
    </div>

    <div *ngIf="!bookingExists()">
      <span>{{ "BOOKING_ADD" | translate }}</span>
    </div>

  </mat-card-content>
</mat-card>


