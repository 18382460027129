<mat-toolbar class="flex justify-between">
  <div class="flex items-center">
    <button matTooltip="{{'MENU_SHOW' | translate}}" *ngIf="device?.isMobile" mat-icon-button
            aria-label="Toggle side menu" (click)="toggleSideNav()">
      <mat-icon>menu</mat-icon>
    </button>
    <div class="flex flex-col">
      <span class="text-lg">{{ (isAdminView ? "ADMIN" : facility?.name) | translate }}</span>
    </div>
    <div *ngIf="false" class="flex flex-col flex-wrap">
      <span class="text-xs ms-2 leading-none"> Access token expire in: {{ accessTokenExpiresAtInSeconds }} </span>
      <span class="text-xs ms-2 leading-none"> Refresh token expires in: {{ refreshTokenExpiresAtInSeconds }}</span>
    </div>
  </div>
  <div class="flex flex-row align-middle">
    <div class="mt-2">
      <button mat-button [matMenuTriggerFor]="menu">
        {{ user?.userName }}
        <mat-icon iconPositionEnd>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        @if (isAdmin()) {
          <button mat-menu-item (click)="toAdmin()">{{ "ADMIN" | translate }}</button>
          <hr/>
        }
        @if (!isSuperAdmin()) {
          <button mat-menu-item (click)="pickFacility()">{{ "MENU_FACILITY_SWITCH" | translate }}</button>
          <hr/>
        }
        <hr/>
        <button *ngFor="let language of languages" mat-menu-item (click)="useLanguage(language)">
          {{ language.label }}
        </button>
        <hr/>
        <button mat-menu-item (click)="logout()">{{ "LOGOUT" | translate }}</button>
      </mat-menu>

    </div>
  </div>
</mat-toolbar>
