import { Component } from '@angular/core';
import { DialogService } from './dialog-service';

@Component({
  selector: 'app-faq-dialog',
  templateUrl: 'faq-dialog.component.html',
  styleUrls: ['faq-dialog.component.scss']
})
export class FaqDialogComponent {
  faqs = [
    { question: 'FAQ.QUESTION1', answer: 'FAQ.ANSWER1' },
    { question: 'FAQ.QUESTION2', answer: 'FAQ.ANSWER2' },
  ];

  constructor(private dialogService: DialogService) {}

  openFaqDialog() {
    this.dialogService.openFaqDialog(this.faqs);
  }
}
