import {Injectable} from "@angular/core";
import {ApiService} from "../../core/api-service";
import {map, Observable} from "rxjs";
import {SlaDuration} from "./sla-duration";
import {UUID} from "../uuid";

@Injectable(
  {providedIn: 'root'}
)
export class SlaDurationService {
  apiPath = '/v1/SlaDurations';

  constructor(private apiService: ApiService) {
  }

  public all(): Observable<[]> {
    return this.apiService.get(this.apiPath).pipe(map((resp: any) => {
      return resp.data.map((t: SlaDuration) => new SlaDuration(t));
    }));
  }

  public by(priorityLevelId: UUID): Observable<SlaDuration> {
    const url = `${this.apiPath}/${priorityLevelId}`;
    return this.apiService.get(url).pipe(map((resp: any) => new SlaDuration(resp.data)));
  }

  createOrUpdate(slaDuration: SlaDuration | null, data: SlaDuration) {
    if (slaDuration && slaDuration.id) {
      const url = `${this.apiPath}/${slaDuration.id}`;
      return this.apiService.patch(url, data);
    }
    return this.apiService.post(this.apiPath, data);
  }

  remove(slaDuration: SlaDuration | null) {
    const url = `${this.apiPath}/${slaDuration?.id}`
    return this.apiService.delete(url);
  }
}
