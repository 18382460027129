<div>
  <p class="text-base font-bold">{{"COMMON_BASIC" | translate}}</p>
  <div>
    <p class="text-xs p-2">{{extendedSummaryIncident.title}}</p>
    <p class="text-xs p-2">{{extendedSummaryIncident.description}}</p>
    <p class="text-xs p-2">{{ ("INCIDENT_TYPE_" + extendedSummaryIncident.type) | translate }}</p>


    <div class="mb-4" *ngFor="let fileData of selectedFiles">
      <mat-chip class="p-0 mt-2 cursor-pointer">
        <button matChipAvatar aria-label="Open document">
          <fa-icon [icon]="faFile" size="sm"/>
        </button>
        <span class="text-xs inline-block -ml-2">{{fileData.filename}}</span>
      </mat-chip>
      @if (fileData.requestFailed) {
        <mat-error>
          {{ fileData.requestFailed && fileData.remove ? ('FILE_FAILED_TO_REMOVE' | translate): '' }}
          {{ fileData.requestFailed && !fileData.storedFile ? ('FILE_FAILED_TO_UPLOAD' | translate) : '' }}
        </mat-error>
      }

    </div>
  </div>

  <p class="text-base font-bold">{{"COMMON_DETAILS" | translate}}</p>
  <div>
    <p class="text-xs p-2">{{extendedSummaryIncident.serviceCatGroupName}}</p>
    <p class="text-xs p-2">{{extendedSummaryIncident.serviceCategoryName}}</p>
    <p class="text-xs p-2">{{extendedSummaryIncident.priorityLevelName}}</p>
    <p class="text-xs p-2">{{extendedSummaryIncident.slaDurationName}}</p>
    <p class="text-xs p-2">{{"INCIDENT_DEADLINE_END_AT" | translate }} {{ getEndDate() | dateTime }}</p>
  </div>


  <p class="text-base font-bold">{{"COMMON_CONTACT_PERSON" | translate}}</p>
  <div>
    <p class="text-xs p-2">{{extendedSummaryIncident.contactName}}</p>
    <p class="text-xs p-2">{{extendedSummaryIncident.contactPhone}}</p>
    <p class="text-xs p-2">{{extendedSummaryIncident.contactEmail}}</p>
  </div>


  <p class="text-base font-bold">{{"ASSETS" | translate}}</p>
  <div *ngFor="let asset of selectedAssets" class="">
    <mat-chip class="p-0 mt-2 cursor-pointer" color="accent" (click)="openAssetDialog(asset)">
        <span class="text-xs inline-block">
          {{asset.modelName}}
        </span>
    </mat-chip>
  </div>
</div>

