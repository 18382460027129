import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {CrudinatorConfig} from "../../domain/crudinator/crudinator-config";

@Component({
  templateUrl: './crudinator-dialog.component.html',
})
export class CrudinatorDialogComponent {
  crudConfig: CrudinatorConfig;

  constructor(private dialogRef: MatDialogRef<CrudinatorDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { crudConfig: CrudinatorConfig }) {
    this.crudConfig = data.crudConfig;
  }

  onBack(changed: boolean) {
    this.dialogRef.close(changed);
  }

  onInProgressChange(inProgress: boolean) {
    this.dialogRef.disableClose = inProgress;
  }
}
