import {Injectable} from "@angular/core";
import {ApiService} from "../../core/api-service";
import {map, Observable} from "rxjs";

import {Asset} from "./asset";
import {Paginator} from "../../core/paginator";
import {Paginated, RespPaginated, RespWrapper} from "../resp-wrapper";
import {UrlBuilder} from "../url-builder";
import {Tenant} from "../tenant/tenant";
import {Facility} from "../facility/facility";

@Injectable({
  providedIn: 'root'
})
export class AssetService {
  apiPath = '/v1/assets';

  constructor(private apiService: ApiService) {
  }

  public all(searchTerm: string = null, paginator: Paginator<Asset>): Observable<Paginated<Asset>> {
    const url = UrlBuilder.of(this.apiPath).addPagination(paginator).addSearch(searchTerm).toUrl();

    return this.apiService.get<RespPaginated<Asset>>(url).pipe(map((resp) => {
      return Paginated.of<Asset>(resp, Asset);
    }));
  }

  public byFacility(facility: Facility, searchTerm: string = null, paginator: Paginator<Asset>): Observable<Paginated<Asset>> {
    const url = UrlBuilder.of(this.apiPath).addFacility(facility).addPagination(paginator).addSearch(searchTerm).toUrl();

    return this.apiService.get<RespPaginated<Asset>>(url).pipe(map((resp) => {
      return Paginated.of<Asset>(resp, Asset);
    }));
  }

  public byId(assetId: string): Observable<Asset> {
    const url = `${this.apiPath}/${assetId}`;

    return this.apiService.get<RespWrapper<Asset>>(url).pipe(map((resp) => {
      return new Asset(resp.data);
    }));
  }

  createOrUpdate(asset: Asset | null, data: Partial<Asset>) {
    if (asset && asset.id) {
      const url = `${this.apiPath}/${asset.id}`;
      return this.apiService.patch<RespWrapper<Tenant>>(url, data);
    }
    return this.apiService.post<RespWrapper<Tenant>>(this.apiPath, data);
  }
}
