import {UUID} from "../../domain/uuid";


export class FileData {
  id: UUID;
  documentId?: UUID;
  filename: string;
  dataUrl: string;
  url?: string;
  mimeType: string;
  file: File;
  remove: boolean = false;
  storedFile: boolean;
  requestFailed: boolean = false;

  constructor(
    filename: string,
    dataUrl: string,
    mimeType: string,
    file: File,
    storedFile: boolean = false,
    url: string = "",
    documentId?: UUID,
  ) {
    this.id = documentId ?? generateUUID();
    this.documentId = documentId;
    this.filename = filename;
    this.mimeType = mimeType;
    this.storedFile = storedFile;
    this.dataUrl = dataUrl;
    this.file = file;
    this.url = url;
  }

  static async of(file: File): Promise<FileData> {
    const data = await FileData.fromFile(file);
    return new FileData(data.filename, data.dataUrl, data.mimeType, data.file, false, data.url, data.documentId)
  }
  static fromFile(file: File): Promise<FileData> {
    return new Promise<FileData>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        const preview = e.target?.result as string;
        const mimeType = getFileType(preview);

        const previewFile = new FileData(file.name, preview, mimeType, file);
        return resolve(previewFile);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  }
}

function generateUUID(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

function getFileType(dataURL: string | ArrayBuffer): string {
  if (typeof dataURL !== 'string') {
    return ''
  }
  const matches = dataURL.match(/^data:(.*);base64,/);
  if (matches) {
    return matches[1];
  }
  return '';
}
