import { Component, OnInit, ViewChild } from "@angular/core";
import { EventUtil } from "../../core/event-util";
import { FormControl } from "@angular/forms";
import { Paginator } from "../../core/paginator";
import { Facility } from "../../domain/facility/facility";
import { MatPaginator } from "@angular/material/paginator";
import { Router } from "@angular/router";
import { LanguageService } from "../../core/language-service";
import { FacilityService } from "../../domain/facility/facility-service";
import { combineLatest, debounceTime, startWith, Subject, Subscription, switchMap } from "rxjs";
import { FacilityStorageService } from "../../core/storage/facility-storage-service";

@Component({
  selector: 'facility-select',
  templateUrl: './facility-pick.component.html',
})
export class FacilityPickComponent implements OnInit {
  triggerClick = EventUtil.triggerClick;
  searchCtrl = new FormControl();

  currentFacility: Facility | null = null;

  facilities: Facility[] | null = null;
  filteredFacilities: Facility[] = []; // New property for filtered facilities

  sub = new Subscription();
  paginator = new Paginator<Facility>("name");
  paginatorSub = new Subject<Paginator<Facility>>();
  paginator$ = this.paginatorSub.asObservable();

  @ViewChild(MatPaginator) set matPaginator(paginator: MatPaginator | null) {
    this.paginator.matPaginator = paginator;
    this.paginatorSub.next(this.paginator);
  }

  constructor(
    private languageService: LanguageService,
    private router: Router,
    private facilityService: FacilityService,
    private facilityStorageService: FacilityStorageService
  ) {}

  ngOnInit() {
    this.paginator.setLang(this.languageService.currLanguage());

    const search$ = this.searchCtrl.valueChanges.pipe(startWith(""), debounceTime(300));
    this.sub.add(combineLatest([search$, this.paginator$])
      .pipe(switchMap(([term, paginator]) => this.facilityService.all(term, paginator)))
      .subscribe(response => {
        this.facilities = response.items;
        this.filteredFacilities = response.items; // Initialize filteredFacilities here
        this.paginator.setTotal(response.totalCount);
      }));
    this.currentFacility = this.facilityStorageService.currentFacility;
  }

  // New method for filtering facilities based on search input
  filterFacilities() {
    const searchTerm = this.searchCtrl.value.toLowerCase();
    this.filteredFacilities = this.facilities?.filter(facility => 
      facility.name.toLowerCase().includes(searchTerm) || 
      facility.description.toLowerCase().includes(searchTerm) ||
      facility.address.street.toLowerCase().includes(searchTerm) ||
      facility.address.city.toLowerCase().includes(searchTerm) ||
      facility.address.country.toLowerCase().includes(searchTerm)
    ) || [];
  }

  clearSearch() {
    this.searchCtrl.setValue('');
  }

  isSelectedFacility(facility: Facility) {
    return this.currentFacility?.id === facility.id;
  }

  navigateTo(facility: Facility) {
    this.facilityService.setFacility(facility);
    this.router.navigate(['/']);
  }

  // Function to track facility by its id for ngFor directive
  trackFacilityById(index: number, facility: Facility): any {
    return facility.id;
  }
}
