import {Component, EventEmitter, Input, Output} from "@angular/core";

@Component({
  selector: 'button-text',
  template: `
    <button type="button" mat-button [disabled]="isDisabled" (click)="onClick()">
      {{ text | translate }}
    </button>
  `,
})
export class ButtonTextComponent {
  @Input() isDisabled = false;
  @Input() text;
  @Output() clickChange = new EventEmitter<void>();

  onClick() {
    this.clickChange.emit();
  }
}
