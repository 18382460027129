import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {FormGroup} from "@angular/forms";
import {CustomerService} from "../../domain/customer/customer-service";
import {Customer} from "../../domain/customer/customer";
import {finalize, Subscription} from "rxjs";
import {CanDeactivateComponent} from "../../core/can-deactivate-guard";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {EnvironmentService} from "../../core/environment-service";
import {Country} from "../../domain/language-country";
import {Tenant} from "../../domain/tenant/tenant";
import {AppRoutes} from "../../app-routing.module";
import {SnackbarService} from "../../shared/snackbar/snackbar-service";
import {CustomerForm} from "./customer-form.component";

@Component({
  templateUrl: './customer-edit.component.html',
})
export class CustomerEditComponent implements OnInit, CanDeactivateComponent, OnDestroy {
  protected readonly faSpinner = faSpinner;
  customer: Customer;
  inProgress = true;
  isSaving = false;
  form: FormGroup<CustomerForm>;
  countriesList: Country[] = [];
  subscription = new Subscription();
  title = "";

  appRoute: AppRoutes;
  tenants: Tenant[] = [];

  constructor(private environmentService: EnvironmentService,
              private customerService: CustomerService,
              private router: Router,
              private route: ActivatedRoute,
              private snackbarService: SnackbarService
  ) {
  }

  ngOnInit() {
    this.countriesList = this.environmentService.countries;
    const customerId = this.route.snapshot.paramMap.get('id');

    if (customerId === "new") {
      this.setTitle(true);
      this.inProgress = false;
      return;
    }

    this.setTitle(false);
    this.customerService.by(customerId!)
      .pipe(finalize(() => {
        this.inProgress = false
      }))
      .subscribe((customer) => {
      this.customer = customer;
    });
  }

  setTitle(isNewCustomer: boolean): void {
    this.title = isNewCustomer ? "COMMON_NEW" : "COMMON_EDIT";
  }

  save(): void {
    if (this.form?.invalid) {
      return;
    }
    this.isSaving = true;
    const change: Partial<Customer> = this.form!.getRawValue();
    this.customerService.createOrUpdate(this.customer, change)
      .pipe(finalize(() => this.isSaving = false))
      .subscribe(() => {
        this.form?.markAsPristine();

        this.snackbarService.success(this.customer?.id ? "CUSTOMER_UPDATED" : "CUSTOMER_CREATED");
        this.router.navigate([`/${AppRoutes.ADMIN_CUSTOMER}/customers`]).then(/* DO NOTHING */);
      });
  }

  canSave(): boolean {
    return this.form != null && this.form.dirty && this.form.valid && !this.inProgress && !this.isSaving;
  }

  cancel() {
    this.router.navigate([`/${AppRoutes.ADMIN_CUSTOMER}/customers`]).then(/* DO NOTHING */);
  }

  onCustomerFormChange(formGroup: FormGroup<CustomerForm>) {
    this.form = formGroup;
  }

  canDeactivateComponent(): boolean {
    return this.form?.dirty ?? false;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

