import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'delete-dialog',
  templateUrl: './on-delete-dialog.component.html',
})
export class OnDeleteDialogComponent {
  langDescriptionKey: string;
  constructor(
    private dialogRef: MatDialogRef<OnDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: string
  ) {
    this.langDescriptionKey = data;
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }
}
