import {ErrorHandler, Injectable, Injector, NgZone} from "@angular/core";
import {DialogService} from "../../shared/dialogs/dialog-service";
import {ApiError} from "./api-error";
import {firstValueFrom} from "rxjs";
import {ApiService} from "../api-service";
import {UserService} from "../../domain/user/user-service";
import {SilentError} from "./silent-error";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(private dialogService: DialogService, private zone: NgZone, private injector: Injector) {
  }

  handleError(error: any) {
    console.error('DepulsifyErrorHandler', error);
    if (error instanceof ApiError) {
      this.apiErrorHandler(error);
      return;
    }

    if (error instanceof SilentError) {
      this.postError(error, "clientError");
      return;
    }

    this.showPostedErrorDialog(error.message, error.code)
    this.postError(error, "clientError");
  }

  private apiErrorHandler(error: ApiError) {
    if (error.isUnauthorized()) {
      const userService = this.injector.get(UserService)
      userService.logout();
      return;
    }
    this.showPostedErrorDialog(error.message, error.code);
    this.postError(error, "apiError");
  }

  private showPostedErrorDialog(message: string, title: string | null = null) {
    this.zone.run(() =>
      firstValueFrom(this.dialogService.errorDialog(message, true, title))
    );
  }

  private postError(error: any, environment: string) {
    const apiService = this.injector.get(ApiService)
    apiService.postToBackendUnlessLocal(error, environment)
  }
}
