import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {UserService} from "../../domain/user/user-service";
import {User} from "../../domain/user/user";


interface SetPasswordForm {
  password: FormControl<string>;
}
@Component({
  selector: 'app-set-password-dialog',
  templateUrl: './set-password-dialog.component.html',

})
export class SetPasswordDialogComponent implements OnInit
{
  hide = true;
  password: string = '';
  form: FormGroup<SetPasswordForm>;

  inProgress = false;

  constructor(
    private dialogRef: MatDialogRef<SetPasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: User,
    private fb: FormBuilder,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      password: this.fb.control(this.password),
    });
  }

  onOk(): void {
    if(this.form.invalid){
      return;
    }

    this.userService.resetUserPassword(this.data.id, this.form.value.password, true).subscribe(
      {
        next: () => {
          this.inProgress = false;
          this.dialogRef.close(true);
        },
        complete: () => {
          this.inProgress = false;
        }
      }
    );
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }

  canSave(): boolean {
    return this.form != null && this.form.dirty && this.form.valid && !this.inProgress;
  }

}
