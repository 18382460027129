<div class="max-w-screen-2xl xl:px-16 md:p-4 py-2">
  <mat-stepper [linear]="isLinear" [animationDuration]="'500'" labelPosition="bottom" orientation="vertical" #stepper>
    <mat-step [stepControl]="firstFormGroup">
      <ng-template>
        <mat-icon>call_end</mat-icon>
      </ng-template>
      <ng-template matStepLabel>{{ 'COMMON_BASIC' | translate }}</ng-template>
      <wizard-incident-basic-form [form]="firstFormGroup" [allFiles]="allFiles" (onFileListChange)="handleFileListChange($event)"></wizard-incident-basic-form>
      <div class="mt-4">
        <button mat-flat-button color="primary" matStepperNext [disabled]="firstFormGroup.invalid">{{ 'COMMON_NEXT' | translate }}</button>
      </div>
    </mat-step>
    <mat-step [stepControl]="secondFormGroup">
      <ng-template matStepLabel>{{ 'COMMON_DETAILS' | translate }}</ng-template>
      <wizard-incident-general-form [form]="secondFormGroup"/>
      <div class="mt-4">
        <button mat-button matStepperPrevious>{{ 'COMMON_BACK' | translate }}</button>
        <button mat-flat-button color="primary" matStepperNext [disabled]="secondFormGroup.invalid">{{ 'COMMON_NEXT' | translate }}</button>
      </div>

    </mat-step>

    <mat-step [stepControl]="thirdFormGroup">
      <ng-template matStepLabel>{{ 'ASSETS' | translate }}</ng-template>
      <wizard-incident-asset-form (onSelectedAssetChange)="handleAssetListChange($event)" [form]="thirdFormGroup"/>
      <div class="mt-4">
        <button mat-button matStepperPrevious>{{ 'COMMON_BACK' | translate }}</button>
        <button mat-flat-button color="primary" matStepperNext [disabled]="thirdFormGroup.invalid">{{ 'COMMON_NEXT' | translate }}</button>
      </div>
    </mat-step>

    <mat-step [stepControl]="fourthFormGroup">
      <ng-template matStepLabel>{{ 'CONTACT_INFORMATION' | translate }}</ng-template>
      <wizard-incident-contact-form [form]="fourthFormGroup"/>
      <div class="mt-4">
        <button mat-button matStepperPrevious>{{ 'COMMON_BACK' | translate }}</button>
        <button mat-flat-button color="primary" matStepperNext [disabled]="fourthFormGroup.invalid">{{ 'COMMON_NEXT' | translate }}</button>
      </div>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>{{ 'COMMON_SUMMARY' | translate }}</ng-template>
      <wizard-incident-summary-form
        [extendedSummaryIncident]="getIncidentFromForm()"
        [selectedAssets]="selectedAssets"
        [selectedFiles]="allFiles"
      />
      <div class="mt-4">
        <button mat-button matStepperPrevious>{{ 'COMMON_BACK' | translate }}</button>
        <button mat-flat-button color="primary" (click)="save()">{{ 'COMMON_SAVE' | translate }}</button>
      </div>
    </mat-step>
  </mat-stepper>
</div>
