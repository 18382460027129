import {UUID} from "../uuid";
import {ObjectMapperHelper} from "../../core/object-mapper-helper";
import {Customer} from "../customer/customer";

export class FacilityGroup {
  id: UUID;
  name: string;
  customerId: UUID;
  description: string;
  parentId: UUID;
  order: number;
  facilityUuids: UUID[];

  children: FacilityGroup[] = [];
  userGroups: string[] = [];

  constructor() {
  }

  static fromJSON(data: any) {
    const group = new FacilityGroup();
    const helper = new ObjectMapperHelper(data);
    group.id = helper.requireString("id");
    group.customerId = helper.requireString("customerId");
    group.name = helper.getString("name");
    group.description = helper.getString("description");
    group.order = helper.getNumber("order");
    group.parentId = helper.getString("parentId");
    group.facilityUuids = helper.getArray("facilityUuids");
    return group;
  }

  static of(customer: Customer, parentId: UUID): FacilityGroup {
    const group = new FacilityGroup();
    group.customerId = customer.id;
    group.parentId = parentId
    return group;
  }

  hasParent(): boolean {
    return this.parentId != null;
  }

  sameParent(other: FacilityGroup): boolean {
    return this.parentId === other.parentId;
  }
}
