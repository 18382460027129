<form *ngIf="form" [formGroup]="form" class="flex flex-wrap">

  <mat-form-field class="w-full md:p-2" appearance="outline">
    <mat-label>{{ "COMMON_TITLE" | translate }}</mat-label>
    <input matInput formControlName="title">
  </mat-form-field>

  <mat-form-field class="w-full md:p-2" appearance="outline">
    <mat-label>{{ "DESCRIPTION" | translate }}</mat-label>
    <textarea rows="3" matInput formControlName="description"></textarea>
  </mat-form-field>


  <mat-form-field class="w-full md:p-2" appearance="outline">
    <mat-label>{{ "COMMON_DEADLINE" | translate }}</mat-label>
    <input matInput [value]="form.controls.deadlineEndDate.value | dateTime" [disabled]="true">
  </mat-form-field>


  <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
    <mat-label>{{ "BOOKING_STATUS" | translate }}</mat-label>
    <mat-select formControlName="status">
      <mat-option *ngFor="let status of bookingStatus" [value]="status">
        {{ translateBookingStatus(status) }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div class="w-full md:w-1/2 md:p-2">
    <mat-checkbox color="primary" formControlName="isActive">
      <span class="whitespace-nowrap">{{ "BOOKING_IS_ACTIVE" | translate }}</span>
    </mat-checkbox>
  </div>

</form>
