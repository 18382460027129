import {Injectable} from "@angular/core";
import {ApiService} from "../../core/api-service";
import {map, Observable} from "rxjs";
import {ServiceCatGroup} from "./service-cat-group";
import {UUID} from "../uuid";

@Injectable(
  {providedIn: 'root'}
)
export class ServiceCatGroupService {
  apiPath = '/v1/ServiceCategoryGroup';

  constructor(private apiService: ApiService) {
  }

  public all(): Observable<[]> {
    return this.apiService.get(this.apiPath).pipe(map((resp: any) => {
      return resp.data.map((t: ServiceCatGroup) => new ServiceCatGroup(t));
    }));
  }

  public by(serviceCatGroupId: UUID): Observable<ServiceCatGroup> {
    const url = `${this.apiPath}/${serviceCatGroupId}`;
    return this.apiService.get(url).pipe(map((resp: any) => new ServiceCatGroup(resp.data)));
  }

  createOrUpdate(serviceCatGroup: ServiceCatGroup | null, data: ServiceCatGroup) {
    if (serviceCatGroup && serviceCatGroup.id) {
      const url = `${this.apiPath}/${serviceCatGroup.id}`;
      return this.apiService.patch(url, data);
    }
    return this.apiService.post(this.apiPath, data);
  }

  remove(serviceCatGroup: ServiceCatGroup | null) {
    const url = `${this.apiPath}/${serviceCatGroup?.id}`
    return this.apiService.delete(url);
  }
}
