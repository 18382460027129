import {Injectable} from "@angular/core";
import {ApiService} from "../../core/api-service";
import {map, Observable} from "rxjs";
import {RespWrapper} from "../resp-wrapper";
import {Event} from "./event";

@Injectable(
  {providedIn: 'root'}
)
export class EventService {
  apiPath = '/v1/events';

  constructor(private apiService: ApiService) {
  }

  public allSimple(): Observable<any> {
    return this.apiService.get<RespWrapper<Event[]>>(this.apiPath).pipe(
      map((resp) => resp.data.map(e => new Event(e))));
  }
}
