import {Component, Input} from "@angular/core";
import {faSort, faSortAsc, faSortDesc} from "@fortawesome/free-solid-svg-icons";
import {SortUtil} from "../core/sorting-service";

@Component({
  selector: 'header-sort-icon',
  template: `
    <fa-icon *ngIf="sortUtil?.isDesc(name)" [icon]="faSortDesc"/>
    <fa-icon *ngIf="sortUtil?.isAsc(name)" [icon]="faSortAsc"/>
    <fa-icon *ngIf="!sortUtil?.isSortingBy(name)" [icon]="faSort"/>
  `,
})
export class HeaderSortIconComponent {
  protected readonly faSort = faSort;
  protected readonly faSortDesc = faSortDesc;
  protected readonly faSortAsc = faSortAsc;

  @Input() name = "";
  @Input() sortUtil: SortUtil<any> | null = null;
}
