import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {TenantService} from "../../domain/tenant/tenant-service";
import {Tenant, TenantType} from "../../domain/tenant/tenant";
import {finalize, Subscription} from "rxjs";
import {CanDeactivateComponent} from "../../core/can-deactivate-guard";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {EnvironmentService} from "../../core/environment-service";
import {Country} from "../../domain/language-country"
import {SnackbarService} from "../../shared/snackbar/snackbar-service";


interface AddressForm {
  street: FormControl<string>;
  state: FormControl<string>;
  postalCode: FormControl<string>;
  city: FormControl<string>;
  country: FormControl<string>;
}

interface TenantForm {
  name: FormControl<string>;
  organizationNumber: FormControl<string>;
  address: FormGroup<AddressForm>;
  contactPhone: FormControl<string>;
  contactEmail: FormControl<string>;
  isActive: FormControl<boolean>;
  tenantType: FormControl<string>;
}

@Component({
  selector: 'tenant-edit',
  templateUrl: './tenant-edit.component.html',
})
export class TenantEditComponent implements OnInit, CanDeactivateComponent, OnDestroy {
  protected readonly faSpinner = faSpinner;
  tenant: Tenant | null = null;
  inProgress = true;
  isUpdating = false;
  form: FormGroup<TenantForm>;
  countriesList: Country[] = [];
  subscription = new Subscription();
  title = "COMMON_EDIT";

  constructor(private fb: FormBuilder,
              private environmentService: EnvironmentService,
              private tenantService: TenantService,
              private router: Router,
              private route: ActivatedRoute,
              private snackbarService: SnackbarService) {
  }

  ngOnInit() {
    this.countriesList = this.environmentService.countries;
    this.tenantService.get()
      .pipe(finalize(()=> {
          this.inProgress = false
        }
      ))
      .subscribe(tenant => {
      this.tenant = tenant
    });
  }

  save(): void {
    if (this.form.invalid) {
      return;
    }
    this.isUpdating = true;

    const tenant: Partial<Tenant> = {
      ...this.form.getRawValue(),
      tenantType: this.form.controls.tenantType.value as TenantType
    };

    this.tenantService.update(tenant)
      .pipe(finalize(() => this.isUpdating = false))
      .subscribe(() => {
          this.form.markAsPristine();
          this.snackbarService.success("TENANT_UPDATED");
        }
      );
  }


  canSave(): boolean {
    return this.form != null && this.form.dirty && this.form.valid && !this.inProgress && !this.isUpdating;
  }


  canDeactivateComponent(): boolean {
    return (this.form && this.form.dirty) ?? false;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onFormChange(form: FormGroup<TenantForm>) {
    this.form = form;
  }
}
