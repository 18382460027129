<div class="flex flex-col drop-zone mb-[20px] md:mb-[30px] p-2"
     [class.hover]="isHovered"
     (drop)="onDrop($event)"
     (dragover)="onDragOver($event)"
     (dragleave)="onDragLeave($event)">

  <div class="flex flex-col items-center">
    <div class="color-depulsify-100">
      <fa-icon [icon]="faCloudUpload" size="2x"/>
    </div>
    <span class="text-base m-1">{{ "FILE_SELECT_DRAG_DROP" | translate }}</span>
  </div>
  <input #fileInput type="file" (change)="onFileSelected($event)" multiple style="display: none"/>
  <p class="text-base font-light">{{ "COMMON_OR" | translate | lowercase }}</p>
  <button mat-button class="text-base" (click)="fileInput.click()">
    <span class="text-base">{{ "COMMON_SELECT_FILE" | translate }}</span>
    <mat-icon iconPositionEnd>chevron_right</mat-icon>
  </button>
</div>

<div class="mb-[20px] md:mb-[30px]" *ngFor="let fileData of allFiles">
  <mat-chip class="p-0 cursor-pointer red-chip">
    <button matChipAvatar aria-label="Open document">
      <fa-icon [icon]="faFile" size="xs"/>
    </button>
    <span class="text-xs inline-block -ml-2">{{fileData.filename}}</span>
    <button matChipRemove aria-label="Remove orange" (click)="removeFile(fileData)">
      <mat-icon>cancel</mat-icon>
    </button>
  </mat-chip>

  @if (fileData.requestFailed) {
    <mat-error>
      {{ fileData.requestFailed && fileData.remove ? ('FILE_FAILED_TO_REMOVE' | translate): '' }}
      {{ fileData.requestFailed && !fileData.storedFile ? ('FILE_FAILED_TO_UPLOAD' | translate) : '' }}
    </mat-error>
  }


</div>

<button mat-stroked-button (click)="handleOpenFilePreviewDialog()" [disabled]="allFiles.length === 0">
  <span class="text-base">{{ "COMMON_PREVIEW_FILES" | translate }}</span>
  <mat-icon iconPositionEnd>chevron_right</mat-icon>
</button>
