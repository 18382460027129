import {Injectable} from "@angular/core";
import {ApiService} from "../../core/api-service";
import {BehaviorSubject, map, Observable} from "rxjs";
import {Customer} from "./customer";
import {Paginated, RespPaginated, RespWrapper} from "../resp-wrapper";
import {Paginator} from "../../core/paginator";
import {UrlBuilder} from "../url-builder";
import {EnvironmentService} from "../../core/environment-service";

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  apiPath = '/v1/customers';
  currentCustomerSubject: BehaviorSubject<Customer> = new BehaviorSubject<Customer>(null);
  currentCustomer: Customer | null = null;
  currentCustomer$: Observable<Customer | null> = this.currentCustomerSubject.asObservable();

  customersSubject: BehaviorSubject<Customer[]> = new BehaviorSubject<Customer[]>(null);
  customers: Customer[] | null = null;
  customers$: Observable<Customer[] | null> = this.customersSubject.asObservable();

  constructor(private apiService: ApiService, private environmentService: EnvironmentService) {
    this.init();
  }

  init() {
    this.currentCustomer = this.environmentService.customer;
    this.currentCustomerSubject.next(this.currentCustomer);
  }

  public setCustomer(customer: Customer) {
    this.environmentService.setCustomer(customer)
    this.currentCustomer = customer;
    this.currentCustomerSubject.next(customer);
  }


  public all(searchTerm: string = null, paginator: Paginator<Customer>): Observable<Paginated<Customer>> {
    const url = UrlBuilder.of(this.apiPath).addPagination(paginator).addSearch(searchTerm).toUrl();

    return this.apiService.get<RespPaginated<Customer>>(url).pipe(map((resp) => {
      return Paginated.of<Customer>(resp, Customer);
    }));
  }

  public by(customerId: string): Observable<Customer> {
    const url = `${this.apiPath}/${customerId}`;
    return this.apiService.get<RespWrapper<Customer>>(url)
      .pipe(map((resp => new Customer(resp.data))));
  }

  createOrUpdate(customer: Customer | null, data: Partial<Customer>): Observable<Customer> {
    if (customer && customer.id) {
      const url = `${this.apiPath}/${customer.id}`;
      return this.apiService.patch<RespWrapper<Customer>>(url, data)
        .pipe(map(resp => resp.data));
    }
    return this.apiService.post<RespWrapper<Customer>>(this.apiPath, data)
      .pipe(map(resp => resp.data));
  }
}
