import {Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild} from "@angular/core";
import {AssetService} from "../../domain/asset/asset-service";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {Paginator} from "../../core/paginator";
import {combineLatest, debounceTime, startWith, Subject, Subscription, switchMap} from "rxjs";
import {EventUtil} from "../../core/event-util";
import {Asset} from "../../domain/asset/asset";
import {FormControl, FormGroup} from "@angular/forms";
import {thirdFormGroup} from "../../pages/incident/wizard/wizard-incident-main.component";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {UUID} from "../../domain/uuid";
import {LanguageService} from "../../core/language-service";
import {FacilityStorageService} from "../../core/storage/facility-storage-service";


@Component({
  templateUrl: './select-assets-dialog.component.html',
})
export class SelectAssetsDialogComponent implements OnInit, OnDestroy {
  triggerClick = EventUtil.triggerClick;
  protected readonly faSpinner = faSpinner;
  inProgress = false;

  assets: Asset[] = []

  selectedAssets: Asset[] = [];


  @Output()
  assetsChange = new EventEmitter<Asset[]>();

  @Input()
  form: FormGroup<thirdFormGroup>;

  sub = new Subscription();
  paginator = new Paginator<Asset>();
  paginatorSub = new Subject<Paginator<Asset>>();
  paginator$ = this.paginatorSub.asObservable();

  searchCtrl = new FormControl();

  @ViewChild(MatPaginator) set matPaginator(paginator: MatPaginator | null) {
    if (!this.paginator.matPaginator) {
      this.paginator.matPaginator = paginator;
      this.paginatorSub.next(this.paginator);
    }
  }

  title = "";

  constructor(
    private dialogRef: MatDialogRef<SelectAssetsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: Asset[],
    private assetService: AssetService,
    private facilityStorageService: FacilityStorageService,
    private languageService: LanguageService) {
    this.selectedAssets = data;
  }

  ngOnInit() {
    this.paginator.setLang(this.languageService.currLanguage());
    const search$ = this.searchCtrl.valueChanges.pipe(startWith(""), debounceTime(300));

    this.sub.add(combineLatest([search$, this.paginator$]).pipe(
      switchMap(([term, paginator]) => this.assetService.byFacility(this.facilityStorageService.currentFacility, term, paginator)))
      .subscribe(paginatedAssets => {
        this.assets = paginatedAssets.items;
        this.paginator.setTotal(paginatedAssets.totalCount);
      }));
    // this.setTitle(false);

  }

  isSelected(id: UUID): boolean {
    return this.selectedAssets.some(asset => asset.id === id);
  }

  setTitle(isNewTenant: boolean): void {
    this.title = isNewTenant ? "COMMON_NEW" : "COMMON_EDIT";
  }

  onSelectSmalScreen(asset: Asset) {
    if (this.isSelected(asset.id)) {
      return;
    }
    this.onSelect(asset);
  }


  onSelect(asset: Asset) {
    if (this.isSelected(asset.id)) {
      return;
    }

    this.selectedAssets.push(asset);
    this.close();
  }

  close() {
    this.dialogRef.close(this.selectedAssets);
  }

  cancel() {
    this.dialogRef.close();
  }

  toggleSort(sort: string): void {
    console.log('sort:', sort)
  }

  clearSearch() {
    this.searchCtrl.setValue('');
  }

  onPageChange(event: PageEvent) {
    this.paginator.onPageChange(event);
    this.paginatorSub.next(this.paginator);
  }

  canDeactivateComponent(): boolean {
    return (this.form && this.form.dirty) ?? false;
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

}
