import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {combineLatest, finalize, Subscription} from "rxjs";
import {UUID} from "../../../domain/uuid";
import {CanDeactivateComponent} from "../../../core/can-deactivate-guard";
import {Provider} from "../../../domain/provider/provider";
import {Country} from "../../../domain/language-country";
import {Tenant} from "../../../domain/tenant/tenant";
import {AppRoutes} from "../../../app-routing.module";
import {EnvironmentService} from "../../../core/environment-service";
import {ProviderManagementService} from "../../domain/providers/provider-management-service";
import {TenantManagementService} from "../../domain/tenants/tenant-management-service";
import {SnackbarService} from "../../../shared/snackbar/snackbar-service";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import {Paginator} from "../../../core/paginator";
import {MutablePartial} from "../../../domain/global-types";


interface AddressForm {
  street: FormControl<string>;
  state: FormControl<string>;
  postalCode: FormControl<string>;
  city: FormControl<string>;
  country: FormControl<string>;
}

interface ProviderForm {
  tenantId: FormControl<UUID | string>
  name: FormControl<string>
  description: FormControl<string>
  organizationNumber: FormControl<UUID | string>
  contactName: FormControl<string>
  contactEmail: FormControl<string>
  contactPhone: FormControl<string>
  address: FormGroup<AddressForm>;
  invoiceId: FormControl<number>
  invoiceAddress: FormGroup<AddressForm>;
  invoiceReference: FormControl<string>
  costAccountNumber: FormControl<string>
  isActive: FormControl<boolean>
}

@Component({
  selector: 'provider-edit',
  templateUrl: './provider-edit-management-admin.component.html',
})
export class ProviderEditManagementAdminComponent implements OnInit, CanDeactivateComponent, OnDestroy {
  protected readonly faSpinner = faSpinner;
  public provider: Provider | null = null;
  inProgress = false;
  form: FormGroup<ProviderForm>;
  countriesList: Country[] = [];
  subscription = new Subscription();
  title = "";
  tenants: Tenant[] = [];
  appRoute: AppRoutes;

  constructor(private fb: FormBuilder,
              private environmentService: EnvironmentService,
              private providerManagementService: ProviderManagementService,
              private router: Router,
              private route: ActivatedRoute,
              private tenantManagementService: TenantManagementService,
              private snackbarService: SnackbarService

  ) {
  }

  ngOnInit() {
    this.countriesList = this.environmentService.countries;
    const providerId = this.route.snapshot.paramMap.get('id');
    const paginator = new Paginator<Tenant>()
    paginator.setTotal(500);

    if (providerId === "new") {
      this.initForm();
      this.setTitle(true);
      this.tenantManagementService.all(null,paginator).subscribe(resp => {
        this.tenants = resp.items;
      });
      return;
    }

    this.setTitle(false);

    combineLatest([
      this.providerManagementService.by(providerId!),
      this.tenantManagementService.all(null, paginator)
    ]).subscribe(([provider, respTenant]) => {
      this.provider = provider;
      this.tenants = respTenant.items;
      this.initForm();
    });
  }

  setTitle(isNewCustomer: boolean): void {
    this.title = isNewCustomer ? "COMMON_NEW" : "COMMON_EDIT";
  }

  initForm(): void {
    const provider = this.provider;
    this.form = this.fb.group({
      tenantId: [provider?.tenantId ?? "", Validators.required],
      name: [provider?.name ?? "", Validators.required],
      description: [provider?.description ?? ""],
      organizationNumber: [provider?.organizationNumber?? ""],
      contactName: [provider?.contactName?? ""],
      contactEmail: [provider?.contactEmail ?? ""],
      contactPhone: [provider?.contactPhone ?? ""],
      address: this.fb.group({
        street: [provider?.address.street],
        state: [provider?.address.state],
        postalCode: [provider?.address.postalCode ?? ""],
        city: [provider?.address.city ?? ""],
        country: [provider?.address.country ?? this.environmentService.countryDefaultCode],
      }),
      invoiceAddress: this.fb.group({
        street: [provider?.invoiceAddress.street],
        state: [provider?.invoiceAddress.state],
        postalCode: [provider?.invoiceAddress.postalCode ?? ""],
        city: [provider?.invoiceAddress.city ?? ""],
        country: [provider?.invoiceAddress.country ?? this.environmentService.countryDefaultCode],
      }),
      invoiceId: [(provider?.invoiceId ?? 0)],

      invoiceReference: [provider?.invoiceReference ?? ""],
      costAccountNumber: [provider?.costAccountNumber ?? ""],
      isActive: [provider?.isActive ?? true]
    });
  }

  save(): void {
    if (this.form?.invalid) {
      return;
    }
    this.inProgress = true;
    const change: MutablePartial<Provider> = this.form!.getRawValue();



    this.providerManagementService.createOrUpdate(this.provider?.id, change)
      .pipe(finalize(() => this.inProgress = false))
      .subscribe(() => {
        this.form?.markAsPristine();
        const url = `${AppRoutes.ADMIN_MANAGEMENT}/providers`;

        const message = this.provider?.id ? "COMMON_UPDATED" : "COMMON_CREATED";
        this.snackbarService.success(message);
        this.router.navigate(["/" + url]).then(/* DO NOTHING */);
      });
  }

  canSave(): boolean {
    return this.form != null && this.form.dirty && this.form.valid && !this.inProgress;
  }

  cancel() {
    const url = `${AppRoutes.ADMIN_MANAGEMENT}/providers`;
    this.router.navigate(["/" + url]).then(/* DO NOTHING */);
  }

  canDeactivateComponent(): boolean {
    return this.form?.dirty ?? false;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

