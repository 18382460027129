import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {EnvironmentService} from "../../core/environment-service";
import {Country} from "../../domain/language-country";
import {Booking, BookingStatus} from "../../domain/booking/booking";
import {TranslateService} from "@ngx-translate/core";

export interface BookingForm {
  title: FormControl<string>
  description: FormControl<string>
  isActive: FormControl<boolean>
  status: FormControl<string>
  deadlineEndDate: FormControl<Date>
}

@Component({
  selector: 'booking-form',
  templateUrl: './booking-form.component.html',
})
export class BookingFormComponent implements OnInit {
  form: FormGroup<BookingForm>;
  countriesList: Country[] = [];
  bookingStatus: string[] = Object.keys(BookingStatus).map(key => BookingStatus[key]);

  @Input() booking: Booking | null = null;
  @Input() disabled = false;
  @Output() bookingForm = new EventEmitter<FormGroup<BookingForm>>();

  constructor(private fb: FormBuilder,
              private environmentService: EnvironmentService,
              private translateService: TranslateService,
  ) {
  }

  ngOnInit() {
    this.countriesList = this.environmentService.countries;
    this.initForm();
    this.form.valueChanges.subscribe(() => {
      this.bookingForm.emit(this.form);
    })
  }

  initForm(): void {
    const booking = this.booking;
    this.form = this.fb.group({
      title: [booking?.title ?? "", Validators.required],
      description: [booking?.description ?? "", Validators.required],
      isActive: [booking?.isActive ?? true, Validators.required],
      deadlineEndDate: [booking?.deadlineEndDate ?? new Date(), Validators.required],
      status: [booking?.status ?? BookingStatus.New.toString(), Validators.required],
    });

    if (!booking?.id) {
      this.form.controls.status.disable();
    }
  }

  markFormAsDirty(){
    this.form.markAsDirty();
    this.bookingForm.emit(this.form);
  }
  translateBookingStatus(status: string): string{
    const translationKey = 'BOOKING_STATUS_' + status;
    return this.translateService.instant(translationKey);
  }

}

