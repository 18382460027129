import {Component} from "@angular/core";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  templateUrl: './unsaved-changes-dialog.component.html',
})
export class UnsavedChangesDialogComponent {
  constructor(private dialogRef: MatDialogRef<UnsavedChangesDialogComponent>) { }

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }
}
