import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {FormControl, FormGroup} from "@angular/forms";
import {finalize, Subscription} from "rxjs";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {CanDeactivateComponent} from "../../../core/can-deactivate-guard";
import {Tenant, TenantType} from "../../../domain/tenant/tenant";
import {Country} from "../../../domain/language-country";
import {EnvironmentService} from "../../../core/environment-service";
import {SnackbarService} from "../../../shared/snackbar/snackbar-service";
import {AppRoutes} from "../../../app-routing.module";
import {TenantManagementService} from "../../domain/tenants/tenant-management-service";


interface AddressForm {
  street: FormControl<string>;
  state: FormControl<string>;
  postalCode: FormControl<string>;
  city: FormControl<string>;
  country: FormControl<string>;
}

interface TenantForm {
  name: FormControl<string>;
  organizationNumber: FormControl<string>;
  address: FormGroup<AddressForm>;
  contactPhone: FormControl<string>;
  contactEmail: FormControl<string>;
  isActive: FormControl<boolean>;
  tenantType: FormControl<string>;
}

@Component({
  templateUrl: './tenant-edit-management.component.html',
})
export class TenantEditManagementComponent implements OnInit, CanDeactivateComponent, OnDestroy {
  protected readonly faSpinner = faSpinner;
  tenant: Tenant | null = null;
  inProgress = true;
  isSaving = false;
  form: FormGroup<TenantForm>;
  countriesList: Country[] = [];
  subscription = new Subscription();
  title = "";

  constructor(
              private environmentService: EnvironmentService,
              private tenantManagementService: TenantManagementService,
              private router: Router,
              private route: ActivatedRoute,
              private snackbarService: SnackbarService) {
  }

  ngOnInit() {
    this.countriesList = this.environmentService.countries;
    const tenantId = this.route.snapshot.paramMap.get('id');


    if (tenantId === "new") {
      this.setTitle(true);
      this.inProgress = false
      return;
    }
    this.setTitle(false);

    this.tenantManagementService.by(tenantId)
      .pipe(finalize(() => {
          this.inProgress = false
        }
      ))
      .subscribe(tenant => {
        this.tenant = tenant
      });
  }

  setTitle(isNewTenant: boolean): void {
    this.title = isNewTenant ? "COMMON_NEW" : "COMMON_EDIT";
  }


  save(): void {
    if (this.form.invalid) {
      return;
    }
    this.isSaving = true;

    const tenant: Partial<Tenant> = {
      ...this.form.getRawValue(),
      tenantType: this.form.controls.tenantType.value as TenantType
    };

    this.tenantManagementService.createOrUpdate(this.tenant, tenant)
      .pipe(finalize(() => this.isSaving = false))
      .subscribe(() => {
          this.form.markAsPristine();
          this.snackbarService.success(this.tenant?.id ? "TENANT_UPDATED" : "TENANT_CREATED");
          this.navigateBack();
        }
      );
  }

  private navigateBack() {
    this.router.navigate([`${AppRoutes.ADMIN_MANAGEMENT}/tenants`]).then(/* DO NOTHING */);
  }

  canSave(): boolean {
    return this.form != null && this.form.dirty && this.form.valid && !this.inProgress && !this.isSaving;
  }

  cancel() {
    this.navigateBack();
  }

  canDeactivateComponent(): boolean {
    return (this.form && this.form.dirty) ?? false;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onFormChange(form: FormGroup<TenantForm>) {
    this.form = form;
  }
}
