import {Component, Input, OnInit} from "@angular/core";
import {FormControl} from "@angular/forms";
import {LanguageService} from "../../../core/language-service";
import {Subscription} from "rxjs";

@Component({
  selector: 'date-input',
  templateUrl: './date-input.component.html',
})
export class DateInputComponent implements OnInit {
  dateFormat: string;
  internalCtrl = new FormControl<string>("");
  subscription = new Subscription();

  @Input() control: FormControl;
  @Input() label: string;

  constructor(languageService: LanguageService) {
    this.dateFormat = languageService.getDateFormat().toLowerCase();
  }

  ngOnInit() {
    this.updateInternalCtrl(this.control.value);

    this.subscription.add(this.control.statusChanges.subscribe(() => {
      this.internalCtrl.setErrors(this.control.errors);
    }));

    this.subscription.add(this.internalCtrl.valueChanges.subscribe(() => {
      if (!this.internalCtrl.value) {
        this.updateExternalCtrl(null);
        return;
      }
      const date = new Date(this.internalCtrl.value);
      const year: number = date.getFullYear();
      const month: number = date.getMonth() + 1;
      const day: number = date.getDate();

      const monthStr = month < 10 ? `0${month}` : `${month}`;
      const dayStr = day < 10 ? `0${day}` : `${day}`;

      this.updateExternalCtrl(`${year}-${monthStr}-${dayStr}`)
    }));
  }

  updateInternalCtrl(value: string) {
    this.internalCtrl.setValue(value);
  }

  updateExternalCtrl(date: string) {
    this.control.setValue(date);
    this.control.markAsDirty();
    this.control.updateValueAndValidity();
  }
}
