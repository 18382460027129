import {Injectable} from "@angular/core";
import {BreakpointObserver} from "@angular/cdk/layout";
import {Observable} from "rxjs";
import {Device} from "./device";

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  currDevice: Device;

  constructor(private breakpointObserver: BreakpointObserver) {
    this.device().subscribe(device => this.currDevice = device)
  }

  device(): Observable<Device> {
    return Device.of(this.breakpointObserver);
  }
}
