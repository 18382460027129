<div class="max-w-screen-2xl lg:px-16 md:p-4 py-2">
  <mat-card class="lg:p-6 mat-mdc-elevation-specific mat-elevation-z0"
            style="background-color: transparent !important;">
    <mat-card-header class="flex items-center">
      <h1 style="margin-bottom: 4px">
        <span>{{ 'ASSET' | translate }}&nbsp;&ndash;&nbsp;</span>
        <span *ngIf="title">{{ (title | translate).toLowerCase() }}</span>
      </h1>
    </mat-card-header>
    <hr class="h-0.5 mb-8 dark:bg-gray-300">
    <mat-spinner *ngIf="!form"></mat-spinner>

    <mat-card-content>
      <form *ngIf="form" [formGroup]="form" class="flex flex-wrap">
        <div class="grid grid:cols-1 xl:grid-cols-2 gap-4">
          <div class="grid grid:cols-1 gap-4">
            <mat-card class="card-border mat-mdc-elevation-specific mat-elevation-z0">
              <mat-card-title>
                <div class="text-sm p-2 pl-4 font-bold">{{ "Bass" }}</div>
              </mat-card-title>
              <mat-card-content>
                <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
                  <mat-label>{{ "COMMON_NAME" | translate }}</mat-label>
                  <input matInput formControlName="name">
                </mat-form-field>

                <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
                  <mat-label>{{ "COMMON_DESCRIPTION" | translate }}</mat-label>
                  <input matInput formControlName="description">
                </mat-form-field>

                <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
                  <mat-label>{{ "ASSET_MODEL_NAME" | translate }}</mat-label>
                  <input matInput formControlName="modelName">
                </mat-form-field>

                <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
                  <mat-label>{{"ASSET_STATUS" | translate}}</mat-label>
                  <mat-select formControlName="status">
                    <mat-option *ngFor="let status of assetStatus" [value]="status">
                      {{ "ASSET_STATUS_" + status | translate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
                  <mat-label>{{ "ASSET_MODEL_NUMBER" | translate }}</mat-label>
                  <input matInput formControlName="modelNumber">
                </mat-form-field>

                <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
                  <mat-label>{{ "ASSET_SERIAL" | translate }}</mat-label>
                  <input matInput formControlName="serial">
                </mat-form-field>

                <div class="w-full md:p-2">
                  <mat-label class="text-sm text-gray-400 opacity-70 px-4" style="color: #00000099">
                    {{ "ASSET_TYPE" | translate }}
                  </mat-label>
                  <crudinator class="w-full" (crudChange)="onCrudTypeChange()" crudType="ASSET_TYPE">
                      <button type="button" class="button-full-width" mat-stroked-button (click)="changeAssetType()">
                        <div class="flex justify-between items-center w-full">
                          <span class="pt-[2px]">{{ getAssetTypeName(form.controls.typeId.value) | translate }}</span>
                          <mat-icon class="pt-[1px]">chevron_right</mat-icon>
                        </div>
                      </button>
                  </crudinator>
                </div>
              </mat-card-content>
            </mat-card>

            <mat-card class="card-border mat-mdc-elevation-specific mat-elevation-z0">
              <mat-card-title>
                <div class="text-sm p-2 pl-4 font-bold">{{ "Specifikation" }}</div>
              </mat-card-title>
              <mat-card-content>
                <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
                  <mat-label>{{ "ASSET_WIDTH" | translate }}</mat-label>
                  <input matInput formControlName="width">
                </mat-form-field>

                <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
                  <mat-label>{{ "ASSET_HEIGHT" | translate }}</mat-label>
                  <input matInput formControlName="height">
                </mat-form-field>

                <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
                  <mat-label>{{ "ASSET_DEPTH" | translate }}</mat-label>
                  <input matInput formControlName="depth">
                </mat-form-field>

                <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
                  <mat-label>{{ "ASSET_WEIGHT" | translate }}</mat-label>
                  <input matInput formControlName="weight">
                </mat-form-field>
              </mat-card-content>
            </mat-card>
          </div>

          <mat-card class="card-border mat-mdc-elevation-specific mat-elevation-z0">
            <mat-card-title>
              <div class="text-sm p-2 pl-4 font-bold">{{ "Detaljer" }}</div>
            </mat-card-title>
            <mat-card-content>
              <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                <mat-form-field appearance="outline">
                  <mat-label>{{ "ASSET_PLACEMENT" | translate }}</mat-label>
                  <input matInput formControlName="placement">
                </mat-form-field>

                <date-input [control]="form.controls.purchaseDate" label="ASSET_PURCHASE_DATE">
                </date-input>

                <date-input [control]="form.controls.installationDate" label="ASSET_INSTALLATION_DATE">
                </date-input>

                <date-input [control]="form.controls.warrantyExpiryDate" label="ASSET_WARRANTY_EXPIRY_DATE">
                </date-input>

                <date-input [control]="form.controls.scrappingDate" label="ASSET_SCRAPPING_DATE">
                </date-input>

                <date-input [control]="form.controls.lastServiceDate" label="ASSET_LAST_SERVICE_DATE">
                </date-input>

<!--                <mat-form-field appearance="outline">-->
<!--                  <mat-label>{{ "ASSET_REFRIGERANT_AMOUNT" | translate }}</mat-label>-->
<!--                  <input matInput formControlName="refrigerantAmount">-->
<!--                </mat-form-field>-->

<!--                <mat-form-field appearance="outline">-->
<!--                  <mat-label>{{ "ASSET_INSTALLED_BY" | translate }}</mat-label>-->
<!--                  <input matInput formControlName="installedBy">-->
<!--                </mat-form-field>-->

<!--                <mat-form-field appearance="outline">-->
<!--                  <mat-label>{{ "ASSET_DELIVERED_BY" | translate }}</mat-label>-->
<!--                  <input matInput formControlName="deliveredBy">-->
<!--                </mat-form-field>-->
              </div>

              <div class="grid grid-rows-1 grid-flow-col gap-2">
                <div class="w-full md:w-1/2 md:p-2">
                  <mat-checkbox color="primary" formControlName="outOfOrder">
                    <span class="whitespace-nowrap">{{ "ASSET_OUT_OF_ORDER" | translate }}</span>
                  </mat-checkbox>
                  <!--                  <mat-slide-toggle color="primary" formControlName="outOfOrder">-->
                  <!--                    <span class="whitespace-nowrap">{{ "ASSET_OUT_OF_ORDER" | translate }}</span>-->
                  <!--                  </mat-slide-toggle>-->
                </div>
                <div class="w-full md:w-1/2 md:p-2">
                  <!--                  <mat-slide-toggle color="primary" formControlName="inUse">-->
                  <mat-checkbox color="primary" formControlName="inUse">
                    <span class="whitespace-nowrap">{{ "ASSET_IN_USE" | translate }}</span>
                  </mat-checkbox>
                  <!--                  </mat-slide-toggle>-->
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>

      </form>
    </mat-card-content>
    <mat-card-actions align="end" class="mt-4">
      <button type="button" mat-flat-button (click)="cancel()">{{ "COMMON_CANCEL" | translate }}</button>
      <button type="button" color="primary" class="ml-2" mat-flat-button [disabled]="!canSave()" (click)="save()">
        {{ "COMMON_SAVE" | translate }}
        <fa-icon *ngIf="inProgress" [icon]="faSpinner" animation="spin" class="ml-1"/>
      </button>
    </mat-card-actions>
  </mat-card>
</div>
