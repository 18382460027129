<main class="max-w-screen-2xl lg:px-16 md:p-4 py-2">
  <mat-card class="lg:p-6">
    <header class="flex justify-between items-center mb-4">
      <div>
        <h1>{{ 'FACILITIES' | translate }}</h1>
      </div>
      <button mat-flat-button color="primary"
        class="create-button ml-auto mr-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        (click)="create()" attr.aria-label="{{ 'COMMON_CREATE' | translate }}">
        {{ "COMMON_CREATE" | translate }}
      </button>
    </header>
    <hr class="h-px mb-8">
    <mat-spinner *ngIf="!facilities" aria-label="Loading facilities"></mat-spinner>

    <section *ngIf="facilities && filteredFacilities">
      <div class="mb-4">
        <mat-form-field class="w-full" appearance="outline">
          <mat-label>{{ 'COMMON_SEARCH' | translate }}</mat-label>
          <input matInput [formControl]="searchCtrl" attr.aria-label="{{ 'COMMON_SEARCH' | translate }}"
            (keyup)="filterFacilities()">
          <button *ngIf="searchCtrl.value" matSuffix mat-icon-button aria-label="Clear search" (click)="clearSearch()">
            <mat-icon>close</mat-icon>
          </button>
          <mat-icon matSuffix *ngIf="!searchCtrl.value">search</mat-icon>
        </mat-form-field>
      </div>

      <!-- Desktop View -->
      <table class="table-container w-full hidden md:table table-fixed">
        <thead>
          <tr class="bg-gray-100">
            <th scope="col" class="text-left px-4 py-4 cursor-pointer header-cell" (click)="toggleSort('name')">
              {{ "COMMON_NAME" | translate }}
              <mat-icon class="sort-icon">{{ getSortIcon('name') }}</mat-icon>
            </th>
            <th scope="col" class="text-left px-4 py-4 header-cell">
              {{ "COMMON_COUNTRY" | translate }}
            </th>
            <th scope="col" class="w-24 text-left px-4 py-4 header-cell">{{ "COMMON_STATUS" | translate }}</th>
            <th scope="col" class="w-24 text-left px-4 py-4 header-cell"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let facility of filteredFacilities" (click)="navigateToEdit(facility)"
            class="hover:bg-gray-100 cursor-pointer border-t-2" tabindex="0" role="button"
            (keyup.enter)="navigateToEdit(facility)">
            <td class="text-left p-2">{{ facility.name }}</td>
            <td class="text-left p-2">{{ facility.countryTranslateKey | translate }}</td>
            <td class="w-24 text-left p-2">
              <div [ngClass]="facility.isActive ? 'text-green-600' : 'text-gray-500'"
                class="inline-block px-2.5 py-1 rounded-xl">
                {{ (facility.isActive ? 'COMMON_ACTIVE' : 'COMMON_INACTIVE') | translate }}
              </div>
            </td>
            <td>
              <button mat-stroked-button (click)="$event.stopPropagation(); visit(facility)"
                class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                {{ "FACILITY_VISIT" | translate }}
                <mat-icon>arrow_forward</mat-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- Mobile View -->
      <ul class="md:hidden divide-y list-none">
        <li *ngFor="let facility of filteredFacilities" class="hover:bg-gray-100 cursor-pointer p-2 list-none">
          <div (click)="navigateToEdit(facility)" tabindex="0" role="button" (keyup.enter)="navigateToEdit(facility)">
            <div class="flex flex-row justify-between items-center">
              <div>
                <div class="flex items-center mb-2">
                  <span class="font-bold text-lg mr-2">{{ facility.name }}
                    <div [ngClass]="facility.isActive ? 'text-green-600' : 'text-gray-500'"
                      class="inline-block px-2.5 py-1 rounded-xl">
                      {{ (facility.isActive ? 'COMMON_ACTIVE' : 'COMMON_INACTIVE') | translate }}
                    </div>
                  </span>
                </div>
                <p class="text-base">{{ facility.description }}</p>
                <p class="text-sm font-light">{{ facility.address.street }}, {{ facility.address.postalCode }},
                  {{ facility.address.city }}, {{ facility.countryTranslateKey | translate }}</p>
              </div>
              <button mat-stroked-button (click)="$event.stopPropagation(); visit(facility)"
                class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                {{ "FACILITY_VISIT" | translate }}
                <mat-icon>arrow_forward</mat-icon>
              </button>
            </div>
          </div>
        </li>
      </ul>

      <mat-paginator class="mt-2" showFirstLastButtons [pageSizeOptions]="paginator.pageSizeOptions"
        [pageSize]="paginator.pageSize" [pageIndex]="paginator.pageIndex" [length]="paginator.totalCount"
        aria-label="Paginator" (page)="onPageChange($event)">
      </mat-paginator>
    </section>
  </mat-card>
</main>