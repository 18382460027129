import {Injectable} from "@angular/core";
import {map, Observable} from "rxjs";
import {ApiService} from "../../../core/api-service";
import {Paginator} from "../../../core/paginator";
import {Tenant} from "../../../domain/tenant/tenant";
import {Paginated, RespPaginated, RespWrapper} from "../../../domain/resp-wrapper";
import {UrlBuilder} from "../../../domain/url-builder";

@Injectable(
  {providedIn: 'root'}
)
export class TenantManagementService {
  apiPath = '/v1/admin/tenants';

  constructor(private apiService: ApiService) {
  }

  public all(searchTerm: string = null, paginator: Paginator<Tenant>): Observable<Paginated<Tenant>> {
    const url = UrlBuilder.of(this.apiPath).addPagination(paginator).addSearch(searchTerm).toUrl();

    return this.apiService.get<RespPaginated<Tenant>>(url).pipe(map((resp) => {
      return Paginated.of<Tenant>(resp, Tenant);
    }));
  }

  public by(tenantId: string): Observable<Tenant> {
    const url = `${this.apiPath}/${tenantId}`;
    return this.apiService.get<RespWrapper<Tenant>>(url).pipe(
      map(resp => new Tenant(resp.data)));
  }

  createOrUpdate(tenant: Tenant | null, data: Partial<Tenant>) {
    if (tenant && tenant.id) {
      const url = `${this.apiPath}/${tenant.id}`;
      return this.apiService.patch<RespWrapper<Tenant>>(url, data);
    }
    return this.apiService.post<RespWrapper<Tenant>>(this.apiPath, data);
  }
}
