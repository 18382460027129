import {Booking} from "../../domain/booking/booking";
import {Subscription} from "rxjs";
import {DialogService} from "../dialogs/dialog-service";
import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";

@Component({
  selector: 'booking-view',
  templateUrl: 'booking-view.component.html',
})
export class BookingViewComponent implements OnInit {
  @Input()
  booking: Booking
  private sub = new Subscription();
  internalBooking: Booking

  @Output()
  onSave = new EventEmitter<Booking>();
  constructor(
    private dialogService: DialogService,
  ) {
  }

  ngOnInit(): void {
    this.internalBooking = this.booking;
  }

  openDialog(): void {
    this.sub.add(
      this.dialogService.openBookingDialog(this.internalBooking).subscribe(booking => {
        this.internalBooking = booking;
      })
    );
  }

  bookingExists(): boolean {
    return this.internalBooking != null && this.internalBooking.id != null;
  }
}
