import {BehaviorSubject, Observable} from "rxjs";
import {Injectable} from "@angular/core";


export enum GlobalStorageAction {
  CLEAR = 'ClearStorage'

}

@Injectable(
  {providedIn: 'root'}
)
export class GlobalStorageService {

  private action: BehaviorSubject<string>;
  public action$: Observable<string>;


  constructor() {
    this.action = new BehaviorSubject<string>('');
    this.action$ = this.action.asObservable();
  }


  public clearStorage(){
    this.action.next(GlobalStorageAction.CLEAR);
  }
}
