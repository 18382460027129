<mat-toolbar style="background-color: transparent" role="heading">
  <h1>{{ "ADMIN" | translate }}</h1>
</mat-toolbar>
<div class="max-w-screen-2xl lg:px-16 md:p-8 py-2">
  <div class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mb-4">
    <ng-container *ngIf="checkRole(customerCard)">
      <mat-card (click)="navigateTo(customerCard)" class="cursor-pointer">
        <mat-card-header>
          <mat-card-title>{{ customerCard.title | translate }}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          {{ customerCard.route | translate }}
        </mat-card-content>
        <mat-card-actions>
        </mat-card-actions>
      </mat-card>
    </ng-container>
  </div>
  <div class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
    <ng-container *ngFor="let card of customerAdminCards">
      <ng-container *ngIf="checkRole(card)">
        <mat-card (click)="navigateTo(card)" class="cursor-pointer">
          <mat-card-header>
            <mat-card-title>{{ card.title | translate }}</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            {{ card.route | translate }}
          </mat-card-content>
          <mat-card-actions>
          </mat-card-actions>
        </mat-card>
      </ng-container>
    </ng-container>
  </div>
</div>
