<form *ngIf="form" [formGroup]="form" class="flex flex-wrap">
  <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
    <mat-label>{{"COMMON_NAME" | translate}}</mat-label>
    <input matInput formControlName="name">
  </mat-form-field>

  <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
    <mat-label>{{"COMMON_ORGANIZATION_NUMBER" | translate}}</mat-label>
    <input matInput formControlName="organizationNumber">
  </mat-form-field>

  <ng-container formGroupName="address">
    <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
      <mat-label>{{"COMMON_ADDRESS" | translate}}</mat-label>
      <input matInput formControlName="street">
    </mat-form-field>

    <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
      <mat-label>{{"COMMON_CITY" | translate}}</mat-label>
      <input matInput formControlName="city">
    </mat-form-field>

    <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
      <mat-label>{{"COMMON_POSTAL_CODE" | translate}}</mat-label>
      <input matInput formControlName="postalCode">
    </mat-form-field>

    <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
      <mat-label>{{"COMMON_COUNTRY" | translate}}</mat-label>
      <mat-select formControlName="country">
        <mat-option *ngFor="let country of countriesList" [value]="country.code">
          {{ country.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>


  <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
    <mat-label>{{"TENANT_TYPE" | translate}}</mat-label>
    <mat-select formControlName="tenantType">
      <mat-option *ngFor="let type of tenantTypes" [value]="type">
        {{ type }}
      </mat-option>
    </mat-select>
  </mat-form-field>


  <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
    <mat-label>{{"TENANT_CONTACT_EMAIL" | translate}}</mat-label>
    <input matInput formControlName="contactEmail">
  </mat-form-field>

  <div class="w-full md:w-1/2 md:p-2">
    <phone-number [control]="form.controls.contactPhone"></phone-number>
  </div>

  <div class="w-full md:w-1/2 md:p-2">
    <mat-slide-toggle color="primary" formControlName="isActive">
      {{"COMMON_ACTIVE" | translate}}
    </mat-slide-toggle>
  </div>
</form>
