import {Injectable} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {EnvironmentService} from "./environment-service";
import {DateAdapter} from "@angular/material/core";

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  language: string;

  constructor(private translateService: TranslateService, private environmentService: EnvironmentService, private dateAdapter: DateAdapter<Date>) {
  }

  init() {
    this.translateService.setDefaultLang(this.environmentService.languageDefaultCode);
    this.useLanguage(this.browserLocaleOrDefault());
  }

  useLanguage(language: string) {
    console.log(language);
    this.translateService.use(language);
    this.environmentService.setLang(language);
    this.language = language;
    const locale = this.environmentService.langCountry.find(c => c.language.code === language).language.locale;
    this.dateAdapter.setLocale(locale);
  }

  getDateTimeFormat() {
    return this.environmentService.getDateFormatFrom(this.language);
  }

  getDateFormat() {
    return this.getDateTimeFormat().substring(0, 10);
  }

  currLanguage(): string {
    return this.browserLocaleOrDefault();
  }

  browserLocaleOrDefault(): string {
    const storedLanguage = this.environmentService.language;
    if (storedLanguage && this.isSupportedLanguage(storedLanguage)) {
      return storedLanguage;
    }

    const browserLocale: string = navigator.language;
    if (browserLocale && this.isSupportedLanguage(browserLocale)) {
      return browserLocale;
    }

    return this.environmentService.languageDefaultCode;
  }

  private isSupportedLanguage(browserLocale: string): boolean {
    return this.languages.map(c => c.code).includes(browserLocale);
  }

  currentLanguageLabel(): string {
    const currLang = this.languages.find(lang => lang.code === this.translateService.currentLang);
    if (currLang) {
      return currLang.label;
    }
    return this.environmentService.languageDefault.label;
  }

  get languages() {
    return this.environmentService.languages;
  }
}
