<form [formGroup]="form" class="flex flex-col py-4">


  <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
    <mat-label>{{"COMMON_NAME" | translate}}</mat-label>
    <input matInput formControlName="contactName">
  </mat-form-field>

  <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
    <mat-label>{{"COMMON_EMAIL" | translate}}</mat-label>
    <input matInput formControlName="contactEmail">
  </mat-form-field>

  <div class="w-full md:w-1/2 md:p-2">
    <phone-number [control]="form.controls.contactPhone"></phone-number>
  </div>

</form>
