<form *ngIf="form" [formGroup]="form" class="flex flex-wrap">
  <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
    <mat-label>{{ "USER_USERNAME" | translate }}</mat-label>
    <input matInput formControlName="userName">
  </mat-form-field>

  <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
    <mat-label>{{ "USER_EMAIL" | translate }}</mat-label>
    <input matInput formControlName="email">
  </mat-form-field>

  <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
    <mat-label>{{ "USER_FIRST_NAME" | translate }}</mat-label>
    <input matInput formControlName="firstName">
  </mat-form-field>

  <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
    <mat-label>{{ "USER_LAST_NAME" | translate }}</mat-label>
    <input matInput formControlName="lastName">
  </mat-form-field>

  <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
    <mat-label>{{ "USER_SOCIAL_SECURITY_NUMBER" | translate }}</mat-label>
    <input matInput formControlName="socialSecurityNumber">
  </mat-form-field>

  <div class="w-full md:w-1/2 md:p-2">
    <phone-number [control]="form.controls.phoneNumber"></phone-number>
  </div>

  @if (isManagement) {
    <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
      <mat-label>{{ 'TENANT' | translate }}</mat-label>
      <mat-select formControlName="tenantId">
        <mat-option *ngFor="let tenant of tenants" [value]="tenant.id">
          {{ tenant.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  }

  <div class="w-full md:w-1/2 md:p-2">
    <mat-slide-toggle color="primary" formControlName="isActive">
      {{ "COMMON_ACTIVE" | translate }}
    </mat-slide-toggle>
  </div>

  <div class="w-full md:w-1/2 md:p-2">
    <mat-label>Select Roles</mat-label>
    <mat-selection-list formControlName="roles">
      <mat-list-option *ngFor="let role of roles" [value]="role">{{ role }}</mat-list-option>
    </mat-selection-list>
  </div>
</form>
