import {ObjectMapperHelper} from "../../core/object-mapper-helper";
import {AddressDetails} from "../address-details";


export class Customer {
  readonly id: string;
  readonly tenantId: string;
  readonly contactId: string | null;
  readonly name: string;
  readonly address: AddressDetails;
  readonly invoiceId: number | null;
  readonly organizationNumber: string;
  readonly isActive: boolean;
  readonly updatedBy: string | null;
  readonly updatedAt: Date;


  constructor(data: any) {
    const helper = ObjectMapperHelper.of(data);
    this.id = helper.requireString('id');
    this.tenantId = helper.getUUID('tenantId');
    this.contactId = helper.getString('contactId');
    this.name = helper.requireString('name');
    this.address = new AddressDetails(data?.address);
    this.invoiceId = helper.getNumber('invoiceId');
    this.organizationNumber = helper.requireString('organizationNumber');
    this.isActive = helper.getBoolean('isActive');
    this.updatedBy = helper.getString('updatedBy');
    this.updatedAt = helper.getDate('updatedAt') || new Date();
  }

  get countryTranslateKey(): string {
    return `COUNTRY_${this.address.country}`;
  }
}
