<div class="h-full">

  <div class="flex justify-between items-center">
    <button type="button" mat-icon-button color="primary" (click)="onClose()">
      <mat-icon>close</mat-icon>
    </button>
    <div class="flex flex-row items-center">
<!--      <div class="text-center w-12 pt-2">-->
<!--        <span class="text-base">{{selectedFileIndex + 1}}</span>-->
<!--      </div>-->

      <button mat-icon-button (click)="previousFile()" [disabled]="selectedFileIndex === 0">
        <mat-icon>arrow_upward</mat-icon>
      </button>

      <button mat-icon-button (click)="nextFile()" [disabled]="selectedFileIndex === (filePreviews.length - 1)">
        <mat-icon>arrow_downward</mat-icon>
      </button>

      <button [disabled]="!selectedFile.mimeType.includes('application/pdf')" mat-icon-button (click)="handleZoom(0.1)">
        <mat-icon>zoom_in</mat-icon>
      </button>

      <button [disabled]="!selectedFile.mimeType.includes('application/pdf')" mat-icon-button
              (click)="handleZoom(-0.1)">
        <mat-icon>zoom_out</mat-icon>
      </button>
    </div>

    <button mat-icon-button (click)="openInNewTab()" [disabled]="selectedFile.mimeType.includes('video') && !selectedFile.storedFile">
      <mat-icon>open_in_new</mat-icon>
    </button>
  </div>

  <div *ngIf="selectedFile" class="h-full w-full border-2 mb-2 rounded">
    <div class="flex bg-gray-200 h-30 p-2 ps-4 ">
      <fa-icon [icon]="faFile" size="sm"/>
      <span class="text-xs ml-2">{{selectedFile.filename}}</span>
    </div>

    <div class="w-full flex flex-row justify-center">

      <img *ngIf="selectedFile.mimeType.includes('image')" [src]="selectedFile.dataUrl" class="p-4 object-contain"
           alt="Image Preview"/>

      <div *ngIf="selectedFile.mimeType.includes('application/pdf')"
           class="flex flex-row flex-wrap w-full h-full justify-center">
        <pdf-viewer [src]="selectedFile.dataUrl"
                    [render-text]="true"
                    [original-size]="false"
                    [style.width.%]="100"
                    [zoom]="zoom"
                    [style.height.px]="dialogHeight"
                    [show-borders]="true"
        ></pdf-viewer>
      </div>

      <div
        *ngIf="selectedFile.mimeType.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document')"
        class="flex flex-row flex-wrap w-full h-full justify-center">
        <span>Preview not available, docx will be converted to pdf</span>
      </div>

      <div *ngIf="selectedFile.mimeType.includes('video')" class="pt-4">
        <video controls #myVideo>
          <source [src]="selectedFile.dataUrl" type="video/mp4">
          <source [src]="selectedFile.dataUrl" type="video/webm">
          <source [src]="selectedFile.dataUrl" type="video/ogg">
          Your browser does not support the video tag.
        </video>
      </div>

    </div>
  </div>
</div>

