export enum UserRole {
  TenantAdmin = 'TenantAdmin',
  CustomerAdmin = 'CustomerAdmin',
  ProviderAdmin = 'ProviderAdmin',
  AssetManager = 'AssetManager',
  ReportViewer = 'ReportViewer',
  BillingAdmin = 'BillingAdmin',
  SupportAgent = 'SupportAgent',
  SalesAgent = 'SalesAgent',
  SuperAdmin = 'SuperAdmin'
}
