import {Injectable} from "@angular/core";
import {ApiService} from "../../core/api-service";
import {map, Observable} from "rxjs";
import {Provider} from "./provider";
import {Paginated, RespPaginated, RespWrapper} from "../resp-wrapper";
import {MutablePartial} from "../global-types";
import {UUID} from "../uuid";
import {Paginator} from "../../core/paginator";
import {Incident} from "../incident/incident";
import {UrlBuilder} from "../url-builder";

@Injectable({
  providedIn: 'root'
})
export class ProviderService {
  apiPath = '/v1/providers';

  constructor(private apiService: ApiService) {
  }

  public all(searchTerm: string = null, paginator: Paginator<Provider>): Observable<Paginated<Provider>> {
    const url = UrlBuilder.of(this.apiPath).addPagination(paginator).addSearch(searchTerm).toUrl();
    return this.apiService.get<RespPaginated<Provider>>(url)
      .pipe(map((resp) => Paginated.of<Provider>(resp, Provider)));
  }

  public by(providerId: string): Observable<Provider> {
    const url = `${this.apiPath}/${providerId}`;
    return this.apiService.get<RespWrapper<Provider>>(url)
      .pipe(map((resp => new Provider(resp.data))));
  }

  createOrUpdate(providerId: Provider["id"] | null, data: MutablePartial<Provider>): Observable<Provider> {
    if (providerId) {
      const url = `${this.apiPath}/${providerId}`;
      return this.apiService.patch<RespWrapper<Provider>>(url, data)
        .pipe(map(resp => resp.data));
    }
    return this.apiService.post<RespWrapper<Provider>>(this.apiPath, data)
      .pipe(map(resp => resp.data));
  }
}
