<div class="flex flex-row w-full">
  <mat-form-field *ngIf="control" floatLabel="always" appearance="outline" class=" w-full flex items-center">
    <mat-label>{{label | translate}}</mat-label>
    <button type="button" matPrefix mat-button color="primary" [matMenuTriggerFor]="mySelect" (click)="focusFirstMenuItem()">
      <span class="flex items-center pl-1" >
        <span class="w-6 pt-0.5">{{phone.country}}</span>
        <mat-icon matSuffix class="opacity-50">arrow_drop_down</mat-icon>
      </span>
    </button>
    <div class="flex">
    <span class="text-base inline-block -ml-2 mr-2 pt-[5px]">{{phone.prefix()}}</span>
    <input [attr.aria-label]="label | translate" matInput type="tel" [mask]="maskUtil.phoneMask"
           [formControl]="internalCtrl" [value]="internalCtrl.value" (blur)="onBlur()">
    </div>
  </mat-form-field>
</div>
<mat-menu #mySelect>
  <button type="button" class="w-full" mat-menu-item *ngFor="let country of countries"
          (click)="onSelectCountry(country)">
      <span class="flex items-center">
      <span>{{ country.code }} </span>
        <mat-icon class="ml-4" *ngIf="country.code === phone.country" matSuffix>check</mat-icon>
      </span>
  </button>
</mat-menu>
