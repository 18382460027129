import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {combineLatest, debounceTime, startWith, Subject, Subscription, switchMap} from "rxjs";
import {Router} from "@angular/router";
import {FormControl} from "@angular/forms";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {Paginator} from "../../core/paginator";
import {EventUtil} from "../../core/event-util";
import {LanguageService} from "../../core/language-service";
import {Incident} from "../../domain/incident/incident";
import {IncidentService} from "../../domain/incident/incident-service";
import {FacilityStorageService} from "../../core/storage/facility-storage-service";

@Component({
  selector: 'incidents',
  templateUrl: './incidents.component.html',
})
export class IncidentsComponent implements OnInit, OnDestroy {
  triggerClick = EventUtil.triggerClick;
  protected readonly faSpinner = faSpinner;
  searchCtrl = new FormControl();
  incidents: Incident[] | null = null;
  sub = new Subscription();
  paginator = new Paginator<Incident>();
  paginatorSub = new Subject<Paginator<Incident>>();
  paginator$ = this.paginatorSub.asObservable();

  @ViewChild(MatPaginator) set matPaginator(paginator: MatPaginator | null) {
    this.paginator.matPaginator = paginator;
    this.paginatorSub.next(this.paginator);
  }

  constructor(
    private router: Router,
    private incidentService: IncidentService,
    private languageService: LanguageService,
    private facilityStorageService: FacilityStorageService
  ) {
  }

  ngOnInit() {
    this.paginator.setLang(this.languageService.currLanguage());
    const search$ = this.searchCtrl.valueChanges.pipe(startWith(""), debounceTime(300));

    this.sub.add(combineLatest([search$, this.paginator$])
      .pipe(switchMap(([term, paginator]) => this.incidentService.all(term, this.facilityStorageService?.currentFacility?.id, paginator)))
      .subscribe(paginatedIncidents => {
        this.incidents = paginatedIncidents.items;
        this.paginator.setTotal(paginatedIncidents.totalCount);
      }));

  }

  create() {
    this.router.navigate([`events/incidents/new`]).then(/* DO NOTHING */);
  }


  clearSearch() {
    this.searchCtrl.setValue('');
  }

  toggleSort(name: keyof Incident) {
    this.paginator.sortBy(name);
    this.paginatorSub.next(this.paginator);
  }

  navigateToEdit(incident: Incident) {
    this.router.navigate([`events/incidents`, incident.id]).then(/* DO NOTHING */);
  }


onPageChange(event: PageEvent) {
  this.paginator.onPageChange(event);
  this.paginatorSub.next(this.paginator);
}

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
