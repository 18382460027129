import {Component, EventEmitter, Input, Output} from "@angular/core";

@Component({
  selector: 'button-icon',
  template: `
    <button mat-icon-button [attr.aria-label]="ariaLabel" [disabled]="isDisabled || inProgress" (click)="onClick($event)">
      <mat-icon [ngClass]="iconClass" *ngIf="!inProgress">{{icon}}</mat-icon>
      <icon-spinner *ngIf="inProgress"></icon-spinner>
    </button>
  `,
})
export class ButtonIconComponent {
  @Input() icon: string;
  @Input() isDisabled = false;
  @Input() inProgress = false;
  @Input() ariaLabel;
  @Input() iconClass = "";
  @Output() clickChange = new EventEmitter<Event>();

  onClick(event: Event) {
    this.clickChange.emit(event);
  }
}
