<mat-tab-group mat-stretch-tabs>
  <mat-tab [label]="'COMMON_BASIC' | translate">
    <form *ngIf="isEditing" [formGroup]="form" class="flex flex-wrap mt-4">
      <heading-h1 [text]="(facilityGroup?.id ? 'FACILITY_GROUP_EDIT' : 'FACILITY_GROUP_NEW')"/>
      <mat-form-field class="w-full mt-4" appearance="outline">
        <mat-label>{{ "COMMON_NAME" | translate }}</mat-label>
        <input matInput formControlName="name">
      </mat-form-field>
      <mat-form-field class="w-full" appearance="outline">
        <mat-label>{{ "COMMON_DESCRIPTION" | translate }}</mat-label>
        <input matInput formControlName="description">
      </mat-form-field>
      <div>
        <button-cancel [isDisabled]="inProgress" (cancelChange)="cancel()"/>
        <button-save class="ml-2" [isDisabled]="!canSave()" [inProgress]="inProgress" (saveClick)="save()"/>
      </div>
    </form>

    <div *ngIf="!isEditing" class="my-4">
      <div class="flex justify-between">
        <heading-h1 [text]="facilityGroup?.name"/>
        <button mat-icon-button (click)="showEdit()" style="margin-top: -16px">
          <mat-icon>edit</mat-icon>
        </button>
      </div>
      <p>{{ facilityGroup.description }}</p>
      <div class="mt-20">
        <heading-h2 text="FACILITIES"/>
        <div *ngIf="facilityGroup" class="mt-6 mb-2">
          <div class="flex flex-col md:flex-row justify-between mb-4 items-start">
            <mat-form-field class="w-full md:w-1/2" appearance="outline">
              <mat-label>{{ 'COMMON_SEARCH' | translate }}</mat-label>
              <button-icon matSuffix *ngIf="searchCtrl.value" (clickChange)="clearSearch()"
                           ariaLabel="Clear">
                <mat-icon>close</mat-icon>
              </button-icon>
              <mat-icon matSuffix *ngIf="!searchCtrl.value">search</mat-icon>
              <input matInput [formControl]="searchCtrl">
            </mat-form-field>
            <button-outlined (clickChange)="openFacilityDialog()" text="FACILITY_GROUP_ADD_MORE"/>
          </div>

          <mat-spinner *ngIf="!facilities"></mat-spinner>
          <div *ngIf="facilities">
            <div>
              <hr class="h-px px-8 color-border-primary">
            </div>
            <ul class="divide-y list-none">
              @for (facility of facilities; track facility.id) {
                <li class="hover:bg-gray-100 cursor-pointer p-2 list-none">
                  <div>
                    <div class="flex items-center justify-between space-x-2">
                      <div class="flex flex-row">
                        <div class="font-bold">{{ facility.name }}</div>
                      </div>
                    </div>
                  </div>
                </li>
              } @empty {
                <li class="p-2">
                  <div>{{ 'COMMON_NO_ENTRIES' | translate }}</div>
                </li>
              }
            </ul>

            <mat-paginator class="mt-2" showFirstLastButtons
                           [pageSizeOptions]="paginator.pageSizeOptions"
                           [pageSize]="paginator.pageSize"
                           [pageIndex]="paginator.pageIndex"
                           [length]="paginator.totalCount"
                           (page)="onPageChange($event)">
            </mat-paginator>
          </div>
        </div>
      </div>
      <button-outlined (clickChange)="cancel()" [isDisabled]="inProgress" text="COMMON_CLOSE"/>
    </div>
  </mat-tab>
  <mat-tab [label]="'USER_GROUPS' | translate" [disabled]="isEditing">
  </mat-tab>
</mat-tab-group>
