import parsePhoneNumberFromString, {getCountryCallingCode, PhoneNumber} from "libphonenumber-js";
import {CountryCode} from "./language-country";

export class Phone {
  private defaultCountry: CountryCode = 'SE';
  private phoneNumber: PhoneNumber;

  static of(value: string, defaultCountry: CountryCode): Phone {
    const phone = new Phone();
    phone.defaultCountry = defaultCountry;
    if (value && value.trim().length === 0) {
      return phone;
    }
    phone.phoneNumber = parsePhoneNumberFromString(value ?? "", defaultCountry);
    return phone;
  }

  static ofCountryChange(value: string, country: CountryCode): Phone {
    const phone = new Phone();
    const temp = parsePhoneNumberFromString(value ?? "", country);
    phone.phoneNumber = parsePhoneNumberFromString(temp?.formatInternational() ?? "", country);
    phone.defaultCountry = country;
    return phone;
  }

  get country(): CountryCode {
    return this.phoneNumber ? this.phoneNumber.country : this.defaultCountry;
  }

  get fullNr(): string {
    return this.phoneNumber ? this.phoneNumber.number : "";
  }

  get prettyEditNumber(): string {
    return this.phoneNumber
      ? this.phoneNumber.formatInternational().replace(this.prefix(), "").trim()
      : "";
  }

  prefix() {
    return "+" + getCountryCallingCode(this.country);
  }
}
