import {Component, EventEmitter, Input, Output} from "@angular/core";

@Component({
  selector: 'button-save',
  template: `
    <button-flat look="primary" [isDisabled]="isDisabled" [inProgress]="inProgress" (clickChange)="onClick()" text="COMMON_SAVE"/>
  `,
})
export class ButtonSaveComponent {
  @Input() isDisabled = false;
  @Input() inProgress = false;
  @Output() saveClick = new EventEmitter<void>();

  onClick() {
    this.saveClick.emit();
  }
}
