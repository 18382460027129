<top-toolbar *ngIf="isLoggedIn()" [isAdminView]="isAdminView" (toggleSidenavChange)="toggleSideNav()"></top-toolbar>
<mat-sidenav-container class="flex" [ngStyle]="isLoggedIn() ? {height: 'calc(100% - 64px)'}: {height: '100%'}">
  <mat-sidenav #drawer [mode]="getSidenavMode()" class="w-64 px-4 pt-2" *ngIf="isLoggedIn()">
    <div id="menu-label">
      <span class="inline-block text-base color-primary-700">{{ getMenuTitle() | translate }}</span>
    </div>

    <mat-nav-list aria-labelledby="management-menu-label">
      <ng-container *ngFor="let item of links">
        <ng-container *ngTemplateOutlet="menuItemTemplate; context: { $implicit: item }"></ng-container>
      </ng-container>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content class="flex-grow">
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>


<ng-template #menuItemTemplate let-item>
  <mat-list-item class="mat-mdc-list-item-interactive" matRipple (click)="navigate(item.route)"
                 *ngIf="!item.children || item.children.length === 0">
    <div class="flex align-middle items-center color-primary-700">
      <mat-icon>{{ item.icon }}</mat-icon>
      <span class="ml-2 text-sm">{{ item.label | translate }}</span>
    </div>
  </mat-list-item>

  <mat-list-item class="mat-mdc-list-item-interactive" matRipple *ngIf="item.children && item.children.length > 0"
                 (click)="toggleSubMenu(item)">
    <div class="flex align-middle items-center color-primary-700">
      <mat-icon>{{ item.icon }}</mat-icon>
      <span class="ml-2 text-sm">{{ item.label | translate }}</span>
    </div>
  </mat-list-item>

  <div *ngIf="item.open">
    <ng-container *ngFor="let childItem of item.children">
      <ng-container *ngTemplateOutlet="menuItemTemplate; context: { $implicit: childItem }"></ng-container>
    </ng-container>
  </div>
</ng-template>
