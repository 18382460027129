import {Component, Inject, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {Paginator} from "../../core/paginator";
import {combineLatest, debounceTime, finalize, startWith, Subject, Subscription, switchMap} from "rxjs";
import {EventUtil} from "../../core/event-util";
import {FormControl} from "@angular/forms";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {UUID} from "../../domain/uuid";
import {LanguageService} from "../../core/language-service";
import {Facility} from "../../domain/facility/facility";
import {FacilityService} from "../../domain/facility/facility-service";
import {FacilityGroup} from "../../domain/facility/facility-group";
import {FacilityGroupService} from "../../domain/facility/facility.group-service";

@Component({
  templateUrl: './facility-group-facilities-select-dialog.component.html',
})
export class FacilityGroupFacilitiesSelectDialogComponent implements OnInit, OnDestroy {
  triggerClick = EventUtil.triggerClick;
  protected readonly faSpinner = faSpinner;
  inProgress = false;
  inProgressId: UUID;
  facilities: Facility[] = [];
  sub = new Subscription();
  paginator = new Paginator<Facility>();
  paginatorSub = new Subject<Paginator<Facility>>();
  paginator$ = this.paginatorSub.asObservable();
  searchCtrl = new FormControl();
  showOnlySelectedCtrl = new FormControl();
  title = "";
  facilityGroup: FacilityGroup;
  updated = false;

  @ViewChild(MatPaginator) set matPaginator(paginator: MatPaginator | null) {
    if (!this.paginator.matPaginator) {
      this.paginator.matPaginator = paginator;
      this.paginatorSub.next(this.paginator);
    }
  }

  constructor(
    private dialogRef: MatDialogRef<FacilityGroupFacilitiesSelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { group: FacilityGroup },
    private facilityService: FacilityService,
    private facilityGroupService: FacilityGroupService,
    private languageService: LanguageService) {
  }

  ngOnInit() {
    this.facilityGroup = this.data.group;
    this.paginator.setLang(this.languageService.currLanguage());
    const search$ = this.searchCtrl.valueChanges.pipe(startWith(""), debounceTime(300));
    const onlySelected$ = this.showOnlySelectedCtrl.valueChanges.pipe(startWith(false), debounceTime(300));

    this.sub.add(combineLatest([search$, onlySelected$, this.paginator$]).pipe(
      switchMap(([term, onlySelected, paginator]) => this.facilityService.all(term, paginator, onlySelected ? this.facilityGroup : null)))
      .subscribe(paginatedAssets => {
        this.facilities = paginatedAssets.items;
        this.paginator.setTotal(paginatedAssets.totalCount);
      }));
  }

  isSelected(id: UUID): boolean {
    return this.facilityGroup.facilityUuids.some(asset => asset === id);
  }

  isInProgress(facility: Facility): boolean {
    return this.inProgress && this.inProgressId === facility.id;
  }

  isDisabled(facility: Facility): boolean {
    return this.inProgress && !this.isInProgress(facility);
  }

  close() {
    this.dialogRef.close(this.updated ? this.facilityGroup : null);
  }

  clearSearch() {
    this.searchCtrl.setValue('');
  }

  onPageChange(event: PageEvent) {
    this.paginator.onPageChange(event);
    this.paginatorSub.next(this.paginator);
  }

  setInProgress(facility: Facility) {
    this.inProgress = true;
    this.inProgressId = facility.id;
  }

  resetInProgress() {
    this.inProgress = false;
    this.inProgressId = null;
  }

  remove(facility: Facility) {
    this.setInProgress(facility);
    this.facilityGroupService.removeFromGroup(this.facilityGroup, facility.id)
      .pipe(finalize(() => this.resetInProgress()))
      .subscribe((group) => {
        this.updated = true;
        this.facilityGroup = group;
      });
  }

  addToGroup(facility: Facility) {
    this.setInProgress(facility);
    this.facilityGroupService.addToGroup(this.facilityGroup, facility.id)
      .pipe(finalize(() => this.resetInProgress()))
      .subscribe((group) => {
        this.updated = true;
        this.facilityGroup = group;
      });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  addOrRemove(facility: Facility) {
    if (this.inProgress) {
      return;
    }
    if (this.isSelected(facility.id)) {
      return this.remove(facility);
    }
    return this.addToGroup(facility);
  }
}
