import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {combineLatest, filter, finalize, firstValueFrom, Observable, of, Subscription, switchMap} from "rxjs";
import {CanDeactivateComponent} from "../../core/can-deactivate-guard";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {EnvironmentService} from "../../core/environment-service";
import {LanguageCountry} from "../../domain/language-country";
import {MaskUtil} from "../../core/mask-util";
import {UUID} from "../../domain/uuid";
import {EventUtil} from "../../core/event-util";
import {DialogService} from "../../shared/dialogs/dialog-service";
import {Facility} from "../../domain/facility/facility";
import {SnackbarService} from "../../shared/snackbar/snackbar-service";
import {Booking, BookingStatus} from "../../domain/booking/booking";
import {BookingService} from "../../domain/booking/booking-service";
import {FacilityStorageService} from "../../core/storage/facility-storage-service";
import {TranslateService} from "@ngx-translate/core";

interface BookingForm {
  title: FormControl<string>
  description: FormControl<string>
  isActive: FormControl<boolean>
  status: FormControl<string>
  expectedCompletionDate: FormControl<Date>
}

@Component({
  templateUrl: './booking-edit.component.html',
})
export class BookingEditComponent implements OnInit, CanDeactivateComponent, OnDestroy {
  maskUtil = new MaskUtil();
  protected readonly faSpinner = faSpinner;
  triggerClick = EventUtil.triggerClick;
  booking: Booking;
  inProgress = true;
  form: FormGroup<BookingForm>;
  countriesList: LanguageCountry[] = [];
  subscription = new Subscription();
  title = "";
  bookingStatus: string[] = Object.keys(BookingStatus).map(key => BookingStatus[key]);
  facility: Facility | null;

  constructor(private fb: FormBuilder,
              private environmentService: EnvironmentService,
              private bookingService: BookingService,
              private router: Router,
              private route: ActivatedRoute,
              private dialogService: DialogService,
              private snackbarService: SnackbarService,
              private facilityStorageService: FacilityStorageService,
              private translateService: TranslateService,
  ) {
  }

  ngOnInit() {
    this.countriesList = this.environmentService.langCountry;
    const bookingId = this.route.snapshot.paramMap.get('id');

    combineLatest([
      this.getInitDataOfBooking(bookingId),
      this.facilityStorageService.currentFacility$
    ]).subscribe(([booking, facility]) => {
        this.facility = facility;
        this.booking = booking;
        this.initForm();
        this.inProgress = false;
    });

    this.setTitle(bookingId === "new");
  }

  translateBookingStatus(status: string): string{
    const translationKey = 'BOOKING_STATUS_' + status;
    return this.translateService.instant(translationKey);
  }

  getInitDataOfBooking(bookingId: string): Observable<Booking> {
    if (!bookingId || bookingId === "new") {
      return of(null)
    }
    return this.bookingService.byId(bookingId);
  }

  setTitle(isNewTenant: boolean): void {
    this.title = isNewTenant ? "COMMON_NEW" : "COMMON_EDIT";
  }

  initForm(): void {
    const booking = this.booking;
    this.form = this.fb.group({
      title: [booking?.title ?? "", Validators.required],
      description: [booking?.description ?? "", Validators.required],
      isActive: [booking?.isActive ?? true, Validators.required],
      expectedCompletionDate: [booking?.expectedCompletionDate ?? new Date(), Validators.required],
      status: [booking?.status ?? BookingStatus.New.toString(), Validators.required],
    });

    if (!booking) {
      this.form.controls.status.disable();
    }
  }

  async save(): Promise<void> {
    if (this.form.invalid) {
      return;
    }
    this.inProgress = true;
    const isUpdate = !!this.booking?.id;

    const bookingData: Partial<Booking> = {
      ...this.form.getRawValue(),
      id: this.booking?.id,
      status: this.form.controls.status.value as BookingStatus,
      customerId: this.facility?.customerId as UUID,
      facilityId: this.facility?.id as UUID,
    }

    const selectedMethod$ = isUpdate
      ? this.bookingService.update(bookingData)
      : this.bookingService.create(bookingData);

    this.booking = await firstValueFrom(selectedMethod$.pipe(finalize(() => this.inProgress = false)));
    this.form.markAsPristine();
    this.snackbarService.success(isUpdate ? "BOOKING_UPDATE_SUCCESS" : "BOOKING_CREATE_SUCCESS");
    this.navigateBack();
  }

  private navigateBack() {
    this.router.navigate(["events/bookings"]).then(/* DO NOTHING */);
  }

  canSave(): boolean {
    return this.form != null && this.form.dirty && this.form.valid && !this.inProgress;
  }

  cancel() {
    this.navigateBack();
  }

  canDeactivateComponent(): boolean {
    return (this.form && this.form.dirty) ?? false;
  }

  delete() {
    const dialogRef = this.dialogService.onDeleteDialog("BOOKING_DELETE_QUESTION");
    this.subscription.add(
      dialogRef.pipe(
        filter((remove: boolean) => remove),
        switchMap(() => this.bookingService.delete(this.booking.id)),
      ).subscribe(()=> {
        this.form.markAsPristine();
        this.snackbarService.success("COMMON_DELETED");
        this.navigateBack();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
