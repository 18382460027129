import {Component, EventEmitter, Input, Output} from "@angular/core";
import {CrudinatorField} from "../../domain/crudinator/crudinator-field";
import {CrudinatorConfig} from "../../domain/crudinator/crudinator-config";
import {CrudinatorFieldType} from "../../domain/crudinator/crudinator-field-type";

@Component({
  selector: 'crudinator-list',
  templateUrl: './crudinator-list.component.html',
})
export class CrudinatorListComponent {

  @Input() crudList: any[] | null = null
  @Input() fields: CrudinatorField[] | null = null
  @Input() crudConfig: CrudinatorConfig | null = null
  @Input() inProgress = false;

  @Output() backChange: EventEmitter<void> = new EventEmitter<void>()
  @Output() selectChange: EventEmitter<any> = new EventEmitter<any>()
  @Output() addChange: EventEmitter<any> = new EventEmitter<any>()

  navigateBack() {
    this.backChange.emit();
  }

  selectRow(row: any) {
    const clone = this.cloneObj(row);
    this.selectChange.emit(clone);
  }

  add(event: Event) {
    event.preventDefault();
    this.selectChange.emit({});
  }

  private cloneObj(obj: any): any {
    const newObj: any = {};
    newObj[this.crudConfig!.idField] = obj[this.crudConfig!.idField];

    for (const field of this.fields!) {
      if (field.field_type === CrudinatorFieldType.Bool) {
        newObj[field.name] = obj[field.name] != null ? obj[field.name] : false;
        continue;
      }
      newObj[field.name] = obj[field.name];
    }

    return newObj;
  }

  getTitle(rowObj: any) {
    return rowObj[this.crudConfig!.labelField];
  }
}
