import {firstValueFrom} from "rxjs";
import {CanDeactivateFn} from "@angular/router";
import {inject} from "@angular/core";
import {DialogService} from "../shared/dialogs/dialog-service";

export interface CanDeactivateComponent {
  canDeactivateComponent: () => boolean;
}

export const canDeactivateGuard: CanDeactivateFn<CanDeactivateComponent> = (component: CanDeactivateComponent) => {
  if (component.canDeactivateComponent && component.canDeactivateComponent()) {
    const dialogService = inject(DialogService);
    const dialogRef = dialogService.unsavedChangesDialog();
    return firstValueFrom(dialogRef);
  }
  return true;
};
