import {Pipe, PipeTransform} from "@angular/core";
import {LanguageService} from "../core/language-service";
import {format} from "date-fns";

@Pipe({
  name: 'dateTime'
})
export class DateTimePipe implements PipeTransform {

  constructor(private languageService: LanguageService) {
  }

  transform(value: Date | string | number | null): string {
    if (!value) {
      return '';
    }

    const date = (typeof value === 'number' || typeof value === 'string') ? new Date(value) : value;
    return format(date, this.languageService.getDateTimeFormat());
  }
}
