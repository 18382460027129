import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {AbstractControl, FormControl, ValidationErrors, ValidatorFn} from "@angular/forms";
import {Subscription} from "rxjs";

@Component({
  selector: 'password-input',
  templateUrl: './password-input.component.html',
})
export class PasswordInputComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  hide = true;


  @Input() control: FormControl;
  @Input() label = "COMMON_PASSWORD";

  constructor() {}

  ngOnInit() {
    this.control.addValidators([this.createPasswordStrengthValidator()]);
  }

  createPasswordStrengthValidator(): ValidatorFn {
    return (control:AbstractControl) : ValidationErrors | null => {

      const value = control.value;

      const errors = {}

      const minLength = value.length > 8;
      if(!minLength) errors['minLength'] = true;

      const hasUpperCase = /[A-Z]+/.test(value);
      if(!hasUpperCase) errors['upperCase'] = true;

      const hasLowerCase = /[a-z]+/.test(value);
      if(!hasLowerCase) errors['lowerCase'] = true;

      const hasNumeric = /[0-9]+/.test(value);
      if(!hasNumeric) errors['numeric'] = true;

      const hasSpecialChar = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(value);
      if(!hasSpecialChar) errors['specialChar'] = true;

      return {...errors};
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
