import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy} from "@angular/core";
import {Router} from "@angular/router";
import {Paginator} from "../../core/paginator";
import {User} from "../../domain/user/user";
import {UserService} from "../../domain/user/user-service";
import {finalize, Subscription} from "rxjs";
import {Paginated} from "../../domain/resp-wrapper";
import {AppRoutes} from "../../app-routing.module";

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UsersComponent implements OnDestroy {
  paginated: Paginated<User>;
  inProgress = false;

  sub = new Subscription();

  constructor(
    private router: Router,
    private userService: UserService,
    private cd: ChangeDetectorRef
  ) {}

  onSearch(data: { searchTerm: string, paginator: Paginator<User> }) {
    this.inProgress = true;
    this.userService.all(data.searchTerm, data.paginator)
      .pipe(finalize(()=> {
        this.inProgress = false
        this.cd.markForCheck()
      }))
      .subscribe(response => {
        this.paginated = response;
      });
  }

  onCreate() {
    this.router.navigate([`/${AppRoutes.ADMIN_CUSTOMER}/users/new`]).then(/* DO NOTHING */);
  }

  onNavigateToEdit(user: User) {
    this.router.navigate([`/${AppRoutes.ADMIN_CUSTOMER}/users`, user.id]).then(/* DO NOTHING */);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
