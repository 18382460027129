import {Injectable} from "@angular/core";
import {ApiService} from "../../core/api-service";
import {map, Observable} from "rxjs";
import {Crudinator} from "./crudinator";
import {CrudinatorConfig} from "./crudinator-config";
import {CrudinatorConfigType} from "./crudinator-config-type";
import {RespWrapper} from "../resp-wrapper";

@Injectable({
  providedIn: 'root'
})
export class CrudinatorMakeService {
  configs: Record<string, CrudinatorConfig> = configurations;

  constructor(private apiService: ApiService) {
  }

  configByType(type: CrudinatorConfigType): CrudinatorConfig {
    return this.configs[type];
  }

  make(crudPath: string): CrudinatorApiService {
    return new CrudinatorApiService(this.apiService, crudPath);
  }
}

export class CrudinatorApiService {
  private readonly url: string;

  constructor(private apiService: ApiService, restPath: string) {
    this.url = `/${restPath}`;
  }

  list(): Observable<Crudinator> {
    return this.apiService.get<RespWrapper<Crudinator>>(this.url)
      .pipe(map(resp => resp.data));
  }

  save(obj: any, id: string | null): Observable<Crudinator> {
    if (id) {
      const idUrl = `${this.url}/${id}`;
      return this.apiService.patch<RespWrapper<Crudinator>>(idUrl, JSON.stringify(obj))
        .pipe(map(resp => resp.data));
    }
    return this.apiService.post<RespWrapper<Crudinator>>(this.url, JSON.stringify(obj))
      .pipe(map(resp => resp.data));
  }

  remove(id: any): Observable<any> {
    const url = `${this.url}/${id}`
    return this.apiService.delete<RespWrapper<Crudinator>>(url)
      .pipe(map(resp => resp.data));
  }
}

const configurations: Record<string, CrudinatorConfig> = {
  [CrudinatorConfigType.TENANT_TYPE]: {
    apiPath: 'tenant/type-crud',
    title: 'TENANT_TYPE',
    idField: 'typeId',
    labelField: 'typeName',
  },  [CrudinatorConfigType.ASSET_TYPE]: {
    apiPath: 'asset/type-crud',
    title: 'ASSET_TYPE',
    idField: 'typeId',
    labelField: 'typeName',
  },
}

