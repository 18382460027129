<form [formGroup]="form" class="flex flex-col">
  <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
    <mat-label>{{"TITLE" | translate}}</mat-label>
    <input matInput formControlName="title" />
  </mat-form-field>

  <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
    <mat-label>{{"DESCRIPTION" | translate}}</mat-label>
    <textarea rows="3" matInput formControlName="description"></textarea>
  </mat-form-field>

  <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
    <mat-label>{{"INCIDENT_TYPE" | translate}}</mat-label>
    <mat-select formControlName="type">
      <mat-option *ngFor="let incident of incidentTypes" [value]="incident">
        {{ ("INCIDENT_TYPE_" + incident) | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div class="w-full md:w-1/2 md:p-2">
    <app-file-upload
      [allFiles]="allFiles"
      [documentType]="DocumentTypeEnum.IncidentAttachment"
      (onFileListChange)="handleOnFileListChange($event)"
    ></app-file-upload>
  </div>
</form>
