import {Component, Input, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {FormControl} from "@angular/forms";
import {Subscription} from "rxjs";
import {EnvironmentService} from "../../core/environment-service";
import {MatMenu} from "@angular/material/menu";
import {Phone} from "../../domain/phone";
import {MaskUtil} from "../../core/mask-util";
import {Country} from "../../domain/language-country";

@Component({
  selector: 'phone-number',
  templateUrl: './phone-number.component.html',
})
export class PhoneNumberComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  maskUtil = new MaskUtil();
  countries: Country[] = [];
  phone: Phone = new Phone();
  internalCtrl = new FormControl<string>("")

  @ViewChild("mySelect") textInput: MatMenu;

  @Input() control: FormControl;
  @Input() label = "COMMON_PHONE";

  constructor(private environmentService: EnvironmentService) {
    this.countries = this.environmentService.countries;
  }

  ngOnInit() {
    this.phone = Phone.of(this.control.value, 'SE');
    this.updateInternalCtrl(this.phone);

    this.subscription.add(this.control.statusChanges.subscribe(() => {
      this.internalCtrl.setErrors(this.control.errors);
    }));
  }

  focusFirstMenuItem() {
    setTimeout(() => {
      this.textInput.focusFirstItem();
    });
  }

  onBlur() {
    this.phone = Phone.of(this.internalCtrl.value, this.phone.country);
    this.updateCtrls(this.phone);
  }

  updateCtrls(phone: Phone) {
    this.updateInternalCtrl(phone);
    this.updateExternalCtrl(phone);
  }

  updateInternalCtrl(phone: Phone) {
    this.internalCtrl.setValue(phone.prettyEditNumber);
  }

  updateExternalCtrl(phone: Phone) {
    this.control.setValue(phone.fullNr);
    this.control.markAsDirty();
    this.control.updateValueAndValidity();
  }

  onSelectCountry(country: Country) {
    this.phone = Phone.ofCountryChange(this.internalCtrl.value, country.code);
    this.updateCtrls(this.phone);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
