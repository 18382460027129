import {Component} from "@angular/core";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'icon-spinner',
  template: `
      <fa-icon [icon]="faSpinner" animation="spin"/>
  `,
})
export class IconSpinnerComponent {
  protected readonly faSpinner = faSpinner;
}
