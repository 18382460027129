import {ObjectMapperHelper} from "../../core/object-mapper-helper";
import {AddressDetails} from "../address-details";


export enum TenantType {
  SuperTenant = "SuperTenant",
  RetailTenant = "RetailTenant",
  ProviderTenant = "ProviderTenant",
  HotelTenant = "HotelTenant",
  RealEstateTenant = "RealEstateTenant",
  ManufacturerTenant = "ManufacturerTenant",
}


export class Tenant {
  readonly id: string;
  readonly name: string;
  readonly contactEmail: string;
  readonly contactPhone: string;
  readonly organizationNumber: string;
  readonly address: AddressDetails;
  readonly invoiceAddress?: AddressDetails;
  readonly invoiceId: number;
  readonly invoiceReference: string;
  readonly isActive: boolean;
  readonly tenantType: TenantType;


  constructor(data: any) {
    const helper = ObjectMapperHelper.of(data);
    this.id = helper.getString('id');
    this.name = helper.requireString('name');
    this.organizationNumber = helper.requireString('organizationNumber');
    this.address = new AddressDetails(data?.address);
    this.invoiceAddress = new AddressDetails(data?.invoiceAddress);

    this.contactPhone = helper.getString('contactPhone');
    this.contactEmail = helper.getString('contactEmail');
    this.isActive = helper.getBoolean('isActive');
    this.invoiceId = helper.getNumber('invoiceId');
    this.tenantType = helper.getEnum('tenantType', TenantType);
  }

  get countryTranslateKey(): string {
    if (this.address.country == null) {
      return "";
    }
    return `COUNTRY_${this.address.country}`;
  }
}
