import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {Router} from "@angular/router";
import {FormControl} from "@angular/forms";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {Paginator} from "../../core/paginator";
import {AppRoutes} from "../../app-routing.module";
import {EventUtil} from "../../core/event-util";
import {User} from "../../domain/user/user";
import {UserService} from "../../domain/user/user-service";
import {combineLatest, debounceTime, distinctUntilChanged, startWith, Subject, Subscription, switchMap} from "rxjs";
import {LanguageService} from "../../core/language-service";
import {Paginated} from "../../domain/resp-wrapper";

@Component({
  selector: 'users-table',
  templateUrl: './users-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UsersTableComponent implements OnInit, OnDestroy {
  triggerClick = EventUtil.triggerClick;
  searchCtrl = new FormControl();
  users: User[] | null = null;
  sub = new Subscription();
  paginator = new Paginator<User>("userName");
  paginatorSub = new Subject<Paginator<User>>();
  paginator$ = this.paginatorSub.asObservable();

  @Input() set userPaginated(paginated: Paginated<User>) {
    this.users = paginated?.items ?? null;
    this.paginator.setTotal(paginated?.totalCount ?? 0);
  }

  @Output() paginatorChange = new EventEmitter<{ searchTerm: string, paginator: Paginator<User> }>();
  @Output() createChange = new EventEmitter<void>();
  @Output() editChange = new EventEmitter<User>();

  @ViewChild(MatPaginator) set matPaginator(paginator: MatPaginator | null) {
    this.paginator.matPaginator = paginator;
  }

  constructor(private languageService: LanguageService) {
  }

  ngOnInit() {
    this.paginator.setLang(this.languageService.currLanguage());
    console.log("init")
    const search$ = this.searchCtrl.valueChanges.pipe(startWith(""), debounceTime(300), distinctUntilChanged());

    this.sub.add(combineLatest([search$, this.paginator$])
      .subscribe(([term, paginator]) => {
        console.log('term', term, 'paginator', paginator);
      this.paginatorChange.emit({searchTerm: term, paginator});
    }));

    this.paginatorSub.next(this.paginator);
  }
  create() {
    this.createChange.emit();
  }

  navigateToEdit(user: User) {
    this.editChange.emit(user)
  }

  clearSearch() {
    this.searchCtrl.setValue('');
  }

  toggleSort(name: keyof User) {
    this.paginator.sortBy(name);
    this.paginatorSub.next(this.paginator);
  }

  onPageChange(event: PageEvent) {
    this.paginator.onPageChange(event);
    this.paginatorSub.next(this.paginator);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
