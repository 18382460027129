import {Injectable} from "@angular/core";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {Observable} from "rxjs";
import {FaqDialogComponent} from "./faq-dialog.component";
import {ErrorDialogComponent} from "./error-dialog.component";
import {UnsavedChangesDialogComponent} from "./unsaved-changes-dialog.component";
import {CrudinatorDialogComponent} from "./crudinator-dialog.component";
import {CrudinatorConfig} from "../../domain/crudinator/crudinator-config";
import {ForgotPasswordDialogComponent} from "./forgot-password-dialog.component";
import {SelectAssetsDialogComponent} from "./select-assets-dialog.component";
import {Asset} from "../../domain/asset/asset";
import {AssetDialogComponent} from "./asset-dialog.component";
import {UUID} from "../../domain/uuid";
import {ListItem, SelectFromListDialogComponent} from "./select-from-list-dialog.component";
import {ViewAttachmentDialogComponent} from "./view-attachment-dialog.component";
import {OnDeleteDialogComponent} from "./on-delete-dialog.component";
import {SetPasswordDialogComponent} from "./set-password-dialog.component";
import {User} from "../../domain/user/user";
import {FacilityGroupFacilitiesSelectDialogComponent} from "./facility-group-facilities-select-dialog.component";
import {FacilityGroup} from "../../domain/facility/facility-group";
import {BookingDialogComponent} from "./booking/booking-dialog.component";
import {Booking} from "../../domain/booking/booking";

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private dialog: MatDialog) {
  }

  errorDialog(message: string, errorPosted: boolean, title?: string | null): Observable<void> {
    const dialogConfig = this.configuration();
    dialogConfig.data = {title, message, errorPosted};
    return this.dialog.open(ErrorDialogComponent, dialogConfig).afterClosed();
  }

  unsavedChangesDialog(): Observable<boolean> {
    const dialogConfig = this.configuration();
    return this.dialog.open(UnsavedChangesDialogComponent, dialogConfig).afterClosed();
  }

  crudinatorDialog(crudConfig: CrudinatorConfig): Observable<boolean> {
    const dialogConfig = this.configuration();
    dialogConfig.data = {crudConfig};
    return this.dialog.open(CrudinatorDialogComponent, dialogConfig).afterClosed();
  }


  onDeleteDialog(key: string): Observable<boolean>{
    const dialogConfig = this.configuration();
    return this.dialog.open(OnDeleteDialogComponent, {...dialogConfig, data: key }).afterClosed();

  }

  openBookingDialog(booking: Booking): Observable<Booking>{
    const dialogConfig = this.configuration();
    dialogConfig.position = { right: '0', top: '0' }
    dialogConfig.width = '500px';
    dialogConfig.height = '100vh';
    return this.dialog.open(BookingDialogComponent, {...dialogConfig, data: booking }).afterClosed()
  }

  openFaqDialog(faqs: any[]): Observable<void> {
    const dialogConfig = this.configuration();
    dialogConfig.data = {faqs};
    return this.dialog.open(FaqDialogComponent, dialogConfig).afterClosed();
  }

  openForgotPasswordDialog(): Observable<void> {
    const dialogConfig = this.configuration();
    return this.dialog.open(ForgotPasswordDialogComponent, dialogConfig).afterClosed();
  }

  openSetPasswordDialog(user: User): Observable<string> {
    const dialogConfig = this.configuration();
    dialogConfig.minHeight = '250px'
    return this.dialog.open(SetPasswordDialogComponent, {...dialogConfig, data: user }).afterClosed();
  }

  openSelectAssetDialog(selectedAssets: Asset[]): Observable<Asset[]> {
    const dialogConfig = this.configuration();
    dialogConfig.width = '98%';
    dialogConfig.height = '98%';

    return this.dialog.open(SelectAssetsDialogComponent, {...dialogConfig, data: selectedAssets}).afterClosed();
  }

  openFacilitySelectDialog(group: FacilityGroup): Observable<FacilityGroup> {
    const dialogConfig = this.configuration();
    dialogConfig.width = '98%';
    dialogConfig.height = '98%';

    return this.dialog.open(FacilityGroupFacilitiesSelectDialogComponent, {...dialogConfig, data: {group}}).afterClosed();
  }

  openAssetDialog(asset: Asset): Observable<void> {
    const dialogConfig = this.configuration();
    return this.dialog.open(AssetDialogComponent, { ...dialogConfig, data: asset }).afterClosed();
  }

  openSelectFromListDialog(title: string, selectedUuid: UUID, list: ListItem[]): Observable<UUID> {
    const dialogConfig = this.configuration();
    dialogConfig.data = {title, selectedUuid, list: list};

    return this.dialog.open(SelectFromListDialogComponent, dialogConfig).afterClosed();
  }

  openAttachmentDialog(filePreviews: any[]): Observable<void> {
    const dialogConfig = this.configuration();
    dialogConfig.position = { right: '0', top: '0' }
    dialogConfig.width = '900px';
    dialogConfig.height = '100vh';
    return this.dialog.open(ViewAttachmentDialogComponent, { ...dialogConfig, data: filePreviews }).afterClosed();
  }


  private configuration(): MatDialogConfig {
    return {
      maxHeight: '100%',
      width: '540px',
      maxWidth: '100%',
      height: 'auto',
      disableClose: true,
      hasBackdrop: true,
    }
  }
}
