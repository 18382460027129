import {Component, EventEmitter, Input, Output} from "@angular/core";

@Component({
  selector: 'button-outlined',
  template: `
    <button type="button" mat-stroked-button [disabled]="isDisabled" (click)="onClick()">
      {{ text | translate }}
      <icon-spinner *ngIf="inProgress" class="ml-1"></icon-spinner>
    </button>
  `,
})
export class ButtonOutlinedComponent {
  @Input() inProgress = false;
  @Input() isDisabled = false;
  @Input() text;
  @Output() clickChange = new EventEmitter<void>();

  onClick() {
    this.clickChange.emit();
  }
}
