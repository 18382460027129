import {Injectable} from "@angular/core";
import {ApiService} from "../../core/api-service";
import {map, Observable} from "rxjs";
import {PriorityLevel} from "./priority-level";
import {UUID} from "../uuid";

@Injectable(
  {providedIn: 'root'}
)
export class PriorityLevelService {
  apiPath = '/v1/PriorityLevel';

  constructor(private apiService: ApiService) {
  }

  public all(): Observable<[]> {
    return this.apiService.get(this.apiPath).pipe(map((resp: any) => {
      return resp.data.map((t: PriorityLevel) => new PriorityLevel(t));
    }));
  }

  public by(priorityLevelId: UUID): Observable<PriorityLevel> {
    const url = `${this.apiPath}/${priorityLevelId}`;
    return this.apiService.get(url).pipe(map((resp: any) => new PriorityLevel(resp.data)));
  }

  createOrUpdate(priorityLevel: PriorityLevel | null, data: PriorityLevel) {
    if (priorityLevel && priorityLevel.id) {
      const url = `${this.apiPath}/${priorityLevel.id}`;
      return this.apiService.patch(url, data);
    }
    return this.apiService.post(this.apiPath, data);
  }

  remove(priorityLevel: PriorityLevel | null) {
    const url = `${this.apiPath}/${priorityLevel?.id}`
    return this.apiService.delete(url);
  }
}
