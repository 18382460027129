import {Paginator} from "../core/paginator";
import {Facility} from "./facility/facility";

export class UrlBuilder {
  private url: string;
  private params: URLSearchParams;

  static of(path: string): UrlBuilder {
    const helper = new UrlBuilder()
    helper.url = path;
    return helper;
  }

  toUrl(): string {
    if (this.hasQuery()) {
      return `${this.url}?${this.params.toString()}`;
    }
    return this.url;
  }

  private hasQuery(): boolean {
    return this.params != null && this.params.size > 0;
  }

  add(key: string, value: string): UrlBuilder {
    if (value == null || value.trim() === "") {
      return this;
    }
    if (this.params == null) {
      this.params = new URLSearchParams();
    }
    this.params.append(key, value);
    return this;
  }

  addSearch(value: string): UrlBuilder {
    this.add("FilterText", value);
    return this;
  }

  addFacility(facility: Facility): UrlBuilder {
    this.add("facility_id", facility.id);
    return this;
  }

  addPagination<T>(paginator: Paginator<T>): UrlBuilder {
    this.add("Page", paginator.pageNumber);
    this.add("PageSize", paginator.pageSize.toString());
    this.add("SortBy", paginator.sortName?.toString());
    this.add("SortOrder", paginator.sortOrder?.toString());
    return this;
  }
}
