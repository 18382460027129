<mat-dialog-content>

  <div>
    <mat-card-content *ngIf="assets">
      <div class="flex flex-col md:flex-row justify-between mb-4 md:items-center">
        <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
          <mat-label>{{'COMMON_SEARCH' | translate}}</mat-label>
          <button *ngIf="searchCtrl.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
            <mat-icon>close</mat-icon>
          </button>
          <mat-icon matSuffix *ngIf="!searchCtrl.value">search</mat-icon>
          <input matInput [formControl]="searchCtrl">
        </mat-form-field>
      </div>

      <table class="w-full hidden md:table table-fixed">
        <thead>
        <tr class="bg-gray-200">
          <th class="w-24 text-left px-2 py-4">Model name</th>
          <th class="w-24 text-left px-2 py-4">Model number</th>
          <th class="w-24 text-left px-2 py-4">Description</th>
          <th class="w-24 text-left px-2 py-4">Selected</th>

        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let asset of assets" class="hover:bg-gray-100 cursor-pointer border-t-2" tabindex="0"
            (click)="onSelect(asset)">
          <td class="text-left p-2">{{asset.modelName}}</td>
          <td class="text-left p-2">{{asset.modelNumber}}</td>
          <td class="text-left p-2">{{asset.description}}</td>
          <td class="text-left p-2">
            <div *ngIf="isSelected(asset.id)">
              <mat-icon>checked</mat-icon>
            </div>
          </td>
        </tr>
        </tbody>
      </table>

      <ul class="md:hidden divide-y list-none">
        <li *ngFor="let asset of assets" class="list-none">
          <div
            class="hover:bg-gray-100 cursor-pointer p-2"
            (click)="onSelectSmalScreen(asset)" (keydown.space)="triggerClick($event)"
            (keydown.enter)="triggerClick($event)" tabindex="0" role="button">
            <div class="flex justify-between">
              <div class="flex items-center">
                <p class="text-base font-bold me-2">{{asset.modelName}}</p>
                <p class="text-sm">({{asset.modelNumber}})</p>
              </div>
              <div *ngIf="isSelected(asset.id)">
                <mat-icon>checked</mat-icon>
              </div>
            </div>
            <div class="flex row justify-between">
              <div class="py-1">
                <p class="text-sm">{{asset.description}}</p>
              </div>
            </div>

          </div>
        </li>
      </ul>

      <mat-paginator class="mt-2" showFirstLastButtons
                     [pageSizeOptions]="paginator.pageSizeOptions"
                     [pageSize]="paginator.pageSize"
                     [pageIndex]="paginator.pageIndex"
                     [length]="paginator.totalCount"
                     (page)="onPageChange($event)">
      </mat-paginator>

    </mat-card-content>
  </div>

  <mat-dialog-actions>
    <button mat-button (click)="close()">{{ "COMMON_CLOSE" | translate}}</button>
  </mat-dialog-actions>
</mat-dialog-content>
