import {Component, OnInit, ViewChild} from "@angular/core";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {Router} from "@angular/router";
import {FormControl} from "@angular/forms";
import {combineLatest, debounceTime, startWith, Subject, Subscription, switchMap} from "rxjs";
import {EventUtil} from "../../../core/event-util";
import {Paginator} from "../../../core/paginator";
import {ProviderManagementService} from "../../domain/providers/provider-management-service";
import {TranslateService} from "@ngx-translate/core";
import {LanguageService} from "../../../core/language-service";
import {SortUtil} from "../../../core/sorting-service";
import {Provider} from "../../../domain/provider/provider";
import {AppRoutes} from "../../../app-routing.module";

@Component({
  selector: 'providers',
  templateUrl: './provider-management-admin.component.html',
})
export class ProviderManagementAdminComponent implements OnInit {
  triggerClick = EventUtil.triggerClick;

  providers: Provider[];
  searchCtrl = new FormControl();

  sub = new Subscription();
  paginator = new Paginator<Provider>();
  paginatorSub = new Subject<Paginator<Provider>>();
  paginator$ = this.paginatorSub.asObservable();

  @ViewChild(MatPaginator) set matPaginator(paginator: MatPaginator | null) {
    this.paginator.matPaginator = paginator;
    this.paginatorSub.next(this.paginator);
  }

  constructor(
    private router: Router,
    private providerManagementService: ProviderManagementService,
    private translateService: TranslateService,
    private languageService: LanguageService) {
    const sortUtil = SortUtil.of<Provider>("name", languageService.currLanguage())
    this.paginator.updateSort(sortUtil);
  }

  ngOnInit() {
    this.paginator.setLang(this.languageService.currLanguage());
    const search$ = this.searchCtrl.valueChanges.pipe(startWith(""), debounceTime(300));


    this.sub.add(combineLatest([search$, this.paginator$])
      .pipe(switchMap(([searchTerm, paginator]) => this.providerManagementService.all(searchTerm, paginator)))
      .subscribe(resp => {
        this.providers = resp.items;
        this.paginator.setTotal(resp.totalCount);
      }));
  }

  private filterBySearch(providers: Provider[]): Provider[] {
    if (!this.searchCtrl.value) {
      return this.providers!;
    }
    return providers.filter(provider => {
      return provider.name.toLowerCase().includes(this.searchCtrl.value.toLowerCase())
        || provider.address.country?.toLowerCase().includes(this.searchCtrl.value.toLowerCase())
        || provider.address.city?.toLowerCase().includes(this.searchCtrl.value.toLowerCase())
    });
  }

  create() {
    this.router.navigate([`${AppRoutes.ADMIN_MANAGEMENT}/providers/new`]).then(/* DO NOTHING */);
  }

  navigateToEdit(provider: Provider) {
    this.router.navigate([`${AppRoutes.ADMIN_MANAGEMENT}/providers`, provider.id]).then(/* DO NOTHING */);
  }

  clearSearch() {
    this.searchCtrl.setValue('');
  }


  toggleSort(name: keyof Provider) {
    this.paginator.sortBy(name)
    this.paginatorSub.next(this.paginator);
  }


  onPageChange(event: PageEvent) {
    this.paginator.onPageChange(event);
    this.paginatorSub.next(this.paginator);
  }
}
