<div class="login-background">
  <div class="login-container">
    <!-- Left Side: Signin Section -->
    <div class="signin-section">
      <div class="language-selector">
        <button mat-button [matMenuTriggerFor]="menu">
          {{ currentLanguageLabel() }}
          <mat-icon>language</mat-icon>
        </button>

        <mat-menu #menu="matMenu">
          <button *ngFor="let language of languages" mat-menu-item (click)="useLanguage(language)">
            {{ language.label }}
          </button>
        </mat-menu>
      </div>

      <mat-card *ngIf="form">
        <div class="flex justify-center p-4">
          <companion-round-logo-light />
        </div>
        <div class="flex justify-center p-4">
          <companion-text-logo-dark />
        </div>
        <br />
        <mat-tab-group mat-stretch-tabs class="login-tab-group">
          <mat-tab label="Sign in">
            <mat-card-content>
              <form [formGroup]="form" (ngSubmit)="login()">
                <mat-form-field appearance="outline" class="w-full">
                  <mat-label>{{"LOGIN_USERNAME" | translate}}</mat-label>
                  <input matInput formControlName="username" placeholder="{{'LOGIN_USERNAME' | translate}}">
                </mat-form-field>

                <mat-form-field appearance="outline" class="w-full">
                  <mat-label>{{"LOGIN_PASSWORD" | translate}}</mat-label>
                  <input matInput formControlName="password" [type]="hide ? 'password' : 'text'"
                    placeholder="{{'LOGIN_PASSWORD' | translate}}">
                  <button type="button" mat-icon-button matSuffix class="password-toggle" (click)="hide = !hide"
                    [attr.aria-label]="hide ? 'Show password' : 'Hide password'" [attr.aria-pressed]="hide">
                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </button>
                </mat-form-field>

                <div class="flex items-center justify-between">
                  <mat-checkbox color="primary" formControlName="rememberMe">{{"LOGIN_REMEMBER_ME" |
                    translate}}</mat-checkbox>
                  <a href="javascript:void(0)" (click)="openForgotPasswordDialog()">
                    {{"LOGIN_PASSWORD_FORGOT" | translate}}
                  </a>
                </div>
                <button mat-raised-button color="primary" class="w-full login-button" [disabled]="loginDisabled()">
                  {{ "LOGIN" | translate }}
                </button>

                <mat-error *ngIf="loginAttemptFailed">{{"LOGIN_FAILED" | translate}}</mat-error>
              </form>
            </mat-card-content>
          </mat-tab>
          <mat-tab label="QR Login">
            <mat-card-content>
              <div class="qr-login">
                <p>Scan the QR code with your mobile device to log in.</p>
                <div class="camera-icon">
                  <mat-icon>qr_code_scanner</mat-icon>
                </div>
              </div>
            </mat-card-content>
          </mat-tab>
        </mat-tab-group>
      </mat-card>
    </div>

    <!-- Right Side: Welcome Section with Image -->
    <div class="welcome-section" [ngStyle]="{'background-image': 'url(' + backgroundImageUrl + ')'}">
      <!-- <div class="flex justify-end p-4 companion-logo-top-right">
        <companion-text-logo-light />
      </div> -->
    </div>
  </div>

  <button mat-fab class="help-button" aria-label="Help" (click)="openFaqDialog()">
    <mat-icon>help_outline</mat-icon>
  </button>
</div>