import {ObjectMapperHelper} from "../../core/object-mapper-helper";
import {Metadata} from "../metadata";
import {UUID} from "../uuid";

export class User extends Metadata {
  readonly id: string;
  readonly userName: string;
  readonly customerId: UUID;
  readonly tenantId: UUID;
  readonly email: string;
  readonly emailConfirmed: boolean;
  readonly isActive: boolean;
  readonly firstName: string;
  readonly lastName: string;
  readonly socialSecurityNumber?: string;
  readonly phoneNumber: string;
  readonly twoFactorEnabled: boolean;
  readonly roles: string[];
  // readonly twofa_phone_number: string;
  // readonly last_login_date: Date;
  // readonly roles: UserRole;


  constructor(data: any) {
    super(data);
    const helper = ObjectMapperHelper.of(data);
    this.id = helper.requireString("id");
    this.userName = helper.requireString("userName");
    this.customerId = helper.getUUID("customerId");
    this.tenantId = helper.getUUID("tenantId");
    this.email = helper.requireString("email");
    this.isActive = helper.getBoolean("isActive");
    this.firstName = helper.requireString("firstName");
    this.lastName = helper.requireString("lastName");
    this.socialSecurityNumber = helper.getString("socialSecurityNumber");
    this.phoneNumber = helper.getString("phoneNumber");
    this.twoFactorEnabled = helper.getBoolean("twoFactorEnabled");
    this.roles = helper.getArray<string>("roles");
    // this.twofa_phone_number = helper.getString("twofa_phone_number");
    // this.last_login_date = helper.getDate("last_login_date");
  }
}
