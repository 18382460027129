import {Component, OnInit, ViewChild} from "@angular/core";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {Router} from "@angular/router";
import {FormControl} from "@angular/forms";
import {AppRoutes} from "../../app-routing.module";
import {EventUtil} from "../../core/event-util";
import {Paginator} from "../../core/paginator";
import {SortUtil} from "../../core/sorting-service";

import {LanguageService} from "../../core/language-service";
import {ProviderService} from "../../domain/provider/provider-service";
import {Provider} from "../../domain/provider/provider";
import {combineLatest, debounceTime, startWith, Subject, Subscription, switchMap} from "rxjs";

@Component({
  selector: 'providers',
  templateUrl: './provider.component.html',
})
export class ProviderComponent implements OnInit {
  triggerClick = EventUtil.triggerClick;

  providers: Provider[];
  searchCtrl = new FormControl();

  sub = new Subscription();
  paginator = new Paginator<Provider>();
  paginatorSub = new Subject<Paginator<Provider>>();
  paginator$ = this.paginatorSub.asObservable();

  @ViewChild(MatPaginator) set matPaginator(paginator: MatPaginator | null) {
    this.paginator.matPaginator = paginator;
    this.paginatorSub.next(this.paginator);
  }

  constructor(
    private router: Router,
    private providerService: ProviderService,
    private languageService: LanguageService) {
    const sortUtil = SortUtil.of<Provider>("name", languageService.currLanguage())
    this.paginator.updateSort(sortUtil);
  }

  ngOnInit() {
    this.paginator.setLang(this.languageService.currLanguage());
    const search$ = this.searchCtrl.valueChanges.pipe(startWith(""), debounceTime(300));


    this.sub.add(combineLatest([search$, this.paginator$])
      .pipe(switchMap(([searchTerm, paginator]) => this.providerService.all(searchTerm, paginator)))
      .subscribe(resp => {
        this.providers = resp.items;
        this.paginator.setTotal(resp.totalCount);
      }));
  }

  create() {
    this.router.navigate([`${AppRoutes.ADMIN_MANAGEMENT}/providers/new`]).then(/* DO NOTHING */);
  }

  navigateToEdit(provider: Provider) {
    this.router.navigate([`${AppRoutes.ADMIN_MANAGEMENT}/providers`, provider.id]).then(/* DO NOTHING */);
  }

  clearSearch() {
    this.searchCtrl.setValue('');
  }


  toggleSort(name: keyof Provider) {
    this.paginator.sortBy(name)
    this.paginatorSub.next(this.paginator);
  }


  onPageChange(event: PageEvent) {
    this.paginator.onPageChange(event);
    this.paginatorSub.next(this.paginator);
  }
}
