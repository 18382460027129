import {Component, OnDestroy, OnInit} from "@angular/core";
import {Customer} from "../../../domain/customer/customer";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {CustomerForm} from "../../../pages/customer/customer-form.component";
import {Country} from "../../../domain/language-country";
import {combineLatest, finalize, Observable, Subscription} from "rxjs";
import {Tenant} from "../../../domain/tenant/tenant";
import {EnvironmentService} from "../../../core/environment-service";
import {ActivatedRoute, Router} from "@angular/router";
import {SnackbarService} from "../../../shared/snackbar/snackbar-service";
import {AppRoutes} from "../../../app-routing.module";
import {Paginator} from "../../../core/paginator";
import {Paginated} from "../../../domain/resp-wrapper";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {CanDeactivateComponent} from "../../../core/can-deactivate-guard";
import {CustomerManagementService} from "../../domain/customer/customer-management-service";
import {TenantManagementService} from "../../domain/tenants/tenant-management-service";


@Component({
  selector: 'customer-edit-management-admin',
  templateUrl: './customer-edit-management-admin.component.html',
})
export class CustomerEditManagementAdminComponent implements OnInit, OnDestroy, CanDeactivateComponent {
  protected readonly faSpinner = faSpinner;
  customer: Customer;
  inProgress = true;
  isSaving = false;
  form: FormGroup<CustomerForm>;
  countriesList: Country[] = [];
  subscription = new Subscription();
  title = "";
  tenants: Tenant[] = [];
  tenantCtrl: FormControl<string> = new FormControl();

  constructor(private environmentService: EnvironmentService,
              private customerManagementService: CustomerManagementService,
              private router: Router,
              private route: ActivatedRoute,
              private tenantManagementService: TenantManagementService,
              private snackbarService: SnackbarService
  ) {
  }

  ngOnInit() {
    this.countriesList = this.environmentService.countries;
    const customerId = this.route.snapshot.paramMap.get('id');

    if (customerId === "new") {
      this.setTitle(true);
      this.fetchTenants().subscribe(res => {
        this.tenants = res.items;
        this.initFormCtrls();
        this.inProgress = false;
      });
      return;
    }

    this.setTitle(false);

    combineLatest([this.customerManagementService.by(customerId!), this.fetchTenants()])
      .subscribe(([customer, tenants]) => {
        this.customer = customer;
        this.tenants = tenants.items;
        this.initFormCtrls();
        this.inProgress = false;
      });
  }

  initFormCtrls() {
    this.tenantCtrl.setValue(this.customer?.tenantId);
    this.tenantCtrl.setValidators(Validators.required);
  }

  fetchTenants(): Observable<Paginated<Tenant>> {
    const paginator = new Paginator<Tenant>("name");
    paginator.setTotal(400);
    return this.tenantManagementService.all("", paginator);
  }

  setTitle(isNewCustomer: boolean): void {
    this.title = isNewCustomer ? "COMMON_NEW" : "COMMON_EDIT";
  }

  save(): void {
    if (this.form?.invalid) {
      return;
    }
    this.isSaving = true;
    const rawValue: Partial<Customer> = this.form!.getRawValue();
    const change = Object.assign({}, rawValue, {tenantId: this.tenantCtrl.value});
    this.customerManagementService.createOrUpdate(this.customer, change)
      .pipe(finalize(() => this.isSaving = false))
      .subscribe(() => {
        this.markFormsAsPristine()
        this.snackbarService.success(this.customer?.id ? "CUSTOMER_UPDATED" : "CUSTOMER_CREATED");
        this.router.navigate([`/${AppRoutes.ADMIN_MANAGEMENT}/customers`]).then(/* DO NOTHING */);
      });
  }

  canSave(): boolean {
    return this.form != null && this.form.valid && !this.inProgress && !this.isSaving
      && this.tenantCtrl.valid && this.hasDirtyFields();
  }

  hasDirtyFields(): boolean {
    return this.form?.dirty || this.tenantCtrl?.dirty;
  }

  markFormsAsPristine(){
    this.form?.markAsPristine();
    this.tenantCtrl.markAsPristine()
  }

  cancel() {
    this.router.navigate([`/${AppRoutes.ADMIN_MANAGEMENT}/customers`]).then(/* DO NOTHING */);
  }

  canDeactivateComponent(): boolean {
    return this.hasDirtyFields();
  }

  onCustomerFormChange(formGroup: FormGroup<CustomerForm>) {
    this.form = formGroup;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
