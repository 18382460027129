import {Injectable} from "@angular/core";
import {ApiService} from "../../../core/api-service";
import {map, Observable} from "rxjs";
import {Customer} from "../../../domain/customer/customer";
import {Paginated, RespPaginated, RespWrapper} from "../../../domain/resp-wrapper";
import {Paginator} from "../../../core/paginator";
import {UrlBuilder} from "../../../domain/url-builder";

@Injectable({
  providedIn: 'root'
})
export class CustomerManagementService {
  apiPath = '/v1/admin/customers';

  constructor(private apiService: ApiService) {
  }

  public all(searchTerm: string = null, paginator: Paginator<Customer>): Observable<Paginated<Customer>> {
    const url = UrlBuilder.of(this.apiPath).addPagination(paginator).addSearch(searchTerm).toUrl();

    return this.apiService.get<RespPaginated<Customer>>(url).pipe(map((resp) => {
      return Paginated.of<Customer>(resp, Customer);
    }));
  }

  public by(customerId: string): Observable<Customer> {
    const url = `${this.apiPath}/${customerId}`;
    return this.apiService.get<RespWrapper<Customer>>(url)
      .pipe(map((resp => new Customer(resp.data))));
  }

  createOrUpdate(customer: Customer | null, data: Partial<Customer>): Observable<Customer> {
    if (customer && customer.id) {
      const url = `${this.apiPath}/${customer.id}`;
      return this.apiService.patch<RespWrapper<Customer>>(url, data)
        .pipe(map(resp => resp.data));
    }
    return this.apiService.post<RespWrapper<Customer>>(this.apiPath, data)
      .pipe(map(resp => resp.data));
  }
}
