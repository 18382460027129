import {Component, EventEmitter, Input, Output} from "@angular/core";

@Component({
  selector: 'button-cancel',
  template: `
    <button-outlined [isDisabled]="isDisabled" (clickChange)="onClick()" text="COMMON_CANCEL"/>
  `,
})
export class ButtonCancelComponent {
  @Input() isDisabled = false;
  @Output() cancelChange = new EventEmitter<void>();

  onClick() {
    this.cancelChange.emit();
  }
}
