<div class="max-w-screen-2xl xl:px-16 md:p-4 py-2">
  <mat-card class="lg:p-6">
    <mat-card-header class="flex items-center">
      <h1 style="margin-bottom: 4px">{{'BOOKINGS' | translate}}</h1>
    </mat-card-header>
    <div class="px-4">
      <hr class="mb-8">
    </div>
    <mat-spinner *ngIf="!bookings"></mat-spinner>

    <mat-card-content *ngIf="bookings">
      <div class="flex flex-col md:flex-row justify-between mb-4 md:items-center">
        <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
          <mat-label>{{'COMMON_SEARCH' | translate}}</mat-label>
          <button *ngIf="searchCtrl.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
            <mat-icon>close</mat-icon>
          </button>
          <mat-icon matSuffix *ngIf="!searchCtrl.value">search</mat-icon>
          <input matInput [formControl]="searchCtrl">
        </mat-form-field>
        <div class="w-full flex justify-end">
          <button mat-flat-button color="primary" (click)="create()">{{"COMMON_CREATE" | translate}}</button>
        </div>
      </div>

      <div>
        <table class="w-full hidden md:table table-auto">
          <thead>
          <tr class="bg-gray-200">
            <th class="text-left px-2 py-4 flex flex-row cursor-pointer" (click)="toggleSort('description')">
              <header-sort-icon name="title" [sortUtil]="paginator"></header-sort-icon>
              <span class="ml-1">{{"COMMON_TITLE" | translate}}</span>
            </th>
            <th class="text-left px-2 py-4">
              <span class="ml-1">{{"COMMON_DESCRIPTION" | translate}}</span>
            </th>
            <th class="w-24 text-left px-2 py-4">{{"COMMON_STATUS" | translate}}</th>

            <th class="w-36 text-left px-2 py-4">{{"BOOKING_EXPECTED_COMPLETED_DATE" | translate}}</th>
          </tr>
          </thead>
          <tbody>
            <tr *ngFor="let booking of bookings" (click)="navigateToEdit(booking)" enterSpacePress
                class="hover:bg-gray-100 cursor-pointer border-t-2" tabindex="0">
              <td class="text-left p-2">{{booking.title}}</td>
              <td class="text-left p-2">{{booking.description}}</td>
              <td class="w-24 text-left p-2">{{ ("BOOKING_STATUS_" + booking.status) | translate }}</td>
              <td class="w-40 text-left p-2">{{booking.expectedCompletionDate | date:"yyyy-MM-dd HH:mm"}}</td>
            </tr>
          </tbody>
        </table>

        <ul class="md:hidden divide-y list-none">
          <li *ngFor="let booking of bookings" class="hover:bg-gray-100 cursor-pointer p-2 list-none">
            <div (click)="navigateToEdit(booking)" (keydown.space)="triggerClick($event)"
                 (keydown.enter)="triggerClick($event)" tabindex="0" role="button">
              <div class="flex items-center justify-between space-x-2">
                <div class="flex flex-row">
                  <div class="font-bold">{{ booking.description }}</div>
                </div>
                <div class="inline-block px-2.5 py-1 rounded-xl">
                  <span class="text-xs">
                    {{ "INCIDENT_DEADLINE_END_AT" | translate }}
                  </span>

                </div>
              </div>
              <div class="flex items-center justify-between space-x-2">
                <div class="text-sm">{{ booking.description }}</div>
                <div class="inline-block px-2.5 py-1 text-right">
                  <span class="text-xs text-right"> {{ booking.expectedCompletionDate | date:"yyyy-MM-dd HH:mm" }}</span>
                </div>
              </div>
            </div>
          </li>
        </ul>

        <mat-paginator class="mt-2" showFirstLastButtons
                       [pageSizeOptions]="paginator.pageSizeOptions"
                       [pageSize]="paginator.pageSize"
                       [pageIndex]="paginator.pageIndex"
                       [length]="paginator.totalCount"
                       (page)="onPageChange($event)">
        </mat-paginator>
      </div>

    </mat-card-content>
  </mat-card>
</div>
