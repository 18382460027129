import {Component, EventEmitter, Input, Output} from "@angular/core";
import {CrudinatorField} from "../../domain/crudinator/crudinator-field";
import {CrudinatorConfig} from "../../domain/crudinator/crudinator-config";
import {finalize, Subject, Subscription, switchMap} from "rxjs";
import {CrudinatorApiService, CrudinatorMakeService} from "../../domain/crudinator/crudinator-make-service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'crudinator-master',
  templateUrl: './crudinator-master.component.html',
})
export class CrudinatorMasterComponent {
  crudList: any[] | null = null;
  fields: CrudinatorField[] | null = null;
  crudConfig: CrudinatorConfig | null = null;
  configSubject = new Subject<CrudinatorConfig>();
  subscription = new Subscription();
  crudinatorService: CrudinatorApiService | null = null;
  selectedRow: any;
  isEditing = false;
  inProgress = false;
  isChanged = false;

  @Input() set config(config: CrudinatorConfig) {
    this.configSubject.next(config);
  }

  @Output() backChange = new EventEmitter<boolean>();
  @Output() inProgressChange = new EventEmitter<boolean>();

  constructor(private crudinatorMakeService: CrudinatorMakeService, private translateService: TranslateService) {
    this.init();
  }

  init(): void {
    this.subscription.add(this.configSubject.pipe(
      switchMap(config => {
        this.crudConfig = config;
        this.crudinatorService = this.crudinatorMakeService.make(config.apiPath);
        return this.crudinatorService.list();
      })).subscribe(data => {
        this.crudList = data.list;
        this.fields = data.fields;
      }
    ));
  }

  onBack() {
    this.backChange.emit(this.isChanged);
  }

  onSelect(obj: any) {
    this.selectedRow = obj;
    this.isEditing = true;
  }

  onEditCancel() {
    this.selectedRow = null;
    this.isEditing = false;
  }


  onEditSave(obj: any) {
    this.setInProgress(true);
    const id = this.idString(obj);
    const change = Object.assign({}, obj);
    delete change[this.crudConfig!.idField];
    this.crudinatorService?.save(change, id)
      .pipe(finalize(() => this.setInProgress(false)))
      .subscribe(data => {
        this.crudList = data.list;
        this.fields = data.fields;
        this.selectedRow = null;
        this.isEditing = false;
        this.isChanged = true;
      });
  }

  idString(obj: any): string | null {
    return obj[this.crudConfig!.idField] ? obj[this.crudConfig!.idField] : null;
  }

  onEditRemove(obj: any) {
    this.setInProgress(true);
    this.crudinatorService?.remove(this.idString(obj))
      .pipe(finalize(() => this.setInProgress(false)))
      .subscribe(data => {
        this.crudList = data.list;
        this.fields = data.fields;
        this.selectedRow = null;
        this.isEditing = false;
        this.isChanged = true;
      });
  }

  isCreating() {
    return this.isEditing && !this.selectedRow[this.crudConfig!.idField];
  }

  getEditTitle() {
    if (!this.selectedRow) {
      return "";
    }
    const suffix = this.translateService.instant(this.crudConfig!.title).toLowerCase();
    if (this.isCreating()) {
      const prefix = this.translateService.instant("COMMON_NEW");
      return `${prefix} ${suffix}`;
    }
    const prefix = this.translateService.instant("COMMON_EDIT");
    return `${prefix} ${suffix}`;
  }

  setInProgress(inProgress: boolean) {
    this.inProgress = inProgress;
    this.inProgressChange.emit(inProgress);
  }
}
