import {ObjectMapperHelper} from "../../core/object-mapper-helper";
import {UUID} from "../uuid";
import {Metadata} from "../metadata";

export class PriorityLevel extends Metadata {
  readonly id: UUID;
  readonly name: string;
  readonly description: string;

  constructor(data: any) {
    super(data);
    const helper = ObjectMapperHelper.of(data);

    this.id = helper.getUUID('id');
    this.name = helper.requireString('name');
    this.description = helper.getString('description');
  }
}
