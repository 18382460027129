import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { FacilityService } from "../../domain/facility/facility-service";
import { EventUtil } from "../../core/event-util";
import { Paginator } from "../../core/paginator";
import { Facility } from "../../domain/facility/facility";
import { LanguageService } from "../../core/language-service";
import { combineLatest, debounceTime, startWith, Subject, Subscription, switchMap } from "rxjs";
import { AppRoutes } from "../../app-routing.module";
import { Customer } from "../../domain/customer/customer";

@Component({
  templateUrl: 'facilities-admin.component.html',
  styleUrls: ["facilities-admin.component.scss"]
})
export class FacilitiesAdminComponent implements OnInit, OnDestroy {
  triggerClick = EventUtil.triggerClick;
  searchCtrl = new FormControl();
  facilities: Facility[] | null = null;
  filteredFacilities: Facility[] = [];
  customer: Customer;
  sub = new Subscription();
  paginator = new Paginator<Facility>("name");
  paginatorSub = new Subject<Paginator<Facility>>();
  paginator$ = this.paginatorSub.asObservable();
  sortColumn: keyof Facility | null = null;
  sortDirection: 'asc' | 'desc' = 'asc';

  @ViewChild(MatPaginator) set matPaginator(paginator: MatPaginator | null) {
    this.paginator.matPaginator = paginator;
    this.paginatorSub.next(this.paginator);
  }

  constructor(
    private languageService: LanguageService,
    private router: Router,
    private facilityService: FacilityService
  ) {}

  ngOnInit() {
    this.paginator.setLang(this.languageService.currLanguage());

    const search$ = this.searchCtrl.valueChanges.pipe(startWith(""), debounceTime(300));
    this.sub.add(combineLatest([search$, this.paginator$])
      .pipe(switchMap(([term, paginator]) => this.facilityService.all(term, paginator)))
      .subscribe(response => {
        this.facilities = response.items;
        this.filteredFacilities = response.items;
        this.paginator.setTotal(response.totalCount);
      }));

    this.filterFacilities();
  }

  //filterFacilities method
  filterFacilities() {
    let sortedFacilities = this.facilities ? [...this.facilities] : [];
  
    // Sort facilities based on sortColumn and sortDirection
    if (this.sortColumn && this.sortDirection) {
      sortedFacilities.sort((a, b) => {
        const valueA = a[this.sortColumn] as string;
        const valueB = b[this.sortColumn] as string;
  
        if (valueA < valueB) {
          return this.sortDirection === 'asc' ? -1 : 1;
        }
        if (valueA > valueB) {
          return this.sortDirection === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
  
    // Apply filtering after sorting
    const searchTerm = this.searchCtrl.value ? this.searchCtrl.value.toLowerCase() : '';
    this.filteredFacilities = sortedFacilities.filter(facility =>
      facility.name.toLowerCase().includes(searchTerm) ||
      facility.description?.toLowerCase().includes(searchTerm) ||
      facility.address?.street.toLowerCase().includes(searchTerm) ||
      facility.address?.city.toLowerCase().includes(searchTerm) ||
      facility.address?.country.toLowerCase().includes(searchTerm)
    );
  }

  create() {
    this.router.navigate([`${AppRoutes.ADMIN_CUSTOMER}/facilities/new`]).then(/* DO NOTHING */);
  }

  navigateToEdit(facility: Facility) {
    this.router.navigate([`${AppRoutes.ADMIN_CUSTOMER}/facilities`, facility.id]).then(/* DO NOTHING */);
  }

  clearSearch() {
    this.searchCtrl.setValue('');
    this.filterFacilities();
  }

  toggleSort(column: keyof Facility) {
    if (this.sortColumn === column) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortColumn = column;
      this.sortDirection = 'asc';
    }
    this.sortAndFilterFacilities();
  }


  getSortIcon(column: keyof Facility): string {
    if (this.sortColumn !== column) {
      return 'sort';
    }
    return this.sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward';
  }

  sortAndFilterFacilities() {
    // Implement sorting logic here, possibly adjusting the filterFacilities method or adding a new sorting logic that applies on top of filtering.
    // This logic will depend on whether you're fetching sorted data from a backend or sorting client-side.
    this.filterFacilities(); // If sorting client-side, make sure to sort within this method.
  }

  onPageChange(event: PageEvent) {
    this.paginator.onPageChange(event);
    this.paginatorSub.next(this.paginator);
    this.filterFacilities();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  visit(facility: Facility) {
    this.facilityService.setFacility(facility);
    this.router.navigate(['/home']).then(/* DO NOTHING */);
  }
}
