import {Metadata} from "../metadata";
import {ObjectMapperHelper} from "../../core/object-mapper-helper";
import {UUID} from "../uuid";
import {Asset} from "../asset/asset";
import {IncidentDocument} from "./incident_document";
import {Booking} from "../booking/booking";

export enum IncidentStatus {
  Open = 'Open',
  New = 'New',
  Accepted = 'Accepted',
  Closed = 'Closed',
  Rejected = 'Rejected',
  OverdueDl = 'OverdueDl',
}

export enum IncidentType {
  Breakdown = 'Breakdown',
  Maintenance = 'Maintenance',
  Installation = 'Installation',
}

enum IncidentMethodOfCommunication {
  Email = 'Email',
  Phone = 'Phone'
}

enum InvoiceStatus {
  Draft = 'Draft',
  Sent = 'Sent',
  Pending = 'Pending',
  Paid = 'Paid',
  Overdue = 'Overdue',
  Partial_paid = 'Partial_paid',
  Void = 'Void',
  Refunded = 'Refunded'
}

enum IncidentApprovedStatus {
  Ok = 'Ok',
  Fail = 'Fail',
}


export class Incident extends Metadata {
  readonly id: UUID
  readonly title: string
  readonly description: string
  readonly status: IncidentStatus
  readonly type: IncidentType
  readonly facilityId: UUID
  readonly customerId: UUID
  readonly bookingId: UUID
  readonly serviceCatGroupId: UUID
  readonly serviceCategoryId: UUID
  readonly providerId: UUID
  readonly contactName: string
  readonly contactPhone: string
  readonly contactEmail: string
  readonly deadlineStartAt: Date
  readonly deadlineEndAt: Date
  readonly priorityLevelId: UUID
  readonly slaDurationId: UUID
  readonly assetIds: UUID[]
  readonly assets: Asset[]
  readonly documents: IncidentDocument[]
  readonly booking: Booking


  constructor(data: any) {
    super(data)
    const helper = ObjectMapperHelper.of(data);
      this.id = helper.getUUID("id")
      this.title = helper.getString("title")
      this.description = helper.getString("description")
      this.status = helper.getEnum("status", IncidentStatus)
      this.type = helper.getEnum("type", IncidentType)
      this.facilityId = helper.getUUID("facilityId")
      this.customerId = helper.getUUID("customerId")
      this.bookingId = helper.getUUID("bookingId")
      this.serviceCatGroupId = helper.getUUID("serviceCatGroupId")
      this.serviceCategoryId = helper.getUUID("serviceCategoryId")
      this.providerId = helper.getUUID("providerId")
      this.contactName = helper.getString("contactName")
      this.contactPhone = helper.getString("contactPhone")
      this.contactEmail = helper.getString("contactEmail")
      this.deadlineStartAt = helper.getDate("deadlineStartAt")
      this.deadlineEndAt = helper.getDate("deadlineEndAt")
      this.priorityLevelId = helper.getUUID("priorityLevelId")
      this.slaDurationId = helper.getUUID("slaDurationId")
      this.assetIds = helper.getArray("assetIds")
      this.assets = helper.getArray("assets")?.map(asset => new Asset(asset)) ?? []
      this.documents = helper.getArray("documents")?.map(document => new IncidentDocument(document)) ?? []
      this.booking = data.booking ? new Booking(data.booking) : null;
  }
}


export class CreateIncident extends Incident {
  deadlineEndMinutes: number
}

export class CreateUpdateIncident extends Incident {
  deadlineEndMinutes: number
}
