import {Component, Input} from "@angular/core";

@Component({
  selector: 'heading-h2',
  template: `<h2 [class.text-xl]="large">{{ text | translate }}</h2>`,
})
export class HeadingH2Component {
  @Input() text: string;
  @Input() large = false;
}
