import {Component, OnInit, ViewChild} from "@angular/core";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {Router} from "@angular/router";
import {FormControl} from "@angular/forms";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {combineLatest, debounceTime, startWith, Subject, Subscription, switchMap} from "rxjs";
import {EventUtil} from "../../../core/event-util";
import {Tenant} from "../../../domain/tenant/tenant";
import {Paginator} from "../../../core/paginator";
import {LanguageService} from "../../../core/language-service";
import {UserService} from "../../../domain/user/user-service";
import {AppRoutes} from "../../../app-routing.module";
import {TenantManagementService} from "../../domain/tenants/tenant-management-service";


@Component({
  templateUrl: './tenants-management.component.html',
})
export class TenantsManagementComponent implements OnInit {
  triggerClick = EventUtil.triggerClick;
  protected readonly faSpinner = faSpinner;
  searchCtrl = new FormControl();
  tenants: Tenant[] | null = null;
  sub = new Subscription();
  paginator = new Paginator<Tenant>("name");
  paginatorSub = new Subject<Paginator<Tenant>>();
  paginator$ = this.paginatorSub.asObservable();

  @ViewChild(MatPaginator) set matPaginator(paginator: MatPaginator | null) {
    this.paginator.matPaginator = paginator;
    this.paginatorSub.next(this.paginator);
  }

  constructor(
    private languageService: LanguageService,
    private userService: UserService,
    private router: Router,
    private tenantManagementService: TenantManagementService) {
  }

  ngOnInit() {
    this.paginator.setLang(this.languageService.currLanguage());

    const search$ = this.searchCtrl.valueChanges.pipe(startWith(""), debounceTime(300));
    this.sub.add(combineLatest([search$, this.paginator$])
      .pipe(switchMap(([term, paginator]) => this.tenantManagementService.all(term, paginator)))
      .subscribe(response => {

        this.tenants = response.items;
        this.paginator.setTotal(response.totalCount);
      }));
  }

  create() {
    this.router.navigate([`${AppRoutes.ADMIN_MANAGEMENT}/tenants/new`]).then(/* DO NOTHING */);
  }

  navigateToEdit(tenant: Tenant) {
    this.router.navigate([`${AppRoutes.ADMIN_MANAGEMENT}/tenants`, tenant.id]).then(/* DO NOTHING */);
  }

  clearSearch() {
    this.searchCtrl.setValue('');
  }

  toggleSort(name: keyof Tenant) {
    this.paginator.sortBy(name);
    this.paginatorSub.next(this.paginator);
  }

  onPageChange(event: PageEvent) {
    this.paginator.onPageChange(event);
    this.paginatorSub.next(this.paginator);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

}
