import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import {FacilityGroup} from "../../domain/facility/facility-group";
import {FacilityGroupService} from "../../domain/facility/facility.group-service";
import {CustomerService} from "../../domain/customer/customer-service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {combineLatest, debounceTime, finalize, startWith, Subject, Subscription, switchMap} from "rxjs";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {Facility} from "../../domain/facility/facility";
import {Paginator} from "../../core/paginator";
import {LanguageService} from "../../core/language-service";
import {FacilityService} from "../../domain/facility/facility-service";
import {DialogService} from "../../shared/dialogs/dialog-service";

export interface FacilityGroupForm {
  name: FormControl<string>
  description: FormControl<string | null>
  parentId: FormControl<string | null>
  order: FormControl<number>
}

@Component({
  selector: 'facility-group-edit',
  templateUrl: 'facility-group-edit.component.html'
})
export class FacilityGroupEditComponent implements OnInit, AfterViewInit, OnDestroy {
  protected readonly faSpinner = faSpinner;
  inProgress = false;
  form: FormGroup<FacilityGroupForm>;
  _facilityGroup: FacilityGroup;
  facilities: Facility[] | null = null;
  searchCtrl = new FormControl();
  paginator = new Paginator<Facility>("name");
  paginatorSub = new Subject<Paginator<Facility>>();
  paginator$ = this.paginatorSub.asObservable();
  sub = new Subscription();

  @ViewChild(MatPaginator) matPaginator: MatPaginator;

  get facilityGroup() {
    return this._facilityGroup;
  }

  @Input() isEditing = false;

  @Input() set facilityGroup(group: FacilityGroup) {
    if (group == null) {
      this._facilityGroup = null;
      this.form = null;
      return;
    }
    this.form = this.fb.group({
      name: [group.name, Validators.required],
      description: [group.description, Validators.required],
      parentId: [group.parentId],
      order: [group.order],
    });
    this._facilityGroup = group;
  }

  @Output() closeChange = new EventEmitter<void>();
  @Output() anyUpdateChange = new EventEmitter<void>();
  @Output() inProgressChange = new EventEmitter<boolean>();

  constructor(
    private cd: ChangeDetectorRef,
    private customerService: CustomerService,
    private dialogService: DialogService,
    private facilityService: FacilityService,
    private facilityGroupService: FacilityGroupService,
    private fb: FormBuilder,
    private languageService: LanguageService) {
  }

  ngAfterViewInit(): void {
    this.paginator.matPaginator = this.matPaginator;
    this.paginatorSub.next(this.paginator);
  }

  ngOnInit() {
    this.paginator.setLang(this.languageService.currLanguage());

    const search$ = this.searchCtrl.valueChanges.pipe(startWith(""), debounceTime(300));
    this.sub.add(combineLatest([search$, this.paginator$])
      .pipe(switchMap(([term, paginator]) => {
        this.facilities = null;
        this.cd.markForCheck();
        return this.facilityService.all(term, paginator, this.facilityGroup);
      }))
      .subscribe(response => {
        this.facilities = response.items;
        this.paginator.setTotal(response.totalCount);
        this.cd.markForCheck();
      }));
  }

  cancel() {
    this.closeChange.emit();
  }

  canSave() {
    return this.form.valid && !this.form.pristine;
  }

  save() {
    this.setInProgress(true);
    const group: Partial<FacilityGroup> = this.form.getRawValue();
    group.customerId = this.customerService.currentCustomer.id;
    this.facilityGroupService.createOrUpdate(this._facilityGroup.id, group)
      .pipe(finalize(() => this.setInProgress(false)))
      .subscribe((group) => {
        this.facilityGroup = group;
        this.anyUpdateChange.emit();
        this.closeChange.emit();
      });
  }

  showEdit() {
    this.isEditing = true;
  }

  setInProgress(inProgress: boolean) {
    this.inProgress = inProgress;
    this.inProgressChange.emit(this.inProgress)
  }

  clearSearch(): void {
    this.searchCtrl.setValue('');
  }

  onPageChange(event: PageEvent) {
    this.paginator.onPageChange(event);
    this.paginatorSub.next(this.paginator);
  }

  private triggerRefetchFacilities(): void {
    const value = this.searchCtrl.getRawValue();
    this.searchCtrl.setValue(value);
  }

  openFacilityDialog(): void {
    this.dialogService.openFacilitySelectDialog(this.facilityGroup).subscribe(updatedGroup => {
      if (updatedGroup) {
        this.facilityGroup = updatedGroup;
        this.facilities = null;
        this.triggerRefetchFacilities();
        this.anyUpdateChange.emit();
      }
    })
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
