<div class="max-w-screen-xl lg:px-16 md:p-4 py-2 px-4">
  <mat-card-header class="flex items-center">
    <h1 style="margin-bottom: 4px">{{ 'FACILITIES' | translate }}</h1>
  </mat-card-header>
  <hr class="h-px mb-8">
  <mat-spinner *ngIf="!facilities"></mat-spinner>
  <div *ngIf="facilities">

    <div class="mb-4 max-w-screen-md">
      <mat-card class="pt-5">
        <mat-card-content>
          <mat-form-field class="w-full" appearance="outline">
            <mat-label>{{ 'COMMON_SEARCH' | translate }}</mat-label>
            <button *ngIf="searchCtrl.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
              <mat-icon>close</mat-icon>
            </button>
            <mat-icon matSuffix *ngIf="!searchCtrl.value">search</mat-icon>
            <input matInput [formControl]="searchCtrl" (keyup)="filterFacilities()">
          </mat-form-field>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="grid grid-cols-1 gap-4 mb-4 max-w-screen-md">
      <ng-container *ngFor="let facility of filteredFacilities; trackBy: trackFacilityById;">
        <mat-card class="hover:shadow-lg transition-shadow duration-300">
          <mat-card-content>
            <div class="flex flex-row justify-between items-center">
              <div>
                <div class="flex items-center mb-2">
                  <span class="font-bold text-lg mr-2">{{ facility.name }}<div
                      [ngClass]="facility.isActive ? 'text-green-600' : 'text-gray-500'"
                      class="inline-block px-2.5 py-1 rounded-xl">
                      {{ (facility.isActive ? 'COMMON_ACTIVE' : 'COMMON_INACTIVE') | translate }}
                    </div></span>
                  <mat-chip *ngIf="isSelectedFacility(facility)" selected color="primary">
                    {{ 'COMMON_CURRENT' | translate }}
                  </mat-chip>
                </div>
                <p class="text-base">{{ facility.description }}</p>
                <p class="text-sm font-light">{{ facility.address.street }}, {{ facility.address.postalCode }} {{
                  facility.address.city }}, {{ facility.address.country }}</p>
              </div>
              <button mat-button color="primary" [disabled]="isSelectedFacility(facility)"
                (click)="navigateTo(facility)">
                {{ 'COMMON_SWITCH' | translate }}
                <mat-icon>arrow_forward</mat-icon>
              </button>
            </div>
          </mat-card-content>
        </mat-card>
      </ng-container>
      <div *ngIf="!filteredFacilities.length" class="text-center py-5">
        <mat-icon color="warn" class="mb-2">error_outline</mat-icon>
        <p>{{ "COMMON_NO_ENTRIES" | translate }}</p>
      </div>
    </div>
  </div>
</div>