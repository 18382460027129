import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {combineLatest, debounceTime, startWith, Subject, Subscription, switchMap} from "rxjs";
import {Router} from "@angular/router";
import {FormControl} from "@angular/forms";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {Paginator} from "../../core/paginator";
import {EventUtil} from "../../core/event-util";
import {LanguageService} from "../../core/language-service";
import {Booking} from "../../domain/booking/booking";
import {BookingService} from "../../domain/booking/booking-service";
import {FacilityStorageService} from "../../core/storage/facility-storage-service";

@Component({
  selector: 'bookings',
  templateUrl: './bookings.component.html',
})
export class BookingsComponent implements OnInit, OnDestroy {
  triggerClick = EventUtil.triggerClick;
  protected readonly faSpinner = faSpinner;
  searchCtrl = new FormControl();
  bookings: Booking[] | null = null;
  sub = new Subscription();
  paginator = new Paginator<Booking>();
  paginatorSub = new Subject<Paginator<Booking>>();
  paginator$ = this.paginatorSub.asObservable();

  @ViewChild(MatPaginator) set matPaginator(paginator: MatPaginator | null) {
    this.paginator.matPaginator = paginator;
    this.paginatorSub.next(this.paginator);
  }

  constructor(
    private router: Router,
    private bookingService: BookingService,
    private languageService: LanguageService,
    private facilityStorageService: FacilityStorageService
  ) {
  }

  ngOnInit() {
    this.paginator.setLang(this.languageService.currLanguage());
    const search$ = this.searchCtrl.valueChanges.pipe(startWith(""), debounceTime(300));

    this.sub.add(combineLatest([search$, this.paginator$])
      .pipe(switchMap(([term, paginator]) => this.bookingService.all(term, this.facilityStorageService?.currentFacility?.id, paginator)))
      .subscribe(paginatedBookings => {
        this.bookings = paginatedBookings.items;
        this.paginator.setTotal(paginatedBookings.totalCount);
      }));

  }

  create() {
    this.router.navigate([`events/bookings/new`]).then(/* DO NOTHING */);
  }


  clearSearch() {
    this.searchCtrl.setValue('');
  }

  toggleSort(name: keyof Booking) {
    this.paginator.sortBy(name);
    this.paginatorSub.next(this.paginator);
  }

  navigateToEdit(booking: Booking) {
    this.router.navigate([`events/bookings`, booking.id]).then(/* DO NOTHING */);
  }


onPageChange(event: PageEvent) {
  this.paginator.onPageChange(event);
  this.paginatorSub.next(this.paginator);
}

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
