import {ObjectMapperHelper} from "../../core/object-mapper-helper";

export class AssetType {
  id: string | null;
  name: string;
  description: string;

  constructor(data: any) {
    const mapper = ObjectMapperHelper.of(data);
    this.id = mapper.requireString('id');
    this.name = mapper.requireString('name');
    this.description = mapper.requireString('description');
  }
}
