import {CompanionTokenBase} from "./companion-token-base";
import {UserRole} from "../domain/user/user-role";
import {ApiError} from "../core/error/api-error";

export class CompanionToken {
  readonly token: CompanionTokenBase | null;

  constructor(token: string) {
    try {
      const payload = token?.split('.')[1];
      const base64 = payload.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      this.token = JSON.parse(jsonPayload);
    } catch (error) {
      this.token = null;
      throw new Error('Token decoding failed ' + error);
    }
  }

  public static of(token: string): CompanionToken {
    return new CompanionToken(token);
  }

  get userId(): string | null {
    return this.token?.sub || null;
  }

  get customerId(): string | null {
    return null;
    // return this.token?.sub?.customerId || null;
  }

  get providerId(): string | null {
    return null;
    // return this.token?.sub?.providerId || null;
  }

  get tenantId(): string | null {
    return null;
    // return this.token?.sub?.tenantId || null;
  }

  get roles(): UserRole[] | null {
    const roles = this.token["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"] ?? "";
    const rolesArray = roles.split(',');
    return roles ? rolesArray.map((role: string) => UserRole[role as keyof typeof UserRole]) : null;
  }

  get expiresAt(): number {
    return this.token?.exp || 0;
  }

  isExpired(): boolean {
    const threeMinutes = 180;
    const currentTime =  new Date().getTime() / 1000;
    return this.token.exp < currentTime + threeMinutes
  }
}
