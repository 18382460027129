import {Component, OnDestroy, OnInit} from '@angular/core';
import {LanguageService} from "./core/language-service";
import {filter, Subscription} from "rxjs";
import {NavigationEnd, Router} from "@angular/router";
import {UserStorageService} from "./core/storage/user-storage-service";
import {AppRoutes} from "./app-routing.module";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  isAdminView = false;
  isLoggedIn: boolean = false;

  constructor(
    private languageService: LanguageService,
    private userStorageService: UserStorageService,
    private router: Router) {
  }

  ngOnInit(): void {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.isAdminView = event.url.includes(AppRoutes.ADMIN_CUSTOMER);
    });

    this.languageService.init();
    this.subscription.add(this.userStorageService.currentUser$.subscribe(user => {
      this.isLoggedIn = !!user;
      if(this.isLoggedIn && !user){
        this.router.navigate(['/login']).then(/*do nothing*/);
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
