<h2 mat-dialog-title>{{ "PASSWORD_NEW" | translate }} -  {{ data.email }}</h2>
<mat-dialog-content>
  <div class="mb-6">
    <span>{{ "PASSWORD_SET_DESCRIPTION" | translate }}</span>
    <span class="ps-1">{{ data.email }}</span>
  </div>
  <password-input [control]="form.controls.password" [label]="'PASSWORD_LABEL' | translate"></password-input>
</mat-dialog-content>
<mat-dialog-actions>
  <button type="button" mat-flat-button (click)="onCancel()">
    {{ "COMMON_CANCEL" | translate }}
  </button>
  <button type="button" mat-flat-button color="primary" [disabled]="!canSave()" (click)="onOk()">
    {{ "PASSWORD_SAVE_BUTTON" |translate }}
  </button>
</mat-dialog-actions>
