import {NgModule} from "@angular/core";
import {ButtonCancelComponent} from "./atoms/butttons/button-cancel.component";
import {TranslateModule} from "@ngx-translate/core";
import {MatButtonModule} from "@angular/material/button";
import {ButtonFlatComponent} from "./atoms/butttons/button-flat.component";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {HeadingH1Component} from "./atoms/heading/heading-h1.component";
import {ButtonOutlinedComponent} from "./atoms/butttons/button-outlined.component";
import {ButtonIconComponent} from "./atoms/butttons/button-icon.component";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {ButtonTextComponent} from "./atoms/butttons/button-text.component";
import {NgClass, NgIf} from "@angular/common";
import {IconSpinnerComponent} from "./atoms/icons/icon-spinner.component";
import {ButtonSaveComponent} from "./atoms/butttons/button-save.component";
import {HeadingH2Component} from "./atoms/heading/heading-h2.component";

const declarations = [
  HeadingH1Component,
  HeadingH2Component,
  ButtonIconComponent,
  ButtonOutlinedComponent,
  ButtonFlatComponent,
  ButtonTextComponent,
  ButtonCancelComponent,
  ButtonSaveComponent,
  IconSpinnerComponent,
];

@NgModule(
  {
      imports: [
          TranslateModule,
          MatButtonModule,
          FaIconComponent,
          MatIconModule,
          MatInputModule,
          NgIf,
          NgClass
      ],
    declarations: declarations,
    exports: declarations
  }
)
export class AtomicModule {
}
