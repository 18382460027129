import {Component, OnInit} from "@angular/core";
import {UserRole} from "../../domain/user/user-role";
import {Router} from "@angular/router";
import {EnvironmentService} from "../../core/environment-service";
import {UserService} from "../../domain/user/user-service";

interface Pages {
  title: string;
  route: string;
  role: UserRole;
}

@Component({
  templateUrl: './admin.component.html',
})
export class AdminComponent implements OnInit {
  customerCard: Pages;
  customerAdminCards: Pages[] = [
    {title: 'USERS', route: 'users', role: UserRole.CustomerAdmin},
    {title: 'FACILITIES', route: 'facilities', role: UserRole.CustomerAdmin},
  ];

  constructor(private router: Router,
              private environmentService: EnvironmentService,
              private userService: UserService) {

  }

  ngOnInit(): void {
    this.customerCard = this.createCustomerCard();
  }

  private createCustomerCard(): Pages {
    const customerId = this.environmentService.companionToken?.customerId;
    const path = `/customers/${customerId}`;
    return {title: 'CUSTOMER', route: path, role: UserRole.CustomerAdmin};
  }

  checkRole(page: Pages): boolean {
    return this.userService.hasRequiredRole(page.role);
  }

  navigateTo(card: Pages) {
    const path = `/admin/${card.route}`
    this.router.navigate([path]).then(/* DO NOTHING */);
  }
}
