import {Component, EventEmitter, Input, Output} from "@angular/core";
import {DialogService} from "../dialogs/dialog-service";
import {CrudinatorMakeService} from "../../domain/crudinator/crudinator-make-service";
import {CrudinatorConfigType} from "../../domain/crudinator/crudinator-config-type";


@Component({
  selector: 'crudinator',
  templateUrl: './crudinator.component.html'
})
export class CrudinatorComponent {

  @Input()
  crudType: string | null = null;

  @Output()
  crudChange = new EventEmitter<void>();

  constructor(private dialogService: DialogService, private crudinatorMakeService: CrudinatorMakeService) {
  }

  canEdit() {
    return true;
  }

  openEdit() {
    const crudtype = this.crudType as CrudinatorConfigType;
    const crudinatorConfig = this.crudinatorMakeService.configByType(crudtype!);
    this.dialogService.crudinatorDialog(crudinatorConfig).subscribe((isChanged) => {
      if (isChanged) {
        this.crudChange.emit();
      }
    })
  }
}
