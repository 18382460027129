import {User} from "../../domain/user/user";
import {GlobalStorageAction, GlobalStorageService} from "./global-stroage-service";
import {BehaviorSubject, Observable} from "rxjs";
import {Injectable} from "@angular/core";


@Injectable(
  {providedIn: 'root'}
)
export class UserStorageService {

  currentUserSubject: BehaviorSubject<User | null> = new BehaviorSubject<User>(null);
  currentUser$: Observable<User | null> = this.currentUserSubject.asObservable();
  currentUser: User;

  constructor(
    private globalStorageService: GlobalStorageService,
  ) {

    this.globalStorageService.action$.subscribe(action => {
      if (action === GlobalStorageAction.CLEAR){
        this.clear();
      }
    });
  }

  setCurrentUser(user: User | null) {
    this.currentUser = user;
    this.currentUserSubject.next(this.currentUser);
  }

  private clear() {
    this.currentUser = null;
    this.currentUserSubject.next(null);
  }
}
