<div class="p-4">
  <div class="flex">
    <div class="mt-2 ml-2">
      <h1>{{editTitle | translate}}</h1>
    </div>
  </div>
  <form autocomplete="off" #form="ngForm" class="w-full p-4" style="flex: 0 1 auto">
    <div *ngFor="let field of fields" class="w-full">
      <ng-container *ngIf="field.field_type === fieldType.String">
        <mat-form-field class="w-full" appearance="outline">
          <mat-label>{{field.label | translate}}</mat-label>
          <input matInput [ngModel]="obj[field.name]" (ngModelChange)="onStringChange($event, field.name)"
                 [name]="field.name" [disabled]="inProgress"/>
        </mat-form-field>
      </ng-container>

      <ng-container *ngIf="field.field_type === fieldType.Text">
        <mat-form-field class="w-full" appearance="outline">
          <mat-label>{{field.label | translate}}</mat-label>
          <textarea matInput cdkTextareaAutosize [(ngModel)]="obj[field.name]" [name]="field.name" [disabled]="inProgress"></textarea>
        </mat-form-field>
      </ng-container>

      <ng-container *ngIf="field.field_type === fieldType.Bool">
        <mat-slide-toggle color="primary" [(ngModel)]="obj[field.name]" [name]="field.name" [disabled]="inProgress">
          {{field.label | translate}}
        </mat-slide-toggle>
      </ng-container>

      <ng-container *ngIf="field.field_type === fieldType.Number">
        <mat-form-field class="w-full" appearance="outline">
          <mat-label>{{field.label | translate}}</mat-label>
          <input matInput type="number" [(ngModel)]="obj[field.name]" [name]="field.name" [disabled]="inProgress"/>
        </mat-form-field>
      </ng-container>
    </div>

  </form>
  <div class="flex w-full justify-between">
    <div>
      <button *ngIf="!isCreating" mat-flat-button (click)="remove()" [disabled]="inProgress">
        <span>{{'COMMON_DELETE' | translate}}</span>
      </button>
    </div>
    <div>
      <button mat-flat-button (click)="cancel()" [disabled]="inProgress">
        <span>{{'COMMON_CANCEL' | translate}}</span>
      </button>
      <button mat-flat-button color="primary" (click)="save()" [disabled]="inProgress" class="ml-2">
        <span>{{'COMMON_SAVE' | translate}}</span>
        <fa-icon *ngIf="inProgress" [icon]="faSpinner" animation="spin" class="ml-1"/>
      </button>
    </div>
  </div>
</div>
