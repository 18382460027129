<div class="max-w-screen-2xl xl:px-16 md:p-4 py-2 px-2">

  <mat-card>
    <mat-card-content>
      <div class="flex justify-around">
        <div class="flex flex-col items-center">
          <div class="mb-2 flex justify-center">
            {{ "EVENTS" | translate }}
          </div>
          <h5 class="mb-2 font-bold text-primary text-center">344 st</h5>
          <div class="font-thin text-xs">Senaste 5 dagarna</div>
        </div>
        <div style="width: 1.25px"
             class=" self-stretch border-t-0 bg-gradient-to-tr from-transparent via-neutral-300 to-transparent opacity-10 dark:opacity-100"></div>

        <div class="flex flex-col items-center">
          <div class="mb-2 flex justify-center">
            {{ "INCIDENTS_CLOSED" | translate }}
          </div>
          <h5 class="mb-2 font-bold text-primary text-center">23 st</h5>
          <div class="font-thin text-xs">Senaste 5 dagarna</div>
        </div>
        <div style="width: 1.25px"
             class=" self-stretch border-t-0 bg-gradient-to-tr from-transparent via-neutral-300 to-transparent opacity-25 dark:opacity-100"></div>
        <div class="flex flex-col items-center">
          <div class="mb-2 flex justify-center">
            <div class="text-sm">{{ "ASSETS" | translate }}</div>
          </div>
          <h5 class="mb-2 font-bold text-primary text-center">1223 st</h5>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
