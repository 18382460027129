<mat-dialog-content>
  <heading-h1 [large]="true" text="FACILITIES"/>
  <p class="mt-2">{{ 'FACILITY_GROUP_SELECT_DIALOG_DESCRIPTION' | translate }}</p>
  <div *ngIf="facilities" class="mt-4">
    <div class="flex flex-col md:flex-row justify-between mb-4 md:items-center">
      <mat-form-field class="w-full md:w-1/2" appearance="outline">
        <mat-label>{{ 'COMMON_SEARCH' | translate }}</mat-label>
        <button-icon *ngIf="searchCtrl.value" matSuffix (click)="clearSearch()" ariaLabel="Clear">
          <mat-icon>close</mat-icon>
        </button-icon>
        <mat-icon matSuffix *ngIf="!searchCtrl.value">search</mat-icon>
        <input matInput [formControl]="searchCtrl">
      </mat-form-field>
      <div class="w-full md:w-1/2 md:p-2">
        <mat-checkbox color="primary" [formControl]="showOnlySelectedCtrl">
          <span class="whitespace-nowrap">{{ "FACILITY_GROUP_SHOW_ONLY_SELECTED" | translate }}</span>
        </mat-checkbox>
      </div>
    </div>
    <table class="w-full hidden md:table table-fixed">
      <thead>
      <tr class="bg-gray-200">
        <th class="w-24 text-left px-2 py-4">{{ "COMMON_NAME" | translate }}</th>
        <th class="w-24 text-left px-2 py-4">{{ "COMMON_DESCRIPTION" | translate }}</th>
        <th class="w-24 text-left px-2 py-4">{{ "FACILITY_GROUP_FACILITY_SELECTED" | translate }}</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let facility of facilities" class="border-t-2 hover:bg-gray-100 cursor-pointer"
          (click)="addOrRemove(facility)">
        <td class="text-left p-2">{{ facility.name }}</td>
        <td class="text-left p-2">{{ facility.description }}</td>
        <td class="text-left p-2">
          @if (isSelected(facility.id)) {
            <button-icon (clickChange)="remove(facility)" ariaLabel="remove" icon="check_box"
                         [inProgress]="isInProgress(facility)"
                         [isDisabled]="isDisabled(facility)"
                         [iconClass]="'text-green-700'"/>
          } @else {
            <button-icon (clickChange)="addToGroup(facility)" ariaLabel="remove" icon="check_box_outline_blank"
                         [inProgress]="isInProgress(facility)"
                         [isDisabled]="isDisabled(facility)"/>
          }
        </td>
      </tr>
      </tbody>
    </table>

    <ul class="md:hidden divide-y list-none">
      <li *ngFor="let facility of facilities" class="list-none">
        <div class="hover:bg-gray-100 cursor-pointer p-2" (click)="addOrRemove(facility)"
             (keydown.space)="triggerClick($event)" (keydown.enter)="triggerClick($event)" tabindex="0" role="button">
          <div class="flex justify-between">
            <div class="flex items-center">
              <p class="text-base font-bold me-2">{{ facility.name }}</p>
              <p class="text-sm">({{ facility.description }})</p>
            </div>
            @if (isSelected(facility.id)) {
              <button-icon (clickChange)="remove(facility)" ariaLabel="remove" icon="check_box"
                           [inProgress]="isInProgress(facility)"
                           [isDisabled]="isDisabled(facility)"
                           [iconClass]="'text-green-700'"
              />
            } @else {
              <button-icon (clickChange)="addToGroup(facility)" ariaLabel="remove" icon="check_box_outline_blank"
                           [inProgress]="isInProgress(facility)"
                           [isDisabled]="isDisabled(facility)"
              />
            }
          </div>
          <div class="flex row justify-between">
            <div class="py-1">
              <p class="text-sm">{{ facility.description }}</p>
            </div>
          </div>
        </div>
      </li>
    </ul>

    <mat-paginator class="mt-2" showFirstLastButtons
                   [pageSizeOptions]="paginator.pageSizeOptions"
                   [pageSize]="paginator.pageSize"
                   [pageIndex]="paginator.pageIndex"
                   [length]="paginator.totalCount"
                   (page)="onPageChange($event)">
    </mat-paginator>

  </div>

  <mat-dialog-actions>
    <button-outlined (clickChange)="close()" [isDisabled]="inProgress" text="COMMON_CLOSE"/>
  </mat-dialog-actions>
</mat-dialog-content>
