import {Router} from "@angular/router";
import {inject} from "@angular/core";
import {UserService} from "../domain/user/user-service";
import {AppRoutes} from "../app-routing.module";

export const authSuperAdminGuard = () => {
  const userService = inject(UserService);
  const router = inject(Router);
  if (!userService.isLoggedIn) {
    router.navigate(['login']).then(/* DO NOTHING */);
    return false;
  }

  if (userService.isAdmin()) {
    router.navigate([AppRoutes.ADMIN_CUSTOMER]).then(/* DO NOTHING */);
    return false;
  }

  if (!userService.isSuperAdmin()) {
    router.navigate(['']).then(/* DO NOTHING */);
    return false;
  }

  return true;
};
