import {ObjectMapperHelper} from "../../core/object-mapper-helper";
import {UUID} from "../uuid";
import {AddressDetails} from "../address-details";
import {Metadata} from "../metadata";

export class Provider extends Metadata {
  readonly id: UUID;
  readonly tenantId: string;
  readonly name: string;
  readonly description: string;
  readonly organizationNumber: string;
  readonly contactName: string;
  readonly contactEmail: string;
  readonly contactPhone: string;
  readonly address: AddressDetails;
  readonly invoiceAddress: AddressDetails;
  readonly invoiceId: number;
  readonly invoiceReference: string;
  readonly costAccountNumber: string;
  readonly isActive: boolean;



  constructor(data: any) {
    super(data);
    const helper = ObjectMapperHelper.of(data);
    this.id = helper.requiredUUID('id');
    this.tenantId = helper.getUUID('tenantId');
    this.name = helper.requireString('name');
    this.description = helper.getString('description');
    this.organizationNumber = helper.requireString('organizationNumber');
    this.contactName = helper.getString('contactName');
    this.contactEmail = helper.getString('contactEmail');
    this.contactPhone = helper.getString('contactPhone');
    this.address = new AddressDetails(data?.address);
    this.invoiceAddress = new AddressDetails(data?.invoiceAddress);
    this.invoiceId = helper.getNumber('invoiceId');
    this.invoiceReference = helper.getString('invoiceReference');
    this.costAccountNumber = helper.getString('costAccountNumber');
    this.isActive = helper.getBoolean('isActive');
  }

  get countryTranslateKey(): string {
    return `COUNTRY_${this.address.country}`;
  }
}
