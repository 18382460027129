import {Metadata} from "../metadata";
import {ObjectMapperHelper} from "../../core/object-mapper-helper";
import {UUID} from "../uuid";

export enum BookingStatus {
  Open = "Open",
  New = "New",
  Accepted = "Accepted",
  InProgress = "InProgress",
  Closed = "Closed",
  Rejected = "Rejected",
  OverdueDl = "OverdueDl"
}

export class Booking extends Metadata {
  readonly id: UUID
  readonly title: string
  readonly description: string
  readonly isActive: boolean
  readonly status: BookingStatus
  readonly expectedCompletionDate: Date
  readonly deadlineEndDate: Date
  readonly completedAt: Date
  readonly tenantId: UUID
  readonly customerId: UUID
  readonly facilityId: UUID
  readonly providerId: UUID
  readonly incidentId: UUID



  constructor(data: any) {
    super(data)
    const helper = ObjectMapperHelper.of(data);
    this.id = helper.getUUID("id")
    this.title = helper.getString("title")
    this.description = helper.getString("description")
    this.isActive = helper.getBoolean("isActive")
    this.status = helper.getEnum("status", BookingStatus)
    this.expectedCompletionDate = helper.getDate("expectedCompletionDate")
    this.deadlineEndDate = helper.getDate("deadlineEndDate")
    this.completedAt = helper.getDate("completedAt")
    this.tenantId = helper.getUUID("tenantId")
    this.customerId = helper.getUUID("customerId")
    this.facilityId = helper.getUUID("facilityId")
    this.providerId = helper.getUUID("providerId")
    this.incidentId = helper.getUUID("incidentId")
  }
}
