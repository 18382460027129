<form [formGroup]="form" class="flex flex-col py-4">

  <div class="flex pb-4">
    <button mat-button (click)="openDialog()">
      {{ "INCIDENT_WIZ_SELECT_ASSETS_INCIDENT" | translate }}
      <mat-icon iconPositionEnd>chevron_right</mat-icon>
    </button>
  </div>

  <ng-container *ngIf="selectedAssets.length === 0">
    <p class="text-xs">{{ "NO_ASSET_SELECTED" | translate }}</p>
  </ng-container>


  <div *ngFor="let asset of selectedAssets" class="">
    <mat-chip class="p-0 mt-2 cursor-pointer" color="accent" (click)="handleShowAsset(asset)">
        <span class="text-xs inline-block">
          {{asset.modelName}}
        </span>
      <button matChipRemove (click)="removeAsset(asset.id)" aria-label="Remove orange">
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip>
  </div>

</form>
