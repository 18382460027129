import {Component, Input} from "@angular/core";
import {faSpinner, faCloudUpload} from "@fortawesome/free-solid-svg-icons";
import {faFile} from "@fortawesome/free-regular-svg-icons";
import {EventUtil} from "../../../core/event-util";
import {Asset} from "../../../domain/asset/asset";
import {ExtendedSummaryIncident} from "./wizard-incident-main.component";
import {DialogService} from "../../../shared/dialogs/dialog-service";
import {FileData} from "../../../core/files-upload/file-data";


@Component({
  selector: 'wizard-incident-summary-form',
  templateUrl: './wizard-incident-summary-form.component.html',
})
export class WizardIncidentSummaryFormComponent {
  triggerClick = EventUtil.triggerClick;
  protected readonly faSpinner = faSpinner;
  inProgress = false;
  protected readonly faFile = faFile;

  @Input()
  extendedSummaryIncident: ExtendedSummaryIncident;

  @Input()
  selectedAssets: Asset[] = [];

  @Input()
  selectedFiles: FileData[];

  constructor(private dialogService: DialogService){}

  getEndDate(): Date {
    const currentDate = new Date();
    return new Date(currentDate.getTime() + this.extendedSummaryIncident.deadlineEndMinutes * 60 * 1000);
  }

  openAssetDialog(asset: Asset) {
    this.dialogService.openAssetDialog(asset)
  }

  protected readonly faCloudUpload = faCloudUpload;
}
