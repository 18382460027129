import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from "@angular/core";
import {Router} from "@angular/router";
import {Paginated} from "../../../domain/resp-wrapper";
import {User} from "../../../domain/user/user";
import {finalize} from "rxjs";
import {UserManagementService} from "../../domain/user/user-management-service";
import {Paginator} from "../../../core/paginator";
import {AppRoutes} from "../../../app-routing.module";


@Component({
  selector: 'users-management',
  templateUrl: './users-management-admin.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UsersManagementAdminComponent {
  paginated: Paginated<User>;
  inProgress = true;


  constructor(
    private router: Router,
    private userManagementService: UserManagementService,
    private cd: ChangeDetectorRef
  ) {}

  onSearch(data: { searchTerm: string, paginator: Paginator<User> }) {
    this.inProgress = true;
    this.userManagementService.all(data.searchTerm, data.paginator)
      .pipe(finalize(()=> {
        this.inProgress = false
        this.cd.markForCheck()
      }))
      .subscribe(response => {
        this.paginated = response;
      });
  }

  onCreate() {
    this.router.navigate([`/${AppRoutes.ADMIN_MANAGEMENT}/users/new`]).then(/* DO NOTHING */);
  }

  onNavigateToEdit(user: User) {
    this.router.navigate([`/${AppRoutes.ADMIN_MANAGEMENT}/users`, user.id]).then(/* DO NOTHING */);
  }
}
