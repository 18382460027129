import {ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {PdfViewerModule} from 'ng2-pdf-viewer';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HomeComponent} from "./pages/home/home.component";
import {LoginComponent} from "./pages/login/login-component";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatListModule} from "@angular/material/list";
import {MatIconModule} from "@angular/material/icon";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatButtonModule} from "@angular/material/button";
import {NgOptimizedImage, registerLocaleData} from "@angular/common";
import {EventsComponent} from "./pages/event/events.component";
import {MatInputModule} from "@angular/material/input";
import {MatTabsModule} from "@angular/material/tabs";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatCardModule} from "@angular/material/card";
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {ErrorDialogComponent} from "./shared/dialogs/error-dialog.component";
import {MatDialogModule} from "@angular/material/dialog";
import {GlobalErrorHandler} from "./core/error/globa-error-handler";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {MatMenuModule} from "@angular/material/menu";
import {MatTooltipModule} from "@angular/material/tooltip";
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {
  CompanionDarkTextLogoComponent,
  CompanionLightTextLogoComponent,
  CompanionRoundLightLogoComponent
} from "./shared/companion-logo/companion-logo.component";
import {DashboardWrapperComponent} from "./shared/dashboard-wrapper/dashboard-wrapper.component";
import {MatTableModule} from "@angular/material/table";
import {MatPaginatorIntl, MatPaginatorModule} from "@angular/material/paginator";
import {TenantEditComponent} from "./pages/tenant/tenant-edit.component";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {UnsavedChangesDialogComponent} from "./shared/dialogs/unsaved-changes-dialog.component";
import {MatSelectModule} from "@angular/material/select";
import {MatPaginationIntlService} from "./core/mat-pagination-service";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatExpansionModule} from "@angular/material/expansion";
import {MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatRippleModule} from "@angular/material/core";
import {CustomerEditComponent} from './pages/customer/customer-edit.component';
import {CustomersComponent} from './pages/customer/customers.component';
import {CrudinatorComponent} from "./shared/crudinator/crudinator.component";
import {CrudinatorDialogComponent} from "./shared/dialogs/crudinator-dialog.component";
import {CrudinatorListComponent} from "./shared/crudinator/crudinator-list.component";
import {CrudinatorMasterComponent} from "./shared/crudinator/crudinator-master.component";
import {CrudinatorEditComponent} from "./shared/crudinator/crudinator-edit.component";
import {AdminComponent} from "./pages/admin/admin.component";
import {RouterParentComponent} from "./router.parent.component";
import {FacilitiesAdminComponent} from "./pages/facilities-admin/facilities-admin.component";
import {FacilityEditAdminComponent} from "./pages/facilities-admin/facility-edit-admin.component";
import {AdminManagementComponent} from "./pages/admin-management/admin-management.component";
import {MatChipsModule} from "@angular/material/chips";
import {MatSortModule} from "@angular/material/sort";
import {CdkTableModule} from "@angular/cdk/table";
import {HeaderSortIconComponent} from "./shared/header-sort-icon.component";
import {EnterSpacePressDirective} from "./shared/directvies/enter-space-press-directive";
import {ForgotPasswordDialogComponent} from './shared/dialogs/forgot-password-dialog.component';
import {FaqDialogComponent} from "./shared/dialogs/faq-dialog.component";
import {da, de, nb, sv} from "date-fns/locale";
import {maskConfig} from "./core/mask-util";
import {NgxMaskDirective, provideNgxMask} from "ngx-mask";
import {MAT_DATE_FNS_FORMATS, MatDateFnsModule} from "@angular/material-date-fns-adapter";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {PhoneNumberComponent} from "./shared/form/phone-number.component";
import {UsersComponent} from "./pages/user/users.component";
import {UserEditComponent} from "./pages/user/user-edit.component";
import {IncidentsComponent} from "./pages/incident/incidents.component";
import {WizardIncidentMainComponent} from "./pages/incident/wizard/wizard-incident-main.component";
import {MatStepperModule} from "@angular/material/stepper";
import {FileUploadComponent} from "./core/files-upload/file-upload.component";
import {MatRadioModule} from "@angular/material/radio";
import {WizardIncidentAssetFormComponent} from "./pages/incident/wizard/wizard-incident-asset-form.component";
import {WizardIncidentBasicFormComponent} from "./pages/incident/wizard/wizard-incident-basic-form.component";
import {WizardIncidentGeneralFormComponent} from "./pages/incident/wizard/wizard-incident-general-form.component";
import {SelectAssetsDialogComponent} from "./shared/dialogs/select-assets-dialog.component";
import {WizardIncidentContactFormComponent} from "./pages/incident/wizard/wizard-incident-contact-form.component";
import {WizardIncidentSummaryFormComponent} from "./pages/incident/wizard/wizard-incident-summary-form.component";
import {DateTimePipe} from "./pipes/localized-date.pipe";
import {AssetsComponent} from "./pages/assets/assets.component";
import {AssetEditComponent} from "./pages/assets/asset-edit.component";
import {DateInputComponent} from "./shared/form/date/date-input.component";
import {IncidentEditComponent} from "./pages/incident/incident-edit.component";
import {AssetDialogComponent} from "./shared/dialogs/asset-dialog.component";
import {SelectFromListDialogComponent} from "./shared/dialogs/select-from-list-dialog.component";
import {FacilityPickComponent} from "./pages/facility-pick/facility-pick.component";
import {ViewAttachmentDialogComponent} from "./shared/dialogs/view-attachment-dialog.component";
import {AdminHomeComponent} from "./pages/admin/admin-home.component";
import {BookingsComponent} from "./pages/bookings/bookings.component";
import {BookingEditComponent} from "./pages/bookings/booking-edit.component";
import {OnDeleteDialogComponent} from "./shared/dialogs/on-delete-dialog.component";
import {ProviderComponent} from "./pages/provider/provider.component";
import {ProviderEditComponent} from "./pages/provider/provider-edit.component";
import {UsersManagementAdminComponent} from "./admin-managment/pages/users/users-management-admin.component";
import {UserEditManagementAdminComponent} from "./admin-managment/pages/users/user-edit-management-admin.component";
import {
  CustomersManagementAdminComponent
} from "./admin-managment/pages/customers/customers-management-admin.component";
import {
  CustomerEditManagementAdminComponent
} from "./admin-managment/pages/customers/customer-edit-management-admin.component";
import {SetPasswordDialogComponent} from "./shared/dialogs/set-password-dialog.component";
import {PasswordInputComponent} from "./shared/form/password/password-input.component";
import {TopToolbarComponent} from "./shared/dashboard-wrapper/top-toolbar.component";
import {CustomerFormComponent} from "./pages/customer/customer-form.component";
import {CustomersTableComponent} from "./pages/customer/customers-table.component";
import {UserFormComponent} from "./pages/user/user-form.component";
import {UsersTableComponent} from "./pages/user/users-table.component";
import {TenantsManagementComponent} from "./admin-managment/pages/tenants/tenants-management.component";
import {TenantEditManagementComponent} from "./admin-managment/pages/tenants/tenant-edit-management.component";
import {AtomicModule} from "./atomic/atomic.modue";
import {TenantFormComponent} from "./pages/tenant/tenant-form.component";
import {BookingViewComponent} from "./shared/booking-view/booking-view.component";
import {BookingDialogComponent} from "./shared/dialogs/booking/booking-dialog.component";
import {BookingFormComponent} from "./pages/bookings/booking-form.component";
import {ProviderManagementAdminComponent} from "./admin-managment/pages/providers/provider-management-admin.component";
import {
  ProviderEditManagementAdminComponent
} from "./admin-managment/pages/providers/provider-edit-management-admin.component";

import {FacilityGroupsComponent} from "./pages/facility-group/facility-groups.component";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {FacilityGroupEditComponent} from "./pages/facility-group/facility-group-edit.component";
import {
  FacilityGroupFacilitiesSelectDialogComponent
} from "./shared/dialogs/facility-group-facilities-select-dialog.component";

@NgModule({
  declarations: [
    AdminComponent,
    AdminHomeComponent,
    AdminManagementComponent,
    AppComponent,
    AssetEditComponent,
    AssetsComponent,
    BookingViewComponent,
    BookingDialogComponent,
    BookingFormComponent,
    ErrorDialogComponent,
    FaqDialogComponent,
    FacilitiesAdminComponent,
    FacilityEditAdminComponent,
    FacilityGroupEditComponent,
    FacilityGroupsComponent,
    FacilityGroupFacilitiesSelectDialogComponent,
    FaqDialogComponent,
    ForgotPasswordDialogComponent,
    CrudinatorComponent,
    CrudinatorListComponent,
    CrudinatorMasterComponent,
    CrudinatorEditComponent,
    CrudinatorDialogComponent,
    DateInputComponent,
    CompanionLightTextLogoComponent,
    CompanionDarkTextLogoComponent,
    CompanionRoundLightLogoComponent,
    EventsComponent,
    EnterSpacePressDirective,
    FacilityPickComponent,
    HeaderSortIconComponent,
    HomeComponent,
    LoginComponent,
    DateTimePipe,
    DashboardWrapperComponent,
    DashboardWrapperComponent,
    TenantEditComponent,
    TenantsManagementComponent,
    TenantEditManagementComponent,
    TenantFormComponent,
    TopToolbarComponent,
    CustomersComponent,
    CustomerEditComponent,
    CustomerFormComponent,
    CustomersTableComponent,
    PhoneNumberComponent,
    RouterParentComponent,
    UnsavedChangesDialogComponent,
    UsersComponent,
    UserEditComponent,
    UsersManagementAdminComponent,
    IncidentsComponent,
    WizardIncidentMainComponent,
    WizardIncidentAssetFormComponent,
    WizardIncidentBasicFormComponent,
    WizardIncidentGeneralFormComponent,
    WizardIncidentContactFormComponent,
    WizardIncidentSummaryFormComponent,
    IncidentEditComponent,
    SelectAssetsDialogComponent,
    AssetDialogComponent,
    SelectFromListDialogComponent,
    FileUploadComponent,
    ViewAttachmentDialogComponent,
    BookingsComponent,
    BookingEditComponent,
    OnDeleteDialogComponent,
    ProviderComponent,
    ProviderEditComponent,
    ProviderManagementAdminComponent,
    ProviderEditManagementAdminComponent,
    UsersManagementAdminComponent,
    UsersTableComponent,
    UserEditManagementAdminComponent,
    UserFormComponent,
    CustomersManagementAdminComponent,
    CustomerEditManagementAdminComponent,
    SetPasswordDialogComponent,
    PasswordInputComponent
  ],
  imports: [
    BrowserModule,
    CdkTableModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    DragDropModule,
    FontAwesomeModule,
    HttpClientModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatListModule,
    MatTabsModule,
    MatIconModule,
    MatToolbarModule,
    MatButtonModule,
    NgOptimizedImage,
    MatInputModule,
    MatRadioModule,
    MatSortModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    MatCardModule,
    MatDialogModule,
    MatMenuModule,
    MatTableModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatRippleModule,
    FormsModule,
    MatChipsModule,
    MatSortModule,
    MatDateFnsModule,
    MatDatepickerModule,
    NgxMaskDirective,
    MatStepperModule,
    PdfViewerModule,
    AtomicModule,
  ],
  providers: [
    {provide: ErrorHandler, useClass: GlobalErrorHandler},
    {provide: MatPaginatorIntl, useClass: MatPaginationIntlService},
    {provide: MAT_DATE_LOCALE, useValue: sv},
    {provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FNS_FORMATS},
    provideNgxMask(maskConfig())
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    registerLocaleData(sv, 'sv');
    registerLocaleData(de, 'de');
    registerLocaleData(nb, 'no');
    registerLocaleData(da, 'dk');
  }
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?cb=' + new Date().getTime().toString());
}
