import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {EventService} from "../../domain/event/event-service";
import {AppRoutes} from "../../app-routing.module";
import {Event} from "../../domain/event/event";
import {EventUtil} from "../../core/event-util";


@Component({
    selector: "home",
    templateUrl: "admin-home.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
  }
)
export class AdminHomeComponent implements OnInit {
  triggerClick = EventUtil.triggerClick;

  events: Event[] | null = null;

  constructor(private eventService: EventService, private router: Router, private cd: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.eventService.allSimple().subscribe(events => {
      this.events = events;
      this.cd.detectChanges();
    })
  }

  navigateToEdit(event: Event) {
    this.router.navigate([`${AppRoutes.ADMIN_MANAGEMENT}/events`, event.event_id]).then(/* DO NOTHING */);
  }

  toIncident() {
    this.router.navigate([`events/incident-wizard`]).then(/* DO NOTHING */);
  }
}
