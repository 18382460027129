import {Facility} from "../domain/facility/facility";
import {Customer} from "../domain/customer/customer";

export const storageKeys = {
  accessToken: 'accessToken',
  refreshToken: 'refreshToken',
  refreshTokenExpiration: 'refreshTokenExpiration',
  rememberMe: 'rememberMe',
  language: 'language',
  facility: 'facility',
  customer: 'customer'
}

export class StorageHelper {

  constructor(private storage: Storage) {
  }

  setValue(value: string | null, key: string): void {
    if (value == null) {
      this.storage.removeItem(key);
    } else {
      this.storage.setItem(key, value);
    }
  }

  get accessToken(): string | null {
    return this.storage.getItem(storageKeys.accessToken);
  }

  get refreshToken(): string | null {
    return this.storage.getItem(storageKeys.refreshToken);
  }

  get refreshTokenExpiration(): string | null {
    return this.storage.getItem(storageKeys.refreshTokenExpiration);
  }

  setAccessToken(accessToken: string | null) {
    this.setValue(accessToken, storageKeys.accessToken);
  }

  removeAccessToken() {
    this.storage.removeItem(storageKeys.accessToken);
  }

  removeRefreshToken() {
    this.storage.removeItem(storageKeys.refreshToken);
    this.storage.removeItem(storageKeys.refreshTokenExpiration)
  }

  removeFacility() {
    this.storage.removeItem(storageKeys.facility);
  }

  removeCustomer() {
    this.storage.removeItem(storageKeys.customer);
  }

  setRefreshToken(refreshToken: string | null, expires: string) {
    this.setValue(refreshToken, storageKeys.refreshToken);
    this.setValue(expires, storageKeys.refreshTokenExpiration);
  }

  get rememberMe(): string | null {
    return this.storage.getItem(storageKeys.rememberMe);
  }

  setRememberMe(rememberMe: string | null) {
    this.setValue(rememberMe, storageKeys.rememberMe);
  }

  get language(): string | null {
    return this.storage.getItem(storageKeys.language);
  }

  setLang(lang: string | null) {
    this.setValue(lang, storageKeys.language);
  }

  get facility(): Facility | null {
    const facility = this.storage.getItem(storageKeys.facility);
    return facility ? JSON.parse(facility) : null;
  }

  setFacility(facility: Facility | null) {
    this.setValue(JSON.stringify(facility), storageKeys.facility);
  }

  setCustomer(customer: Customer) {
    this.setValue(JSON.stringify(customer), storageKeys.customer);
  }

  get customer(): Customer | null {
    const customer = this.storage.getItem(storageKeys.customer);
    return customer ? JSON.parse(customer) : null;
  }
}


