import {Component, Inject, OnInit} from "@angular/core";
import {EventUtil} from "../../core/event-util";
import {FormControl} from "@angular/forms";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {UUID} from "../../domain/uuid";
import {SelectAssetsDialogComponent} from "./select-assets-dialog.component";
import {AssetType} from "../../domain/asset/asset-type";
import {LanguageService} from "../../core/language-service";
import {startWith} from "rxjs";
import {DeviceService} from "../../core/device.service";

export class ListItem {
  uuid: UUID;
  name: string;

  static fromAssetType(asset: AssetType): ListItem {
    const data = new ListItem();
    data.uuid = asset.id;
    data.name = asset.name;
    return data;
  }
}

@Component({
  templateUrl: './select-from-list-dialog.component.html',
})
export class SelectFromListDialogComponent implements OnInit {
  triggerClick = EventUtil.triggerClick;
  protected readonly faSpinner = faSpinner;
  inProgress = false;
  list: ListItem[] = [];
  filteredList: ListItem[] = [];
  selectedItem: UUID;
  searchCtrl = new FormControl();
  title: string;

  constructor(
    private languageService: LanguageService,
    private deviceService: DeviceService,
    private dialogRef: MatDialogRef<SelectAssetsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: {
      title: string,
      selectedUuid: UUID,
      list: ListItem[]
    }) {
  }

  ngOnInit() {
    this.selectedItem = this.data.selectedUuid;
    this.list = this.data.list;
    this.filteredList = [...this.list];
    this.title = this.data.title;

    this.searchCtrl.valueChanges.pipe(startWith("")).subscribe(value => {
      this.filteredList = this.list.filter(item => item.name.toLowerCase()
        .includes(value.toLowerCase())).sort((a, b) => a.name.localeCompare(b.name, this.languageService.language));
    });

    this.deviceService.device().pipe(startWith(this.deviceService.currDevice)).subscribe(device => {
      if (device.isMobile) {
        this.dialogRef.updateSize('98%', '98%');
      } else {
        this.dialogRef.updateSize('540px', '50%');
      }
    });
  }

  isSelected(item: ListItem): boolean {
    return this.selectedItem === item.uuid;
  }

  onSelect(item: ListItem) {
    if (this.isSelected(item)) {
      return;
    }
    this.selectedItem = item.uuid;
    this.close();
  }

  close() {
    this.dialogRef.close(this.selectedItem);
  }

  clearSearch() {
    this.searchCtrl.setValue('');
  }
}
