import {Metadata} from "../metadata";
import {ObjectMapperHelper} from "../../core/object-mapper-helper";
import {UUID} from "../uuid";

export enum AssetStatus {
  inUse = "inUse",
  notInUse = "notInUse",
}

export class Asset extends Metadata {
  readonly id: UUID;
  readonly facilityId: UUID;
  readonly customerId: UUID;
  readonly tenantId: UUID;
  readonly providerId: UUID;
  readonly name: string
  readonly typeId: UUID;
  readonly costAccountNumber: string;
  readonly manufacturerId: UUID;
  readonly modelName: string;
  readonly modelNumber: string;
  readonly serial: string;
  readonly placement: string;
  readonly purchaseDate: string;
  readonly installationDate: string;
  readonly warrantyExpiryDate: string;
  readonly lastServiceDate: string;
  readonly refrigerantAmount: number; //decimal
  readonly isScrapped: boolean;
  readonly scrappingDate: string;
  readonly width: number;
  readonly depth: number;
  readonly height: number;
  readonly weight: number;
  readonly description: string;
  readonly installedBy: UUID;
  readonly deliveredBy: UUID;
  readonly inUse: boolean;
  readonly outOfOrder: boolean;
  readonly status: AssetStatus;
  readonly deleted: boolean;

  constructor(data: any) {
    super(data)
    const helper = ObjectMapperHelper.of(data);
    this.id = helper.getUUID('id');
    this.facilityId = helper.getUUID('facilityId');
    this.customerId = helper.getUUID('customerId');
    this.tenantId = helper.getUUID('tenantId');
    this.name = helper.getString('name');
    this.typeId = helper.getUUID('typeId');
    this.costAccountNumber = helper.getString('costAccountNumber');
    this.manufacturerId = helper.getUUID('manufacturerId');
    this.modelName = helper.getString('modelName');
    this.modelNumber = helper.getString('modelNumber');
    this.serial = helper.getString('serial');
    this.placement = helper.getString('placement');
    this.purchaseDate = helper.getString('purchaseDate');
    this.installationDate = helper.getString('installationDate');
    this.warrantyExpiryDate = helper.getString('warrantyExpiryDate');
    this.lastServiceDate = helper.getString('lastServiceDate');
    this.refrigerantAmount = helper.getNumber('refrigerantAmount');
    this.isScrapped = helper.getBoolean('isScrapped');
    this.scrappingDate = helper.getString('scrappingDate')
    this.width = helper.getNumber('width');
    this.depth = helper.getNumber('depth');
    this.height = helper.getNumber('height');
    this.weight = helper.getNumber('weight');
    this.description = helper.getString('description');
    this.installedBy = helper.getUUID('installedBy')
    this.deliveredBy = helper.getUUID('deliveredBy')
    this.inUse = helper.getBoolean('inUse');
    this.outOfOrder = helper.getBoolean('outOfOrder');
    this.status = helper.getEnum('status', AssetStatus);
    this.deleted = helper.getBoolean('deleted');
  }
}
