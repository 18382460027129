import {Component} from "@angular/core";

@Component({
  selector: 'companion-text-logo-light',
  template: `
    <div class="flex align-items-center">
      <img src="assets/companion-text-logo-light.png" alt="Companion" class="h-8 w-auto">
    </div> `,
})
export class CompanionLightTextLogoComponent {

}

@Component({
  selector: 'companion-text-logo-dark',
  template: `
    <div class="flex align-items-center">
      <img src="assets/companion-text-logo-dark.png" alt="Companion" class="h-8 w-auto">
    </div> `,
})
export class CompanionDarkTextLogoComponent {

}

@Component({
  selector: 'companion-round-logo-light',
  template: `
    <div class="flex align-items-center">
      <img src="assets/companion-round-logo-light.png" alt="Companion" class="h-24 w-auto">
    </div> `,
})
export class CompanionRoundLightLogoComponent {

}
