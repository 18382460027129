import {AfterViewInit, Component, ElementRef, Inject, ViewChild} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Asset} from "../../domain/asset/asset";
import {faFile} from "@fortawesome/free-regular-svg-icons";
import {FilePreview} from "../../core/files-upload/file-upload.component";

const defaultZoomLevel = 0.98;

@Component({
  templateUrl: './view-attachment-dialog.component.html',
  styleUrls: ['./view-attachment-dialog.component.scss']
})
export class ViewAttachmentDialogComponent {
  @ViewChild('myVideo') videoElementRef:ElementRef;

  asset: Asset;
  zoom: number = defaultZoomLevel;

  selectedFileIndex = 0;
  selectedFile: FilePreview;
  filePreviews: FilePreview[] = [];

  dialogHeight = window.innerHeight - 100;

  constructor(
    private dialogRef: MatDialogRef<ViewAttachmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: FilePreview[],
  ) {
    this.filePreviews = data;
    this.selectedFile = this.filePreviews[0];
  }

  onClose(): void {
    this.dialogRef.close(true);
  }

  nextFile() {
    this.zoom = defaultZoomLevel;
    this.selectedFileIndex = (this.selectedFileIndex + 1) % this.filePreviews.length;
    this.selectedFile = this.filePreviews[this.selectedFileIndex];
    this.resetVideo();
  }

  previousFile() {
    this.zoom = defaultZoomLevel;
    this.selectedFileIndex = (this.selectedFileIndex - 1 + this.filePreviews.length) % this.filePreviews.length;
    this.selectedFile = this.filePreviews[this.selectedFileIndex];
    this.resetVideo();
  }

  // needed due it will not detect changes on video element if two videos is shown in a row
  resetVideo(){
    if (this.selectedFile.mimeType.includes('video') && this.videoElementRef) {
      this.videoElementRef.nativeElement.load();
    }
  }

  openInNewTab() {
    if(this.selectedFile.storedFile){
      window.open(this.selectedFile.dataUrl, '_blank');
      return;
    }

    const contentType = this.selectedFile.mimeType;
    const dataUrl = this.selectedFile.dataUrl;

    const newWindow = window.open();
    newWindow.document.write('<html lang="en"><head><title>Content Preview</title></head><body>');

    if (contentType.startsWith('image')) {
      newWindow.document.write(`<img src="${dataUrl}" alt="Image">`);
    } else if (contentType.startsWith('video')) {
      newWindow.document.write(`
        <video controls width="100%">
          <source [src]=${dataUrl} type="video/mp4">
          <source [src]=${dataUrl} type="video/webm">
          <source [src]=${dataUrl} type="video/ogg">
          Your browser does not support the video tag.
        </video>`);
    } else if (contentType === 'application/pdf') {
      newWindow.document.write(`<iframe src="${dataUrl}" width="100%" height="${window.innerHeight - 20}px"></iframe>`);
    } else {
      newWindow.document.write(`<p>Unsupported content type: ${contentType}</p>`);
    }

    newWindow.document.write('</body></html>');
  }

  handleZoom(value: number){
    this.zoom = this.zoom + value;
  }


  protected readonly faFile = faFile;
}
