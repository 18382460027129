import {Injectable} from "@angular/core";
import {ApiService} from "../../core/api-service";
import {User} from "./user";
import {catchError, map, Observable, of, Subject, switchMap, tap} from "rxjs";
import {Credentials} from "../credentials";
import {Authentication} from "../../auth/authentication";
import {EnvironmentService} from "../../core/environment-service";
import {Router} from "@angular/router";
import {UserRole} from "./user-role";
import {Paginated, RespPaginated, RespWrapper} from "../resp-wrapper";
import {Tenant} from "../tenant/tenant";
import {Paginator} from "../../core/paginator";
import {UrlBuilder} from "../url-builder";
import {UUID} from "../uuid";
import {TokenStorageService} from "../../core/storage/token-storage-service";
import {GlobalStorageService} from "../../core/storage/global-stroage-service";
import {UserStorageService} from "../../core/storage/user-storage-service";

@Injectable(
  {providedIn: 'root'}
)
export class UserService {
  apiPath = '/v1/users';
  adminApiPath = '/v1/admin/users';

  currentUser: User | null = null;

  constructor(
    private apiService: ApiService,
    private environmentService: EnvironmentService,
    private router: Router,
    private tokenStorageService: TokenStorageService,
    private userStorageService: UserStorageService,
    private globalStorageService: GlobalStorageService
  ) {

    // When user is logged in and refresh the page we need to fetch the user data from the server
    this.tokenStorageService.token$.subscribe(()=> {
      const userId = this.tokenStorageService.userId;
      if(userId){
        this.init(userId).subscribe();
      }
    })

    this.userStorageService.currentUser$.subscribe((user) => {
      this.currentUser = user;
    });


  }

  public init(userId: UUID): Observable<User | null> {

    if (userId && userId !== this.currentUser?.id) {
      return this.byId(userId).pipe(tap((user) => {
        this.userStorageService.setCurrentUser(user)
      }))
    }
    return of(null);
  }

  get isLoggedIn(): boolean {
    return this.tokenStorageService.userId != null;
  }

  login(credentials: Credentials): Observable<User> {

    return this.apiService.login<RespWrapper<Authentication>>(credentials).pipe(
      tap((resp) => {
        this.tokenStorageService.setToken(resp.data);
        return resp;
      }),
      switchMap(() => this.init(this.tokenStorageService.userId)),
      catchError(() => {
        this.environmentService.clearStorageFromUserData()
        this.globalStorageService.clearStorage();
        return [this.currentUser];
      }));
  }

  logout(): void {
    this.logoutUser().subscribe({
      next: () => {
        this.globalStorageService.clearStorage();
        this.router.navigate(['/login']).then(/* do nothing */);
      },
      error: () => {
        this.globalStorageService.clearStorage();
        this.router.navigate(['/login']).then(/* do nothing */);
      }
    })
  }

  private logoutUser(): Observable<RespWrapper<void>> {
    const url = `/v1/auth/logout`;
    return this.apiService.get<RespWrapper<void>>(url);
  }


  public byId(userId: string): Observable<User> {
    const url = `${this.apiPath}/${userId}`;
    return this.apiService.get<RespWrapper<User>>(url).pipe(
      map((resp) => {
        return new User(resp.data)
      }),
    );
  }

  public all(searchTerm: string = null, paginator: Paginator<User>): Observable<Paginated<User>> {
    const url = UrlBuilder.of(this.apiPath).addPagination(paginator).addSearch(searchTerm).toUrl();
    return this.apiService.get<RespPaginated<User>>(url)
      .pipe(map((resp) => Paginated.of<User>(resp, User)));
  }

  public isAdmin(): boolean {
    const currentUserRoles = this.tokenStorageService.roles ?? [];
    return this.adminRoles()?.some(requiredRole => currentUserRoles.includes(requiredRole)) ?? false;
  }

  public isTenantAdmin(): boolean {
    return this.hasRequiredRole(UserRole.TenantAdmin);
  }

  public isSuperAdmin(): boolean {
    return this.hasRequiredRole(UserRole.SuperAdmin);
  }

  private adminRoles() {
    return [UserRole.CustomerAdmin, UserRole.TenantAdmin];
  }

  public superAdmin() {
    return this.environmentService.companionToken?.roles?.includes(UserRole.SuperAdmin) ?? false;
  }

  public hasRequiredRole(requiredRole: UserRole | null): boolean {
    if (!requiredRole) {
      return true;
    }
    return this.tokenStorageService.roles.includes(requiredRole) ?? false;
  }

  isTenantAdminFor(tenantId: string | null) {
    if (!tenantId && !this.isTenantAdmin()) {
      return false;
    }
    return this.environmentService.companionToken?.tenantId === tenantId;
  }

  isCustomerAdmin(customerId: string | null) {
    return customerId != null && this.environmentService.companionToken?.customerId === customerId;
  }

  createOrUpdate(user: User | null, data: Partial<User>) {
    if (user && user.id) {
      const url = `${ this.apiPath}/${user.id}`;
      return this.apiService.patch<RespWrapper<Tenant>>(url, data);
    }
    return this.apiService.post<RespWrapper<Tenant>>(this.apiPath, data);
  }

  resetUserPassword(userId: UUID, password: string, useAdminApi = false) {
    const apiPath = useAdminApi ? this.adminApiPath : this.apiPath;
    const url = `${apiPath}/${userId}/password/reset`;
    return this.apiService.post<RespWrapper<User>>(url, JSON.stringify(password));
  }
}
