<div *ngIf="crudList" class="items-stretch h-full">
  <crudinator-list *ngIf="!selectedRow"
                   [crudList]="crudList"
                   [fields]="fields"
                   [crudConfig]="crudConfig"
                   [inProgress]="inProgress"
                   (selectChange)="onSelect($event)"
                   (backChange)="onBack()">
  </crudinator-list>

  <crudinator-edit *ngIf="selectedRow"
                   [fields]="fields"
                   [obj]="selectedRow"
                   [editTitle]="getEditTitle()"
                   [isCreating]="isCreating()"
                   [inProgress]="inProgress"
                   (cancelChange)="onEditCancel()"
                   (saveChange)="onEditSave($event)"
                   (removeChange)="onEditRemove($event)">
  </crudinator-edit>
</div>
<div *ngIf="!crudList" class="flex flex-col items-center justify-center p-20">
  <mat-spinner diameter="64"></mat-spinner>
  <div class="mt-2">
    {{'COMMON_LOADING' | translate}}
  </div>
</div>
