import {Directive, ElementRef, HostListener} from "@angular/core";

@Directive({
  selector: '[enterSpacePress]'
})
export class EnterSpacePressDirective {
  constructor(private el: ElementRef) {
  }

  @HostListener('keydown', ['$event'])
  onSpaceOrEnterKeyDown(event: KeyboardEvent) {
    const target = event.target as HTMLElement;
    if (target.tagName === 'BUTTON' || target.closest('button')) {
      return;
    }
    if (event.code === 'Space' || event.code === 'Enter') {
      event.preventDefault();
      if (event.target === this.el.nativeElement) {
        this.el.nativeElement.click();
      }
    }
  }
}
