import {Component, EventEmitter, Input, Output} from "@angular/core";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'button-flat',
  template: `
    <button mat-flat-button [color]="look" [disabled]="isDisabled" (click)="onClick()">
      {{ text | translate }}
      <icon-spinner *ngIf="inProgress" class="ml-1"></icon-spinner>
    </button>
  `,
})
export class ButtonFlatComponent {
  protected readonly faSpinner = faSpinner;

  @Input() look: '' | 'primary' | 'accent' | 'warn' = '';
  @Input() isDisabled = false;
  @Input() inProgress = false;
  @Input() text;
  @Output() clickChange = new EventEmitter<void>();

  onClick() {
    this.clickChange.emit();
  }
}
