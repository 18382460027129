import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {FormControl} from "@angular/forms";
import {EventUtil} from "../../core/event-util";
import {Paginator} from "../../core/paginator";
import {Customer} from "../../domain/customer/customer";
import {LanguageService} from "../../core/language-service";
import {combineLatest, debounceTime, distinctUntilChanged, startWith, Subject, Subscription} from "rxjs";
import {Paginated} from "../../domain/resp-wrapper";

@Component({
  selector: 'customers-table',
  templateUrl: './customers-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomersTableComponent implements OnInit, OnDestroy {
  triggerClick = EventUtil.triggerClick;
  searchCtrl = new FormControl();
  customers: Customer[] | null = null;
  sub = new Subscription();
  paginator = new Paginator<Customer>("name");
  paginatorSub = new Subject<Paginator<Customer>>();
  paginator$ = this.paginatorSub.asObservable()

  @Input() set customerPaginated(paginated: Paginated<Customer>) {
    this.customers = paginated?.items ?? null;
    this.paginator.setTotal(paginated?.totalCount ?? 0);
  }

  @Output() paginatorChange = new EventEmitter<{ searchTerm: string, paginator: Paginator<Customer> }>();
  @Output() createChange = new EventEmitter<void>();
  @Output() editChange = new EventEmitter<Customer>();

  @ViewChild(MatPaginator) set matPaginator(paginator: MatPaginator | null) {
    this.paginator.matPaginator = paginator;
  }

  constructor(private languageService: LanguageService) {
  }

  ngOnInit() {
    this.paginator.setLang(this.languageService.currLanguage());
    const search$ = this.searchCtrl.valueChanges.pipe(startWith(""), debounceTime(300), distinctUntilChanged());

    this.sub.add(combineLatest([search$, this.paginator$])
      .subscribe(([term, paginator]) => {
        this.paginatorChange.emit({searchTerm: term, paginator});
      })
    );
    this.paginatorSub.next(this.paginator);
  }

  create() {
    this.createChange.emit();
  }

  navigateToEdit(customer: Customer) {
    this.editChange.emit(customer)
  }

  clearSearch() {
    this.searchCtrl.setValue('');
  }

  toggleSort(name: keyof Customer) {
    this.paginator.sortBy(name);
    this.paginatorSub.next(this.paginator);
  }

  onPageChange(event: PageEvent) {
    this.paginator.onPageChange(event);
    this.paginatorSub.next(this.paginator);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
