import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {finalize, Subscription} from "rxjs";
import {CanDeactivateComponent} from "../../core/can-deactivate-guard";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {EnvironmentService} from "../../core/environment-service";
import {LanguageCountry} from "../../domain/language-country";
import {MaskUtil} from "../../core/mask-util";
import {Asset, AssetStatus} from "../../domain/asset/asset";
import {AssetService} from "../../domain/asset/asset-service";
import {UUID} from "../../domain/uuid";
import {AssetType} from "../../domain/asset/asset-type";
import {AssetTypeService} from "../../domain/asset/asset-type-service";
import {DialogService} from "../../shared/dialogs/dialog-service";
import {ListItem} from "../../shared/dialogs/select-from-list-dialog.component";
import {Facility} from "../../domain/facility/facility";
import {FacilityStorageService} from "../../core/storage/facility-storage-service";

interface AssetForm {
  name: FormControl<UUID>;
  typeId: FormControl<UUID>;
  costAccountNumber: FormControl<string>;
  manufacturerId: FormControl<UUID>;
  modelName: FormControl<string>;
  modelNumber: FormControl<string>;
  serial: FormControl<string>;
  placement: FormControl<string>;
  purchaseDate: FormControl<string>;
  installationDate: FormControl<string>;
  warrantyExpiryDate: FormControl<string>;
  lastServiceDate: FormControl<string>;
  refrigerantAmount: FormControl<number>;
  isScrapped: FormControl<boolean>;
  scrappingDate: FormControl<string>;
  description: FormControl<string>;
  width: FormControl<number>;
  depth: FormControl<number>;
  height: FormControl<number>;
  weight: FormControl<number>;
  installedBy: FormControl<UUID>;
  deliveredBy: FormControl<UUID>;
  inUse: FormControl<boolean>;
  outOfOrder: FormControl<boolean>;
  status: FormControl<AssetStatus>;
  deleted: FormControl<boolean>;
}

@Component({
  templateUrl: './asset-edit.component.html',
})
export class AssetEditComponent implements OnInit, CanDeactivateComponent, OnDestroy {
  maskUtil = new MaskUtil();
  protected readonly faSpinner = faSpinner;
  private asset: Asset;
  inProgress = false;
  form: FormGroup<AssetForm>;
  countriesList: LanguageCountry[] = [];
  assetStatus: string[] = Object.values(AssetStatus);
  subscription = new Subscription();
  title = "";
  assetTypes: AssetType[] | null = null;

  facility: Facility | null;

  constructor(private fb: FormBuilder,
              private environmentService: EnvironmentService,
              private assetTypeService: AssetTypeService,
              private facilityStorageService: FacilityStorageService,
              private assetService: AssetService,
              private router: Router,
              private dialogService: DialogService,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.countriesList = this.environmentService.langCountry;
    const assetId = this.route.snapshot.paramMap.get('id');



    this.subscription.add(this.facilityStorageService.currentFacility$.subscribe(facility => {
      this.facility = facility;
      this.fetchAssetTypes();
    }))

    if (assetId === "new") {
      this.initForm();
      this.setTitle(true);
      return;
    }
    this.setTitle(false);
    this.assetService.byId(assetId).subscribe(asset => {
      this.asset = asset
      this.initForm();
    });
  }

  setTitle(isNew: boolean): void {
    this.title = isNew ? "COMMON_NEW" : "COMMON_EDIT";
  }

  initForm(): void {
    const asset = this.asset;
    this.form = this.fb.group({
      name: [asset?.name, Validators.required],
      typeId: [asset?.typeId, Validators.required],
      costAccountNumber: asset?.costAccountNumber,
      manufacturerId: asset?.manufacturerId,
      modelName: [asset?.modelName, Validators.required],
      modelNumber: asset?.modelNumber,
      serial: asset?.serial,
      placement: asset?.placement,
      purchaseDate: asset?.purchaseDate,
      installationDate: asset?.installationDate,
      warrantyExpiryDate: asset?.warrantyExpiryDate,
      lastServiceDate: asset?.lastServiceDate,
      refrigerantAmount: asset?.refrigerantAmount,
      isScrapped: asset?.isScrapped ?? false,
      scrappingDate: asset?.scrappingDate,
      width: asset?.width,
      depth: asset?.depth,
      height: asset?.height,
      weight: asset?.weight,
      description: [asset?.description, Validators.required],
      installedBy: asset?.installedBy,
      deliveredBy: asset?.deliveredBy,
      inUse: asset?.inUse ?? true,
      outOfOrder: asset?.inUse ?? false,
      status: [asset?.status ?? AssetStatus.inUse, Validators.required],
      deleted: asset?.deleted ?? false,
    });
  }

  save(): void {
    if (this.form.invalid) {
      return;
    }
    this.inProgress = true;
    const data: Partial<Asset> = {
      ...this.form.getRawValue(),
      customerId: this.facilityStorageService.currentFacility?.customerId,
      facilityId: this.facilityStorageService.currentFacility?.id
    };

    this.assetService.createOrUpdate(this.asset, data)
      .pipe(finalize(() => this.inProgress = false))
      .subscribe(() => {
        this.form.markAsPristine();
        this.navigateBack();
      });
  }

  private navigateBack() {
    this.router.navigate(["assets"]).then(/* DO NOTHING */);
  }

  canSave(): boolean {
    return this.form != null && this.form.dirty && this.form.valid && !this.inProgress;
  }

  cancel() {
    this.navigateBack();
  }

  canDeactivateComponent(): boolean {
    return (this.form && this.form.dirty) ?? false;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onCrudTypeChange() {
    this.fetchAssetTypes();
  }

  fetchAssetTypes() {
    this.assetTypeService.getListByCustomerId(this.facility.customerId).subscribe(types => {
      this.assetTypes = types;
    })
  }

  openSelectType(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }

  getAssetTypeName(value: UUID): string {
    if (!value) {
      return "ASSET_TYPE_SELECT"
    }
    const type = this.assetTypes?.find(type => type.id === value);
    return type ? type?.name : "ASSET_TYPE_SELECT";
  }

  changeAssetType() {
    const selectedUuid = this.form.controls.typeId.value;
    const list = this.assetTypes?.map(type => ListItem.fromAssetType(type));
    this.dialogService.openSelectFromListDialog("ASSET_TYPE_SELECT", selectedUuid, list).subscribe(uuid => {
      if (uuid) {
        console.log(uuid);
        this.form.controls.typeId.setValue(uuid);
        console.log(this.form.controls.typeId.value);
      }
    });
  }
}
