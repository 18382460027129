import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Tenant, TenantType} from "../../domain/tenant/tenant";
import {Subscription} from "rxjs";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {EnvironmentService} from "../../core/environment-service";
import {Country} from "../../domain/language-country";
import {FormUtil} from "../../core/form-util";


interface AddressForm {
  street: FormControl<string>;
  state: FormControl<string>;
  postalCode: FormControl<string>;
  city: FormControl<string>;
  country: FormControl<string>;
}

interface TenantForm {
  name: FormControl<string>;
  organizationNumber: FormControl<string>;
  address: FormGroup<AddressForm>;
  contactPhone: FormControl<string>;
  contactEmail: FormControl<string>;
  isActive: FormControl<boolean>;
  tenantType: FormControl<string>;
}

@Component({
  selector: 'tenant-form',
  templateUrl: './tenant-form.component.html',
})
export class TenantFormComponent implements OnInit {
  protected readonly faSpinner = faSpinner;
  form: FormGroup<TenantForm>;
  countriesList: Country[] = [];
  subscription = new Subscription();
  tenantTypes: string[] = Object.keys(TenantType).map(key => TenantType[key]);

  @Input() isManagement = false;
  @Input() tenant: Tenant;
  @Output() tenantForm = new EventEmitter<FormGroup<TenantForm>>();

  constructor(private fb: FormBuilder, private environmentService: EnvironmentService) {
  }

  ngOnInit() {
    this.countriesList = this.environmentService.countries;
    this.initForm();
    this.form.valueChanges.subscribe(()=> {
      this.tenantForm.emit(this.form);
    })
  }

  initForm(): void {
    const tenant = this.tenant;
    this.form = this.fb.group({
      name: [tenant?.name, Validators.required],
      organizationNumber: [tenant?.organizationNumber, Validators.required],
      address: this.fb.group({
        street: [tenant?.address.street],
        state: [tenant?.address.state],
        postalCode: [tenant?.address.postalCode ?? ""],
        city: [tenant?.address.city ?? ""],
        country: [tenant?.address.country ?? this.environmentService.countryDefaultCode],
      }),
      contactPhone: [tenant?.contactPhone ?? "", FormUtil.validateInternational],
      contactEmail: [tenant?.contactEmail, Validators.email],
      isActive: [tenant?.isActive ?? true],
      tenantType: [tenant?.tenantType ?? "", Validators.required],
    });
  }
}
