import {Injectable} from "@angular/core";
import {ApiService} from "../../core/api-service";
import {map, Observable} from "rxjs";
import {ServiceCategory} from "./service-category";
import {UUID} from "../uuid";

@Injectable(
  {providedIn: 'root'}
)
export class ServiceCategoryService {
  apiPath = '/v1/ServiceCategories';

  constructor(private apiService: ApiService) {
  }

  public all(): Observable<[]> {
    return this.apiService.get(this.apiPath).pipe(map((resp: any) => {
      return resp.data.map((t: ServiceCategory) => new ServiceCategory(t));
    }));
  }

  public by(serviceCategory: UUID): Observable<ServiceCategory> {
    const url = `${this.apiPath}/${serviceCategory}`;
    return this.apiService.get(url).pipe(map((resp: any) => new ServiceCategory(resp.data)));
  }

  createOrUpdate(serviceCategory: ServiceCategory | null, data: ServiceCategory) {
    if (serviceCategory && serviceCategory.id) {
      const url = `${this.apiPath}/${serviceCategory.id}`;
      return this.apiService.patch(url, data);
    }
    return this.apiService.post(this.apiPath, data);
  }

  remove(serviceCategory: ServiceCategory | null) {
    const url = `${this.apiPath}/${serviceCategory?.id}`
    return this.apiService.delete(url);
  }
}
