import {ObjectMapperHelper} from "../core/object-mapper-helper";
import {UUID} from "./uuid";

export class Metadata {
  createdAt: Date;
  updatedAt: Date;
  createdBy: UUID;
  updatedBy: UUID;
  deletedAt: UUID;

  constructor(data: any) {
    const helper = ObjectMapperHelper.of(data);
    this.createdAt = helper.getDate("createdAt");
    this.updatedAt = helper.getDate("updatedAt");
    this.createdBy = helper.getUUID("createdBy");
    this.updatedBy = helper.getUUID("updatedBy");
    this.updatedBy = helper.getUUID("deletedAt");
  }
}
