import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {catchError, Subscription, switchMap} from "rxjs";
import {CanDeactivateComponent} from "../../../core/can-deactivate-guard";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {EnvironmentService} from "../../../core/environment-service";
import {LanguageCountry} from "../../../domain/language-country";
import {
  CreateIncident,
  CreateUpdateIncident,
  Incident,
  IncidentStatus,
  IncidentType
} from "../../../domain/incident/incident";
import {IncidentService} from "../../../domain/incident/incident-service";
import {UUID} from "../../../domain/uuid";
import {Facility} from "../../../domain/facility/facility";
import {Asset} from "src/app/domain/asset/asset";
import {FileData} from "../../../core/files-upload/file-data";
import {DialogService} from "../../../shared/dialogs/dialog-service";
import {SnackbarService} from "../../../shared/snackbar/snackbar-service";
import {DocumentTypeEnum} from "../../../domain/document/document";
import {DocumentService} from "../../../domain/document/document-service";
import {SilentError} from "../../../core/error/silent-error";
import {FacilityStorageService} from "../../../core/storage/facility-storage-service";

interface IncidentForm {
  description: FormControl<string>
}

export interface FirstFormGroup {
  title: FormControl<string>
  description: FormControl<string>
  type: FormControl<string>
}

export interface SecondFormGroup {
  serviceCatGroupId: FormControl<UUID | string>
  serviceCategoryId: FormControl<UUID | string>
  slaDurationId: FormControl<UUID | string>
  deadlineEndMinutes: FormControl<number>
  priorityLevelId: FormControl<UUID | string>
  serviceCatGroupName: FormControl<string>
  serviceCategoryName: FormControl<string>
  slaDurationName: FormControl<string>
  priorityLevelName: FormControl<string>
}

export interface thirdFormGroup {
  assets: FormArray
}

export interface forthFormGroup {
  contactName: FormControl<string>
  contactEmail: FormControl<string>
  contactPhone: FormControl<string>
}

export interface ExtendedSummaryIncident extends Partial<CreateIncident> {
  serviceCategoryName: string;
  serviceCatGroupName: string;
  slaDurationName: string;
  priorityLevelName: string;
}


@Component({
  templateUrl: './wizard-incident-main.component.html',
})
export class WizardIncidentMainComponent implements OnInit, CanDeactivateComponent, OnDestroy {
  protected readonly faSpinner = faSpinner;
  private incident: Incident;
  inProgress = false;
  form: FormGroup<IncidentForm>;
  countriesList: LanguageCountry[] = [];
  subscription = new Subscription();
  title = "COMMON_EDIT"
  facility: Facility | null;

  firstFormGroup: FormGroup<FirstFormGroup>;
  secondFormGroup: FormGroup<SecondFormGroup>;
  thirdFormGroup: FormGroup;
  fourthFormGroup: FormGroup<any>;
  isLinear = false;

  selectedAssets: Asset[] = [];
  allFiles: FileData[] = [];

  constructor(private fb: FormBuilder,
              private environmentService: EnvironmentService,
              private incidentService: IncidentService,
              private router: Router,
              private facilityStorageService: FacilityStorageService,
              private dialogService: DialogService,
              private documentService: DocumentService,
              private route: ActivatedRoute,
              private snackbarService: SnackbarService) {}

  ngOnInit() {
    this.countriesList = this.environmentService.langCountry;

    this.subscription.add(this.facilityStorageService.currentFacility$.subscribe(facility => {
      this.facility = facility;
    }))

    this.initForm();
  }

  initForm(): void {
    this.firstFormGroup = this.fb.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      type: ['', Validators.required],
    });

    this.secondFormGroup = this.fb.group({
      serviceCatGroupId: ['', Validators.required],
      serviceCatGroupName: [''],
      serviceCategoryId: ['', Validators.required],
      serviceCategoryName: [''],
      slaDurationId: ['', Validators.required],
      slaDurationName: [''],
      priorityLevelId: [''],
      priorityLevelName: [''],
      deadlineEndMinutes: [40, Validators.required],
    });

    this.thirdFormGroup = this.fb.group({
      assets: this.fb.array([])
    });

    this.fourthFormGroup = this.fb.group({
      contactName: ['', Validators.required],
      contactEmail: ['', Validators.required],
      contactPhone: ['', Validators.required],
    });
  }

  handleFileListChange(files: FileData[]): void {
    this.allFiles = files;
  }

  handleAssetListChange(assets: Asset[]): void {
    this.selectedAssets = assets;
  }


  getIncidentFromForm(): ExtendedSummaryIncident {
    return {
      facilityId: this.facility?.id as UUID,
      deadlineEndMinutes: this.secondFormGroup.get('deadlineEndMinutes').value,
      title: this.firstFormGroup.get('title').value,
      description: this.firstFormGroup.get('description').value,
      type: this.firstFormGroup.get('type').value as IncidentType,
      serviceCategoryId: this.secondFormGroup.get('serviceCategoryId').value as UUID,
      serviceCatGroupId: this.secondFormGroup.get('serviceCatGroupId').value as UUID,
      priorityLevelId: this.secondFormGroup.get('priorityLevelId').value as UUID,
      priorityLevelName: this.secondFormGroup.get('priorityLevelName').value as string,
      serviceCatGroupName: this.secondFormGroup.get('serviceCatGroupName').value as string,
      serviceCategoryName: this.secondFormGroup.get('serviceCategoryName').value as string,
      slaDurationName: this.secondFormGroup.get('slaDurationName').value as string,
      assetIds: this.selectedAssets.map((asset: Asset) => asset.id),
      contactName: this.fourthFormGroup.get('contactName').value,
      contactEmail: this.fourthFormGroup.get('contactEmail').value,
      contactPhone: this.fourthFormGroup.get('contactPhone').value
    }
  }

  async save(): Promise<void> {

    if (!(this.firstFormGroup.invalid || this.secondFormGroup.invalid || this.thirdFormGroup.invalid || this.fourthFormGroup)) {
      return;
    }

    this.inProgress = true;

    const incidentData: Partial<CreateUpdateIncident> = {
      id: this.incident?.id as UUID,
      facilityId: this.facility?.id as UUID,
      customerId: this.facility.customerId,
      deadlineEndMinutes: this.secondFormGroup.get('deadlineEndMinutes').value,
      title: this.firstFormGroup.get('title').value,
      description: this.firstFormGroup.get('description').value,
      type: this.firstFormGroup.get('type').value as IncidentType,
      status: IncidentStatus.New,
      serviceCategoryId: this.secondFormGroup.get('serviceCategoryId').value as UUID,
      serviceCatGroupId: this.secondFormGroup.get('serviceCatGroupId').value as UUID,
      priorityLevelId: this.secondFormGroup.get('priorityLevelId').value as UUID,
      slaDurationId: this.secondFormGroup.get('slaDurationId').value as UUID,
      assetIds: this.selectedAssets.map((asset: Asset) => asset.id),
      contactName: this.fourthFormGroup.get('contactName').value,
      contactEmail: this.fourthFormGroup.get('contactEmail').value,
      contactPhone: this.fourthFormGroup.get('contactPhone').value,
    }

    const isUpdate = !!this.incident?.id;

    const selectedMethod = isUpdate ?
      this.incidentService.update(incidentData, this.incident.id) :
      this.incidentService.create(incidentData);

    selectedMethod.pipe(
      switchMap((incident) =>
        this.documentService.uploadDocuments(
          this.allFiles,
          DocumentTypeEnum.IncidentAttachment,
          null,
          this.facility.customerId,
          null,
          incident.id,
        )),
      catchError(error => {
        this.inProgress = false;
        throw error;
      })).subscribe( resp => {
      this.allFiles = resp;
      this.inProgress = false;

      const fileNameOfFailed = resp.filter(file => file.requestFailed).map(file => file.file.name).join(", ");
      if (fileNameOfFailed.length > 0) {
        this.dialogService.errorDialog("INCIDENT_SAVED_BUT_ALL_DOCUMENTS_COULD_NOT_BE_STORED", false, "INCIDENT_SAVED");
        throw new SilentError(500, "Unable to save incident documents: " + fileNameOfFailed, "INCIDENT_SAVED_BUT_ALL_DOCUMENTS_COULD_NOT_BE_STORED");
      } else {
        this.snackbarService.success("INCIDENT_SAVED");
        this.navigateBack();
      }
    });
  }

  private navigateBack() {
    const url = 'events/incidents';
    this.router.navigate(["/" + url]).then(/* DO NOTHING */);
  }

  canSave(): boolean {
    return this.form != null && this.form.dirty && this.form.valid && !this.inProgress;
  }



  cancel() {
    this.navigateBack();
  }

  canDeactivateComponent(): boolean {
    return (this.form && this.form.dirty) ?? false;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
