<mat-drawer-container class="h-full">
  <mat-drawer #drawer class="w-[500px] max-w-[100%] px-4 py-2 color: white" mode="over" position="end"
              [disableClose]="inProgress || inProgressSideNav" (closedStart)="onDrawerClose()">
    <ng-container *ngIf="selectedFacilityGroup">
      <facility-group-edit [facilityGroup]="selectedFacilityGroup"
                           [isEditing]="editingGroup"
                           (inProgressChange)="onSidenavInProgress($event)"
                           (anyUpdateChange)="onFacilityUpdated()"
                           (closeChange)="onCloseSideNav()">
      </facility-group-edit>
    </ng-container>
  </mat-drawer>
  <mat-drawer-content>
    <div class="max-w-screen-2xl lg:px-16 md:p-4 py-2">
      <mat-card class="lg:p-6 mat-mdc-elevation-specific mat-elevation-z0"
                style="background-color: transparent !important;">
        <mat-card-header>
          <heading-h1 text="FACILITY_GROUPS"/>
          <hr class="h-0.5 mb-8 mt-1 dark:bg-gray-300">
        </mat-card-header>
        <mat-card-content cdkDropListGroup class="flex flex-nowrap overflow-x-auto">
          <div>
            <div class="grid grid-cols-[repeat(5,minmax(250px,1fr))] gap-1">
              @for (level of levels; track level; let last = $last) {
                <mat-card class="card-border mat-mdc-elevation-specific mat-elevation-z0"
                          style="background-color: transparent !important; min-width: 200px;">
                  <mat-card-title>
                    <div class="text-sm p-2 pl-4 font-bold">
                      {{ "FACILITY_GROUP_LEVEL" | translate }}&nbsp;{{ level + 1 }}
                    </div>
                  </mat-card-title>
                  <mat-card-content cdkDropList [cdkDropListData]="visibleGroups[level]"
                                    (cdkDropListDropped)="drop($event, level)" style="min-height: calc(100% - 48px)">
                    <div class="grid grid-cols-1 gap-1">
                      @for (item of visibleGroups[level]; track item) {
                        <mat-card (click)="openItemslvl(item, level)" cdkDrag
                                  [cdkDragDisabled]="selectedUuids[level] === item.id"
                                  [ngClass]="getSelectedClass(item, level)"
                                  class="card-border mat-mdc-elevation-specific mat-elevation-z0">
                          <mat-card-title>
                            <div class="flex justify-between">
                              <div class="text-sm p-2 pl-4 font-bold">{{ item.name }}</div>
                              <button mat-icon-button (click)="showGroup($event, item)">
                                <mat-icon>edit</mat-icon>
                              </button>
                            </div>
                          </mat-card-title>
                          <mat-card-content>
                            <div class="text-xs">{{ item.facilityUuids.length }} {{ 'FACILITIES' | translate }}</div>
                            <div class="text-xs">{{ item.userGroups.length }} {{ 'USER_GROUPS' | translate }}</div>
                          </mat-card-content>
                        </mat-card>
                      }
                      <mat-card (click)="create(level)" matRipple
                                class="card-border mat-mdc-elevation-specific mat-elevation-z0">
                        <mat-card-title>
                          <div class="flex justify-end p-1">
                            <mat-icon>add</mat-icon>
                          </div>
                        </mat-card-title>
                      </mat-card>
                    </div>
                  </mat-card-content>
                </mat-card>
              }
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </mat-drawer-content>
</mat-drawer-container>

