@if (users){
  <div class="flex flex-col md:flex-row justify-between mb-4 md:items-center">
    <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
      <mat-label>{{ 'COMMON_SEARCH' | translate }}</mat-label>
      <button *ngIf="searchCtrl.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
        <mat-icon>close</mat-icon>
      </button>
      <mat-icon matSuffix *ngIf="!searchCtrl.value">search</mat-icon>
      <input matInput [formControl]="searchCtrl">
    </mat-form-field>
    <div class="w-full flex justify-end">
      <button mat-flat-button color="primary" (click)="create()">{{ "COMMON_CREATE" | translate }}</button>
    </div>
  </div>

  <div>
    <table class="w-full hidden md:table table-fixed">
      <thead>
      <tr class="bg-gray-200">
        <th class="text-left px-2 py-4 flex flex-row cursor-pointer" (click)="toggleSort('userName')">
          <header-sort-icon name="username" [sortUtil]="paginator"></header-sort-icon>
          <span class="ml-1">{{ "USER_USERNAME" | translate }}</span>
        </th>
        <th class="text-left px-2 py-4 cursor-pointer" (click)="toggleSort('firstName')">
          <header-sort-icon name="first_name" [sortUtil]="paginator"></header-sort-icon>
          {{ "USER_FIRSTNAME" | translate }}
        </th>
        <th class="text-left px-2 py-4 cursor-pointer" (click)="toggleSort('lastName')">
          <header-sort-icon name="last_name" [sortUtil]="paginator"></header-sort-icon>
          {{ "USER_LASTNAME" | translate }}
        </th>
        <th class="w-24 text-left px-2 py-4">{{ "COMMON_STATUS" | translate }}</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let user of users" (click)="navigateToEdit(user)" enterSpacePress
          class="hover:bg-gray-100 cursor-pointer border-t-2" tabindex="0">
        <td class="text-left p-2">{{ user.userName }}</td>
        <td class="text-left p-2">{{ user.firstName }}</td>
        <td class="text-left p-2">{{ user.lastName }}</td>
        <td class="w-24 text-left p-2">
          <div [ngClass]="user.isActive ? 'status-icon-success' : 'status-icon-inactive'"
               class="inline-block px-2.5 py-1 rounded-xl text-center">
            {{ (user.isActive ? 'COMMON_ACTIVE' : 'COMMON_INACTIVE') | translate }}
          </div>
        </td>
      </tr>
      </tbody>
    </table>

    <ul class="md:hidden divide-y list-none">
      <li *ngFor="let user of users" class="hover:bg-gray-100 cursor-pointer p-2 list-none">
        <div (click)="navigateToEdit(user)" (keydown.space)="triggerClick($event)"
             (keydown.enter)="triggerClick($event)" tabindex="0" role="button">
          <div class="flex items-center justify-between space-x-2">
            <div class="flex flex-row">
              <div class="font-bold">{{ user.userName }}</div>
            </div>
            <div [ngClass]="user.isActive ? 'status-icon-success' : 'status-icon-inactive'"
                 class="inline-block px-2.5 py-1 rounded-xl">
              {{ (user.isActive ? 'TENANT_ACTIVE' : 'TENANT_INACTIVE') | translate }}
            </div>
          </div>
          <div>
            <div class="text-sm">{{ user.firstName }} {{ user.lastName }}</div>
          </div>
        </div>
      </li>
    </ul>

    <mat-paginator class="mt-2" showFirstLastButtons
                   [pageSizeOptions]="paginator.pageSizeOptions"
                   [pageSize]="paginator.pageSize"
                   [pageIndex]="paginator.pageIndex"
                   [length]="paginator.totalCount"
                   (page)="onPageChange($event)">
    </mat-paginator>
  </div>
}
