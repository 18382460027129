export class SortUtil<T> {
  sortOrder: "asc" | "desc" = "asc";
  sortName: keyof T;
  lang: string;
  sortingFuncs: Record<keyof T, (data: T) => string> | null = null;

  constructor(sortBy: keyof T, lang: string) {
    this.sortName = sortBy;
    this.lang = lang;
  }

  setLang(lang: string) {
    this.lang = lang;
  }

  static of<T>(sortBy: keyof T, lang: string) {
    return new SortUtil<T>(sortBy, lang);
  }

  isDesc(propName: string): boolean {
    return this.sortOrder === 'desc' && this.sortName === propName
  }

  isAsc(propName: string): boolean {
    return this.sortOrder === 'asc' && this.sortName === propName
  }

  isSortingBy(propName: string) {
    return this.sortName === propName;
  }

  addFunc(propName: keyof T, func: (data: T) => string) {
    if (!this.sortingFuncs) {
      this.sortingFuncs = {} as Record<keyof T, (data: T) => string>;
    }
    this.sortingFuncs[propName] = func;
    return this;
  }

  protected updateSorting(propName: keyof T) {
    this.sortOrder = this.sortName !== propName || this.sortOrder === 'desc' ? 'asc' : 'desc';
    this.sortName = propName;
  }

  sort(list: T[]) {
    list.sort((a, b) => {
      const aString = this.getValue(a);
      const bString = this.getValue(b);
      if (this.sortOrder === 'asc') {
        return (aString).localeCompare(bString, this.lang);
      }
      if (this.sortOrder === 'desc') {
        return (bString).localeCompare(aString, this.lang);
      }
      return 0;
    })
  }

  private getValue(data: T) {
    if (this.sortingFuncs && this.sortingFuncs[this.sortName]) {
      return this.sortingFuncs[this.sortName](data)
    }
    return data[this.sortName] as string ?? '';
  }
}
