import {Component, OnInit, ViewChild} from "@angular/core";
import {CdkDragDrop, moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";
import {FacilityGroup} from "../../domain/facility/facility-group";
import {UUID} from "../../domain/uuid";
import {MatSidenav} from "@angular/material/sidenav";
import {Facility} from "../../domain/facility/facility";
import {FacilityGroupService} from "../../domain/facility/facility.group-service";
import {CustomerService} from "../../domain/customer/customer-service";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {concatMap} from "rxjs";

@Component({
  templateUrl: 'facility-groups.component.html'
})
export class FacilityGroupsComponent implements OnInit {
  protected readonly faSpinner = faSpinner;
  inProgress = false;
  drawer: MatSidenav;
  original: FacilityGroup[] = [];
  levels = [0];
  visibleGroups: FacilityGroup[][] = []
  selectedUuids: UUID[] = [];
  maxLevel = 5;
  selectedFacilityGroup: FacilityGroup;
  facilities: Facility[];
  editingGroup = false;
  inProgressSideNav = false;
  facilityUpdated = false;

  @ViewChild('drawer') set matDrawer(drawer: MatSidenav) {
    this.drawer = drawer;
  }

  constructor(
    private customerService: CustomerService,
    private facilityGroupService: FacilityGroupService
  ) {
  }

  ngOnInit(): void {
    this.facilityGroupService.byCustomer(this.customerService.currentCustomer).subscribe(groups => {
      this.original = groups;
      this.reset(this.original);
    })
  }

  private reset(groups: FacilityGroup[]) {
    this.visibleGroups = [];
    this.visibleGroups[0] = [];
    groups.forEach(f => {
      return f.children = [];
    });
    groups.forEach(f => {
      if (f.hasParent()) {
        groups.find(g => g.id === f.parentId).children.push(f);
      }
    })
    groups.forEach(f => f.children.sort((a, b) => a.order - b.order));
    groups.filter(f => !f.hasParent()).forEach(f => {
      this.visibleGroups[0].push(f);
    });
    this.selectedUuids.forEach((selectedUuid, index) => {
      this.visibleGroups[index + 1] = groups.find(g => g.id === selectedUuid).children;
    })
  }

  drop(event: CdkDragDrop<FacilityGroup[]>, level: number) {
    if (event.previousContainer === event.container) {
      const facility = event.container.data[event.previousIndex]
      if (facility.id != null) {
        this.moveItem(facility.id, facility.parentId, event.currentIndex)
      }
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      return;
    }

    const newParentId = this.selectedUuids[level - 1] ? this.selectedUuids[level - 1] : null;
    const facility = event.previousContainer.data[event.previousIndex]
    if (facility.id != null) {
      this.moveItem(facility.id, newParentId, event.currentIndex)
    }
    transferArrayItem(
      event.previousContainer.data,
      event.container.data,
      event.previousIndex,
      event.currentIndex,
    );
  }

  private moveItem(facilityId: UUID, parentId: UUID, index: number) {
    this.facilityGroupService.move(facilityId, parentId, index).pipe(
      concatMap(() => this.facilityGroupService.byCustomer(this.customerService.currentCustomer))
    ).subscribe(groups => {
      this.original = groups;
      this.reset(this.original);
    });
  }

  openItemslvl(item: FacilityGroup, lvl: number) {
    const nexLevel = lvl + 1 === this.maxLevel ? this.maxLevel : lvl + 2;
    this.levels = Array.from({length: nexLevel}, (_, index) => index);
    this.selectedUuids = this.selectedUuids.slice(0, lvl);
    this.selectedUuids.push(item.id);
    this.visibleGroups = this.visibleGroups.slice(0, lvl + 1);
    this.visibleGroups.push(item.children ? [...item.children] : null);
  }

  create(level: number) {
    this.selectedFacilityGroup = FacilityGroup.of(this.customerService.currentCustomer, this.selectedUuids[level - 1]);
    this.editingGroup = true;
    this.drawer.open().then(/*DO NOTHING*/)
  }

  showGroup(event: Event, item: FacilityGroup) {
    event.preventDefault();
    event.stopPropagation();
    this.editingGroup = false;
    this.selectedFacilityGroup = item;
    this.drawer.open().then(/*DO NOTHING*/)
  }

  getSelectedClass(group_id: FacilityGroup, level: number): string {
    const selected = this.selectedUuids[level] === group_id.id
    const groupSelected = this.selectedFacilityGroup?.id === group_id.id
    if (groupSelected && selected) {
      return "card-selected-bg card-selected";
    }
    if (groupSelected) {
      return "card-selected-bg";
    }
    if (selected) {
      return "card-selected";
    }
    return "";
  }

  onCloseSideNav() {
    this.drawer.close().then(/*DO NOTHING*/);
  }

  onSidenavInProgress(inProgress: boolean): void {
    this.inProgressSideNav = inProgress;
  }

  onFacilityUpdated(): void {
    this.facilityUpdated = true;
  }

  onDrawerClose() {
    this.editingGroup = false;
    this.selectedFacilityGroup = null;

    if (!this.facilityUpdated) {
      return;
    }
    this.facilityUpdated = false;
    this.facilityGroupService.byCustomer(this.customerService.currentCustomer).subscribe(groups => {
      this.original = groups;
      this.reset(this.original);
    });
  }
}
