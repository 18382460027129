import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders, HttpResponse} from "@angular/common/http";
import {Credentials} from "../domain/credentials";
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  filter,
  firstValueFrom,
  map,
  Observable,
  of,
  switchMap,
  take,
  throwError
} from "rxjs";
import {ApiError} from "./error/api-error";
import {EnvironmentService} from "./environment-service";
import {DepulsifyFrontendError} from "../domain/depulsify-frontend-error";
import {CompanionToken} from "../auth/companion-token";
import {Authentication} from "../auth/authentication";
import {RespWrapper} from "../domain/resp-wrapper";
import {TokenStorageService, TokenUserData} from "./storage/token-storage-service";

@Injectable(
  {providedIn: 'root'}
)
export class ApiService {
  private fetchingToken = false;
  private tokenData: TokenUserData;

  constructor(private http: HttpClient,
              private environmentService: EnvironmentService,
              private tokenStorageService: TokenStorageService
  ) {

    tokenStorageService.token$.subscribe(tokenData => {
      this.tokenData = tokenData;
    })
  }

  login<T>(credentials: Credentials): Observable<T> {
    return this.requireNewTokenIfExpired().pipe(
      switchMap(headers =>
        this.http.post<T>(this.createUrl("/v1/auth/login"), JSON.stringify(credentials), {headers})
      )
    );
  }

  get<T>(url: string, urlParams: any = null): Observable<T> {
    return this.requireNewTokenIfExpired().pipe(
      switchMap(headers => this.http.get<T>(this.createUrl(url), {headers, params: urlParams}).pipe(
        catchError(resp => this.errorHandling(resp))
      )));
  }

  post<T>(url: string, body: any): Observable<T> {
    return this.requireNewTokenIfExpired().pipe(
      switchMap(headers => this.http.post<T>(this.createUrl(url), body, { headers }).pipe(
        catchError(resp => this.errorHandling(resp))
      )));
  }

  postFormData<T>(url: string, formData: FormData): Observable<T> {
    return this.requireNewTokenIfExpired().pipe(
      switchMap(_ => this.http.post<T>(this.createUrl(url), formData, {headers: this.headerWithContentTypeSetByBrowser()}).pipe(
        catchError(resp => this.errorHandling(resp)))));
  }

  private headerWithContentTypeSetByBrowser(): HttpHeaders {
    return new HttpHeaders()
      .append('Authorization', `Bearer ${this.environmentService.accessToken}`)
  }

  patch<T>(url: string, body: any): Observable<T> {
    return this.requireNewTokenIfExpired().pipe(
      switchMap(headers => this.http.patch<T>(this.createUrl(url), body, {headers}).pipe(
        catchError(resp => this.errorHandling(resp))
      )))
  }

  delete<T>(url: string): Observable<T> {
    return this.requireNewTokenIfExpired().pipe(
      switchMap(headers => this.http.delete<T>(this.createUrl(url), {headers}).pipe(
        catchError(resp => this.errorHandling(resp))
      )))
  }

  errorHandling(response: HttpResponse<any>): Observable<never> {
    const apiError = ApiError.fromErrorResponse(response);
    return throwError(() => apiError);
  }

  postToBackendUnlessLocal(error: any, environment: string): void {
    const accessToken = this.environmentService.companionToken;

    const body: DepulsifyFrontendError = {
      message: `${environment}: ${error.message}`,
      level: "ERROR",
      details: error.stack,
      customerId: accessToken?.customerId || "unknown",
      tenantId: accessToken?.tenantId || "unknown",
      userId: accessToken?.userId || "unknown",
      viewName: window.location.href,
      timestamp: null
    }

    if (!this.environmentService.isProduction()) {
      console.error("Not logging error to backend when not in production, would have posted:");
      console.error(body);
      return;
    }


    this.requireNewTokenIfExpired().subscribe(headers => {
      const post = this.http.post<void>(this.createUrl("/log"), body, {headers});
      firstValueFrom(post).then(/* DO NOTHING */)
    })
  }


  requireNewTokenIfExpired(): Observable<HttpHeaders> {

    // If not yet initialized, wait then call this method again
    if (!this.tokenStorageService.initialized$.value) {
      return this.tokenStorageService.token$.pipe(switchMap(_ => this.requireNewTokenIfExpired())
      )
    }


    // I access token has expired, refresh it
    if (this.tokenData?.authentication?.accessToken && CompanionToken.of(this.tokenData.authentication.accessToken).isExpired()) {

      // if token is being fetched, wait for it to complete and use the new access token
      if (this.fetchingToken) {
        return this.tokenStorageService.token$.pipe(
          filter(tokenUserData => tokenUserData.authentication.accessToken !== null),
          take(1),
          switchMap(tokenUserData => {
            return of(this.header(tokenUserData.authentication.accessToken))
          })
        );
      }

      // Start token fetch
      this.fetchingToken = true;

      return this.http.post<RespWrapper<Authentication>>(this.createUrl("/v1/auth/refresh-token"),
        {
          accessToken: this.tokenData.authentication.accessToken,
          refreshToken: this.tokenData.authentication.refreshToken
        }).pipe(
        switchMap((resp) => {

          const authentication = resp.data;
          if (!authentication || !authentication.accessToken) {
            throw new Error('No access token in response');
          }

          this.tokenStorageService.setToken(authentication)

          this.fetchingToken = false; // Reset token fetch status
          return of(this.header(authentication.accessToken));
        }),
        catchError(resp => this.errorHandling(resp)));
    }


    return of(this.header(this.tokenData?.authentication?.accessToken));
  }


  private header(token: string){
    return new HttpHeaders()
      .append('Authorization', `Bearer ${token}`)
      .append('Content-Type', 'application/json')
  }

  private createUrl(url: string) {
    return `${this.environmentService.apiEndpoint}${url}`;
  }
}
