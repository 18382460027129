import {HttpResponse} from "@angular/common/http";

export class ApiError {
  status: number;
  message: string;
  code: string;

  constructor(status: number, message: string, code: string) {
    this.status = status;
    this.message = message;
    this.code = code;
  }

  static fromErrorResponse(response: HttpResponse<any>): ApiError {

    const code = response["error"]?.["code"];


    if (code) {
      return new ApiError(response.status, "API_ERROR_" + code, "API_ERROR_SomethingWentWrong")
    }

    if (response.status === 400) {
      return new ApiError(response.status, "API_ERROR_BadRequest", "API_ERROR_BadRequest");
    }

    if (response.status === 404) {
      return new ApiError(response.status, "API_ERROR_ResourceNotFound", "API_ERROR_NotFound");
    }


    //
    // if (response.body && response.body.error) {
    //   message = response.body.error.message || response.body.error || 'Unknown error';
    // } else {
    //   message = response.statusText || 'Unknown error occurred';
    // }
    //
    //

    return new ApiError(response.status, "Unknown Error occurred", "API_ERROR_UNKNOWN");
  }

  isUnauthorized(): boolean {
    return this.status === 401;
  }
}
