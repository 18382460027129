<h2 mat-dialog-title class="text-xl">{{ title |translate }}</h2>
<div class="px-4">
  <mat-form-field class="w-full" appearance="outline">
    <mat-label>{{ 'COMMON_SEARCH' | translate }}</mat-label>
    <input matInput [formControl]="searchCtrl">
    <button mat-icon-button matSuffix *ngIf="searchCtrl.value" aria-label="Clear" (click)="clearSearch()">
      <mat-icon>close</mat-icon>
    </button>
    <mat-icon matSuffix *ngIf="!searchCtrl.value" class="p-0">search</mat-icon>
  </mat-form-field>
</div>

<mat-dialog-content *ngIf="filteredList" class="">
  <mat-action-list role="list">
    @for (item of filteredList; track item.uuid; let last = $last) {
      <button mat-list-item (click)="onSelect(item)" class="button-full-width">
        <div class="flex justify-between w-full">
          <span class="pt-[4px]">{{ item.name }}</span>
          <mat-icon *ngIf="isSelected(item)">checked></mat-icon>
        </div>
      </button>
      <mat-divider *ngIf="!last"></mat-divider>
    } @empty {
      <div class="p-2">{{ "COMMON_NO_ENTRIES" | translate }}</div>
    }
  </mat-action-list>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <div class="p-4">
    <button mat-stroked-button (click)="close()">{{ "COMMON_CLOSE" | translate }}</button>
  </div>
</mat-dialog-actions>
