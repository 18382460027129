<div class="max-w-screen-2xl lg:px-16 md:p-4 py-2">
  <mat-card class="lg:p-6 mat-mdc-elevation-specific mat-elevation-z0"
            style="background-color: transparent !important;">
    <mat-card-header class="flex items-center">
      <h1 style="margin-bottom: 4px">
        <span>{{ 'PROVIDERS' | translate }}&nbsp;&ndash;&nbsp;</span>
        <span *ngIf="title">{{ (title | translate).toLowerCase() }}</span>
      </h1>
    </mat-card-header>
    <hr class="h-0.5 mb-8 dark:bg-gray-300">
    <mat-spinner *ngIf="!form"></mat-spinner>

    <mat-card-content>
      <form *ngIf="form" [formGroup]="form" class="flex flex-wrap">
        <div class="grid grid:cols-1 xl:grid-cols-2 gap-4">
          <div class="grid grid:cols-1 gap-4">
            <mat-card class="card-border mat-mdc-elevation-specific mat-elevation-z0">
              <mat-card-title>
                <div class="text-sm p-2 pl-4 font-bold">{{ "Bass" }}</div>
              </mat-card-title>
              <mat-card-content>

                <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
                  <mat-label>{{"COMMON_NAME" | translate}}</mat-label>
                  <input matInput formControlName="name">
                </mat-form-field>

                <mat-form-field class="w-full md:p-2" appearance="outline">
                  <mat-label>{{"DESCRIPTION" | translate}}</mat-label>
                  <textarea rows="3" matInput formControlName="description"></textarea>
                </mat-form-field>

                <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
                  <mat-label>{{"COMMON_ORGANIZATION_NUMBER" | translate}}</mat-label>
                  <input matInput formControlName="organizationNumber">
                </mat-form-field>

                <ng-container formGroupName="address">
                  <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
                    <mat-label>{{"COMMON_ADDRESS" | translate}}</mat-label>
                    <input matInput formControlName="street">
                  </mat-form-field>

                  <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
                    <mat-label>{{"COMMON_CITY" | translate}}</mat-label>
                    <input matInput formControlName="city">
                  </mat-form-field>

                  <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
                    <mat-label>{{"COMMON_POSTAL_CODE" | translate}}</mat-label>
                    <input matInput formControlName="postalCode">
                  </mat-form-field>

                  <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
                    <mat-label>{{"COMMON_COUNTRY" | translate}}</mat-label>
                    <mat-select formControlName="country">
                      <mat-option *ngFor="let country of countriesList" [value]="country.code">
                        {{ country.label }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </ng-container>


                <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
                  <mat-label>{{ 'TENANT' | translate }}</mat-label>
                  <mat-select formControlName="tenantId">
                    <mat-option *ngFor="let tenant of tenants" [value]="tenant.id">
                      {{ tenant.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>



                <div class="w-full md:w-1/2 md:p-2">
                  <mat-slide-toggle color="primary" formControlName="isActive">
                    {{"COMMON_ACTIVE" | translate}}
                  </mat-slide-toggle>
                </div>


              </mat-card-content>
            </mat-card>
          </div>



          <div class="grid grid:cols-1 gap-4">
            <mat-card class="card-border mat-mdc-elevation-specific mat-elevation-z0">
              <mat-card-title>
                <div class="text-sm p-2 pl-4 font-bold">{{ "COMMON_INVOICE_ADDRESS" | translate }}</div>
              </mat-card-title>
              <mat-card-content>

                <ng-container formGroupName="invoiceAddress">
                  <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
                    <mat-label>{{"COMMON_ADDRESS" | translate}}</mat-label>
                    <input matInput formControlName="street">
                  </mat-form-field>

                  <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
                    <mat-label>{{"COMMON_CITY" | translate}}</mat-label>
                    <input matInput formControlName="city">
                  </mat-form-field>

                  <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
                    <mat-label>{{"COMMON_POSTAL_CODE" | translate}}</mat-label>
                    <input matInput formControlName="postalCode">
                  </mat-form-field>

                  <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
                    <mat-label>{{"COMMON_COUNTRY" | translate}}</mat-label>
                    <mat-select formControlName="country">
                      <mat-option *ngFor="let country of countriesList" [value]="country.code">
                        {{ country.label }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </ng-container>

                <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
                  <mat-label>{{"COMMON_INVOICE_ID" | translate}}</mat-label>
                  <input matInput formControlName="invoiceId">
                </mat-form-field>



              </mat-card-content>
            </mat-card>
          </div>

          <div class="grid grid:cols-1 gap-4">
            <mat-card class="card-border mat-mdc-elevation-specific mat-elevation-z0">
              <mat-card-title>
                <div class="text-sm p-2 pl-4 font-bold">{{ "COMMON_CONTACT_PERSON" | translate }}</div>
              </mat-card-title>
              <mat-card-content>

                <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
                  <mat-label>{{"COMMON_NAME" | translate}}</mat-label>
                  <input matInput formControlName="contactName">
                </mat-form-field>

                <mat-form-field class="w-full md:w-1/2 md:p-2" appearance="outline">
                  <mat-label>{{"COMMON_EMAIL" | translate}}</mat-label>
                  <input matInput formControlName="contactEmail">
                </mat-form-field>

                <div class="w-full md:w-1/2 md:p-2">
                  <phone-number [control]="form.controls.contactPhone"></phone-number>
                </div>

              </mat-card-content>
            </mat-card>
          </div>
        </div>

      </form>
    </mat-card-content>
    <mat-card-actions align="end" class="mt-4">
      <button type="button" mat-flat-button (click)="cancel()">{{ "COMMON_CANCEL" | translate }}</button>
      <button type="button" color="primary" class="ml-2" mat-flat-button [disabled]="!canSave()" (click)="save()">
        {{ (provider?.id ? "COMMON_UPDATE" : "COMMON_SAVE")| translate }}
        <fa-icon *ngIf="inProgress" [icon]="faSpinner" animation="spin" class="ml-1"/>
      </button>
    </mat-card-actions>
  </mat-card>
</div>
