import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {Router} from "@angular/router";
import {FormControl} from "@angular/forms";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {Paginator} from "../../core/paginator";
import {EventUtil} from "../../core/event-util";
import {combineLatest, debounceTime, startWith, Subject, Subscription, switchMap} from "rxjs";
import {LanguageService} from "../../core/language-service";
import {Asset} from "../../domain/asset/asset";
import {AssetService} from "../../domain/asset/asset-service";
import {FacilityStorageService} from "../../core/storage/facility-storage-service";

@Component({
  templateUrl: './assets.component.html',
})
export class AssetsComponent implements OnInit, OnDestroy {
  triggerClick = EventUtil.triggerClick;
  protected readonly faSpinner = faSpinner;
  searchCtrl = new FormControl();
  assets: Asset[] | null = null;
  assetsEmpty: Asset[] | null = null;
  sub = new Subscription();
  paginator = new Paginator<Asset>("name");
  paginatorSub = new Subject<Paginator<Asset>>();
  paginator$ = this.paginatorSub.asObservable();

  @ViewChild(MatPaginator) set matPaginator(paginator: MatPaginator | null) {
    this.paginator.matPaginator = paginator;
    this.paginatorSub.next(this.paginator);
  }

  constructor(private router: Router,
              private assetService: AssetService,
              private languageService: LanguageService,
              private facilityStorageService: FacilityStorageService)
  {}

  ngOnInit() {
    this.paginator.setLang(this.languageService.currLanguage());

    const search$ = this.searchCtrl.valueChanges.pipe(startWith(""), debounceTime(300));
    this.sub.add(combineLatest([search$, this.paginator$])
      .pipe(switchMap(([term, paginator]) => this.assetService.byFacility(this.facilityStorageService.currentFacility, term, paginator)))
      .subscribe(paginatedUsers => {
        this.assets = paginatedUsers.items;
        this.paginator.setTotal(paginatedUsers.totalCount);
      }));
  }

  create() {
    this.router.navigate([`assets/new`]).then(/* DO NOTHING */);
  }

  navigateToEdit(asset: Asset) {
    this.router.navigate([`assets`, asset.id]).then(/* DO NOTHING */);
  }

  clearSearch() {
    this.searchCtrl.setValue('');
  }

  toggleSort(name: keyof Asset) {
    this.paginator.sortBy(name);
    this.paginatorSub.next(this.paginator);
  }

  onPageChange(event: PageEvent) {
    this.paginator.onPageChange(event);
    this.paginatorSub.next(this.paginator);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
