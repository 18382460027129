import {Injectable} from "@angular/core";
import {ApiService} from "../../core/api-service";
import {map, Observable} from "rxjs";
import {Paginated, RespPaginated, RespWrapper} from "../resp-wrapper";
import {Paginator} from "../../core/paginator";
import {UrlBuilder} from "../url-builder";

import {UUID} from "../uuid";
import {Booking} from "./booking";

@Injectable(
  {providedIn: 'root'}
)
export class BookingService {
  apiPath = '/v1/bookings';

  constructor(private apiService: ApiService) {}

  public all(searchTerm: string = null, facilityId: UUID, paginator: Paginator<Booking>): Observable<Paginated<Booking>> {
    const url = UrlBuilder.of(this.apiPath).addPagination(paginator).addSearch(searchTerm).add("facility_id", facilityId).toUrl();
    return this.apiService.get<RespPaginated<Booking>>(url)
      .pipe(map((resp) => Paginated.of<Booking>(resp, Booking)));
  }

  public byId(id: string): Observable<Booking>{
    const url = `${this.apiPath}/${id}`
    return this.apiService.get<RespWrapper<Booking>>(url).pipe(
      map( (resp)=> new Booking(resp.data)));
  }

  public create(booking: Partial<Booking>): Observable<Booking> {
    return this.apiService.post<RespWrapper<Booking>>(this.apiPath, booking).pipe(
      map((resp) => new Booking(resp.data)));
  }

  public update(booking: Partial<Booking>): Observable<Booking> {
    return this.apiService.patch<RespWrapper<Booking>>(`${this.apiPath}/${booking.id}`, booking).pipe(
      map((resp) => new Booking(resp.data)));
  }

  public delete(bookingId: UUID): Observable<void> {
    return this.apiService.delete<void>(`${this.apiPath}/${bookingId}`);
  }
}
