import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment";
import {StorageHelper} from "./storage-helper";
import {CompanionToken} from "../auth/companion-token";
import {Country, Language, LanguageCountry} from "../domain/language-country";
import {da, de, enGB, nb, sv} from "date-fns/locale";
import {Facility} from "../domain/facility/facility";
import {Customer} from "../domain/customer/customer";

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  private storage: StorageHelper;
  languageDefaultCode = "sv";
  countryDefaultCode = "SE";

  constructor() {
    this.storage = new StorageHelper(localStorage);
  }

  isProduction(): boolean {
    return environment.production;
  }

  get maps(): { apiKey: string; } {
    return environment.map;
  }

  get accessToken(): string | null {
    return this.storage.accessToken;
  }

  get refreshToken(): string | null {
    return this.storage.refreshToken;
  }

  get companionToken(): CompanionToken | null {
    return this.accessToken ? CompanionToken.of(this.accessToken) : null;
  }

  get userIdFromToken(): string | null {
    return this.accessToken ? CompanionToken.of(this.accessToken).userId : null;
  }

  get rememberedUsername(): string | null {
    return this.storage.rememberMe;
  }

  setRememberedUsername(username: string | null): void {
    this.storage.setRememberMe(username);
  }

  clearStorageFromUserData(): void {
    this.storage.removeFacility();
    this.storage.removeCustomer();
  }

  get language(): string | null {
    return this.storage.language;
  }

  setLang(lang: string | null): void {
    this.storage.setLang(lang);
  }

  get facility(): Facility | null {
    return this.storage.facility;
  }

  get customer(): Customer | null {
    return this.storage.customer;
  }


  setFacility(facility: Facility): void {
    this.storage.setFacility(facility);
  }

  setCustomer(customer: Customer): void {
    this.storage.setCustomer(customer);
  }

  get apiEndpoint(): string {
    return environment.apiUrl;
  }

  get languages(): Language[] {
    return this.langCountry.map(l => l.language).filter(l => l.active);
  }

  get countries(): Country[] {
    return this.langCountry.map(l => l.country).filter(c => c.active);
  }

  get langCountry(): LanguageCountry[] {
    return [
      {
        language: {code: "en", label: "English", locale: enGB, active: true},
        country: {code: null, label: null, active: false}
      },
      {
        language: {code: "sv", label: "Svenska", locale: sv, active: true},
        country: {code: "SE", label: "Sverige / Sweden", active: true}
      },
      {
        language: {code: "no", label: "Norsk", locale: nb, active: true},
        country: {code: "NO", label: "Norge / Norway", active: true}
      },
      {
        language: {code: "da", label: "Dansk", locale: da, active: false},
        country: {code: "DK", label: "Danmark / Denmark", active: true}
      },
      {
        language: {code: "de", label: "Deutsch", locale: de, active: true},
        country: {code: "DE", label: "Deutschland / Germany", active: true}
      },
    ]
  }

  getDateFormatFrom(language: string): string {
    return this.dateFormatMap[language] || 'yyyy-MM-dd HH:mm:ss';
  }

  private get dateFormatMap(): { [key: string]: string } {
    return {
      "en": 'MM/dd/yyyy HH:mm:ss',
      "sv": 'yyyy-MM-dd HH:mm:ss',
      "da": 'dd/MM/yyyy HH:mm:ss',
      "de": 'dd.MM.yyyy HH:mm:ss',
      "no": 'dd.MM.yyyy HH:mm:ss',
    }
  }


  get languageDefault(): { code: string, label: string; } {
    return this.langCountry.find(l => l.language.code === this.languageDefaultCode).language!;
  }
}
