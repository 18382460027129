import {Component, Inject, OnDestroy, ViewChild} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Booking, BookingStatus} from "../../../domain/booking/booking";
import {ProviderService} from "../../../domain/provider/provider-service";
import {Provider} from "../../../domain/provider/provider";
import {EventUtil} from "../../../core/event-util";
import {Paginator} from "../../../core/paginator";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {
  combineLatest,
  debounceTime,
  finalize,
  firstValueFrom, map,
  Observable,
  of,
  startWith,
  Subject,
  Subscription,
  switchMap
} from "rxjs";
import {FormControl, FormGroup} from "@angular/forms";
import {LanguageService} from "../../../core/language-service";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {UUID} from "../../../domain/uuid";
import {BookingForm, BookingFormComponent} from "../../../pages/bookings/booking-form.component";
import {BookingService} from "../../../domain/booking/booking-service";
import {SnackbarService} from "../../snackbar/snackbar-service";
import {Paginated} from "../../../domain/resp-wrapper";
import {MatStepper} from "@angular/material/stepper";

@Component({
  selector: 'dialog-booking',
  templateUrl: './booking-dialog.component.html',
})
export class BookingDialogComponent implements OnDestroy {
  @ViewChild(BookingFormComponent) bookingFormComponent: BookingFormComponent;
  @ViewChild('stepper') stepper: MatStepper;
  triggerClick = EventUtil.triggerClick;
  protected readonly faSpinner = faSpinner;
  booking?: Booking;
  providers: Provider[];
  searchCtrl = new FormControl();
  search$: Observable<string>

  form: FormGroup<BookingForm>;
  selectedProvider?: Provider;

  sub = new Subscription();
  paginator = new Paginator<Provider>();
  paginatorSub = new Subject<Paginator<Provider>>();
  paginator$ = this.paginatorSub.asObservable();

  init: boolean = false
  inProgress = true;
  isSaving: boolean = false;

  selectedIndex: number = 0;
  @ViewChild(MatPaginator) set matPaginator(paginator: MatPaginator | null) {
    if (!this.init) {
      this.paginator.matPaginator = paginator;
      this.paginatorSub.next(this.paginator);
      this.init = true;
    }
  }

  constructor(
    private dialogRef: MatDialogRef<BookingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: Booking,
    private languageService: LanguageService,
    private providerService: ProviderService,
    private bookingService: BookingService,
    private snackbarService: SnackbarService,
  ) {

    this.booking = this.data
    this.sub.add(combineLatest(
      [this.fetchSingleProvider(),
        this.fetchProvidersList()])
      .subscribe(([provider, resp]) => {
        this.providers = resp.items
        this.paginator.setTotal(resp.totalCount);
        this.inProgress = false;
      }))
  }


  fetchSingleProvider(): Observable<Provider> {
    if (!this.booking?.providerId) {
      return of(null)
    }
    return this.providerService.by(this.booking.providerId).pipe(map(provider => {
      this.selectedIndex = provider ? 1 : 0;
      this.selectedProvider = provider;
      return provider;
    }))
  }

  fetchProvidersList(): Observable<Paginated<Provider>> {
    this.paginator.setLang(this.languageService.currLanguage());
    const search$ = this.searchCtrl.valueChanges.pipe(startWith(""), debounceTime(300));

    return combineLatest([search$, this.paginator$])
      .pipe(switchMap(([searchTerm, paginator]) =>
        this.providerService.all(searchTerm, paginator)))
  }

  onClose(): void {
    this.dialogRef.close(this.booking);
  }

  selectProvider(provider: Provider) {
    this.selectedProvider = provider
    this.bookingFormComponent.markFormAsDirty();
    this.stepper.next();
  }

  onPageChange(event: PageEvent) {
    this.paginator.onPageChange(event);
    this.paginatorSub.next(this.paginator);
  }

  clearSearch() {
    this.searchCtrl.setValue('');
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  async save(): Promise<void> {
    if (this.form.invalid) {
      return;
    }
    this.isSaving = true;
    const isUpdate = !!this.booking?.id;

    const bookingData: Partial<Booking> = {
      ...this.form.getRawValue(),
      id: this.booking?.id,
      status: this.form.controls.status.value as BookingStatus,
      customerId: this.booking?.customerId as UUID,
      facilityId: this.booking?.facilityId as UUID,
      incidentId: this.booking?.incidentId,
      providerId: this.selectedProvider.id
    }

    const selectedMethod$ = isUpdate
      ? this.bookingService.update(bookingData)
      : this.bookingService.create(bookingData);

    this.booking = await firstValueFrom(selectedMethod$.pipe(finalize(() => this.isSaving = false)));

    this.form.markAsPristine();
    this.snackbarService.success(isUpdate ? "BOOKING_UPDATE_SUCCESS" : "BOOKING_CREATE_SUCCESS");
    this.onClose();
  }
  onBookingFormChange(formGroup: FormGroup<BookingForm>) {
    this.form = formGroup;
  }

  canSave(): boolean {
    return this.form != null && this.form.dirty && this.form.valid && !this.inProgress && !this.isSaving && !!this.selectedProvider;
  }
}
