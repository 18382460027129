<div class="p-4 h-4/5">
  <div class="flex">
    <button mat-icon-button (click)="navigateBack()">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <div class="mt-2 ml-2">
      <h1 class="text-2xl">{{ crudConfig!.title | translate }}</h1>
    </div>
  </div>
  <div class="pt-8 pl-8 text-sm">{{ "COMMON_NAME" | translate }}</div>
  <div class="px-4">
    <hr class="h-px px-8 color-border-primary">
  </div>
  <mat-dialog-content>
    <mat-action-list role="list">
      <mat-spinner *ngIf="inProgress"></mat-spinner>
      <ng-container *ngIf="!inProgress">
        <div *ngFor="let row of crudList; let last = last;">
          <button mat-list-item (click)="selectRow(row)">
            {{ getTitle(row) }}
          </button>
          <mat-divider *ngIf="!last"></mat-divider>
        </div>
      </ng-container>
    </mat-action-list>
  </mat-dialog-content>
  <div class="fab-button">
    <a mat-fab [routerLink]="[]" (click)="add($event)">
      <mat-icon>add</mat-icon>
    </a>
  </div>
</div>
