<h1 mat-dialog-title>{{ "BOOKING" | translate }}</h1>

<mat-spinner *ngIf="inProgress" />
<ng-container *ngIf="!inProgress">
  <mat-dialog-content>
    <mat-stepper [linear]="true" [animationDuration]="'500'" [selectedIndex]="selectedIndex"  orientation="horizontal" #stepper>
      <mat-step>
        <ng-template matStepLabel><span class="text-xs">{{ 'PROVIDER' | translate }}</span></ng-template>

        <div class="flex flex-col md:flex-row justify-between md:items-center">
          <mat-form-field class="w-full md:p-2" appearance="outline">
            <mat-label>{{ 'COMMON_SEARCH' | translate }}</mat-label>
            <button *ngIf="searchCtrl.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
              <mat-icon>close</mat-icon>
            </button>
            <mat-icon matSuffix *ngIf="!searchCtrl.value">search</mat-icon>
            <input matInput [formControl]="searchCtrl">
          </mat-form-field>
        </div>
        <ul class="divide-y list-none">
          <li *ngFor="let provider of providers" class="hover:bg-gray-100 cursor-pointer p-2 list-none">
            <div class="flex" (click)="selectProvider(provider)" (keydown.space)="triggerClick($event)"
                 (keydown.enter)="triggerClick($event)" tabindex="0" role="button">
              <div>
                <div class="mb-1">
                  <span class="text-base font-bold">{{ provider.name }}</span>
                  <span class="text-xs ml-2">({{ provider.address.city }})</span>
                </div>
                <span class="text-base">{{ provider.description }}</span>
              </div>
              <div *ngIf="provider.id == selectedProvider?.id">
                <mat-icon>checked</mat-icon>
              </div>
            </div>
          </li>
        </ul>

        <mat-paginator class="mt-2" showFirstLastButtons
                       [pageSizeOptions]="paginator.pageSizeOptions"
                       [pageSize]="paginator.pageSize"
                       [pageIndex]="paginator.pageIndex"
                       [length]="paginator.totalCount"
                       (page)="onPageChange($event)">
        </mat-paginator>
      </mat-step>

      <mat-step>
        <ng-template matStepLabel><span class="text-xs">{{ 'BASS' | translate }}</span></ng-template>
        <div *ngIf="selectedProvider" class="flex justify-between mb-10">
          <mat-card class="w-full">
            <mat-card-content class="bg-gray-200">
              <div>
                <div class="mb-1 mt-2">
                  <span class="text-base font-bold">{{ selectedProvider.name }}</span>
                  <span class="text-xs ml-2">({{ selectedProvider.address.city }})</span>
                </div>
                <span class="text-base">{{ selectedProvider.description }}</span>
              </div>
            </mat-card-content>
          </mat-card>

        </div>
        <booking-form
          [booking]="booking"
          (bookingForm)="onBookingFormChange($event)"
        />
      </mat-step>
    </mat-stepper>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button type="button" mat-flat-button color="primary" (click)="onClose()">{{ "COMMON_CLOSE" | translate }}</button>
    <button type="button" mat-flat-button color="primary" (click)="save()" [disabled]="!canSave()">
      {{ (booking?.id ? "COMMON_UPDATE": "COMMON_SAVE" ) | translate }}
      <fa-icon *ngIf="isSaving" [icon]="faSpinner" animation="spin" class="ml-1"/>
    </button>
  </mat-dialog-actions>
</ng-container>

