import { Component } from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-forgot-password-dialog',
  templateUrl: './forgot-password-dialog.component.html',
  styleUrls: ['./forgot-password-dialog.component.scss']
})
export class ForgotPasswordDialogComponent {
  email: string = '';
  
  constructor(private dialogRef: MatDialogRef<ForgotPasswordDialogComponent>) { }

  resetPassword() {
    console.log('Reset password for:', this.email);
    //TODO: Implement reset password agains the API (already in place in backend)
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }
}
